import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { endpoint } from '../http/endpoint';
import { SupportedPartsDTORuntype } from '../part/partBackendTypes';
import {
    SupplierDTORuntype,
    SupplierLineCardDTORuntype,
    SupplierLineCardPatchDTORuntype,
    SupplierLineCardRuleImportResponseRuntype,
    SupplierLineCardRulesImportDTORunType as SupplierLineCardRulesImportDTORuntype,
    SupplierPartType,
    SupplierType,
} from './supplierBackendTypes';

export const supplierEndpoints = {
    'GET /suppliers': endpoint({
        description: 'Returns all suppliers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(SupplierDTORuntype) }),
    }),

    'DELETE /suppliers/:id': endpoint({
        description: 'Deletes a supplier',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: [
            'GET /suppliers',
            'GET /suppliers-and-stock-locations',
            'POST /suppliers-and-stock-locations/bulk',
            'GET /organization-settings/organization-solution-preference',
            'GET /email-template',
        ],
    }),

    'POST /suppliers/import': endpoint({
        description: 'Imports suppliers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            items: r.Array(
                r.Record({
                    supplier_number: r.String,
                    name: r.String,
                    supplier_part_type: runtypeFromEnum(SupplierPartType),
                    supplier_type: runtypeFromEnum(SupplierType),
                }),
            ),
        }),
        responseBody: r.Record({
            items: r.Array(
                r.Record({
                    success: r.Boolean,
                    error_code: r
                        .Record({
                            code: r.String,
                            message: r.String,
                        })
                        .optional(),
                }),
            ),
        }),
        invalidates: ['GET /email-template'],
    }),

    'POST /suppliers/supported-parts': endpoint({
        description: 'Returns a list of supported parts given the supplier IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            rfq_id: r.String,
            supplier_ids: r.Array(r.String),
            part_ids: r.Array(r.String),
        }),
        responseBody: r.Record({ items: r.Array(SupportedPartsDTORuntype) }),
    }),

    'GET /suppliers/line-card': endpoint({
        description: 'Returns the supplier line cards for multiple suppliers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(SupplierLineCardDTORuntype),
    }),

    'GET /suppliers/line-card-rules': endpoint({
        description: 'Returns the supplier line cards for multiple suppliers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(SupplierLineCardDTORuntype),
    }),

    'POST /suppliers/line-card-rules/import': endpoint({
        description: 'Import the supplier line card rules',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SupplierLineCardRulesImportDTORuntype,
        responseBody: SupplierLineCardRuleImportResponseRuntype,
        invalidates: ['GET /suppliers/line-card-rules'],
    }),

    'GET /suppliers/:id/line-card': endpoint({
        description: 'Returns the supplier line card',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: SupplierLineCardDTORuntype,
    }),

    'GET /suppliers/:id/line-card-rules': endpoint({
        description: 'Returns the supplier line card rules',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: SupplierLineCardDTORuntype,
    }),

    'PATCH /suppliers/:id/line-card': endpoint({
        description: 'Returns the supplier line card',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: SupplierLineCardPatchDTORuntype,
        responseBody: SupplierLineCardDTORuntype,
        invalidates: ['GET /suppliers/line-card', 'GET /suppliers/:id/line-card', 'GET /suppliers/:id/line-card-rules'],
    }),

    'PATCH /suppliers/:id/line-card-rules': endpoint({
        description: 'Updates the approved vendor list',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: SupplierLineCardPatchDTORuntype,
        responseBody: SupplierLineCardDTORuntype,
        invalidates: ['GET /suppliers/:id/line-card-rules', 'GET /suppliers/:id/line-card', 'GET /suppliers/line-card'],
    }),
};
