import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface VenkelCredentialsDTO extends r.Static<typeof VenkelCredentialsDTORuntype> {}
export const VenkelCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface VenkelCredentialsInputDTO extends r.Static<typeof VenkelCredentialsInputDTORuntype> {}
export const VenkelCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface VenkelResponseBodyDTO extends r.Static<typeof VenkelResponseBodyDTORuntype> {}
export const VenkelResponseBodyDTORuntype = r.Record({
    data: VenkelCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
