import { extractFileNameFromUrl, isPresent } from '@luminovo/commons';
import {
    CustomerDTO,
    PriceBreakInputDTO,
    QuantityUnitDTO,
    RfqContext,
    RfqListItemDTO,
    StandardPartOfferDTO,
    StandardPartOfferInputDTO,
    StandardPartOfferLinkedPartType,
    StandardPartOfferLinkedPartTypeRunType,
    StandardPartOfferUpdateDTO,
} from '@luminovo/http-client';
import { parseSpn } from '@luminovo/sourcing-core';
import { AttachmentFile } from '../../../resources/http/useHttpFileUpload';
import { undefinedToNull } from '../../../utils/typingUtils';
import { PriceBreakInput, StandardPartOfferFormValues } from './StandardPartOfferForm';

function convertToPriceBreakInputDTO(priceBreaks: PriceBreakInput[]): PriceBreakInputDTO[] {
    return priceBreaks.map((priceBreak) => {
        return {
            moq: priceBreak.moq,
            mpq: priceBreak.mpq,
            unit_price: priceBreak.unitPrice,
        };
    });
}

function createUnitOfMeasurement(values: StandardPartOfferFormValues): QuantityUnitDTO {
    return { unit: values.quantityUnit, quantity: values.quantity };
}

export function convertPriceBreaks(
    priceBreaks: StandardPartOfferDTO['available_prices']['price_breaks'],
): StandardPartOfferFormValues['priceBreaks'] {
    return priceBreaks.map((priceBreak) => {
        return {
            moq: priceBreak.moq,
            mpq: priceBreak.mpq,
            unitPrice: parseFloat(priceBreak.unit_price.amount),
        };
    });
}

export function convertValidForRfq(
    value: string | null,
    options: Array<RfqListItemDTO>,
    defaultData: RfqListItemDTO | null,
) {
    if (!isPresent(value)) {
        return {
            enabled: false,
            data: defaultData,
        };
    }
    const found = options.find((option) => option.id === value);
    if (!isPresent(found)) {
        return {
            enabled: false,
            data: defaultData,
        };
    }
    return {
        enabled: true,
        data: found,
    };
}

export function convertValidForCustomer(
    value: string | null,
    options: Array<CustomerDTO>,
    defaultData: CustomerDTO | null,
) {
    if (!isPresent(value)) {
        return {
            enabled: false,
            data: defaultData,
        };
    }
    const found = options.find((option) => option.id === value);
    if (!isPresent(found)) {
        return {
            enabled: false,
            data: defaultData,
        };
    }
    return {
        enabled: true,
        data: found,
    };
}

function extractValidFor(value: { enabled: boolean; data: RfqListItemDTO | CustomerDTO | null }) {
    if (!value.enabled || !isPresent(value.data)) {
        return null;
    }
    return value.data.id;
}

export function convertToStandardPartOfferUpdateDTO(values: StandardPartOfferFormValues): StandardPartOfferUpdateDTO {
    return {
        supplier_part_number: parseSpn(values.supplierPartNumber),
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        price_type: values.priceType,
        customer: extractValidFor(values.validForCustomer),
        rfq: extractValidFor(values.validForRfq),
        notes: undefinedToNull(values.notes),
        valid_until: undefinedToNull(values.validUntilDate),
        valid_from: undefinedToNull(values.validFromDate),
        item_class: undefinedToNull(values.itemClass),
        cancellation_window_in_days: undefinedToNull(values.cancellationWindowInDays),
        currency: values.currency,
        availability_input: {
            stock: undefinedToNull(values.stock),
            factory_lead_time_days: undefinedToNull(values.factoryLeadTime),
            factory_quantity: undefinedToNull(values.factoryQuantity),
            on_order: values.onOrder.map((onOrder) => {
                return {
                    quantity: onOrder.quantity,
                    date: undefinedToNull(onOrder.date),
                };
            }),
        },
        price_break_inputs: convertToPriceBreakInputDTO(values.priceBreaks),
        one_time_costs: values.oneTimeCosts,
        packaging: undefinedToNull(values.packaging),
        unit_of_measurement: createUnitOfMeasurement(values),
        offer_number: undefinedToNull(values.offerNumber),
        ncnr: undefinedToNull(values.ncnr),
    };
}

export function convertToStandardPartOfferInputDTO(values: StandardPartOfferFormValues): StandardPartOfferInputDTO {
    return {
        supplier_part_number: parseSpn(values.supplierPartNumber),
        linked_part: values.linkedPart,
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        price_type: values.priceType,
        customer: extractValidFor(values.validForCustomer),
        rfq: extractValidFor(values.validForRfq),
        notes: undefinedToNull(values.notes),
        valid_until: undefinedToNull(values.validUntilDate),
        valid_from: undefinedToNull(values.validFromDate),
        item_class: undefinedToNull(values.itemClass),
        cancellation_window_in_days: undefinedToNull(values.cancellationWindowInDays),
        currency: values.currency,
        availability_input: {
            stock: undefinedToNull(values.stock),
            factory_lead_time_days: undefinedToNull(values.factoryLeadTime),
            factory_quantity: undefinedToNull(values.factoryQuantity),
            on_order: values.onOrder,
        },
        price_break_inputs: convertToPriceBreakInputDTO(values.priceBreaks),
        one_time_costs: values.oneTimeCosts,
        packaging: undefinedToNull(values.packaging),
        unit_of_measurement: createUnitOfMeasurement(values),
        offer_number: undefinedToNull(values.offerNumber),
        ncnr: undefinedToNull(values.ncnr),
    };
}

export function convertAzureFilesToUploadFiles(files: string[]): AttachmentFile[] {
    return files.map((file) => ({
        file: null,
        state: 'StoredInAzure',
        name: extractFileNameFromUrl(file),
        url: file,
    }));
}

export function extractRfqIdFromContext(rfqContext: RfqContext): string | undefined {
    return rfqContext.type === 'WithinRfQ' ? rfqContext.rfq_id : undefined;
}

export function extractRfqContextFromRfqId(rfqId?: string | null): RfqContext {
    return rfqId ? { type: 'WithinRfQ', rfq_id: rfqId } : { type: 'OutsideRfQ' };
}

export function extractStandardPartTypeFromString(partType: string): StandardPartOfferLinkedPartType {
    return StandardPartOfferLinkedPartTypeRunType.check(partType);
}
