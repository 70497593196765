import { t } from '@lingui/macro';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FieldTextControlled,
    Flexbox,
    TertiaryIconButton,
} from '@luminovo/design-system';
import { Edit as EditIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

interface EditNotesValues {
    notes: string;
}

export const useEditExpenseConfigurationNotes = () => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({
            expenseConfigurationId,
            expenseConfigurationNotes,
        }: {
            expenseConfigurationId: string;
            expenseConfigurationNotes: string | undefined;
        }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Edit note`} handleClose={() => closeDialog()} />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <EditNotesFormInner
                                expenseConfigurationId={expenseConfigurationId}
                                expenseConfigurationNotes={expenseConfigurationNotes ?? ''}
                                closeDialog={closeDialog}
                            />
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
    };
};

function FormItemNotes() {
    const { control } = useFormContext<EditNotesValues>();
    return (
        <FieldTextControlled
            control={control}
            name="notes"
            FieldProps={{
                multiline: true,
                rows: 8,
            }}
        />
    );
}

export const EditNotesFormInner = ({
    expenseConfigurationId,
    expenseConfigurationNotes,
    closeDialog,
}: {
    expenseConfigurationId: string;
    expenseConfigurationNotes: string;
    closeDialog: () => void;
}) => {
    const defaultValues: EditNotesValues = {
        notes: expenseConfigurationNotes,
    };
    const { mutateAsync } = useHttpMutation('PATCH /expense-configurations/:expenseConfigurationId/notes', {
        snackbarMessage: t`Notes updated`,
        onSuccess: () => {
            closeDialog();
        },
    });

    const onSubmit = async (data: EditNotesValues) => {
        await mutateAsync({
            pathParams: { expenseConfigurationId },
            queryParams: {},

            requestBody: { notes: data.notes },
        });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <FormItemNotes />
            <Box mt={4} mb={2}>
                <Flexbox justifyContent={'flex-end'}>
                    <SubmitButton />
                </Flexbox>
            </Box>
        </FormContainer>
    );
};

export const EditExpenseConfigurationNotes = ({
    expenseConfigurationId,
    notes,
}: {
    expenseConfigurationId: string;
    notes: string | undefined;
}): JSX.Element => {
    const { openDialog } = useEditExpenseConfigurationNotes();

    return (
        <>
            <TertiaryIconButton
                onClick={() => openDialog({ expenseConfigurationId, expenseConfigurationNotes: notes })}
            >
                <EditIcon fontSize="inherit" />
            </TertiaryIconButton>
        </>
    );
};
