import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { PackagingRuntype } from '../offer';
import { PackageMountingEnum } from '../part';
import { PnpSideRuntype } from '../pnp';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import {
    AttributeReferenceRuntype,
    ConditionValuesRuntype,
    EnumFilterOperatorRuntype,
    MonetaryAttributeRuntype,
    NumberAttributeRuntype,
    NumberFilterOperatorRuntype,
    OfferTypeRuntype,
    PartTypeRuntype,
} from './driverCalculationBackendTypes';

export enum DriverDetailsType {
    Automatic = 'Automatic',
    Manual = 'Manual',
}

export enum DriverOrigin {
    Bom = 'Bom',
    Sourcing = 'Sourcing',
    Pnp = 'Pnp',
}

const FilterFormulaRuntype = r.Union(r.Literal('Enum'), r.Literal('Number'), r.Literal('AnyOf'), r.Literal('Monetary'));
export type FilterFormulaType = r.Static<typeof FilterFormulaRuntype>;

const AggregationTypeRuntype = r.Union(r.Literal('Count'), r.Literal('Sum'));
export type AggregationType = r.Static<typeof AggregationTypeRuntype>;

const ManualRuntype = r.Record({
    type: r.Literal(DriverDetailsType.Manual),
    automatic_details: r.Null.optional(),
});

const PackageFamilyRuntype = r.Record({
    type: r.Literal('PackageFamily'),
    value: r.Record({
        mounting: runtypeFromEnum(PackageMountingEnum),
        name: r.String,
    }),
});

const MountingOnlyRuntype = r.Record({
    type: r.Literal('MountingOnly'),
    value: runtypeFromEnum(PackageMountingEnum),
});

const MountingPackageRuntype = r.Union(PackageFamilyRuntype, MountingOnlyRuntype);

export type MountingPackageRhs = r.Static<typeof MountingPackageRuntype>;

const SidesOfPlacementRhsRuntype = r.Record({
    attribute_reference: r.Literal('PnpSide'),
    value: PnpSideRuntype,
});

const OfferTypeRhsRuntype = r.Record({
    attribute_reference: r.Literal('OfferType'),
    value: OfferTypeRuntype,
});

const CustomPartTypeRhsRuntype = r.Record({
    attribute_reference: r.Literal('CustomPartType'),
    value: PartTypeRuntype,
});

const PackageRhsRuntype = r.Record({
    attribute_reference: r.Literal('Package'),
    value: MountingPackageRuntype,
});

const PackagingRhsRuntype = r.Record({
    attribute_reference: r.Literal('Packaging'),
    value: PackagingRuntype,
});

const EnumDriverFilterPropertiesRuntype = r.Record({
    type: r.Literal('Enum'),
    filter: r.Record({
        operator: EnumFilterOperatorRuntype,
        rhs: SidesOfPlacementRhsRuntype.Or(OfferTypeRhsRuntype)
            .Or(CustomPartTypeRhsRuntype)
            .Or(PackageRhsRuntype)
            .Or(PackagingRhsRuntype),
    }),
});

const NumberDriverFilterPropertiesRuntype = r.Record({
    type: r.Literal('Number'),
    filter: r.Record({
        operator: NumberFilterOperatorRuntype,
        attribute: NumberAttributeRuntype,
        rhs: r.String,
    }),
});

const AnyOfAttributeRhsRuntype = r.Record({
    attribute_reference: AttributeReferenceRuntype,
    value: MountingPackageRuntype,
});

const MonetaryDriverFilterPropertiesRuntype = r.Record({
    type: r.Literal('Monetary'),
    filter: r.Record({
        operator: NumberFilterOperatorRuntype,
        attribute: MonetaryAttributeRuntype,
        rhs: MonetaryValueBackendRuntype,
    }),
});
export interface AnyOfAttributeRhsDTO extends r.Static<typeof AnyOfAttributeRhsRuntype> {}

const AnyofDriverFilterPropertiesRuntype = r.Record({
    type: r.Literal('AnyOf'),
    filter: r.Record({
        operator: EnumFilterOperatorRuntype,
        rhs: r.Array(AnyOfAttributeRhsRuntype),
    }),
});

export type EnumDriverFilterProperties = r.Static<typeof EnumDriverFilterPropertiesRuntype>;
export interface NumberDriverFilterProperties extends r.Static<typeof NumberDriverFilterPropertiesRuntype> {}
export interface AnyOfDriverFilterProperties extends r.Static<typeof AnyofDriverFilterPropertiesRuntype> {}
export interface MonetaryDriverFilterProperties extends r.Static<typeof MonetaryDriverFilterPropertiesRuntype> {}

const FilterFormulaPropertiesRuntype = r.Union(
    EnumDriverFilterPropertiesRuntype,
    NumberDriverFilterPropertiesRuntype,
    AnyofDriverFilterPropertiesRuntype,
    MonetaryDriverFilterPropertiesRuntype,
);
export type FilterFormulaPropertiesDTO = r.Static<typeof FilterFormulaPropertiesRuntype>;

const filterFormulaRuntype = r.Record({
    filter: FilterFormulaPropertiesRuntype,
    join_with_previous_using: ConditionValuesRuntype,
});

export interface DriverFilterDTO extends r.Static<typeof filterFormulaRuntype> {}

const CountAggregationValueRuntype = r.Union(
    r.Literal('UniqueParts'),
    r.Literal('DesignItems'),
    r.Literal('UniqueSuppliers'),
);
export type CountAggregationValue = r.Static<typeof CountAggregationValueRuntype>;

const CountAggregationRuntype = r.Record({
    type: r.Literal('Count'),
    value: CountAggregationValueRuntype,
});

const SumAggregationValueRuntype = r.Union(r.Literal('PartNumberOfPins'), r.Literal('MaterialPrice'));
export type SumAggregationValue = r.Static<typeof SumAggregationValueRuntype>;

const SumAggregationRuntype = r.Record({
    type: r.Literal('Sum'),
    value: SumAggregationValueRuntype,
});

const AggregationRuntype = CountAggregationRuntype.Or(SumAggregationRuntype);
export type AutomaticDriverAggregationDTO = r.Static<typeof AggregationRuntype>;

const AutomaticDetailsRuntype = r.Record({
    aggregation: AggregationRuntype,
    filter_formula: r.Array(filterFormulaRuntype),
});

export interface AutomaticDetailsDTO extends r.Static<typeof AutomaticDetailsRuntype> {}

const AutomaticRuntype = r.Record({
    type: r.Literal(DriverDetailsType.Automatic),
    automatic_details: AutomaticDetailsRuntype,
});

const DriverDetailsPostBaseRuntype = r.Record({
    name: r.String,
    is_per_panel: r.Boolean,
    notes: r.String.nullable(),
    lexorank: r.String,
});

export interface DriverDetailsPostBaseDTO extends r.Static<typeof DriverDetailsPostBaseRuntype> {}

const ManualDriverDetailsPostRuntype = DriverDetailsPostBaseRuntype.extend({
    details: ManualRuntype,
});

const AutomaticDriverDetailsPostRuntype = DriverDetailsPostBaseRuntype.extend({
    details: AutomaticRuntype,
});

const DriverOriginRuntype = r.Union(
    r.Literal(DriverOrigin.Bom),
    r.Literal(DriverOrigin.Sourcing),
    r.Literal(DriverOrigin.Pnp),
);
export type DriverOriginDTO = r.Static<typeof DriverOriginRuntype>;

export const ManualDriverDetailsRuntype = ManualDriverDetailsPostRuntype.extend({
    id: r.String,
    status: ManufacturingEntityStatusRuntype,
    origin_tags: r.Array(DriverOriginRuntype).optional(),
});

export const AutomaticDriverDetailsRuntype = AutomaticDriverDetailsPostRuntype.extend({
    id: r.String,
    status: ManufacturingEntityStatusRuntype,
    origin_tags: r.Array(DriverOriginRuntype),
});

export const UserDriverDetailsDTOPatchRuntype = r.Record({
    name: r.String.optional(),
    is_per_panel: r.Boolean.optional(),
    details: r.Union(AutomaticRuntype, ManualRuntype).optional(),
    status: ManufacturingEntityStatusRuntype.optional(),
    notes: r.String.nullable().optional(),
    lexorank: r.String.optional(),
});
export type UserDriverDetailsPatchDTO = r.Static<typeof UserDriverDetailsDTOPatchRuntype>;
export const UserDriverDetailsBulkPatchDTORuntype = r.Record({
    drivers: r.Array(
        r.Record({
            id: r.String,
            update: UserDriverDetailsDTOPatchRuntype,
        }),
    ),
});
export type UserDriverDetailsBulkPatchDTO = r.Static<typeof UserDriverDetailsBulkPatchDTORuntype>;

export const UserDriverDetailsDTOPostRuntype = r.Union(
    ManualDriverDetailsPostRuntype,
    AutomaticDriverDetailsPostRuntype,
);

export type UserDriverDetailsPostDTO = r.Static<typeof UserDriverDetailsDTOPostRuntype>;

export const UserDriverDetailsRuntype = r.Union(AutomaticDriverDetailsRuntype, ManualDriverDetailsRuntype);

export type UserDriverDetailsDTO = r.Static<typeof UserDriverDetailsRuntype>;
