import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    AdditionalServicesGetResponseRuntype,
    AdditionalServicesPatchRequestRuntype,
    AdditionalServicesPatchResponseRuntype,
    AdditionalServicesPostRequestRuntype,
    AdditionalServicesPostResponseRuntype,
} from './additionalServicesBackendTypes';

export const additionalServicesEndpoints = {
    'POST /additional-services': endpoint({
        description: 'Create an additional service to the organization',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AdditionalServicesPostRequestRuntype,
        responseBody: AdditionalServicesPostResponseRuntype,
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
    'POST /additional-services/bulk': endpoint({
        description: 'Create an additional service to the organization',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Array(AdditionalServicesPostRequestRuntype),
        responseBody: r.Record({ data: r.Array(AdditionalServicesPostResponseRuntype) }),
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
    'PATCH /additional-services/:additionalServiceId': endpoint({
        description: 'Create an additional service to the organization',
        pathParams: r.Record({ additionalServiceId: r.String }),
        queryParams: r.Undefined,
        requestBody: AdditionalServicesPatchRequestRuntype,
        responseBody: AdditionalServicesPatchResponseRuntype,
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
    'GET /additional-services': endpoint({
        description: 'Gets all additional services of the organization.',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(AdditionalServicesGetResponseRuntype),
        }),
    }),
    'GET /rfqs/:rfqId/additional-services': endpoint({
        description: 'Gets all additional services of an rfq.',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(AdditionalServicesGetResponseRuntype),
        }),
    }),
    'DELETE /additional-services/:additionalServiceId': endpoint({
        description: 'Delete an additional service.',
        pathParams: r.Record({ additionalServiceId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
};
