import { t, Trans } from '@lingui/macro';
import { transEnum, typeSafeObjectKeys, validEmailRegex } from '@luminovo/commons';
import {
    DestructiveTertiaryIconButton,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FlexboxProps,
    FormItem,
    SecondaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { allUserRoles, ExtractRequestBody } from '@luminovo/http-client';
import { Add, Delete } from '@mui/icons-material';
import { useFieldArray, useFormContext, UseFormGetValues } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { languageTranslations } from '../../../utils/internationalisation';
import { userRoleTranslations } from './i18n';

export type CustomerUserInviteFormState = { data: ExtractRequestBody<'POST /users/customers/:customerId/invites'> };

export function UserInviteForm({
    initialValues,
    onSubmit,
    onClose: onCancel,
    multiple = false,
}: {
    initialValues: CustomerUserInviteFormState;
    onSubmit: (form: CustomerUserInviteFormState) => void;
    onClose: () => void;
    multiple?: boolean;
}) {
    const shouldAllowMultipleUsers = multiple;

    const validationErrors: ValidationErrors<CustomerUserInviteFormState> = {
        'invite.email_already_exists': {
            fieldPath: 'root.serverError',
        },
        'invite.invalid_user_invite': {
            fieldPath: 'root.serverError',
        },
        'invite.invite_already_sent': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <Flexbox>
            <FormContainer defaultValues={initialValues} onSubmit={onSubmit} validationErrors={validationErrors}>
                <Flexbox flexDirection="column" gap={24}>
                    <UserInviteFormInner shouldAllowMultipleUsers={shouldAllowMultipleUsers} />

                    <Flexbox justifyContent="end" gap={8}>
                        <SecondaryButton onClick={() => onCancel()}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                        <Flexbox marginRight={shouldAllowMultipleUsers ? '16px' : 0}>
                            <SubmitButton label={t`Save and send invite`} />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </FormContainer>
        </Flexbox>
    );
}

function validateEmail(x: unknown, index: number, getValues: UseFormGetValues<CustomerUserInviteFormState>) {
    if (typeof x !== 'string') return t`Invalid type`;
    if (!validEmailRegex.test(x)) return t`Invalid email address`;
    for (let i = 0; i < index; i++) {
        if (x === getValues(`data.${i}.email`)) return t`Duplicate email address`;
    }
}

function UserInviteFormInner({ shouldAllowMultipleUsers }: { shouldAllowMultipleUsers: boolean }): JSX.Element {
    const { control, getValues } = useFormContext<CustomerUserInviteFormState>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'data',
    });

    const multipleFlexboxProps: FlexboxProps = {
        flexDirection: 'row',
        minWidth: 400,
        alignItems: 'flex-start',
    };

    return (
        <Flexbox flexDirection="column" gap={8}>
            <Flexbox flexDirection={'column'} gap={24}>
                {fields.map((user, index) => (
                    <Flexbox
                        key={user.id}
                        gap={16}
                        flexDirection={'column'}
                        {...(shouldAllowMultipleUsers ? multipleFlexboxProps : {})}
                    >
                        <FormItem label={t`Email`} required>
                            <FieldTextControlled
                                control={control}
                                name={`data.${index}.email`}
                                required
                                validate={(x) => {
                                    return validateEmail(x, index, getValues);
                                }}
                                FieldProps={{ placeholder: t`Email` }}
                            />
                        </FormItem>
                        <FormItem label={t`First name`} required>
                            <FieldTextControlled
                                control={control}
                                name={`data.${index}.first_name`}
                                required
                                FieldProps={{ placeholder: t`First name` }}
                            />
                        </FormItem>
                        <FormItem label={t`Last name`} required>
                            <FieldTextControlled
                                control={control}
                                name={`data.${index}.last_name`}
                                required
                                FieldProps={{ placeholder: t`Last name` }}
                            />
                        </FormItem>
                        <FormItem
                            label={t`Language`}
                            description={t`Select the language the user will receive the invite in.`}
                        >
                            <FieldSelectControlled
                                control={control}
                                name={`data.${index}.language`}
                                required
                                FieldProps={{
                                    style: { minWidth: 125 },
                                    disableClearable: true,
                                    options: typeSafeObjectKeys(languageTranslations),
                                    getOptionLabel: (role) => transEnum(role, languageTranslations),
                                }}
                            />
                        </FormItem>
                        <FormItem label={t`User role`} description={t`Select the role the user will be assigned.`}>
                            <FieldSelectControlled
                                control={control}
                                name={`data.${index}.user_role`}
                                required
                                FieldProps={{
                                    style: { minWidth: 125 },
                                    disableClearable: true,
                                    options: allUserRoles,
                                    getOptionLabel: (role) => transEnum(role, userRoleTranslations),
                                }}
                            />
                        </FormItem>

                        {shouldAllowMultipleUsers && (
                            <DestructiveTertiaryIconButton
                                onClick={() => remove(index)}
                                style={{ marginTop: '24px' }}
                                disabled={fields.length === 1}
                            >
                                <Delete fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        )}
                    </Flexbox>
                ))}
            </Flexbox>
            {shouldAllowMultipleUsers && (
                <TertiaryButton
                    style={{ whiteSpace: 'nowrap', padding: 0, marginBottom: 8, alignSelf: 'flex-start' }}
                    onClick={() =>
                        append({ email: '', language: null, first_name: '', last_name: '', user_role: 'Member' })
                    }
                >
                    <Add />
                    <Text variant="h4" style={{ marginLeft: 8 }}>
                        <Trans>Add new</Trans>
                    </Text>
                </TertiaryButton>
            )}
        </Flexbox>
    );
}
