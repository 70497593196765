import { assertPresent, isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { Box } from '@mui/material';
import {
    ColumnDef,
    ColumnPinningState,
    Table,
    VisibilityState,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TableVirtuoso } from 'react-virtuoso';
import * as r from 'runtypes';
import { DefaultColumnSelection, DefaultExpandColumn } from './DefaultColumn';
import { DefaultOuterEmptyPlaceholder } from './DefaultEmptyPlaceholder';
import { DefaultFallbackComponent } from './DefaultFallbackComponent';
import { DefaultScroller } from './DefaultScroller';
import { DefaultTable } from './DefaultTable';
import { DefaultTableRow } from './DefaultTableRow';
import { getFixedFooterContent } from './FixedFooterContent';
import { getFixedHeaderContent } from './FixedHeaderContent';
import { TanStackMenuBar, calculateBoxHeight } from './TanStackMenuBar';
import {
    TanStackTableOverrides,
    TanStackTableProps,
    TanStackTableState,
    UseTanStackTablePaginationProps,
    UseTanStackTableProps,
    UseTanStackTableStateProps,
} from './type';
import {
    URLStorage,
    buildFilter,
    createFuzzyFilter,
    getSaveAsDefaultConfig,
    monetaryValueSortingFn,
    resetTable,
    useTanStackPersistedState,
} from './utils';

export function useColumnDefs<TData>({
    columns,
    enableSelection,
    getSubRows,
    disableInferredColumns = false,
}: Pick<UseTanStackTableProps<TData>, 'columns' | 'enableSelection' | 'getSubRows'> & {
    disableInferredColumns?: boolean;
}): {
    columns: ColumnDef<TData, any>[];
    inferredColumnPinning: ColumnPinningState;
    inferredColumnVisibility: VisibilityState;
} {
    let processedColumns = columns as ColumnDef<TData, any>[];

    if (isPresent(getSubRows)) {
        processedColumns = [DefaultExpandColumn, ...columns] as ColumnDef<TData, any>[];
    }

    if (isPresent(enableSelection) && enableSelection.enabled) {
        processedColumns = [enableSelection.column ?? DefaultColumnSelection, ...processedColumns] as ColumnDef<
            TData,
            any
        >[];
    }

    const inferredColumnPinning: ColumnPinningState = { left: [], right: [] };
    const inferredColumnVisibility: VisibilityState = {};

    processedColumns.forEach((column) => {
        if (column.meta?.initialPinning) {
            if (!isPresent(column.id)) {
                throwErrorUnlessProduction(
                    `Error: The column with label "${column.meta?.label()}" requires an ID for initial pinning.`,
                );
                return;
            }
            if (disableInferredColumns) {
                throwErrorUnlessProduction(
                    `Error: The column with label "${column.meta?.label()}" has initial pinning but inferred columns are disabled for pagination tables.`,
                );
            }

            inferredColumnPinning[column.meta.initialPinning]?.push(column.id);
        }
        if (column.meta?.initialVisibility !== undefined) {
            if (!isPresent(column.id)) {
                throwErrorUnlessProduction(
                    `Error: The column with label "${column.meta?.label()}" requires an ID to set initial visibility.`,
                );
                return;
            }
            if (disableInferredColumns) {
                throwErrorUnlessProduction(
                    `Error: The column with label "${column.meta?.label()}" has initial visibility but inferred columns are disabled for pagination tables.`,
                );
            }

            inferredColumnVisibility[column.id] = column.meta.initialVisibility;
        }
    });

    return { columns: processedColumns, inferredColumnPinning, inferredColumnVisibility };
}

const TAN_STACK_TABLE_STATE_KEY = 'version-2';
/**
 * @deprecated EXPERIMENTAL: This hook is not yet stable
 */
export function useTanStackTableState<TData>({
    initialState = {},
    columnsKey,
    enableSelection,
    enableColumnHiding = false,
    enableColumnOrdering = false,
    enablePersistentColumnFilters = false,
    enablePersistentColumnOrder = true,
    enablePersistentColumnVisibility = true,
    enablePersistentRowSelection = false,
    enablePersistentGlobalFilter = false,
    enableSaveAsDefault = false,
    enablePersistentExpanded = false,
}: UseTanStackTableStateProps<TData>): { tableState: TanStackTableState<TData> } {
    const {
        columnFilters: initialColumnFilters = [],
        columnOrder: initialColumnOrder = [],
        columnPinning: initialColumnPinning = {},
        columnVisibility: initialColumnVisibility = {},
        globalFilter: initialGlobalFilter = '',
        rowSelection: initialRowSelection = {},
        sorting: initialSorting = [],
    } = initialState;

    const defautlInitalState = {
        columnFilters: initialColumnFilters,
        columnOrder: initialColumnOrder,
        columnPinning: initialColumnPinning,
        columnVisibility: initialColumnVisibility,
        globalFilter: initialGlobalFilter,
        rowSelection: initialRowSelection,
        sorting: initialSorting,
    };

    const { storage, key, enabled } = getSaveAsDefaultConfig(enableSaveAsDefault);

    const [persistentFilters, setPersistentFilters] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${key}-${columnsKey}-filters`,
        initialColumnFilters,
        { storage, enabled },
    );

    const [persistentSorting, setPersistentSorting] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${key}-${columnsKey}-sorting`,
        initialSorting,
        {
            storage,
            enabled,
            validation: (value) => {
                return r
                    .Array(
                        r.Record({
                            id: r.String,
                            desc: r.Boolean,
                        }),
                    )
                    .guard(value);
            },
        },
    );

    const [columnFilters, setColumnFilters] = useTanStackPersistedState(`columnFilters`, persistentFilters, {
        storage: new URLStorage(),
        enabled: enablePersistentColumnFilters,
        validation: (value) => {
            return r
                .Array(
                    r.Record({
                        id: r.String,
                        value: r.Record({
                            filterFn: r.String,
                            value: r.Unknown,
                        }),
                    }),
                )
                .guard(value);
        },
    });

    const [sorting, setSorting] = useTanStackPersistedState(`sorting`, persistentSorting, {
        storage: new URLStorage(),
        enabled: enablePersistentColumnFilters,
    });

    const [columnOrder, setColumnOrder] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${columnsKey}-columnOrder`,
        initialColumnOrder,
        {
            storage: localStorage,
            enabled: enablePersistentColumnOrder && enableColumnOrdering,
            validation: (value) => {
                return r.Array(r.String).guard(value);
            },
        },
    );

    const [columnPinning, setColumnPinning] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${columnsKey}-columnPinning`,
        initialColumnPinning,
        {
            storage: localStorage,
            enabled: false,
            validation: (value) => {
                return r.Record({ left: r.Array(r.String), right: r.Array(r.String) }).guard(value);
            },
        },
    );

    const [columnVisibility, setColumnVisibility] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${columnsKey}-ColumnVisibility`,
        initialColumnVisibility,
        {
            storage: localStorage,
            enabled: enablePersistentColumnVisibility && enableColumnHiding,
            validation: (value) => {
                return r.Dictionary(r.Boolean, r.String).guard(value);
            },
        },
    );

    const [globalFilter, setGlobalFilter] = useTanStackPersistedState(`globalFilter`, initialGlobalFilter, {
        storage: new URLStorage(),
        enabled: enablePersistentGlobalFilter,
    });

    const [rowSelection, setRowSelection] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${enablePersistentRowSelection}-${columnsKey}-RowSelection`,
        initialRowSelection,
        {
            storage: sessionStorage,
            enabled: Boolean(enablePersistentRowSelection) && enableSelection?.enabled,
            callback: enableSelection?.onRowSelectionChange,
        },
    );

    const [expanded, setExpanded] = useTanStackPersistedState(
        `${TAN_STACK_TABLE_STATE_KEY}-${enablePersistentExpanded}-${columnsKey}-RowExtended`,
        {},
        {
            storage: sessionStorage,
            enabled: Boolean(enablePersistentExpanded),
            validation: (value) => r.Dictionary(r.Boolean, r.String).guard(value),
        },
    );

    return {
        tableState: {
            initialState,
            columnFilters,
            setColumnFilters,
            columnOrder,
            setColumnOrder,
            columnPinning,
            setColumnPinning,
            columnVisibility,
            setColumnVisibility,
            globalFilter,
            setGlobalFilter,
            rowSelection,
            setRowSelection,
            expanded,
            setExpanded,
            sorting,
            setSorting,
            meta: {
                defautlInitalState,
                columnsKey,
                enableColumnHiding,
                enableColumnOrdering,
                enablePersistentColumnFilters,
                enablePersistentColumnOrder,
                enablePersistentColumnVisibility,
                enablePersistentGlobalFilter,
                enablePersistentRowSelection,
                enableSaveAsDefault,
                sessionFiltersAndSorting: enableSaveAsDefault
                    ? {
                          persistentFilters,
                          setPersistentFilters,
                          persistentSorting,
                          setPersistentSorting,
                      }
                    : null,
                enablePersistentExpanded,
            },
        },
    };
}

/**
 * Can be used to create a table with pagination.
 *
 * But be careful, the support for it isn't perfect yet and it might change in the future.
 */
export function useTanStackTablePagination<TData, TSharedContext>({
    data: initialData,
    columns: initialColumns,
    sharedContext,
    totalCount,
    onRowClick,
    isLoading: inputIsLoading,
    fetchNextPage,
    isFetchingNextPage,
    enableSelection,
    tableState,
}: UseTanStackTablePaginationProps<TData, TSharedContext>): {
    table: Table<TData>;
} {
    const { columns } = useColumnDefs({
        columns: initialColumns,
        enableSelection,
        disableInferredColumns: true,
    });

    const data = initialData ?? [];
    const isLoading = inputIsLoading ?? !isPresent(initialData);

    const getIsLoading = React.useCallback(() => isLoading, [isLoading]);

    const {
        initialState,
        columnFilters,
        setColumnFilters,
        columnOrder,
        setColumnOrder,
        columnPinning,
        setColumnPinning,
        columnVisibility,
        setColumnVisibility,
        globalFilter,
        setGlobalFilter,
        rowSelection,
        setRowSelection,
        sorting,
        setSorting,
        expanded,
        setExpanded,
        meta,
    } = tableState;

    const table = useReactTable({
        data,
        columns,
        // This disables the built-in filtering
        manualFiltering: true,
        state: {
            columnFilters,
            columnOrder,
            columnPinning,
            columnVisibility,
            globalFilter,
            rowSelection,
            sorting,
            expanded,
        },
        sortingFns: {
            monetaryValue: monetaryValueSortingFn,
        },
        filterFns: {
            buildFilter,
        },
        initialState: {
            globalFilter,
            ...initialState,
        },
        meta: {
            sharedContext,
            totalCount,
            onRowClick,
            getIsLoading,
            fetchNextPage,
            isFetchingNextPage,
            enableSelection,
            enablePersistentScrollPosition: false,
            enableExcelExport: false,
            ...meta,
        },
        globalFilterFn: 'auto',
        onColumnFiltersChange: setColumnFilters,
        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        onColumnVisibilityChange: setColumnVisibility,
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        onExpandedChange: setExpanded,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowId: enableSelection?.getRowId,
    });

    return { table };
}

/**
 * A hook to create and manage a TanStack table. This hook handles the configuration
 * and state management for the table, including data processing, column definitions,
 * and various table options.
 *
 * @example
 * const columnHelper = createColumnHelper<MyDataType>();
 * const columns = [
 *   columnHelper.accessor('name', {
 *     header: 'Name',
 *     cell: info => info.getValue()
 *   }),
 * ];
 *
 * const { table } = useTanStackTable({
 *   data: fetchData(),
 *   columns,
 *   onRowClick: (row) => console.log('Row clicked:', row)
 * });
 *
 * return <TanStackTable table={table} />;
 *
 * @see TanStackTable
 * @see createColumnHelper
 */
export function useTanStackTable<TData, TSharedContext>({
    data: initialData,
    columns: initialColumns,
    initialState: inputInialState = {},
    enableRowSelection,
    getSubRows,
    getColumnCanGlobalFilter,
    sharedContext,
    onRowClick,
    columnsKey: initialColumnsKey = '',
    filterFromLeafRows = false,
    isLoading: inputIsLoading,
    enableSelection,
    enableColumnHiding = false,
    enableColumnOrdering = false,
    enablePersistentColumnFilters = false,
    enablePersistentColumnOrder = true,
    enablePersistentColumnVisibility = true,
    enablePersistentRowSelection = false,
    enablePersistentGlobalFilter = false,
    enablePersistentScrollPosition = false,
    enableSaveAsDefault = false,
    enableExcelExport = false,
    enablePersistentExpanded = false,
}: UseTanStackTableProps<TData, TSharedContext>): {
    table: Table<TData>;
} {
    const { columns, inferredColumnPinning, inferredColumnVisibility } = useColumnDefs({
        columns: initialColumns,
        enableSelection,
        getSubRows,
    });

    const columnsKey = `${initialColumnsKey}-${columns.map(({ id }) => id).join()}`;

    const data = initialData ?? [];
    const isLoading = inputIsLoading ?? !isPresent(initialData);

    const getIsLoading = React.useCallback(() => isLoading, [isLoading]);

    const {
        tableState: {
            initialState,
            columnFilters,
            setColumnFilters,
            columnOrder,
            setColumnOrder,
            columnPinning,
            setColumnPinning,
            columnVisibility,
            setColumnVisibility,
            globalFilter,
            setGlobalFilter,
            rowSelection,
            setRowSelection,
            sorting,
            setSorting,
            expanded,
            setExpanded,
            meta,
        },
    } = useTanStackTableState({
        initialState: {
            columnPinning: inferredColumnPinning,
            columnVisibility: inferredColumnVisibility,
            ...inputInialState,
        },
        columnsKey,
        enableSelection,
        enableColumnHiding,
        enableColumnOrdering,
        enablePersistentColumnFilters,
        enablePersistentColumnOrder,
        enablePersistentColumnVisibility,
        enablePersistentRowSelection,
        enablePersistentGlobalFilter,
        enableSaveAsDefault,
        enablePersistentExpanded,
    });

    const globalFilterFn = React.useMemo(() => createFuzzyFilter(columns), [columns]);

    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            columnOrder,
            columnPinning,
            columnVisibility,
            globalFilter,
            rowSelection,
            expanded,
            sorting,
        },
        sortingFns: {
            monetaryValue: monetaryValueSortingFn,
        },
        filterFns: {
            buildFilter,
        },
        initialState: {
            globalFilter,
            ...initialState,
        },
        meta: {
            ...meta,
            sharedContext,
            onRowClick,
            columnsKey,
            getIsLoading,
            enableSelection,
            enablePersistentScrollPosition,
            enableExcelExport,
            fetchNextPage: undefined,
            isFetchingNextPage: false,
        },
        globalFilterFn,
        onColumnFiltersChange: setColumnFilters,
        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        onColumnVisibilityChange: setColumnVisibility,
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        onExpandedChange: setExpanded,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowId: enableSelection?.getRowId,
        getSubRows,
        enableRowSelection,
        getColumnCanGlobalFilter,
        filterFromLeafRows,
    });

    return { table };
}

/**
 * A component to render a TanStack table. This component handles the display
 * of the table with various configurable options such as size, placeholders, and overrides.
 *

 *
 * @example
 * const { table } = useTanStackTable({
 *   data: fetchData(),
 *   columns,
 * });
 *
 * const EmptyPlaceholder = ({ sharedContext }) => <div>No data available</div>;
 *
 * return (
 *   <Flexbox flexDirection={'column'} gap={'12px'} height={'65vh'}>
 *     <TanStackTable<TData, TShareContext>
 *       table={table}
 *       size={'medium'}
 *       EmptyPlaceholder={EmptyPlaceholder}
 *     />
 *   </Flexbox>
 * );
 * 
 * @see useTanStackTable 
 * @see createColumnHelper
 */
export function TanStackTable<TData, TSharedContext>({
    table,
    size = 'medium',
    MenuBarTitle,
    ActionButton,
    EmptyPlaceholder,
    TableRow,
    enableMenuBar,
    overrides = {},
}: TanStackTableProps<TData, TSharedContext>): JSX.Element {
    const {
        FallbackComponent = DefaultFallbackComponent,
        Scroller = DefaultScroller as TanStackTableOverrides<TData, TSharedContext>['Scroller'],
        Table = DefaultTable as TanStackTableOverrides<TData, TSharedContext>['Table'],
        OuterTableRow = DefaultTableRow as TanStackTableOverrides<TData, TSharedContext>['OuterTableRow'],
        OuterEmptyPlaceholder = DefaultOuterEmptyPlaceholder as TanStackTableOverrides<
            TData,
            TSharedContext
        >['OuterEmptyPlaceholder'],
    } = overrides;
    /**
     * This is a workaround to maintain the visible range of the table.
     *
     * The `TableVirtuoso` component doesn't support persisting the visible range, so we use URL storage to save and restore it.
     * However, while the table is loading, we don't know the total row count, which prevents us from restoring the visible range.
     * To handle this, we trigger a new state via update the `key` when the `isLoading` flag changes
     */
    const { enablePersistentScrollPosition, fetchNextPage } = assertPresent(table.options.meta);
    const [startIndex, setStartIndex] = useTanStackPersistedState(`startIndex`, 0, {
        storage: new URLStorage(),
        enabled: enablePersistentScrollPosition,
    });
    const totalCount = table.getRowModel().rows.length;
    const extraProps = totalCount > startIndex ? { initialTopMostItemIndex: startIndex } : {};

    const isLoading = assertPresent(table.options.meta?.getIsLoading());
    const fixedHeaderContent = React.useCallback(() => getFixedHeaderContent({ table }), [table]);
    const fixedFooterContent = React.useCallback(() => getFixedFooterContent({ table }), [table]);

    /**
     * NICE TO HAVE: Add support for analytics events
     *  - e.g. onRowClick, onColumnVisibilityChange, onColumnPinningChange, onColumnFiltersChange, onSortingChange, onGlobalFilterChange, onRowSelectionChange
     */
    return (
        <ErrorBoundary
            fallbackRender={(props) => {
                return <FallbackComponent {...props} onReset={() => resetTable(table)} />;
            }}
        >
            <Box height={calculateBoxHeight(table, enableMenuBar)}>
                <TanStackMenuBar
                    table={table}
                    enableMenuBar={enableMenuBar}
                    ActionButton={ActionButton}
                    MenuBarTitle={MenuBarTitle}
                />
                <TableVirtuoso
                    // Note: This fixes the issue where the table doesn't render anything on first load.
                    // For useInfiniteQuery, we disable this "hack" since it would reset the table state
                    // when a new page is loaded.
                    // TODO: We might need to add a better key to handle column changes.
                    key={isLoading && !isPresent(fetchNextPage) ? `loading` : `done`}
                    context={{ table, size, isLoading, MenuBarTitle, ActionButton, EmptyPlaceholder, TableRow }}
                    totalCount={totalCount}
                    overscan={200}
                    // Note: this `rangeChanged` is impacted by overscan. See https://github.com/petyosi/react-virtuoso/issues/118#issuecomment-642156138
                    rangeChanged={
                        enablePersistentScrollPosition ? ({ startIndex }) => setStartIndex(startIndex) : undefined
                    }
                    endReached={() => {
                        fetchNextPage?.();
                    }}
                    // Note: Do not inline the components here, every render would create new instances.
                    components={{
                        Scroller,
                        Table,
                        TableRow: OuterTableRow,
                        EmptyPlaceholder: OuterEmptyPlaceholder,
                    }}
                    fixedFooterContent={fixedFooterContent}
                    fixedHeaderContent={fixedHeaderContent}
                    {...extraProps}
                />
            </Box>
        </ErrorBoundary>
    );
}
