import { t } from '@lingui/macro';
import { isPresent, transEnum, uniqBy } from '@luminovo/commons';
import {
    Checkbox,
    FieldMultiSelectControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { SupplierPreference } from '@luminovo/http-client';
import { formatSupplierDTO, supplierPreferenceTranslations } from '@luminovo/sourcing-core';
import { Add, SettingsRounded } from '@mui/icons-material';
import { RadioGroup } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { useDialogAddQuoteRequestSupplier, useQuoteRequestSuppliers } from './AddQuoteRequestSupplier';
import { QuoteRequestAssignmentFormValues } from './types';

export const FormItemSupplierSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();

    return (
        <FormItem label={t`Choose supplier by`}>
            <FieldSelectControlled
                name="supplierSelectionStrategy"
                control={control}
                required={true}
                FieldProps={{
                    disableClearable: true,
                    getOptionLabel: (strategy) => {
                        switch (strategy.type) {
                            case 'SupplierPartTypeMatches':
                                return t`Manually`;
                            case 'SupplierLineCard':
                                return t`Supplier line card`;
                            case 'ApprovedVendorList':
                                return t`Approved vendor list`;
                        }
                    },
                    options: [
                        { type: 'SupplierPartTypeMatches' },
                        { type: 'SupplierLineCard', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                        { type: 'ApprovedVendorList', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                    ],
                }}
            />
        </FormItem>
    );
};

export const FormItemSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const supplierSelectionStrategyType = useWatch({ control, name: 'supplierSelectionStrategy.type' });

    if (!['SupplierLineCard', 'ApprovedVendorList'].includes(supplierSelectionStrategyType)) {
        return null;
    }

    return (
        <RadioGroup>
            <FormItem label={t`What should be sent to the selected suppliers?`}>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'OnlyMatchingPartOptions', size: 'small' }}
                    />
                    <Text>{t`Only part options matching line card`}</Text>
                </Flexbox>
                <Flexbox alignItems={'start'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'AllPartOptions', size: 'small' }}
                    />

                    <Text>{t`Part options matching line card plus all approved alternatives`}</Text>
                </Flexbox>
            </FormItem>
        </RadioGroup>
    );
};

export const FormItemSuppliers: React.FunctionComponent = () => {
    const context = useFormContext<QuoteRequestAssignmentFormValues>();
    const { control, setValue } = context;
    const selectedSuppliers = useWatch({ control, name: 'selectedQuoteRequestSuppliers' });
    const { data: options } = useQuoteRequestSuppliers();

    const getGroupByStatus = React.useCallback(
        (preference: SupplierPreference): 'all' | 'indeterminate' | 'none' => {
            const selectedIds = new Set(selectedSuppliers.map((s) => s.id));
            const suppliersInGroup = selectedSuppliers.filter((s) => s.preference === preference);

            if (suppliersInGroup.length === 0) {
                return 'none';
            }

            const allSelected = suppliersInGroup.every((s) => selectedIds.has(s.id));
            if (allSelected) {
                return 'all';
            }

            const someSelected = suppliersInGroup.some((s) => selectedIds.has(s.id));
            if (someSelected) {
                return 'indeterminate';
            }
            return 'none';
        },
        [selectedSuppliers],
    );

    const toggleGroup = React.useCallback(
        (preference: SupplierPreference, currentStatus: 'all' | 'indeterminate' | 'none') => {
            const updateSuppliers = (suppliers: typeof selectedSuppliers) => {
                setValue('selectedQuoteRequestSuppliers', suppliers, { shouldValidate: true });
            };

            if (currentStatus === 'all') {
                const filteredSuppliers = uniqBy(
                    selectedSuppliers.filter((s) => s.preference !== preference),
                    (s) => s.id,
                );
                updateSuppliers(filteredSuppliers);
            } else {
                const suppliersToAdd = options?.filter((s) => s.preference === preference) ?? [];
                const mergedSuppliers = uniqBy([...selectedSuppliers, ...suppliersToAdd], (s) => s.id);
                updateSuppliers(mergedSuppliers);
            }
        },
        [selectedSuppliers, setValue, options],
    );

    const { openDialog } = useDialogAddQuoteRequestSupplier();

    return (
        <FormItem
            label={t`Suppliers`}
            actions={
                <SecondaryButton size="small" onClick={() => openDialog(context)} startIcon={<SettingsRounded />}>
                    {t`Edit`}
                </SecondaryButton>
            }
        >
            <FieldMultiSelectControlled
                name="selectedQuoteRequestSuppliers"
                control={control}
                FieldProps={{
                    chipBoxProps: {
                        maxHeight: '200px',
                        overflow: 'auto',
                    },
                    options: options ?? [],
                    disabled: !isPresent(options),
                    getOptionLabel: (option) => formatSupplierDTO(option),
                    getOptionSublabel: (option) => option.supplier_number,
                    placeholder: selectedSuppliers.length === 0 ? t`No suppliers` : undefined,
                    getOptionKey: (supplier) => supplier.id,
                    virtualized: true,
                    disableCloseOnSelect: true,
                    groupBy: (supplier): SupplierPreference => {
                        return supplier.preference;
                    },
                    renderGroupLabel: (params) => {
                        //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const groupByKey = params.group as SupplierPreference;
                        const status = getGroupByStatus(groupByKey);

                        return (
                            <Flexbox gap={8} paddingLeft={'12px'} alignItems={'center'}>
                                <Checkbox
                                    checked={status === 'all'}
                                    indeterminate={status === 'indeterminate'}
                                    size={'small'}
                                    onClick={() => toggleGroup(groupByKey, status)}
                                    disabled={groupByKey === SupplierPreference.NotApproved}
                                />
                                <Text variant="h4">{transEnum(groupByKey, supplierPreferenceTranslations)}</Text>
                            </Flexbox>
                        );
                    },
                }}
            />
        </FormItem>
    );
};

export const ButtonAddToQuoteRequest: React.FunctionComponent<{
    disabled: boolean;
    groupMode: 'manufacturers' | 'offTheShelfPartOptions' | 'customPartOptions';
}> = ({ disabled, groupMode }) => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const selectedSuppliers = useWatch({ control, name: 'selectedQuoteRequestSuppliers' });
    const hasNoSuppliers = selectedSuppliers.length === 0;

    return (
        <Tooltip
            variant="white"
            title={
                disabled
                    ? {
                          manufacturers: t`Select at least one manufacturer`,
                          offTheShelfPartOptions: t`Select at least one part option`,
                          customPartOptions: t`Select at least one custom part option`,
                      }[groupMode]
                    : ''
            }
        >
            <div>
                <SubmitButton
                    disabled={disabled || hasNoSuppliers}
                    size="medium"
                    label={t`Add to quote request`}
                    startIcon={<Add />}
                />
            </div>
        </Tooltip>
    );
};
