import { useDialogContext } from '@/components/contexts/ModalContext';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '@/resources/organizationSettings/currencySettingsHandler';
import { useSupplierAndStockLocation } from '@/resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { t } from '@lingui/macro';
import { isEqual } from '@luminovo/commons';
import { colorSystem, Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import {
    SupplierAndStockLocationDTO,
    SupplierAndStockLocationPatchDTO,
    SupplierPreference,
} from '@luminovo/http-client';
import { SupplierForm, SupplierFormState } from './SupplierForm';

export function useDialogEditSupplierAndStockLocation({
    supplierAndStockLocation,
}: {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}) {
    const { setDialog, closeDialog } = useDialogContext();
    const { data: innerSupplierAndStockLocation } = useSupplierAndStockLocation(supplierAndStockLocation.id);

    const { preferredCurrency } = useGlobalCurrency();

    const defaultValues: SupplierFormState = {
        name: supplierAndStockLocation.supplier.name,
        stockLocation: supplierAndStockLocation.stock_location,
        supplierNumber: supplierAndStockLocation.supplier_number ?? '',
        supplierPreference: innerSupplierAndStockLocation?.preference ?? SupplierPreference.NotApproved,
        shippingTimeInDays: supplierAndStockLocation.shipping_time_in_days ?? undefined,
        supplierType: supplierAndStockLocation.supplier.supplier_type,
        supplierPartType: supplierAndStockLocation.supplier.supplier_part_type,
        originalSupplierAndStockLocation: supplierAndStockLocation,
        defaultCurrency: supplierAndStockLocation.supplier.default_currency ?? preferredCurrency,
    };

    const { mutateAsync } = useHttpMutation('PATCH /suppliers-and-stock-locations/:id', {
        snackbarMessage: t`Supplier updated`,
    });

    const onSubmit = async (formValues: SupplierFormState) => {
        const requestBody: SupplierAndStockLocationPatchDTO = {
            name: isEqual(formValues.name, defaultValues.name) ? undefined : formValues.name,
            supplier_number: Boolean(formValues.supplierNumber) ? formValues.supplierNumber : null,
            supplier_type: isEqual(formValues.supplierType, defaultValues.supplierType)
                ? undefined
                : formValues.supplierType,
            supplier_part_type: isEqual(formValues.supplierPartType, defaultValues.supplierPartType)
                ? undefined
                : formValues.supplierPartType,
            supplier_preference: formValues.supplierPreference,
            shipping_time_in_days: formValues.shippingTimeInDays,

            default_currency: isEqual(formValues.defaultCurrency, defaultValues.defaultCurrency)
                ? undefined
                : formValues.defaultCurrency,
        };

        await mutateAsync({ pathParams: { id: supplierAndStockLocation.id }, requestBody });
        closeDialog();
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Edit supplier`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <DialogContent>
                        <SupplierForm
                            defaultValues={defaultValues}
                            onSubmit={onSubmit}
                            options={{ mode: 'edit', disableSupplierPreferenceField: false }}
                        />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
