import { t } from '@lingui/macro';
import { isEqual, isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { LifecycleEnum, OtsFormPatchValues, OtsFullPart, PartCategoryDTO, RfqContext } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { FormContainer, ValidationErrors } from '../../../../../../components/formLayouts/FormContainer';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';
import { convertRfqContext, useOtsFullPart, usePartCategories } from '../../../../../../resources/part/partHandler';
import { nullIfEmptyOrUndefined } from '../../../../../../utils/typingUtils';
import { OtsPartForm } from './OtsPartForm';
import { PART_CATEGORY_OFF_THE_SHELF } from './components/PartCategorySelectControlled';
import { OtsFormValues } from './components/formValues';

const convertToInitialFormValues = (part: OtsFullPart, partCategories: PartCategoryDTO[]): OtsFormValues => {
    return {
        mpn: part.mpn,
        manufacturerDto: part.manufacturer,
        partCategory:
            partCategories.find((category) => category.id === part.part_category?.part_category.id) ??
            PART_CATEGORY_OFF_THE_SHELF,
        reach_compliant: part.reach_compliant,
        rohs_compliant: part.rohs_compliant,
        aecq_compliant: part.aecq_compliant,
        lifecycle_status: part.lifecycle_status,
        last_buy_date: part.last_buy_date !== null ? part.last_buy_date : undefined,
        description: part.description !== null ? part.description : undefined,
        datasheet_url: part.datasheet_url !== null ? part.datasheet_url : undefined,
        manufacturer_product_url: part.manufacturer_product_url !== null ? part.manufacturer_product_url : undefined,
        image_url: part.image_url !== null ? part.image_url : undefined,
        mpn_aliases: [],
        mounting: part.package?.mounting ?? null,
        package_name: part.package?.name ?? null,
        number_of_pins: part.package?.number_of_pins ?? null,
        qualifications: part.qualifications ?? [],
    };
};

const generatePartUpdateFormData = (
    formValues: OtsFormValues,
    initialFormValues: OtsFormValues,
): OtsFormPatchValues => {
    const result: OtsFormPatchValues = {};
    if (formValues.partCategory !== initialFormValues.partCategory) {
        result['part_category'] = formValues.partCategory.id;
    }

    if (formValues.reach_compliant !== initialFormValues.reach_compliant) {
        result['reach_compliant'] = formValues.reach_compliant;
    }

    if (formValues.rohs_compliant !== initialFormValues.rohs_compliant) {
        result['rohs_compliant'] = formValues.rohs_compliant;
    }

    if (formValues.aecq_compliant !== initialFormValues.aecq_compliant) {
        result['aecq_compliant'] = formValues.aecq_compliant;
    }

    if (formValues.last_buy_date !== initialFormValues.last_buy_date) {
        result['last_buy_date'] =
            formValues.last_buy_date && formValues.lifecycle_status === LifecycleEnum.EndOfLife
                ? formValues.last_buy_date
                : null;
    }

    if (formValues.lifecycle_status !== initialFormValues.lifecycle_status) {
        result['lifecycle_status'] = formValues.lifecycle_status;
    }

    if (formValues.description !== initialFormValues.description) {
        result['description'] = nullIfEmptyOrUndefined(formValues.description);
    }

    if (formValues.datasheet_url !== initialFormValues.datasheet_url) {
        result['datasheet_url'] = nullIfEmptyOrUndefined(formValues.datasheet_url);
    }

    if (formValues.manufacturer_product_url !== initialFormValues.manufacturer_product_url) {
        result['manufacturer_product_url'] = nullIfEmptyOrUndefined(formValues.manufacturer_product_url);
    }

    if (formValues.image_url !== initialFormValues.image_url) {
        result['image_url'] = nullIfEmptyOrUndefined(formValues.image_url);
    }

    if (!isEqual(formValues.qualifications, initialFormValues.qualifications)) {
        result['qualifications'] = formValues.qualifications;
    }

    if (
        formValues.mounting !== initialFormValues.mounting ||
        formValues.package_name !== initialFormValues.package_name ||
        formValues.number_of_pins !== initialFormValues.number_of_pins
    ) {
        result.package = {
            mounting: formValues.mounting,
            name: formValues.package_name,
            number_of_pins: formValues.number_of_pins,
        };
    }

    return result;
};

export const OtsPartEditForm: React.FunctionComponent<{
    otsPartId: string;
    rfqContext: RfqContext;
    onClose: () => void;
}> = ({ otsPartId: partId, rfqContext, onClose }): JSX.Element => {
    const { data: otsFullPartDTO } = useOtsFullPart({
        partOptionId: partId,
        rfqContext,
    });

    const { data: partCategories } = usePartCategories();

    const { mutateAsync } = useHttpMutation('PATCH /parts/off-the-shelf/:partId', {
        snackbarMessage: t`Part updated`,
    });

    if (!isPresent(otsFullPartDTO) || !isPresent(partCategories)) {
        return (
            <CenteredLayout height={'50vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const defaultValues = convertToInitialFormValues(otsFullPartDTO, partCategories);
    const onSubmit = async (values: OtsFormValues): Promise<void> => {
        const requestBody = generatePartUpdateFormData(values, defaultValues);
        const queryParams = convertRfqContext(rfqContext);
        await mutateAsync({ pathParams: { partId }, requestBody, queryParams });
        onClose();
    };

    const validationErrors: ValidationErrors<OtsFormValues> = {
        'off_the_shelf.duplicated_mpn': {
            fieldPath: 'mpn',
        },
        'off_the_shelf.mpn_length_violation': {
            fieldPath: 'mpn',
        },
        'off_the_shelf.package_violation': {
            fieldPath: 'package_name',
        },
        'off_the_shelf.part_already_exists': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <OtsPartForm isEditPartForm={true} onCancel={() => onClose()} />
        </FormContainer>
    );
};
