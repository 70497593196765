import { t } from '@lingui/macro';
import { isPresent, typeSafeObjectKeys } from '@luminovo/commons';
import { PartLiteTypes, StandardPartTypes } from '@luminovo/http-client';
import { fields } from '../../../model/fields/fields';
import { ValidationResult, Validator } from '../../../model/fields/types';
import { formatMoqDifference } from '../../../model/formatMoqDifference';
import { formatTargetPriceDifference } from '../../../model/formatTargetPriceDifference';
import { monetaryValue } from '../../../model/monetaryValueMath';
import { convertPartLiteToStandardPartDTO } from './model';
import { OfferedPart, RowItem } from './types';

export function validateLineItem(lineItem: RowItem): ValidationResult {
    // Unselected line items are always valid
    if (!lineItem.selected) {
        return { status: 'success' };
    }

    let warningResult: ValidationResult | undefined;

    for (const key of typeSafeObjectKeys(validators)) {
        const validator = validators[key];
        const result = validator(
            // @ts-ignore safe to ignore as we know the keys
            lineItem[key],
            lineItem,
        );

        if (result.status === 'error') {
            return result;
        }

        if (result.status === 'warning' && !warningResult) {
            warningResult = result;
        }
    }

    if (warningResult) {
        return warningResult;
    }

    return { status: 'success' };
}

const positiveOrNullValidator = (
    value: number | null | undefined,
): { status: 'error' | 'warning' | 'success'; message?: string } => {
    if (!isPresent(value)) {
        return { status: 'success' };
    }
    if (value <= 0) {
        return { status: 'error', message: t`Must be a positive number` };
    }
    return { status: 'success' };
};

const alwaysSuccessValidator = () => {
    return { status: 'success' } as const;
};

export const validators: { [TKey in keyof RowItem]: Validator<Exclude<RowItem[TKey], undefined>, RowItem> } = {
    selected: fields.selected.validate,
    lineItemId: alwaysSuccessValidator,
    component_origin: alwaysSuccessValidator,
    requested_part: alwaysSuccessValidator,
    description: alwaysSuccessValidator,
    requiredQuantity: alwaysSuccessValidator,
    potentialQuantity: alwaysSuccessValidator,
    recipients: alwaysSuccessValidator,
    customerName: alwaysSuccessValidator,
    targetPrice: alwaysSuccessValidator,
    id: alwaysSuccessValidator,
    offerId: alwaysSuccessValidator,
    offerCreatedAt: alwaysSuccessValidator,
    status: alwaysSuccessValidator,
    supplierPartNumber: alwaysSuccessValidator,
    oneTimeCosts: fields.oneTimeCosts.validate,
    bid: (bid: boolean | undefined, row: RowItem) => {
        if (row.offerId && bid === false) {
            return { status: 'error', message: t`You cannot unbid an offer` };
        }
        return fields.bid.validate(bid, row);
    },
    validUntil: validateOfferField(fields.validUntil.validate),
    validFrom: validateOfferField(fields.validFrom.validate),
    moq: (moq: number | undefined, row: RowItem) => {
        if (!row.selected || !row.bid) {
            return { status: 'success' };
        }
        if (moq && moq > row.requiredQuantity.quantity) {
            return {
                status: 'warning',
                message: t`MOQ is ${formatMoqDifference(row.requiredQuantity, moq)} higher than required quantity`,
            };
        }
        return fields.moq.validate(moq, row);
    },
    mpq: (mpq: number | undefined, row: RowItem): ValidationResult => {
        // Offer fields are only relevant for selected line items with bids
        if (!row.selected || !row.bid) {
            return { status: 'success' };
        }
        if (!isPresent(mpq)) {
            return { status: 'success' };
        }
        if (mpq > row.requiredQuantity.quantity) {
            return {
                status: 'warning',
                message: t`MPQ is ${formatMoqDifference(row.requiredQuantity, mpq)} higher than required quantity`,
            };
        }
        const moq = row.moq;
        if (isPresent(moq) && mpq > moq) {
            return {
                status: 'warning',
                message: t`MPQ is higher than MOQ`,
            };
        }
        return fields.mpq.validate(mpq, row);
    },
    stock: validateOfferField(fields.stock.validate),
    offeredPart: validateOfferField((offeredPart: OfferedPart | undefined, row: RowItem) => {
        if (!offeredPart) {
            return {
                status: 'error',
                message: t`Offered part is required`,
            };
        }
        const { part, warning } = offeredPart;
        if (part.kind === PartLiteTypes.Custom || part.kind === PartLiteTypes.CustomComponent) {
            return {
                status: 'success',
            };
        }

        const linkedPart = convertPartLiteToStandardPartDTO(part, row.component_origin ?? undefined);
        if (!linkedPart) {
            return {
                status: 'error',
                message: t`Required`,
            };
        }
        if (linkedPart.type === StandardPartTypes.Generic) {
            return {
                status: 'error',
                message: t`You cannot offer a generic part`,
            };
        }
        if (warning) {
            return {
                status: 'warning',
                message: warning,
            };
        }
        if (offeredPart.part.id !== row.requested_part.id && row.requested_part.kind === PartLiteTypes.Generic) {
            const knownMatches = row.requested_part.matches.map((match) => match.id);
            if (knownMatches.includes(offeredPart.part.id)) {
                return {
                    status: 'success',
                };
            }
            return {
                status: 'warning',
                message: t`We cannot verify if the part meets the generic specification. By bidding, you confirm it does. Click the offered part to review its details.`,
            };
        }

        if (offeredPart.part.id !== row.requested_part.id) {
            return {
                status: 'warning',
                message: t`Offered part does not match requested part`,
            };
        }
        return { status: 'success' };
    }),
    unitPrice: (value, row) => {
        if (!row.selected) {
            return { status: 'success' };
        }

        const validation = fields.unitPrice.validate(value, row);
        if (!row.bid && validation.status === 'success') {
            return { status: 'warning', message: t`Line item is marked as no-bid` };
        }
        if (!row.bid) {
            return { status: 'success' };
        }
        if (row.targetPrice && value) {
            const scaledUnitPrice = monetaryValue.scale(value, 1 / row.pricePer);
            const diff = monetaryValue.relativeDifference(scaledUnitPrice, row.targetPrice);
            if (diff && diff > 0) {
                return {
                    status: 'warning',
                    message: t`Unit price is ${formatTargetPriceDifference(row.targetPrice, scaledUnitPrice)} higher than target price`,
                };
            }
        }

        return validation;
    },
    pricePer: validateOfferField(fields.pricePer.validate),
    notes: (notes, row) => {
        if (!row.selected) {
            return { status: 'success' };
        }
        if (!row.bid && notes) {
            return { status: 'warning', message: t`Won't add notes to offer because line item is marked as no-bid` };
        }
        return fields.notes.validate(notes, row);
    },
    itemClass: validateOfferField(fields.itemClass.validate),
    packaging: validateOfferField(fields.packaging.validate),
    cancellationWindow: validateOfferField(positiveOrNullValidator),
    cancellationWindowUnit: validateOfferField((value) => {
        // TODO(supplier-portal) validate cancellation window unit
        return { status: 'success' };
    }),
    stdFactoryLeadTime: validateOfferField(positiveOrNullValidator),
    stdFactoryLeadTimeUnit: validateOfferField((value) => {
        // TODO(supplier-portal) validate std factory lead time unit
        return { status: 'success' };
    }),
    ncnr: validateOfferField(fields.ncnr.validate),
};

function validateOfferField<T>(validator: Validator<T, RowItem>): Validator<T, RowItem> {
    return (value: T | undefined, row: RowItem): ValidationResult => {
        // Offer fields are only relevant for selected line items with bids
        if (!row.selected || !row.bid) {
            return { status: 'success' };
        }
        return validator(value, row);
    };
}
