import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    DemandScenarioCreationRuntype,
    DemandScenarioRuntype,
    DemandScenarioUpdateRuntype,
} from './demandScenarioBackendTypes';

export const demandScenarioEndpoints = {
    'PATCH /demand-scenarios/:demandScenarioId': endpoint({
        description: 'Updates a single demand scenario',
        pathParams: r.Record({ demandScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: DemandScenarioUpdateRuntype,
        responseBody: r.Record({
            data: DemandScenarioRuntype,
        }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),
    'DELETE /demand-scenarios/:demandScenarioId': endpoint({
        description: 'Deletes a single demand scenario',
        pathParams: r.Record({ demandScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            deleted: r.Number,
        }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),
    'POST /demand-scenarios': endpoint({
        description: 'Creates a single demand scenario',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DemandScenarioCreationRuntype,
        responseBody: r.Record({ data: DemandScenarioRuntype }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId', 'POST /sourcing-scenarios/sourcing-full/bulk'],
    }),
};
