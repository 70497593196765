import { t, Trans } from '@lingui/macro';
import { assertPresent, indexBy, isPresent, sortBy, throwErrorUnlessProduction, uniqBy } from '@luminovo/commons';
import {
    colorSystem,
    Flexbox,
    MenuButton,
    MenuItem,
    RequestViaMailIcon,
    SecondaryButton,
    SupportedFilterFn,
    Table,
    TanStackMenuBar,
    TanStackTable,
    Text,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    CustomComponentFull,
    CustomFullPart,
    CustomOptionOfferDTO,
    FullSourcingDTO,
    isOtsFullPart,
    MonetaryValueBackend,
    OtsComponentFull,
    OtsFullPart,
    PartCategoryDTO,
    PartDTO,
    PartIdTypes,
    PartLite,
    PartLiteTypes,
    PurchaseOrderDTO,
    QuoteTrackingLight,
    RfqContext,
    SolutionConfigurationDTO,
    SolutionConfigurationExcelOriginInfo,
    SolutionConfigurationSourcingDTO,
    SolutionSelection,
    SolutionStatus,
    SolutionTag,
    SourcingCalculationDTO,
    SourcingScenarioDTO,
    StandardPartOfferDTO,
    SupplierAndStockLocationDTO,
    Tag,
} from '@luminovo/http-client';
import {
    extractAssemblyName,
    extractCustomPartOptions,
    extractDesignators,
    extractPartDTO,
    extractPartOptions,
    extractSolutionStatus,
    extractStandardPartOptions,
    formatSolutionStatus,
    formatSupplierAndStockLocationDTO,
    hasSolutionTag,
    isConsignedSolution,
    isStandardPartInventoryOffer,
    isStandardPartMarketOffer,
    parseSolution,
    Solution,
    SolutionStatusCircleIcon,
} from '@luminovo/sourcing-core';
import { ArrowDropDownRounded, AutoAwesomeRounded, GetApp, LockOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React from 'react';
import { PageLayout } from '../../../../components/PageLayout';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { useDownloadQuotePriceTemplate } from '../../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useInventorySites, useSites } from '../../../../resources/organizationSettings/sitesHandler';
import {
    useCustomPartsFromOffers,
    usePartCategories,
    useStandardPartsFromOffers,
} from '../../../../resources/part/partHandler';
import { getTotalQuantity } from '../../../../resources/sourcingScenario/getTotalQuantity';
import { useSourcingFull, useSourcingScenario } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { useSupplierAndStockLocations } from '../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { usePersistedState } from '../../../../utils/usePersistedState';
import { useTotalCostOfOwnershipRules } from '../../../Settings/OrganizationSettings/pages/TotalCostOfOwnershipRulesPage/TotalCostOfOwnershipRulesPage';
import { useManualCostWarningDialog } from '../../../SolutionManager/components/Sidebar/ButtonSolutionSelection';
import { chartSpecLeadTime } from '../ChartLeadTime';
import { chartSpecLeadTime as chartSpecLeadTime2 } from '../Charts2/ChartLeadTime';
import { ChartSolutionConfiguration as ChartSolutionConfiguration2 } from '../Charts2/ChartSolutionConfiguration';
import { chartSpecSuppliers as chartSpecSuppliers2 } from '../Charts2/ChartSuppliers';
import { chartSpecTotalPrice as chartSpecTotalPrice2 } from '../Charts2/ChartTotalPrice';
import { chartSpecUnitPrice as chartSpecUnitPrice2 } from '../Charts2/ChartUnitPrice';
import { ChartSolutionConfiguration } from '../ChartSolutionConfiguration';
import { FieldId } from '../ChartSpec';
import { chartSpecSuppliers } from '../ChartSuppliers';
import { chartSpecTotalPrice } from '../ChartTotalPrice';
import { chartSpecUnitPrice } from '../ChartUnitPrice';
import { useRequestConfigurationDialog } from '../QuoteExporter/RequestConfigurationDialog';
import { SourcingFiltersSidebar } from '../SourcingFiltersSidebar';
import { SourcingListDetailsLayout } from '../SourcingListDetailsLayout';
import SourcingScenarioSummaryBox from '../SourcingScenarios/SourcingScenarioSummaryBox';
import { EditScrapQuantityButton, useScrapQuantityHandlers } from './EditScrapQuantityButton';
import { useSolutionConfigurationTableState } from './SolutionConfigurationTable';
import { useSolutionConfigurationTable2State } from './SolutionConfigurationTable2';
import {
    ScrapQuantityHandlers,
    SolutionConfigurationsTableContext,
    SolutionConfigurationsTableData,
} from './solutionConfigurationTypes';
import {
    createSolutionConfigurationTable2Data,
    SolutionConfigurationsTable2Context,
    SolutionConfigurationTable2Data,
} from './SolutionConfigurationTypes2';
import { SourcingConfigurationContentHeader } from './SourcingConfigurationContentHeader';
import { ToolbarSourcingScenario } from './ToolbarSourcingScenario';
import { SourcingCalculation } from './types';

interface SolutionConfigurationsProps {
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    sourcingCalculationDTO: SourcingCalculation;
    filteredAssemblyId?: string;
}

function extractDisplayableSupplierName(
    itemType: SolutionConfigurationsTableData['itemType'],
    supplier: SupplierAndStockLocationDTO | undefined,
) {
    if (itemType === 'consigned') {
        return { supplierName: t`Consigned by the customer`, supplierId: undefined };
    }
    if (itemType === 'unknown') {
        return { supplierName: t`No offers`, supplierId: undefined };
    }
    if (itemType === 'unavailable') {
        return { supplierName: t`No offers found`, supplierId: undefined };
    }

    if (itemType === 'inventoryOffer') {
        return { supplierName: t`Inventory`, supplierId: undefined };
    }

    if (itemType === 'solutionOnly' || !isPresent(supplier)) {
        return { supplierName: t`Unknown`, supplierId: undefined };
    }

    if (itemType === 'marketOffer') {
        return { supplierName: formatSupplierAndStockLocationDTO(supplier), supplierId: supplier.id };
    }
    if (itemType === 'customPart') {
        return { supplierName: formatSupplierAndStockLocationDTO(supplier), supplierId: supplier.id };
    }

    assertUnreachable(itemType);
}

function extractLineValue({
    solution,
    solutionConfigurationSourcing,
    sourcingScenario,
}: {
    solution: Pick<Solution, 'unitPrice' | 'firstPurchaseOption'>;
    solutionConfigurationSourcing: Pick<SolutionConfigurationDTO, 'aggregated_quantity'>;
    sourcingScenario: SourcingScenarioDTO;
}): MonetaryValueBackend | null {
    const { unitPrice } = solution;

    if (!isPresent(unitPrice)) {
        return null;
    }

    const aggregateQuantity = solutionConfigurationSourcing.aggregated_quantity.quantity;
    const sourcingScenarioQuantity = getTotalQuantity(sourcingScenario);
    const quantityPerDesignator = aggregateQuantity / sourcingScenarioQuantity;

    return {
        amount: String(Number(unitPrice.amount) * quantityPerDesignator),
        currency: unitPrice.currency,
    };
}

function createSolutionConfigurationsTableData({
    item,
    standardPartOffersById,
    customPartOffersById,
    standardParts,
    customParts,
    purchaseOrders,
    sourcingScenario,
    quotes,
    partCategoriesById,
    excelOriginInfos,
}: {
    item: SolutionConfigurationSourcingDTO;
    standardPartOffersById: Map<string, StandardPartOfferDTO>;
    customPartOffersById: Map<string, CustomOptionOfferDTO>;
    standardParts: Array<OtsFullPart | OtsComponentFull>;
    customParts: Array<CustomFullPart | CustomComponentFull>;
    purchaseOrders: PurchaseOrderDTO[];
    sourcingScenario: SourcingScenarioDTO;
    quotes: QuoteTrackingLight[];
    partCategoriesById: Map<string, PartCategoryDTO>;
    excelOriginInfos: Array<SolutionConfigurationExcelOriginInfo>;
}): SolutionConfigurationsTableData {
    const baseValues = {
        quotes,
        solutionConfigurationSourcing: item,
        designators: extractDesignators(item),
        standardPartOptions: extractStandardPartOptions(item, { filterByStatus: ApprovalStatus.Approved }),
        customPartOptions: extractCustomPartOptions(item, { filterByStatus: ApprovalStatus.Approved }),
        purchaseOrder: purchaseOrders.find((po) => po.line_items.some((li) => li.solution_config_id === item.id)),
        sourcingScenario,
        partCategories: [],
        lineValue: null,
        excelOriginInfo: excelOriginInfos.find((x) => x.solution_configuration_id === item.id),
    };

    if (!isPresent(baseValues.standardPartOptions) && !isPresent(baseValues.customPartOptions)) {
        return {
            ...baseValues,
            itemType: 'unknown',
            solution: undefined,
            offer: undefined,
            selectedPart: undefined,
            ...extractDisplayableSupplierName('unknown', undefined),
        };
    }

    if (!isPresent(item.effective_solution)) {
        return {
            ...baseValues,
            itemType: 'unavailable',
            solution: undefined,
            offer: undefined,
            selectedPart: undefined,
            ...extractDisplayableSupplierName('unknown', undefined),
        };
    }

    const solution = parseSolution(item.effective_solution);

    const standardPartOffer = standardPartOffersById.get(solution.firstPurchaseOption.offer);
    const customPartOffer = customPartOffersById.get(solution.firstPurchaseOption.offer);

    const standardPart = standardParts.find((p) => p.id === standardPartOffer?.linked_part.id);
    const customPart = customParts.find((p) => p.id === customPartOffer?.linked_part.id);

    if (isPresent(standardPartOffer) && isPresent(standardPart) && isStandardPartMarketOffer(standardPartOffer)) {
        const partCategories = isOtsFullPart(standardPart)
            ? (standardPart.part_category?.path
                  .map((id) => partCategoriesById.get(String(id)))
                  .flatMap((x) => (isPresent(x) ? [x] : []))
                  .reverse() ?? [])
            : [];

        return {
            ...baseValues,
            itemType: 'marketOffer',
            solution,
            supplierAndStockLocation: standardPartOffer.linked_location,
            offer: standardPartOffer,
            selectedPart: standardPart,
            partCategories,
            lineValue: extractLineValue({ solution, solutionConfigurationSourcing: item, sourcingScenario }),
            ...extractDisplayableSupplierName('marketOffer', standardPartOffer.linked_location),
        };
    }

    if (isPresent(customPartOffer) && isPresent(customPart)) {
        return {
            ...baseValues,
            itemType: 'customPart',
            solution,
            supplierAndStockLocation: customPartOffer.linked_location,
            offer: customPartOffer,
            selectedPart: customPart,
            lineValue: extractLineValue({ solution, solutionConfigurationSourcing: item, sourcingScenario }),
            assemblyName: extractAssemblyName(item),
            ...extractDisplayableSupplierName('customPart', customPartOffer.linked_location),
        };
    }

    if (isPresent(standardPartOffer) && isPresent(standardPart) && isStandardPartInventoryOffer(standardPartOffer)) {
        const partCategories = isOtsFullPart(standardPart)
            ? (standardPart.part_category?.path
                  .map((id) => partCategoriesById.get(String(id)))
                  .flatMap((x) => (isPresent(x) ? [x] : []))
                  .reverse() ?? [])
            : [];

        return {
            ...baseValues,
            itemType: 'inventoryOffer',
            solution,
            inventorySite: standardPartOffer.linked_location,
            offer: standardPartOffer,
            selectedPart: standardPart,
            partCategories,
            lineValue: extractLineValue({ solution, solutionConfigurationSourcing: item, sourcingScenario }),
            ...extractDisplayableSupplierName('inventoryOffer', undefined),
        };
    }

    if (isConsignedSolution(solution)) {
        return {
            ...baseValues,
            itemType: 'consigned',
            solution: solution,
            offer: undefined,
            selectedPart: undefined,
            ...extractDisplayableSupplierName('consigned', undefined),
        };
    }

    // TODO: This is a workaround for the case where the offer is not available.
    return {
        ...baseValues,
        itemType: 'solutionOnly',
        solution: solution,
        offer: undefined,
        selectedPart: undefined,
        ...extractDisplayableSupplierName('solutionOnly', undefined),
    };
}

function useSolutionConfigurationsTableData({
    rfqId,
    sourcingScenarioId,
}: {
    rfqId: string;
    sourcingScenarioId: string;
}): SolutionConfigurationsTableData[] | undefined {
    const { data: fullSourcingDTO } = useSourcingFull(sourcingScenarioId);
    const { data: sourcingScenario } = useSourcingScenario(sourcingScenarioId);
    const { data: excelOriginInfos } = useHttpQuery(
        'POST /sourcing-scenarios/excel-origins/bulk',
        { requestBody: { ids: [sourcingScenarioId] } },
        {
            select: (res) => res.items,
        },
    );

    const rfqContext: RfqContext = {
        type: 'WithinRfQ',
        rfq_id: rfqId,
    };

    const stardardPartOffers = React.useMemo(() => {
        if (!isPresent(fullSourcingDTO)) {
            return undefined;
        }
        return [...fullSourcingDTO.off_the_shelf_offers.items, ...fullSourcingDTO.inventory_offers.items];
    }, [fullSourcingDTO]);

    const { data: partCategories = [] } = usePartCategories();
    const { data: standardParts } = useStandardPartsFromOffers(stardardPartOffers, rfqContext);
    const { data: customParts } = useCustomPartsFromOffers(fullSourcingDTO?.custom_part_offers.items, rfqContext);
    const { data: purchaseOrders } = useHttpQuery(
        'POST /purchase-orders/find',
        {
            requestBody: { sourcing_scenario_ids: [sourcingScenarioId] },
        },
        {
            select: (data) => data.purchase_orders,
        },
    );

    return React.useMemo(() => {
        if (
            !fullSourcingDTO ||
            !stardardPartOffers ||
            !standardParts ||
            !customParts ||
            !purchaseOrders ||
            !sourcingScenario ||
            !excelOriginInfos
        ) {
            return undefined;
        }

        const standardPartOffersById = indexBy(stardardPartOffers, (x) => x.id);
        const customPartOffersById = indexBy(fullSourcingDTO.custom_part_offers.items, (x) => x.id);

        const items = fullSourcingDTO.solution_configurations_sourcing.items.map((item) => {
            return createSolutionConfigurationsTableData({
                item,
                standardPartOffersById,
                customPartOffersById,
                standardParts,
                customParts,
                purchaseOrders,
                sourcingScenario,
                quotes: uniqBy(fullSourcingDTO.quotes_by_solution_config[item.id] ?? [], (x) => x.id),
                partCategoriesById: indexBy(partCategories, (x) => String(x.id)),
                excelOriginInfos,
            });
        });

        // Sort the items by default using the designator column. The DataTable will perform additional sorting
        // depending on the user's preferences but this ensures a sensible default order.
        return sortBy(items, (s) => s.designators[0] ?? '');
    }, [
        fullSourcingDTO,
        stardardPartOffers,
        standardParts,
        customParts,
        purchaseOrders,
        sourcingScenario,
        partCategories,
        excelOriginInfos,
    ]);
}

function useSolutionConfigurationsTableContext({ rfqId }: { rfqId: string }): SolutionConfigurationsTableContext {
    const { data: supplierAndStockLocations = [] } = useSupplierAndStockLocations();
    const { data: inventorySites = [] } = useInventorySites();
    const { data: totalCostOfOwnershipRules = [] } = useTotalCostOfOwnershipRules();
    const scrapQuantityHandlers = useScrapQuantityHandlers();

    const init: Record<string, string> = {};
    const tcoRuleNameMap = totalCostOfOwnershipRules.reduce((agg, tcoRule) => {
        agg[tcoRule.id] = tcoRule.name;
        return agg;
    }, init);

    return {
        rfqId,
        scrapQuantityHandlers,
        displaySiteName: inventorySites.length > 1,
        supplierAndStockLocations,
        tcoRuleNameMap,
    };
}

function useSolutionConfigurationsTable2({
    rfqId,
    sourcingCalculationDTO,
}: {
    rfqId: string;
    sourcingCalculationDTO: SourcingCalculationDTO;
}): {
    data: SolutionConfigurationTable2Data[] | undefined;
    sharedContext: SolutionConfigurationsTable2Context;
} {
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();
    const { data: sites } = useSites();
    const scrapQuantityHandlers = useScrapQuantityHandlers();

    return React.useMemo(() => {
        const sharedContext: SolutionConfigurationsTable2Context = {
            rfqId,
            scrapQuantityHandlers,
            displaySiteName: sites.length > 1,
        };

        const data = createSolutionConfigurationTable2Data(sourcingCalculationDTO, supplierAndStockLocations, sites);

        return { data, sharedContext };
    }, [rfqId, sourcingCalculationDTO, scrapQuantityHandlers, sites, supplierAndStockLocations]);
}

const ActionButtonOld: React.FunctionComponent<{
    table: Table<SolutionConfigurationsTableData>;
    sharedContext: SolutionConfigurationsTableContext;
}> = ({ table, sharedContext }) => {
    const selectedItems = table.getSelectedRowModel().rows.map((row) => row.original);

    const manuallySelectedPartIds = selectedItems
        .flatMap((item) =>
            extractPartOptions(item.solutionConfigurationSourcing, {
                filterByStatus: ApprovalStatus.Approved,
            }),
        )
        .map((part) => extractPartDTO(part));

    const solutionStatuses = selectedItems.map(({ solution }) =>
        isPresent(solution) ? extractSolutionStatus(solution, 'effective') : SolutionStatus.Error,
    );

    const solutionConfigurationsWithOffers = selectedItems
        .filter(({ solution }) => isPresent(solution) && !isConsignedSolution(solution))
        .map(({ solutionConfigurationSourcing }) => solutionConfigurationSourcing.id);

    const solutionConfigurationIds = table
        .getRowModel()
        .rows.map((row) => row.original.solutionConfigurationSourcing.id);

    const extractSelection = (item: SolutionConfigurationsTableData): SolutionSelection => {
        const solution = item.solution;

        if (!isPresent(solution)) {
            return 'Unknown';
        }
        if (hasSolutionTag(solution, SolutionTag.AutoSelected) && !hasSolutionTag(solution, SolutionTag.Selected)) {
            return 'Automatic';
        }
        if (hasSolutionTag(solution, SolutionTag.Selected)) {
            return 'Manual';
        }
        throwErrorUnlessProduction(new Error('Solution has no selection status'));
        return 'Unknown';
    };

    const selectedSolutionConfigurations: MultiManualSelectedSolutionConfiguration[] = selectedItems.map((item) => {
        const id = item.solutionConfigurationSourcing.id;
        const hasSolution = isPresent(item.solution);
        const solutionTags = item.solution?.solutionTags;
        const isConsigned = item.solutionConfigurationSourcing.is_consigned;
        const token = item.solution?.token;
        const selection = extractSelection(item);

        return {
            id,
            hasSolution,
            solutionTags,
            isConsigned,
            token,
            selection,
        };
    });

    return (
        <ActionButtonInternal
            rfqId={sharedContext.rfqId}
            selectedItemCount={selectedItems.length}
            manuallySelectedPartIds={manuallySelectedPartIds}
            solutionStatuses={solutionStatuses}
            solutionConfigurationsWithOffers={solutionConfigurationsWithOffers}
            solutionConfigurationIds={solutionConfigurationIds}
            scrapQuantityHandlers={sharedContext.scrapQuantityHandlers}
            selectedSolutionConfigurations={selectedSolutionConfigurations}
        />
    );
};

const extractPartDTOFromPartLite = (part: PartLite): PartDTO | undefined => {
    const partKind = part.kind;
    const data = part.id;
    switch (partKind) {
        case PartLiteTypes.OffTheShelf:
            return {
                type: PartIdTypes.OffTheShelf,
                data,
            };
        case PartLiteTypes.Custom:
            return {
                type: PartIdTypes.Custom,
                data,
            };
        case PartLiteTypes.CustomComponent:
            return {
                type: PartIdTypes.CustomComponent,
                data,
            };
        case PartLiteTypes.Generic:
            return {
                type: PartIdTypes.Generic,
                data,
            };
        case PartLiteTypes.Ipn:
            return {
                type: PartIdTypes.Ipn,
                data,
            };
        case PartLiteTypes.RawSpecification:
            return {
                type: PartIdTypes.RawSpecification,
                data,
            };
        case PartLiteTypes.Unknown:
            return undefined;
        default:
            assertUnreachable(partKind);
    }
};

const extractSolutionStatus2 = (row: SolutionConfigurationTable2Data): SolutionStatus => {
    const status = row.solutionConfigurationSummary.status;
    const type = status.type;
    switch (type) {
        case 'NoApprovedParts':
        case 'NoOffer':
            return SolutionStatus.Error;
        case 'Status':
            return status.data.status;
        default:
            assertUnreachable(type);
    }
};

const ActionButtonNew: React.FunctionComponent<{
    table: Table<SolutionConfigurationTable2Data>;
    sharedContext: SolutionConfigurationsTable2Context;
}> = ({ table, sharedContext }) => {
    const selectedItems = table.getSelectedRowModel().rows.map((row) => row.original);

    const manuallySelectedPartIds = selectedItems
        .flatMap((item) => item.partSummary.approved_part_options)
        .map(extractPartDTOFromPartLite)
        .filter(isPresent);

    const solutionStatuses = selectedItems.map(extractSolutionStatus2);

    const solutionConfigurationsWithOffers = selectedItems
        .filter((row) => row.offerSummary.type === 'Offer')
        .map((row) => row.id);

    const solutionConfigurationIds = table.getRowModel().rows.map((row) => row.original.id);

    const selectedSolutionConfigurations: MultiManualSelectedSolutionConfiguration[] = selectedItems.map((item) => {
        const id = item.id;
        const solutionTags =
            item.solutionConfigurationSummary.status.type === 'Status'
                ? item.solutionConfigurationSummary.status.data.tags
                : undefined;
        const hasSolution = isPresent(solutionTags);
        const isConsigned = item.offerSummary.type === 'Consigned';
        const token = item.token ?? undefined;
        const selection = item.solutionConfigurationSummary.selection;

        return {
            id,
            hasSolution,
            isConsigned,
            solutionTags,
            selection,
            token,
        };
    });

    return (
        <ActionButtonInternal
            rfqId={sharedContext.rfqId}
            selectedItemCount={selectedItems.length}
            manuallySelectedPartIds={manuallySelectedPartIds}
            solutionStatuses={solutionStatuses}
            solutionConfigurationsWithOffers={solutionConfigurationsWithOffers}
            solutionConfigurationIds={solutionConfigurationIds}
            scrapQuantityHandlers={sharedContext.scrapQuantityHandlers}
            selectedSolutionConfigurations={selectedSolutionConfigurations}
        />
    );
};

const ActionButtonInternal: React.FunctionComponent<{
    rfqId: string;
    selectedItemCount: number;
    manuallySelectedPartIds: PartDTO[];
    solutionStatuses: SolutionStatus[];
    solutionConfigurationsWithOffers: string[];
    solutionConfigurationIds: string[];
    scrapQuantityHandlers: ScrapQuantityHandlers;
    selectedSolutionConfigurations: MultiManualSelectedSolutionConfiguration[];
}> = ({
    rfqId,
    selectedItemCount,
    manuallySelectedPartIds,
    solutionStatuses,
    solutionConfigurationsWithOffers,
    solutionConfigurationIds,
    scrapQuantityHandlers,
    selectedSolutionConfigurations,
}): JSX.Element => {
    const hasSelectedItems = selectedItemCount > 0;

    return (
        <Flexbox gap={8}>
            {hasSelectedItems && (
                <Flexbox gap={8} alignItems={'center'}>
                    <Text variant="body" color={colorSystem.neutral[6]}>{t`${selectedItemCount} rows selected`}</Text>

                    <RequestQuoteButtons rfqId={rfqId} manuallySelectedPartIds={manuallySelectedPartIds} />

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />

                    <MultiManualSolutionStatusSelector
                        solutionStatuses={solutionStatuses}
                        solutionConfigurationsWithOffer={solutionConfigurationsWithOffers}
                    />

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />

                    <MultiManuallySelectedSolutionSelector solutionConfigurations={selectedSolutionConfigurations} />
                </Flexbox>
            )}

            {!hasSelectedItems && (
                <EditScrapQuantityButton
                    solutionConfigurationIds={solutionConfigurationIds}
                    handlers={scrapQuantityHandlers}
                />
            )}
        </Flexbox>
    );
};

const RequestQuoteButtons: React.FunctionComponent<{
    rfqId: string;
    manuallySelectedPartIds: PartDTO[];
}> = ({ rfqId, manuallySelectedPartIds }): JSX.Element => {
    const { openDialog } = useRequestConfigurationDialog({
        rfqId,
        manuallySelectedPartIds,
    });

    const { mutateAsync: download, isPending: isLoadingFile } = useDownloadQuotePriceTemplate();
    const disabled = isLoadingFile || manuallySelectedPartIds.length === 0;

    return (
        <Flexbox gap={8}>
            <SecondaryButton
                size="medium"
                disabled={disabled}
                onClick={() =>
                    download({ requestBody: { rfq: rfqId, part_ids: manuallySelectedPartIds.map((p) => p.data) } })
                }
                isLoading={isLoadingFile}
                startIcon={<GetApp />}
            >
                <Trans>Download quote template</Trans>
            </SecondaryButton>
            <SecondaryButton
                size="medium"
                disabled={disabled}
                onClick={() => openDialog()}
                startIcon={<RequestViaMailIcon />}
            >
                {t({ id: 'request.quote.cta', message: 'Request quote' })}
            </SecondaryButton>
        </Flexbox>
    );
};

const MultiManualSolutionStatusSelector: React.FunctionComponent<{
    solutionStatuses: SolutionStatus[];
    solutionConfigurationsWithOffer: string[];
}> = ({ solutionStatuses, solutionConfigurationsWithOffer }): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Status updated`,
    });

    const isAllSameValue = solutionStatuses.every((status) => status === solutionStatuses[0]);

    const handleClick = React.useCallback(
        (manualSolutionStatus: SolutionStatus) => {
            const items = solutionConfigurationsWithOffer.map((id) => {
                return { id, update: { manual_solution_status: manualSolutionStatus } };
            });
            mutateAsync({ requestBody: { items } });
        },
        [solutionConfigurationsWithOffer, mutateAsync],
    );

    return (
        <Flexbox gap={12} alignItems={'center'}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Status</Trans>
            </Text>

            <MenuButton
                size="medium"
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        {isAllSameValue && <SolutionStatusCircleIcon status={solutionStatuses[0]} />}
                        {isAllSameValue ? formatSolutionStatus(solutionStatuses[0]) : t`Mixed`}
                        <ArrowDropDownRounded />
                    </Flexbox>
                }
                appearance="secondary"
                disabled={isLoading || solutionConfigurationsWithOffer.length === 0}
                menuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: -4 },
                }}
            >
                {[SolutionStatus.Good, SolutionStatus.Warning, SolutionStatus.Error].map((manualSolutionStatus) => (
                    <MenuItem
                        key={manualSolutionStatus}
                        startIcon={<SolutionStatusCircleIcon status={manualSolutionStatus} />}
                        onClick={() => handleClick(manualSolutionStatus)}
                        label={formatSolutionStatus(manualSolutionStatus)}
                    />
                ))}
            </MenuButton>
        </Flexbox>
    );
};

type MultiManualSelectedSolutionConfiguration = {
    id: string;
    hasSolution: boolean;
    solutionTags: Tag[] | undefined;
    isConsigned: boolean;
    token: string | undefined;
    selection: SolutionSelection;
};

const MultiManuallySelectedSolutionSelector = ({
    solutionConfigurations,
}: {
    solutionConfigurations: MultiManualSelectedSolutionConfiguration[];
}): JSX.Element => {
    const selections: ('Automatic' | 'Manual')[] = solutionConfigurations
        .flatMap((solutionConfig) => {
            const selection = solutionConfig.selection;
            switch (selection) {
                case 'Unknown':
                    return undefined;
                case 'Manual':
                    return 'Manual';
                case 'Automatic':
                    return 'Automatic';
                default:
                    return assertUnreachable(selection);
            }
        })
        .filter(isPresent);

    const isSelectionEqualForAll = new Set(selections).size === 1;

    const changeableSolutionConfigurations = solutionConfigurations.filter(
        (solutionConfig) => solutionConfig.hasSolution && !solutionConfig.isConsigned,
    );

    const formatSelection = (selection: 'Automatic' | 'Manual'): string => {
        if (!isSelectionEqualForAll) {
            return t`Mixed`;
        }
        return selection === 'Automatic' ? t`Automatic` : t`Manual`;
    };

    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Selection updated`,
    });

    const { openDialog } = useManualCostWarningDialog();

    const handleClick = React.useCallback(
        (manualSolutionStatus: 'Automatic' | 'Manual') => {
            const items = changeableSolutionConfigurations.map(({ id, solutionTags, token }) => {
                if (!isPresent(solutionTags)) {
                    return { id, update: {} };
                }

                if (manualSolutionStatus === 'Automatic') {
                    return { id, update: { manually_selected_solution_token: null } };
                }

                if (manualSolutionStatus === 'Manual' && !hasSolutionTag({ solutionTags }, SolutionTag.Selected)) {
                    return {
                        id,
                        update: { manually_selected_solution_token: token },
                    };
                }

                return { id, update: {} };
            });
            mutateAsync({ requestBody: { items } });
        },
        [changeableSolutionConfigurations, mutateAsync],
    );

    return (
        <Flexbox gap={12} alignItems="center">
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Selection</Trans>
            </Text>

            <MenuButton
                size="medium"
                label={
                    <Flexbox gap={4} alignItems="center">
                        {formatSelection(selections[0])}
                        <ArrowDropDownRounded />
                    </Flexbox>
                }
                appearance="secondary"
                disabled={isLoading || changeableSolutionConfigurations.length === 0}
                menuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: -4 },
                }}
            >
                <MenuItem
                    startIcon={<AutoAwesomeRounded />}
                    label={t`Automatic`}
                    onClick={() => {
                        if (
                            changeableSolutionConfigurations.some(
                                ({ solutionTags }) =>
                                    isPresent(solutionTags) &&
                                    hasSolutionTag({ solutionTags }, SolutionTag.ManualOneTimeCost),
                            )
                        ) {
                            openDialog(() => handleClick('Automatic'));
                        } else {
                            handleClick('Automatic');
                        }
                    }}
                />
                <MenuItem startIcon={<LockOutlined />} label={t`Manual`} onClick={() => handleClick('Manual')} />
            </MenuButton>
        </Flexbox>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useFilteredAssembly = (assemblyId: string | undefined) => {
    const { data: assembly } = useAssembly(assemblyId ?? '', {
        enabled: isPresent(assemblyId),
        with_aggregations: false,
    });

    return React.useMemo(() => {
        if (!isPresent(assembly)) {
            return undefined;
        }

        return {
            id: assemblyId,
            name: assembly.designator,
            parent: Object.keys(assembly.parents).map((parent) => parent)[0],
        };
    }, [assemblyId, assembly]);
};

const useAssemblyFilter = <T extends {}>(filteredAssemblyId: string | undefined, table: Table<T>) => {
    const filteredAssembly = useFilteredAssembly(filteredAssemblyId);
    React.useEffect(() => {
        if (filteredAssembly) {
            table.setColumnFilters([
                {
                    id: 'assembly',
                    value: {
                        filterFn: SupportedFilterFn.arrIncludesSome,
                        value: [filteredAssembly],
                    },
                },
            ]);
        }
    }, [filteredAssembly, table]);
};

const SolutionConfigurations: React.FunctionComponent<SolutionConfigurationsProps> = ({
    rfqId,
    sourcingScenarioDTO,
    sourcingCalculationDTO,
    filteredAssemblyId,
}: SolutionConfigurationsProps): JSX.Element => {
    const [graphId, setSelectedGraphId] = usePersistedState<FieldId>(
        'sourcing.solutionConfiguration.selectedGraphId',
        'table',
    );

    return (
        <PageLayout
            removeHubspotPaddingFix
            header={
                <ToolbarSourcingScenario
                    rfqId={rfqId}
                    sourcingScenarioName={sourcingScenarioDTO.name}
                    sourcingScenarioId={sourcingScenarioDTO.id}
                />
            }
        >
            <SourcingListDetailsLayout
                title={t`Solution preferences`}
                sidebar={<SourcingFiltersSidebar sourcingScenarioId={sourcingScenarioDTO.id} />}
            >
                {sourcingCalculationDTO.type === 'old' ? (
                    <SolutionConfigurationsOld
                        rfqId={rfqId}
                        sourcingScenarioDTO={sourcingScenarioDTO}
                        fullSourcingDTO={sourcingCalculationDTO.data}
                        graphId={graphId}
                        setSelectedGraphId={setSelectedGraphId}
                        filteredAssemblyId={filteredAssemblyId}
                    />
                ) : (
                    <SolutionConfigurationsNew
                        rfqId={rfqId}
                        sourcingScenarioDTO={sourcingScenarioDTO}
                        sourcingCalculationDTO={sourcingCalculationDTO.data}
                        graphId={graphId}
                        setSelectedGraphId={setSelectedGraphId}
                        filteredAssemblyId={filteredAssemblyId}
                    />
                )}
            </SourcingListDetailsLayout>
        </PageLayout>
    );
};

const SolutionConfigurationsOld: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    fullSourcingDTO: FullSourcingDTO;
    graphId: FieldId;
    setSelectedGraphId: (graphId: FieldId) => void;
    filteredAssemblyId: string | undefined;
}> = ({ rfqId, sourcingScenarioDTO, fullSourcingDTO, graphId, setSelectedGraphId, filteredAssemblyId }) => {
    const sourcingScenarioId = sourcingScenarioDTO.id;

    const data = useSolutionConfigurationsTableData({ rfqId, sourcingScenarioId });
    const sharedContext = useSolutionConfigurationsTableContext({ rfqId });
    const { table } = useSolutionConfigurationTableState({
        rfqId,
        sourcingScenarioId: sourcingScenarioDTO.id,
        data,
        sharedContext,
    });

    useAssemblyFilter(filteredAssemblyId, table);

    const supplierCount = data && new Set(data.map((s) => s.supplierId).filter(isPresent)).size;
    const solutionConfigurations = fullSourcingDTO.solution_configurations_sourcing.items.map((solutionConfig) => {
        return {
            solutionTags: solutionConfig.effective_solution?.tags,
        };
    });

    return (
        <>
            <SourcingConfigurationContentHeader
                rfqId={rfqId}
                sourcingScenarioDTO={sourcingScenarioDTO}
                solutionConfigurations={solutionConfigurations}
            />

            <SourcingScenarioSummaryBox
                sourcingScenarioId={sourcingScenarioId}
                sourcingCalculation={{ type: 'old', data: fullSourcingDTO, supplierCount }}
                selectedGraphId={graphId}
                setSelectedGraphId={setSelectedGraphId}
            />

            {graphId !== 'table' && <SolutionConfigurationGraph graphId={graphId} table={table} />}
            {graphId === 'table' && (
                <Flexbox flexDirection="column" flexGrow={1}>
                    <TanStackTable
                        table={table}
                        size={'medium'}
                        ActionButton={ActionButtonOld}
                        enableMenuBar={({ table, sharedContext }) => {
                            const hasSelectedItems = table.getSelectedRowModel().rows.length > 0;
                            const hasEnabledScrapQuantity = sharedContext.scrapQuantityHandlers.isEditable;
                            return {
                                resultCount: !hasSelectedItems && !hasEnabledScrapQuantity,
                                controlButtons: !hasSelectedItems && !hasEnabledScrapQuantity,
                            };
                        }}
                    />
                </Flexbox>
            )}
        </>
    );
};

const SolutionConfigurationsNew: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    sourcingCalculationDTO: SourcingCalculationDTO;
    graphId: FieldId;
    setSelectedGraphId: (graphId: FieldId) => void;
    filteredAssemblyId: string | undefined;
}> = ({ rfqId, sourcingScenarioDTO, sourcingCalculationDTO, graphId, setSelectedGraphId, filteredAssemblyId }) => {
    const sourcingScenarioId = sourcingScenarioDTO.id;

    const { data, sharedContext } = useSolutionConfigurationsTable2({
        rfqId,
        sourcingCalculationDTO,
    });
    const { table } = useSolutionConfigurationTable2State({
        rfqId,
        sourcingScenarioId,
        data,
        sharedContext,
    });

    useAssemblyFilter(filteredAssemblyId, table);

    const solutionConfigurations = sourcingCalculationDTO.solution_configurations
        .map((solutionConfig) => {
            const solutionStatus = solutionConfig.solution_configuration_summary.status;
            const type = solutionStatus.type;
            switch (type) {
                case 'NoApprovedParts':
                case 'NoOffer':
                    return undefined;
                case 'Status':
                    return { solutionTags: solutionStatus.data.tags.map((tag) => tag) };
                default:
                    return assertUnreachable(type);
            }
        })
        .filter(isPresent);

    return (
        <>
            <SourcingConfigurationContentHeader
                rfqId={rfqId}
                sourcingScenarioDTO={sourcingScenarioDTO}
                solutionConfigurations={solutionConfigurations ?? []}
            />

            <SourcingScenarioSummaryBox
                sourcingScenarioId={sourcingScenarioId}
                sourcingCalculation={{ type: 'new', data: sourcingCalculationDTO }}
                selectedGraphId={graphId}
                setSelectedGraphId={setSelectedGraphId}
            />

            {graphId !== 'table' && <SolutionConfigurationGraph2 graphId={graphId} table={table} />}
            {graphId === 'table' && (
                <Flexbox flexDirection="column" flexGrow={1}>
                    <TanStackTable<SolutionConfigurationTable2Data, SolutionConfigurationsTable2Context>
                        table={table}
                        size={'medium'}
                        ActionButton={ActionButtonNew}
                        enableMenuBar={({ table, sharedContext }) => {
                            const hasSelectedItems = table.getSelectedRowModel().rows.length > 0;
                            const hasEnabledScrapQuantity = sharedContext.scrapQuantityHandlers.isEditable;
                            return {
                                resultCount: !hasSelectedItems && !hasEnabledScrapQuantity,
                                controlButtons: !hasSelectedItems && !hasEnabledScrapQuantity,
                            };
                        }}
                    />
                </Flexbox>
            )}
        </>
    );
};

export default SolutionConfigurations;

const SolutionConfigurationGraph: React.FunctionComponent<{
    graphId: FieldId;
    table: Table<SolutionConfigurationsTableData>;
}> = ({ graphId, table }): JSX.Element => {
    const renderGraph = React.useCallback(
        (graphId: FieldId) => {
            const data = table.getRowModel().rows.map((row) => row.original);
            const sharedContext = assertPresent(
                table.options.meta?.sharedContext,
            ) as SolutionConfigurationsTableContext;

            if (graphId === 'unitPrice') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecUnitPrice}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'effectiveTotalPrice') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecTotalPrice}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'leadTime') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecLeadTime}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'supplierName') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecSuppliers}
                        sharedContext={sharedContext}
                    />
                );
            }

            if (graphId === 'table') {
                return <></>;
            }

            assertUnreachable(graphId);
        },
        [table],
    );

    return (
        <Flexbox flexDirection="column">
            <TanStackMenuBar table={table} enableMenuBar={{ controlButtons: true }} />
            <React.Fragment key={graphId}>{renderGraph(graphId)}</React.Fragment>
        </Flexbox>
    );
};

const SolutionConfigurationGraph2: React.FunctionComponent<{
    graphId: FieldId;
    table: Table<SolutionConfigurationTable2Data>;
}> = ({ graphId, table }): JSX.Element => {
    const renderGraph = React.useCallback(
        (graphId: FieldId) => {
            const data = table.getRowModel().rows.map((row) => row.original);
            const sharedContext = assertPresent(
                table.options.meta?.sharedContext,
            ) as SolutionConfigurationsTable2Context;

            if (graphId === 'unitPrice') {
                return (
                    <ChartSolutionConfiguration2
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecUnitPrice2}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'effectiveTotalPrice') {
                return (
                    <ChartSolutionConfiguration2
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecTotalPrice2}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'leadTime') {
                return (
                    <ChartSolutionConfiguration2
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecLeadTime2}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'supplierName') {
                return (
                    <ChartSolutionConfiguration2
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecSuppliers2}
                        sharedContext={sharedContext}
                    />
                );
            }

            if (graphId === 'table') {
                return <></>;
            }

            assertUnreachable(graphId);
        },
        [table],
    );

    return (
        <Flexbox flexDirection="column">
            <TanStackMenuBar table={table} enableMenuBar={{ controlButtons: true }} />
            <React.Fragment key={graphId}>{renderGraph(graphId)}</React.Fragment>
        </Flexbox>
    );
};
