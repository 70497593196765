import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface TrustedPartsCredentialsDTO extends r.Static<typeof TrustedPartsCredentialsDTORuntype> {}
export const TrustedPartsCredentialsDTORuntype = r.Union(
    r.Record({
        company_id: r.String,
        api_key: r.String,
        locale: r.Record({
            country: r.String,
            currency: r.String,
        }),
    }),
);

export interface TrustedPartsCredentialsInputDTO extends r.Static<typeof TrustedPartsCredentialsInputDTORuntype> {}
export const TrustedPartsCredentialsInputDTORuntype = r.Record({
    company_id: r.String.optional(),
    api_key: r.String.optional(),
    locale: r
        .Record({
            country: r.String,
            currency: r.String,
        })
        .optional(),
});

export interface TrustedPartsResponseBodyDTO extends r.Static<typeof TrustedPartsResponseBodyDTORuntype> {}
export const TrustedPartsResponseBodyDTORuntype = r.Record({
    data: TrustedPartsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});

export interface TrustedPartsSupportedSettingDTO extends r.Static<typeof TrustedPartsSupportedSettingDTORuntype> {}
export const TrustedPartsSupportedSettingDTORuntype = r.Record({
    country: r.String,
    currencies: r.Array(r.String),
});
