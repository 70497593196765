import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    CustomComponentFullRuntype,
    EmsPartNumberPartMatchRequestRuntype,
    EmsPartNumberPartMatchResponseRuntype,
    IpnFullPartMatchRuntype,
    OtsComponentFullRuntype,
    OtsOrCustomComponentRuntype,
} from '../internalPartNumber/internalPartNumberBackendTypes';
import { UsedInResponseRuntype } from '../rfq';
import { RfQContextQueryParamsRuntype } from '../rfqContext';
import {
    BackendPartOfferSummaryDTO,
    CustomFullPartRuntype,
    CustomPartInputRuntype,
    CustomPartTypeEnumRuntype,
    CustomPartUpdateInputRuntype,
    EmissionDataRequestDTORuntype,
    FileUrlRuntype,
    GenericFullPartRuntype,
    IpnInventoryRuntype,
    LoadingOffersProgressDTORuntype,
    MpnMatchesRuntype,
    OffTheShelfPartSearchFilterRequestRuntype,
    OffTheShelfPartSearchResponseRuntype,
    OffTheShelfPartSearchSortRequestRuntype,
    OffTheShelfPartVoteRuntype,
    OtsFormPatchValuesRuntype,
    OtsFormPostValuesRuntype,
    OtsFullPartRuntype,
    PackageDTORuntype,
    PackageFamilyRuntype,
    PackageTagRuntype,
    PartAlternativeRuntype,
    PartAvailabilityRuntype,
    PartCategoryDTORuntype,
    PartDTORuntype,
    PartLiteRuntype,
    RFQContextualizedBulkIdsRuntype,
    StandardPartDTORuntype,
    TechnicalParametersRuntype,
} from './partBackendTypes';

export const partEndpoints = {
    'POST /parts/lite': endpoint({
        description: 'Returns lite versions of parts for the given list of part IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.Union(PartDTORuntype, r.Record({ type: r.Literal('Unknown'), data: r.String }))),
        }),
        responseBody: r.Record({
            items: r.Array(PartLiteRuntype),
        }),
    }),

    'GET /parts/off-the-shelf-types': endpoint({
        description: 'Fetches all OTS part types',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(r.String),
        }),
    }),

    'POST /parts/off-the-shelf': endpoint({
        description: 'Creates an off-the-shelf part',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: OtsFormPostValuesRuntype,
        responseBody: r.Record({
            data: OtsFullPartRuntype,
        }),
        invalidates: ['POST /parts/off-the-shelf/bulk', 'POST /ipns/bulk'],
    }),

    'POST /parts/off-the-shelf/search/mpn/bulk': endpoint({
        description: 'Searches via mpns for multiple parts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                items: r.Array(r.String),
                search_external_apis: r.Boolean,
                use_elastic: r.Boolean,
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
                size: r.Number.optional(),
            }),
            r.Record({
                items: r.Array(r.String),
                search_external_apis: r.Boolean,
                use_elastic: r.Boolean,
                rfq_context: r.Literal('OutsideRfQ'),
                size: r.Number.optional(),
            }),
        ),
        responseBody: r.Record({
            data: r.Dictionary(r.Array(OtsFullPartRuntype)),
        }),
    }),

    'POST /parts/library/search': endpoint({
        description: 'Searches for parts in the library',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r
            .Record({
                page_size: r.Number,
                page_params: r.Unknown.Or(r.Undefined),
                filters: r.Array(OffTheShelfPartSearchFilterRequestRuntype),
                sorts: r.Array(OffTheShelfPartSearchSortRequestRuntype),
                global_filter: r.String,
                mpn_terms: r.Array(r.String), // no need for this
                include_aggregations: r.Boolean.optional(),
                search_external_apis: r.Boolean.optional(),
            })
            .And(RfQContextQueryParamsRuntype),
        responseBody: OffTheShelfPartSearchResponseRuntype,
    }),

    'PATCH /parts/off-the-shelf/:partId': endpoint({
        description: 'Updates an off-the-shelf part',
        pathParams: r.Record({ partId: r.String }),
        queryParams: RfQContextQueryParamsRuntype,
        requestBody: OtsFormPatchValuesRuntype,
        responseBody: r.Record({
            data: OtsFullPartRuntype,
        }),
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'POST /ipns/bulk',
            'POST /parts/availability',
            'POST /parts/off-the-shelf/bulk',
            'POST /parts/off-the-shelf/search/generic/bulk',
            'POST /components/bulk',
            'GET /parts/off-the-shelf/:partId/votes',
        ],
    }),

    'POST /parts/off-the-shelf/bulk': endpoint({
        description: 'Returns all off-the-shelf parts for the given list of OTS part IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RFQContextualizedBulkIdsRuntype,
        responseBody: r.Record({
            items: r.Array(OtsFullPartRuntype),
        }),
    }),

    'POST /parts/availability': endpoint({
        description:
            'Returns the availability of all the given parts. The response is a map from part IDs to availability information',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(StandardPartDTORuntype),
            assembly_id: r.String,
        }),
        responseBody: r.Record({
            items: r.Array(PartAvailabilityRuntype),
        }),
    }),

    'POST /parts/emission-data-requests': endpoint({
        description: 'Sends emission data requests for off-the-shelf part ids and generic part ids',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: EmissionDataRequestDTORuntype,
        responseBody: r.String,
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'POST /parts/off-the-shelf/bulk',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:id/descendants-part-ids-without-emission-data',
            'GET /organization/emission-request-quota',
        ],
    }),

    'PATCH /parts/off-the-shelf/bulk': endpoint({
        description: 'Updates off-the-shelf parts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Array(OtsFormPatchValuesRuntype),
        responseBody: r.Record({
            items: r.Array(OtsFullPartRuntype),
        }),
    }),

    'POST /ipns/bulk': endpoint({
        description: 'Returns all IPNs for the given list of IPN IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({
            items: r.Array(OtsComponentFullRuntype),
        }),
    }),

    'POST /components/bulk': endpoint({
        description: 'Returns all components for the given list of component IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({
            items: r.Array(OtsOrCustomComponentRuntype),
        }),
    }),

    'PATCH /components/custom/:componentId': endpoint({
        description: 'Updates custom components',
        pathParams: r.Record({ componentId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Array(CustomFullPartRuntype),
        responseBody: CustomComponentFullRuntype,
        invalidates: ['POST /components/bulk', 'POST /components/custom/bulk', 'POST /components/search'],
    }),

    'GET /parts/custom/:partId': endpoint({
        description: 'Returns a custom part for a given part ID',
        pathParams: r.Record({ partId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: CustomFullPartRuntype,
        }),
    }),

    'GET /parts/custom/:partId/upload': endpoint({
        description: 'Returns URL for uploading file to Azure',
        pathParams: r.Record({ partId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: FileUrlRuntype,
        }),
    }),

    'POST /parts/custom/bulk': endpoint({
        description: 'Returns all custom parts for the given list of part IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String) }),
        responseBody: r.Record({
            items: r.Array(CustomFullPartRuntype),
        }),
    }),

    'GET /parts/custom-types': endpoint({
        description: 'Returns all existing types for custom parts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(CustomPartTypeEnumRuntype),
        }),
    }),

    'GET /parts/custom/:partId/resources': endpoint({
        description: 'Returns a string of resources for a given part',
        pathParams: r.Record({ partId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(r.String),
        }),
    }),

    'GET /parts/library/search/progress/:taskId': endpoint({
        description: 'Returns the number of in-progress offers for the given taskID',
        pathParams: r.Record({ taskId: r.String }),
        queryParams: r.Record({
            get_part_offer_status: r.Boolean.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: LoadingOffersProgressDTORuntype,
    }),

    'POST /parts/library/sourcing': endpoint({
        description: 'Finds stock information for all the given part IDs.',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                part_ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                part_ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({ items: r.Array(BackendPartOfferSummaryDTO) }),
    }),

    'POST /parts/generic': endpoint({
        description: 'Creates a generic part',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                input: TechnicalParametersRuntype,
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
                recompute_matches: r.Optional(r.Boolean),
            }),
            r.Record({
                input: TechnicalParametersRuntype,
                rfq_context: r.Literal('OutsideRfQ'),
                recompute_matches: r.Optional(r.Boolean),
            }),
        ),
        responseBody: r.Record({
            data: GenericFullPartRuntype,
        }),
        invalidates: [
            'POST /parts/availability',
            'POST /parts/generic/bulk',
            'POST /parts/off-the-shelf/search/generic/bulk',
            'POST /parts/off-the-shelf/search/generic/bulk',
        ],
    }),

    'POST /parts/generic/bulk': endpoint({
        description: 'Returns all the generic parts for the IDs specified in the request body',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({
            items: r.Array(GenericFullPartRuntype),
        }),
    }),

    'POST /parts/off-the-shelf/search/generic/bulk': endpoint({
        description:
            'Returns all OTS parts that match the generic parts parameters for the given list of generic part IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({
            data: MpnMatchesRuntype,
        }),
    }),

    'GET /inventory/:id': endpoint({
        description: 'Returns the inventory for the part id specified in the path params',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: IpnInventoryRuntype.nullable(),
        }),
        handleResponse: async (response, _) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return { data: null };
            }
            throw Error(`Failed to GET inventory by id. Status code: ${response.status}`);
        },
    }),

    'PATCH /ipns/matches': endpoint({
        description: 'Updates the linked parts of the IPN specified in the request body',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: EmsPartNumberPartMatchRequestRuntype,
        responseBody: r.Record({
            items: r.Array(EmsPartNumberPartMatchResponseRuntype),
        }),
        invalidates: [
            'POST /components/search',
            'POST /components/bulk',
            'POST /ipns/search/linked-parts/bulk',
            'POST /ipns/bulk',
        ],
    }),

    'PATCH /components/:ipn/specifications/matches': endpoint({
        description: 'Updates the linking of a part to a part specification',
        pathParams: r.Record({ ipn: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            raw_specification_id: r.String.nullable(),
            generic_part_ids: r.Array(r.String),
            ots_part_ids: r.Array(r.String),
        }),
        responseBody: r.Record({
            items: r.Array(IpnFullPartMatchRuntype),
        }),
        invalidates: ['POST /components/bulk'],
    }),

    'GET /parts/packages': endpoint({
        description: 'Get all the packages',
        pathParams: r.Undefined,
        queryParams: r.Record({
            tags: PackageTagRuntype.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(PackageDTORuntype),
        }),
    }),

    'GET /parts/package-families': endpoint({
        description: 'Get all the package families. A package family represents a group of packages.',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(PackageFamilyRuntype),
        }),
    }),

    'POST /parts/custom': endpoint({
        description: 'Creates a custom part',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CustomPartInputRuntype,
        responseBody: r.Record({ data: CustomFullPartRuntype }),
    }),

    'PATCH /parts/custom/:partId': endpoint({
        description: 'Updates a custom part',
        pathParams: r.Record({ partId: r.String }),
        queryParams: r.Undefined,
        requestBody: CustomPartUpdateInputRuntype,
        responseBody: r.Record({ data: CustomFullPartRuntype }),
        invalidates: ['POST /components/bulk', 'POST /parts/custom/bulk'],
    }),

    'DELETE /parts/custom/:partId/files': endpoint({
        description: "Deletes custom part's file from Azure",
        pathParams: r.Record({ partId: r.String }),
        queryParams: r.Record({ filename: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: ['POST /parts/custom/bulk'],
        removes: ['POST /parts/custom/bulk'],
    }),

    'POST /parts/off-the-shelf/assemblies/bulk': endpoint({
        description: 'Returns an array of assemblies in which the specified ots parts have been used',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String), rfq_context: r.Literal('OutsideRfQ') }),
        responseBody: r.Record({
            data: UsedInResponseRuntype,
        }),
    }),

    'GET /parts/part-categories': endpoint({
        description: 'Returns all part categories',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(PartCategoryDTORuntype),
        }),
    }),

    'POST /parts/off-the-shelf/alternatives/bulk': endpoint({
        description: 'Returns all the alternatives for the given list of OTS part IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RFQContextualizedBulkIdsRuntype,
        responseBody: r.Record({
            data: r.Dictionary(r.Array(PartAlternativeRuntype), r.String),
            show_part_alternatives: r.Boolean,
        }),
    }),

    'POST /components/custom/bulk': endpoint({
        description: 'Returns all custom components for the given list of IPNs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({
            items: r.Array(CustomComponentFullRuntype),
        }),
    }),

    'GET /parts/off-the-shelf/:partId/votes': endpoint({
        description: 'Returns the votes for a specific off-the-shelf part',
        pathParams: r.Record({ partId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(OffTheShelfPartVoteRuntype),
        }),
    }),
};
