import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface WinSourceCredentialsDTO extends r.Static<typeof WinSourceCredentialsDTORuntype> {}
export const WinSourceCredentialsDTORuntype = r.Union(
    r.Record({
        access_key: r.String,
        access_token: r.String,
    }),
);

export interface WinSourceCredentialsInputDTO extends r.Static<typeof WinSourceCredentialsInputDTORuntype> {}
export const WinSourceCredentialsInputDTORuntype = r.Record({
    access_key: r.String.optional(),
    access_token: r.String.optional(),
});

export interface WinSourceResponseBodyDTO extends r.Static<typeof WinSourceResponseBodyDTORuntype> {}
export const WinSourceResponseBodyDTORuntype = r.Record({
    data: WinSourceCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
