import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { ExtractRequestBody, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { UpdateUserFormState } from '../../modules/Customers/CustomerUserManagement/UpdateUserForm';
import { useToken } from '../hooks';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { useHttpQuery } from '../http/useHttpQuery';

export function useUser(userId: string = '') {
    return useHttpQuery(
        'GET /users/:userId',
        { pathParams: { userId } },
        { enabled: Boolean(userId), select: (res) => res.data },
    );
}

/**
 * @returns all users from the organization
 */
export function useAllOrganizationUsers() {
    return useHttpQuery(
        'GET /users/organization',
        {},
        { select: (response) => response.data, refetchOnWindowFocus: true },
    );
}

/**
 * @returns all users of the customer
 */
export function useAllCustomerUsers(customerId: string | undefined) {
    return useHttpQuery(
        'GET /users/customers/:customerId',
        { pathParams: { customerId: customerId ?? '' } },
        { select: (response) => response.data, enabled: customerId !== undefined },
    );
}

/**
 * @returns all the contributors of an RfQ
 */
export const useContributors = (rfqId: string, queryOptions?: { enabled?: boolean }) => {
    return useHttpQuery(
        'GET /contributors/rfqs/:rfqId',
        { pathParams: { rfqId } },
        {
            ...queryOptions,
        },
    );
};

export function useMutationDeleteUser(userId: string) {
    const { token } = useToken();
    const debugErrorHandler = useDebugErrorHandler();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => http('DELETE /users/:userId', { pathParams: { userId } }, token),
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /users/:userId', { pathParams: { userId } }),
            });
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization') });
        },
    });
}

export function useMutationUpdateUser(userId: string) {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    return useMutation({
        mutationFn: (form: UpdateUserFormState) =>
            http(
                'PATCH /users/:userId',
                {
                    pathParams: { userId: userId },
                    requestBody: {
                        first_name: form.firstName,
                        last_name: form.lastName,
                        phone_number: form.phoneNumber,
                        position: form.position,
                    },
                },
                token,
            ),
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /users/:userId', { pathParams: { userId: userId } }),
            });
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization') });
        },
    });
}

export function useMutationAddSupportUser() {
    const token = getToken();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    return useMutation({
        mutationFn: () => http('POST /users/organization/support-user', {}, token),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization') });
            enqueueSnackbar(t`Gave access to customer support`, { variant: 'success' });
        },
        onError: debugErrorHandler,
    });
}

export function useMutationRemoveSupportUser() {
    const token = getToken();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    return useMutation({
        mutationFn: () => http('DELETE /users/organization/support-user', {}, token),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization') });
            enqueueSnackbar(t`Removed access for customer support`, { variant: 'success' });
        },
        onError: debugErrorHandler,
    });
}

/**
 * Helper function to check if the userId passed to it belongs to an admin user
 * @param userId
 * @returns boolean
 */
export function useIsUserAnAdmin(userId?: string) {
    const { data } = useHttpQuery(
        'POST /users/roles/bulk',
        {
            requestBody: { ids: [userId!] },
        },
        {
            select: (res) => res.data[0],
            meta: { globalErrorHandler: false },
        },
    );

    return data?.roles?.includes('ems:admin') || data?.roles?.includes('customer:admin');
}

export function useMutationUpdateUserRole(userId: string) {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    return useMutation({
        mutationFn: (body: ExtractRequestBody<'PATCH /users/:userId/roles'>) =>
            http(
                'PATCH /users/:userId/roles',
                {
                    pathParams: { userId: userId },
                    requestBody: body,
                },
                token,
            ),
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('POST /users/roles/bulk', { requestBody: { ids: [userId] } }),
            });
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /users/:userId', { pathParams: { userId: userId } }),
            });
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /users/:userId/roles', { pathParams: { userId: userId } }),
            });
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /users/organization'),
            });
        },
    });
}

export function useMutationInviteCustomerUsers(customerId: string) {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    return useMutation({
        mutationFn: (requestBody: ExtractRequestBody<'POST /users/customers/:customerId/invites'>) =>
            http(
                'POST /users/customers/:customerId/invites',
                {
                    pathParams: { customerId: customerId },
                    requestBody,
                },
                token,
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /users/customers/:customerId/invites', {
                    pathParams: { customerId: customerId },
                }),
            });
        },
        onError,
    });
}

export function useMutationInviteOrganizationUsers() {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    return useMutation({
        mutationFn: (requestBody: ExtractRequestBody<'POST /users/organization/invites'>) =>
            http(
                'POST /users/organization/invites',
                {
                    requestBody,
                },
                token,
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization/invites') });
        },
        onError,
    });
}
export function useMutationInviteExternalContributorUsers(rfqId: string) {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    return useMutation({
        mutationFn: (requestBody: ExtractRequestBody<'POST /contributors/rfqs/:rfqId/invite'>) =>
            http(
                'POST /contributors/rfqs/:rfqId/invite',
                {
                    requestBody,
                    pathParams: { rfqId },
                },
                token,
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization/invites') });
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /contributors/rfqs/:rfqId') });
        },
        onError,
    });
}

export function useMutationDeleteUserInvitation(inviteId: string) {
    const { token } = useToken();
    const debugErrorHandler = useDebugErrorHandler();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => http('DELETE /users/invites/:inviteId', { pathParams: { inviteId: inviteId } }, token),
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/customers/:customerId/invites') });
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /users/organization/invites') });
        },
    });
}
