import * as r from 'runtypes';
import { CustomerDTORuntype } from '../customer';
import { UserDTORuntype } from '../user';

export enum OrganizationType {
    Customer = 'OEM',
    Ems = 'EMS',
}

export enum UserType {
    Customer = 'Customer',
    Supplier = 'Supplier',
    Internal = 'Internal',
}

const ClientTypeRuntype = r.Union(r.Literal('customer'), r.Literal('test'), r.Literal('trial'), r.Literal('inactive'));

export interface OrganizationDTO extends r.Static<typeof OrganizationDTORuntype> {}
export const OrganizationDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    billing_address: r.String.nullable(),
    client_type: ClientTypeRuntype.nullable(),
    has_access_to_customer_portal: r.Boolean,
    has_access_to_source_to_contract: r.Boolean,
    has_full_manufacturing_access: r.Boolean.optional(),
    has_customers: r.Boolean,
    is_closed_beta_tester: r.Boolean,
});

export interface CurrentUserDetailsDTO extends r.Static<typeof CurrentUserDetailsDTORuntype> {}
export const CurrentUserDetailsDTORuntype = r.Record({
    user: UserDTORuntype,
    tenant: r.String,
    all_tenants_for_user: r.Array(r.String),
    organization: OrganizationDTORuntype,
    customer: CustomerDTORuntype.nullable(),
    supplier: r.Unknown.nullable(),
});

export interface VersionInformationDTO extends r.Static<typeof VersionInformationDTORuntype> {}
export const VersionInformationDTORuntype = r.Record({
    epibator_sha: r.String,
    luminovo_version: r.String,
});
