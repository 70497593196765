import { isProductionEnvironment } from '@luminovo/commons';
import { StepEnum } from '@luminovo/http-client';
import { ADMIN_PERMISSIONS, useIsCustomer } from './components/contexts/CurrentUserDetailsContext';
import { Permission } from './permissions/permissions';
import { useProtected } from './permissions/useProtected';
import { useHttpQuery } from './resources/http/useHttpQuery';

export const MANUFACTURING_LITE_PERMISSIONS: Permission[] = [
    'view:resource',
    'view:driver',
    'view:expense',
    'view:activity',
    'view:activity_configuration',
    'create:activity_configuration',
    'edit:activity_configuration',
    'view:expense_configuration',
    'create:expense_configuration',
    'edit:expense_configuration',
    'view:manufacturing_scenario_template',
    'view:manually_overwritten_driver_count',
    'create:manually_overwritten_driver_count',
    'edit:manually_overwritten_driver_count',
    'create:manufacturing_assembly_details',
    'view:manufacturing_assembly_details',
    'edit:manufacturing_assembly_details',
    'view:manufacturing_scenario',
    'create:manufacturing_scenario',
    'edit:manufacturing_scenario',
    'view:frozen_scenario_cost',
];

export const MANUFACTURING_PAID_PERMISSIONS: Permission[] = [
    'edit:resource',
    'create:resource',
    'edit:driver',
    'create:driver',
    'edit:expense',
    'create:expense',
    'edit:activity',
    'create:activity',
    'create:manufacturing_scenario_template',
    'edit:manufacturing_scenario_template',
    'delete:manufacturing_scenario_template',
    'edit:calculation_template',
    'delete:calculation_template',
    'create:calculation_template',
    'view:scenario_combinations_for_calculation',
    'create:scenario_combinations_for_calculation',
    'edit:scenario_combinations_for_calculation',
    'view:calculation_assembly_details',
    'create:calculation_assembly_details',
    'view:calculation',
    'edit:calculation_assembly_details',
    'view:calculation_assembly_cost',
    'create:calculation_assembly_cost',
    'edit:calculation_assembly_cost',
    'create:frozen_scenario_cost',
    'edit:frozen_scenario_cost',
];

type ManufacturingModuleVariant = 'full' | 'lite';

export const useManufacturingModuleVariant = (): ManufacturingModuleVariant => {
    // All paid manufacturing permissions are now exclusively in the `ems:manufacturing_user`
    // role so we can use the to determine the variant of the manufacturing module.
    const manufacturingModuleVariant = useProtected<ManufacturingModuleVariant>({
        requiredPermissions: MANUFACTURING_PAID_PERMISSIONS,
        fallback: 'lite',
        value: 'full',
    });

    return manufacturingModuleVariant;
};

//TODO: rename everywhere which says quotations v2 to calculation. https://www.notion.so/luminovo/Rename-everywhere-it-says-quotations-v2-to-calculation-747349b7805f4e439b3934070597933e
export const CALCULATION_PERMISSIONS: Permission[] = ['view:calculation'];

export const useIsCalculationModuleActive = () => {
    const isCalculationModuleActive = useProtected({
        requiredPermissions: CALCULATION_PERMISSIONS,
        fallback: false,
        value: true,
    });

    return { isCalculationModuleActive };
};

export const useIsPnpImporterActive = () => {
    const isCustomer = useIsCustomer();
    const { data: canCustomerUploadPnpFiles = false } = useHttpQuery(
        'GET /organization-settings/customer-portal',
        {},
        {
            select: (data) => {
                const uploadPnpFiles = data.customer_portal_settings.upload_pnp_files;
                return uploadPnpFiles === StepEnum.required || uploadPnpFiles === StepEnum.optional;
            },
        },
    );

    return {
        //not available for customer users at the moment
        isPnpImporterActive: !isCustomer || canCustomerUploadPnpFiles,
    };
};

export const MANUFACTURING_ADMIN_PERMISSIONS: Permission[] = [...ADMIN_PERMISSIONS, ...MANUFACTURING_LITE_PERMISSIONS];

export const QUOTATIONS_V2_ADMIN_PERMISSIONS: Permission[] = [...ADMIN_PERMISSIONS, ...CALCULATION_PERMISSIONS];

const supplyChainCollaborationEnvironments = [
    /* eslint-disable-next-line spellcheck/spell-checker */
    'cms.luminovo',
    /* eslint-disable-next-line spellcheck/spell-checker */
    'fluidra.luminovo',
    /* eslint-disable-next-line spellcheck/spell-checker */
    'hanonsystems.luminovo',
    /* eslint-disable-next-line spellcheck/spell-checker */
    'knorr-bremse.luminovo',
    'luminovo.luminovo',
    'luminovo.staging',
    'sick.luminovo',
    /* eslint-disable-next-line spellcheck/spell-checker */
    'festo.luminovo',
    'demo.luminovo',
];

export const isSupplyChainCollaborationEnvironment = (): boolean => {
    return (
        supplyChainCollaborationEnvironments.some((host) => window.location.host.startsWith(host)) ||
        !isProductionEnvironment()
    );
};

const totalCostOfOwnershipEnvironments = [
    'cobalt.luminovo.com',
    'demo.luminovo.com',
    'demo.luminovo.ai',
    'luminovo.luminovo.com',
    'luminovo.luminovo.ai',
    'luminovo.staging.luminovo.com',
    'luminovo.staging.luminovo.ai',
];

export const isTotalCostOfOwnershipEnabled = (): boolean => {
    return (
        totalCostOfOwnershipEnvironments.some((host) => window.location.host.startsWith(host)) ||
        !isProductionEnvironment()
    );
};

const assemblyPortalEnvironments = [
    'luminovo.luminovo.com',
    'demo.luminovo.com',
    'luminovo.luminovo.ai',
    'luminovo.staging.luminovo.ai',
    'luminovo.staging.luminovo.com',
    'staging.luminovo.ai',
    'staging.luminovo.com',
    /* eslint-disable-next-line spellcheck/spell-checker */
    'zollner-ccp.luminovo.com',
    /* eslint-disable-next-line spellcheck/spell-checker */
    'zollner-demo.luminovo.com',
];

export const isAssemblyPortalEnabled = (): boolean => {
    return (
        !isProductionEnvironment() || assemblyPortalEnvironments.some((host) => window.location.host.startsWith(host))
    );
};

/* eslint-disable-next-line spellcheck/spell-checker */
const customerRfQDisabledEnvironments = ['zollner-ccp.luminovo.com'];

export const isCustomerRfQDisabled = (): boolean => {
    return customerRfQDisabledEnvironments.some((host) => window.location.host.startsWith(host));
};
