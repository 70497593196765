import * as r from 'runtypes';
import { CalculationResult, DriverStatusDetailsRuntype } from '../activityConfiguration';
import { DriverCountsRuntype } from '../activityConfiguration/activityConfigurationBackendTypes';
import {
    ExchangedMonetaryValueBackendOriginalMVOnlyRuntype,
    ExchangedMonetaryValueBackendRuntype,
} from '../backendTypes';
import { DriverRuntype } from '../driver';
import { CategoryRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';
import { ExpenseLevelRuntype } from './expensesBackendTypes';

export interface ExpenseConfigurationDTO extends r.Static<typeof ExpenseConfigurationRuntype> {}

export const ExpenseConfigurationRuntype = r.Record({
    id: r.String,
    expense_id: r.String,
});

export const ExpenseConfigurationsPostRuntype = r.Record({
    manufacturing_scenario_id: r.String,
    manufacturing_scenario_template_instance_id: r.String.nullable(),
    expenses: r.Array(r.String),
});

export interface ExpenseConfigurationsPostDTO extends r.Static<typeof ExpenseConfigurationsPostRuntype> {}

const CostComponentExpensesRuntype = r.Record({
    driver_count: DriverCountsRuntype,
    automatic_driver_count: DriverCountsRuntype,
    driver_statuses: r.Array(DriverStatusDetailsRuntype),
    costs_per_batch_size: r.Dictionary(
        r.Record({
            variable_cost: CalculationResult(ExchangedMonetaryValueBackendOriginalMVOnlyRuntype.nullable()),
            fixed_cost: CalculationResult(ExchangedMonetaryValueBackendOriginalMVOnlyRuntype.nullable()),
            total_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
        }),
        r.String,
    ),
});

export interface CostComponentExpenseConfiguration extends r.Static<typeof CostComponentExpensesRuntype> {}

export const ExpenseConfigurationSummaryRuntype = r.Record({
    expense_configuration_details: r.Record({
        expense_configuration_id: r.String,
        expense_id: r.String,
        expense_name: r.String,
        level: ExpenseLevelRuntype,
        process: ProcessRuntype,
        expense_internal_number: r.String.nullable(),
        category: CategoryRuntype,
        cost_components: CostComponentExpensesRuntype,
        expense_description: r.String.nullable(),
        template_instance_id: r.String.nullable(),
        expense_configuration_notes: r.String.nullable(),
        rank: r.Number,
    }),
    used_drivers: r.Array(DriverRuntype),
});

export interface ExpenseConfigurationSummaryDTO extends r.Static<typeof ExpenseConfigurationSummaryRuntype> {}
