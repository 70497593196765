import * as r from 'runtypes';

interface Version extends r.Static<typeof VersionRuntype> {}
const VersionRuntype = r.Record({
    creation_date: r.String,
    path: r.String,
});

export interface QuotationProcessDTO {
    id: string;
    name: string;
    order_confirmations: Version[];
    orders: Version[];
    quotations: QuotationDTO[];
    rfq: string;
}

interface QuotationDTO {
    valid_until: string;
    version: Version;
}
