import { Trans, t } from '@lingui/macro';
import { assertPresent, formatDecimal, isPresent, throwErrorUnlessProduction, transEnum } from '@luminovo/commons';
import {
    Chip,
    FieldCheckboxControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    Message,
    Switch,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import {
    DepanelizationDTO,
    HttpError,
    PCBV2,
    PanelDetailsPostDTO,
    PanelPreferenceDTO,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { Box, Divider } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { errorCodeTranslations } from '../../../Error/errorCodeTranslations';
import { depanelizationTranslations } from '../../../Settings/OrganizationSettings/pages/PcbPanelPreferencesPage/utils/i18n';
import { PcbCollapse } from '../../components/CollapsibleSection';
import { ExportPanelInPDFButton } from '../../components/ToolbarPcb/components/ExportSpecificationInPDFButton';
import { getPanelDimensionFromPanelDetails } from '../utils/getPanelDimension';
import { isValidPanelDetails } from '../utils/isValidPanelDetails';
import { DefaultPanelFormState, PanelFormState, ScopeType } from '../utils/types';
import { PcbPanelNumberInput } from './PcbPanelNumberInput';

const formatPanelPreferenceLabel = (
    option: string | null | undefined,
    panelPreferences: PanelPreferenceDTO[] | null,
) => {
    if (option === null || option === undefined || panelPreferences === null)
        return t({
            id: 'panel_pref.custom',
            comment: 'Custom: Panel preference value overwritten',
            message: `Custom`,
        });

    const panelPreference = panelPreferences?.find((panelPreference) => panelPreference.id === option);
    return panelPreference ? panelPreference.name : '';
};

const getSourcingScenarioInformation = ({
    sourcingScenarioId,
    sourcingScenarios,
    assemblyId,
}: {
    sourcingScenarioId: string;
    sourcingScenarios: SourcingScenarioDTO[];
    assemblyId: string;
}) => {
    const sourcingScenario = sourcingScenarios.find((sourcingScenario) => sourcingScenario.id === sourcingScenarioId);
    if (sourcingScenario === undefined) {
        throwErrorUnlessProduction(`Sourcing scenario ${sourcingScenarioId} not found`);
    }
    const quantity = sourcingScenario?.assembly_quantities.items.find((item) => item.assembly === assemblyId)?.quantity;
    if (quantity === undefined) {
        throwErrorUnlessProduction(
            `Item quantity for assembly ${assemblyId} not found in sourcing scenario ${sourcingScenarioId}`,
        );
    }

    return {
        name: sourcingScenario?.name ?? '',
        quantity: quantity?.toString() ?? '',
    };
};

export const Label = ({
    sourcingScenarioId,
    sourcingScenarios,
    assemblyId,
}: {
    sourcingScenarioId: string;
    sourcingScenarios: SourcingScenarioDTO[];
    assemblyId: string;
}) => {
    const { name, quantity } = getSourcingScenarioInformation({ sourcingScenarioId, sourcingScenarios, assemblyId });

    return (
        <Flexbox gap={'8px'}>
            {name} <Chip label={quantity} color="neutral" />
        </Flexbox>
    );
};

type FormFieldType = {
    index: number;
    isEditing: boolean;
    sourcingScenarios: SourcingScenarioDTO[];
    panelPreferences: PanelPreferenceDTO[];
    assemblyId: string;
    pcb: PCBV2;
    rfqId: string;
    currentlyDisplayedPanelField: number;
    setCurrentlyDisplayedPanelField: (index: number) => void;
};

export const PanelFormField = ({
    index,
    isEditing,
    sourcingScenarios,
    panelPreferences,
    assemblyId,
    pcb,
    currentlyDisplayedPanelField,
    ...rest
}: FormFieldType) => {
    const { control, setValue } = useFormContext<DefaultPanelFormState>();
    const data = useWatch({
        control,
        name: 'data',
    });
    const scope = useWatch({
        control,
        name: 'scope',
    });

    const panelDetails = data[index];
    const panelId = panelDetails.id;
    const sourcingScenarioId = panelDetails.sourcingScenarioId;
    const depanelizationType = panelDetails.depanelization;
    const maxXOuts = panelDetails.max_x_outs;

    const [showMaxXOutField, setShowMaxXOutField] = React.useState(isPresent(maxXOuts));

    if (!panelDetails) return null;

    const { isValid, errorMessage } = isValidPanelDetails(panelDetails);

    const amount =
        sourcingScenarios.length === 0
            ? null
            : scope === 'PerSourcingScenario'
              ? // Pick out the amount of the sourcing scenarios for the assembly
                sourcingScenarioId
                  ? getSourcingScenarioInformation({ sourcingScenarioId, sourcingScenarios, assemblyId }).quantity
                  : '0'
              : // Pick out the minimum amount of the sourcing scenarios for the assembly
                Math.min(
                    ...sourcingScenarios.map(
                        (sourcingScenario) =>
                            assertPresent(
                                sourcingScenario.assembly_quantities.items.find((item) => item.assembly === assemblyId),
                            ).quantity,
                    ),
                ).toString();

    const resetPanelPreference = () => {
        if (panelDetails.panel_preference !== null) setValue(`data.${index}.panel_preference`, null);
    };

    return (
        <Collapse
            {...rest}
            index={index}
            isEditing={isEditing}
            assemblyId={assemblyId}
            currentlyDisplayedPanelField={currentlyDisplayedPanelField}
            scope={scope}
            sourcingScenarioId={sourcingScenarioId}
            sourcingScenarios={sourcingScenarios}
            isValid={isValid}
        >
            {isValid === false && errorMessage && (
                <Box style={{ width: '100%' }}>
                    <Message
                        attention="high"
                        variant="yellow"
                        title={t`Warning`}
                        message={errorMessage}
                        size={'large'}
                    />
                </Box>
            )}

            <FormItemPanelPreferenceSelect
                isEditing={isEditing}
                panelPreferences={panelPreferences}
                pcbId={pcb.id}
                amount={amount}
                index={index}
            />

            <PanelDimension pcb={pcb} panelDetails={panelDetails} />

            <Box display={'grid'} flexDirection={'column'} gridTemplateColumns={'1fr 1fr'} gap={'24px'} width={'100%'}>
                <FormItem label={t`Row count`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.row_count`}
                        min={1}
                        endAdornmentText=""
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Column count`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.column_count`}
                        min={1}
                        endAdornmentText=""
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Horizontal spacing`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.horizontal_spacing_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Vertical spacing`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.vertical_spacing_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Top padding`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.padding.top_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Bottom padding`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.padding.bottom_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Left padding`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.padding.left_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Right padding`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.padding.right_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>
                <FormItem label={t`Depanelization type`} LabelProps={{ variant: 'h5' }}>
                    {isEditing === false ? (
                        <Text>{transEnum(depanelizationType, depanelizationTranslations)}</Text>
                    ) : (
                        <FieldSelectControlled
                            control={control}
                            name={`data.${index}.depanelization`}
                            FieldProps={{
                                size: 'small',
                                options: Object.keys(depanelizationTranslations) as DepanelizationDTO[],
                                getOptionLabel: (x) => transEnum(x, depanelizationTranslations),
                                onValueChange: resetPanelPreference,
                            }}
                        />
                    )}
                </FormItem>
                <FormItem label={t`Min. milling distance`} LabelProps={{ variant: 'h5' }}>
                    <PcbPanelNumberInput
                        control={control}
                        name={`data.${index}.min_milling_distance_in_mm`}
                        disabled={!isEditing}
                        onChangeCallback={resetPanelPreference}
                    />
                </FormItem>

                <Flexbox flexDirection={'column'} gap={'8px'} style={{ gridColumn: 'span 2' }}>
                    {isEditing === true && (
                        <Flexbox alignItems={'center'} gap={'8px'}>
                            <Switch
                                disabled={!isEditing}
                                checked={showMaxXOutField}
                                onChange={(_, checked) => setShowMaxXOutField(checked)}
                            />
                            <Text variant="h5">
                                <Trans>Set maximum X-outs</Trans>
                            </Text>
                        </Flexbox>
                    )}
                    {showMaxXOutField && (
                        <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'24px'}>
                            <FormItem label={isEditing ? undefined : t`Maximum X-outs`} LabelProps={{ variant: 'h5' }}>
                                <PcbPanelNumberInput
                                    control={control}
                                    name={`data.${index}.max_x_outs`}
                                    min={0}
                                    endAdornmentText={t`boards`}
                                    disabled={!isEditing}
                                />
                            </FormItem>
                        </Box>
                    )}

                    <FormItem label={t`Rotated`} LabelProps={{ variant: 'h5' }}>
                        <FieldCheckboxControlled
                            control={control}
                            name={`data.${index}.pcb_is_rotated`}
                            // onChangeCallback={resetPanelPreference}
                            FieldProps={{
                                disabled: !isEditing,
                            }}
                        />
                    </FormItem>

                    {!isEditing && panelId && <ExportPanelInPDFButton pcbId={pcb.id} panelId={panelId} index={index} />}
                </Flexbox>
            </Box>
        </Collapse>
    );
};

const Collapse = ({
    children,
    assemblyId,
    currentlyDisplayedPanelField,
    index,
    isEditing,
    scope,
    sourcingScenarioId,
    sourcingScenarios,
    setCurrentlyDisplayedPanelField,
    isValid,
}: Omit<FormFieldType, 'panelPreferences' | 'pcb'> & {
    isValid: boolean;
    children: React.ReactNode;
    scope: ScopeType;
    sourcingScenarioId: string | undefined;
}) => {
    const [isOpen, setIsOpen] = React.useState(currentlyDisplayedPanelField === index);

    React.useEffect(() => {
        if (isEditing) {
            setIsOpen(currentlyDisplayedPanelField === index);
        }
    }, [isEditing, currentlyDisplayedPanelField, index]);

    return (
        <PcbCollapse
            isOpen={isOpen}
            ContentStyle={{
                gap: '8px',
            }}
            CollapseStyle={
                currentlyDisplayedPanelField === index
                    ? {
                          borderRadius: '8px',
                          backgroundColor: colorSystem.neutral.white,
                          paddingBlockEnd: '8px',
                          border: `1px solid ${colorSystem.primary['5']}`,
                          boxShadow: isEditing ? `0px 0px 0px 2px ${colorSystem.primary[3]}` : 'none',
                      }
                    : {
                          borderRadius: '8px',
                          backgroundColor: colorSystem.neutral.white,
                          paddingBlockEnd: '8px',
                          border: '1px solid transparent',
                      }
            }
            label={
                scope === 'PerSourcingScenario' &&
                sourcingScenarioId && (
                    <Flexbox gap={'8px'} alignItems={'center'}>
                        {isValid === false && isOpen === false && (
                            <Box
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: colorSystem.yellow['6'],
                                }}
                            />
                        )}
                        <Label
                            sourcingScenarioId={sourcingScenarioId}
                            sourcingScenarios={sourcingScenarios}
                            assemblyId={assemblyId}
                        />
                    </Flexbox>
                )
            }
            onClick={
                scope === 'PerPcb'
                    ? undefined
                    : () => {
                          if (currentlyDisplayedPanelField !== index) {
                              setCurrentlyDisplayedPanelField(index);
                              if (isOpen) return;
                          }
                          setIsOpen((x) => !x);
                      }
            }
        >
            {children}
        </PcbCollapse>
    );
};

const FormItemPanelPreferenceSelect = ({
    index,
    isEditing,
    panelPreferences,
    pcbId,
    amount,
}: {
    index: number;
    isEditing: boolean;
    panelPreferences: PanelPreferenceDTO[];
    pcbId: string;
    amount: string | null;
}) => {
    const { control, setValue } = useFormContext<PanelFormState>();
    const [errorCode, setErrorCode] = React.useState<string | null>(null);

    const panelPreference = useWatch({
        control,
        name: `data.${index}.panel_preference`,
    });
    const sourcingScenarioId = useWatch({
        control,
        name: `data.${index}.sourcingScenarioId`,
    });

    // Why did we do this?
    // We only want to generate the panel values if the panel preference is changed
    // and not when the component is mounted, this is whu we use the useMutation hook here

    const { mutateAsync } = useHttpMutation('POST /panels/generate', {
        snackbarMessage: null,
    });

    const handleChange = async (panelPreference: string | null) => {
        if (panelPreference === null || amount === null) return;
        try {
            const data = await mutateAsync({
                requestBody: {
                    amount: parseInt(amount),
                    pcb_id: pcbId,
                    panel_preferences_id: panelPreference,
                },
            });

            setValue(`data.${index}`, {
                row_count: data.row_count,
                column_count: data.column_count,
                horizontal_spacing_in_mm: data.horizontal_spacing_in_mm,
                vertical_spacing_in_mm: data.vertical_spacing_in_mm,
                min_milling_distance_in_mm: data.min_milling_distance_in_mm,
                panel_preference: data.panel_preference ?? panelPreference,
                max_x_outs: data.max_x_outs ?? undefined,
                padding: data.padding,
                depanelization: data.depanelization,
                sourcingScenarioId: sourcingScenarioId,
                pcb_is_rotated: data.pcb_is_rotated,
            });
        } catch (error) {
            if (error && error instanceof HttpError) {
                setErrorCode(error.code);
                setValue(`data.${index}.panel_preference`, null);
            }
        }
    };

    return (
        <Flexbox flexDirection={'column'} gap={'16px'} style={{ width: '100%' }}>
            {errorCode && (
                <Message
                    attention="high"
                    variant="yellow"
                    title={t`Error generating panel values`}
                    message={transEnum(errorCode, errorCodeTranslations)}
                    size={'large'}
                    onClose={() => setErrorCode(null)}
                />
            )}

            {isEditing === false ? (
                <>
                    <Flexbox flexDirection="column" gap={8} marginBlock={'12px'}>
                        <Text variant="h5">
                            <Trans>Panel preference</Trans>
                        </Text>
                        <Text>{formatPanelPreferenceLabel(panelPreference, panelPreferences)}</Text>
                    </Flexbox>
                </>
            ) : (
                <Box
                    style={{
                        backgroundColor: colorSystem.neutral['0'],
                        border: `1px solid ${colorSystem.neutral['2']}`,
                        borderRadius: '8px',
                        padding: '16px 8px',
                    }}
                >
                    <FormItem label={t`Panel preference`} LabelProps={{ variant: 'h5' }}>
                        <FieldSelectControlled
                            control={control}
                            name={`data.${index}.panel_preference`}
                            FieldProps={{
                                style: {
                                    backgroundColor: colorSystem.neutral.white,
                                },
                                size: 'small',
                                disabled: !isEditing,
                                disableClearable: true,
                                options: panelPreferences.map((panelPreference) => panelPreference.id),
                                getOptionLabel: (option) => formatPanelPreferenceLabel(option, panelPreferences),
                                renderOption: (option) => formatPanelPreferenceLabel(option, panelPreferences),
                                onValueChange: (value) => {
                                    handleChange(value);
                                },
                            }}
                        />
                    </FormItem>
                </Box>
            )}
        </Flexbox>
    );
};

const PanelDimension = ({ pcb, panelDetails }: { pcb: PCBV2; panelDetails: PanelDetailsPostDTO }) => {
    const { x, y } = getPanelDimensionFromPanelDetails(panelDetails, pcb);

    return (
        <Flexbox
            style={{
                paddingBlockEnd: '8px',
                flexDirection: 'column',
                gap: '8px',
                width: '100%',
            }}
        >
            <Text variant="h5">
                <Trans>Total panel size</Trans>
            </Text>
            <Box
                style={{
                    gap: '24px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                }}
            >
                <Text>
                    <strong style={{ color: colorSystem.neutral[8] }}>X:</strong> {formatDecimal(x)} mm
                </Text>
                <Text>
                    <strong style={{ color: colorSystem.neutral[8] }}>Y:</strong> {formatDecimal(y)} mm
                </Text>
            </Box>
            <Divider />
        </Flexbox>
    );
};
