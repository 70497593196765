import { useQuoteRequestSuppliers } from '@/modules/Sourcing/components/QuoteRequests/QuoteRequestAssignPage/AddQuoteRequestSupplier';
import { t } from '@lingui/macro';
import { isPresent, transEnum, uniqBy } from '@luminovo/commons';
import {
    FieldMultiSelectControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { SupplierPreference } from '@luminovo/http-client';
import { formatSupplierDTO, supplierPreferenceTranslations } from '@luminovo/sourcing-core';
import { Add } from '@mui/icons-material';
import { Checkbox, RadioGroup } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useAwardScenarios } from '../../hooks/negotiationHandlers';
import { formatAwardScenario, isAutomaticAwardScenario } from '../../model/awardScenario';
import { QuoteRequestAssignmentFormValues } from './types';

export const FormItemSupplierSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();

    return (
        <FormItem label={`Choose supplier by`}>
            <FieldSelectControlled
                name="supplierSelectionStrategy"
                control={control}
                required={true}
                FieldProps={{
                    disableClearable: true,
                    getOptionLabel: (strategy) => {
                        switch (strategy.type) {
                            case 'SupplierPartTypeMatches':
                                return `Manually`;
                            case 'SupplierLineCard':
                                return `Supplier line card`;
                            case 'ApprovedVendorList':
                                return `Approved vendor list`;
                            case 'ReferenceSupplier':
                                return `Reference supplier`;
                        }
                    },
                    options: [
                        { type: 'SupplierPartTypeMatches' },
                        { type: 'SupplierLineCard', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                        { type: 'ApprovedVendorList', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                        { type: 'ReferenceSupplier', awardScenario: null },
                    ],
                }}
            />
        </FormItem>
    );
};

export const FormItemAwardScenario: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const negotiationId = useWatch({ control, name: 'negotiationId' });
    const { data: options } = useAwardScenarios(negotiationId);
    const supplierSelectionStrategyType = useWatch({ control, name: 'supplierSelectionStrategy.type' });
    if (supplierSelectionStrategyType !== 'ReferenceSupplier' || !isPresent(options)) {
        return null;
    }

    return (
        <FormItem label={`Reference award scenario`}>
            <FieldSelectControlled
                name="supplierSelectionStrategy.awardScenario"
                control={control}
                required={true}
                FieldProps={{
                    options,
                    disableClearable: true,
                    getOptionLabel: (opt) => formatAwardScenario(opt),
                    groupBy: (item) => (isAutomaticAwardScenario(item) ? 'Automatic' : 'Manual'),
                }}
            />
        </FormItem>
    );
};

export const FormItemSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const supplierSelectionStrategyType = useWatch({ control, name: 'supplierSelectionStrategy.type' });

    if (!['SupplierLineCard', 'ApprovedVendorList'].includes(supplierSelectionStrategyType)) {
        return null;
    }

    return (
        <RadioGroup>
            <FormItem label={`What should be sent to the selected suppliers?`}>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'OnlyMatchingPartOptions', size: 'small' }}
                    />
                    <Text>Only part options matching line card</Text>
                </Flexbox>
                <Flexbox alignItems={'start'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'AllPartOptions', size: 'small' }}
                    />

                    <Text>Part options matching line card plus all approved alternatives</Text>
                </Flexbox>
            </FormItem>
        </RadioGroup>
    );
};

export const ButtonAddToQuoteRequest: React.FunctionComponent<{
    disabled: boolean;
    groupMode: 'manufacturers' | 'partOptions';
}> = ({ disabled, groupMode }) => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const selectedSuppliers = useWatch({ control, name: 'selectedSuppliers' });
    const supplierSelectionStrategy = useWatch({ control, name: 'supplierSelectionStrategy' });
    const hasNoSuppliers = selectedSuppliers.length === 0;

    return (
        <Tooltip
            variant="white"
            title={
                disabled
                    ? groupMode === 'manufacturers'
                        ? 'Select at least one manufacturer'
                        : 'Select at least one part option'
                    : ''
            }
        >
            <div>
                <SubmitButton
                    disabled={disabled || (supplierSelectionStrategy.type !== 'ReferenceSupplier' && hasNoSuppliers)}
                    size="medium"
                    label={t`Add to quote request`}
                    startIcon={<Add />}
                />
            </div>
        </Tooltip>
    );
};

export const FormItemSuppliers: React.FunctionComponent = () => {
    const context = useFormContext<QuoteRequestAssignmentFormValues>();
    const { control, setValue } = context;
    const selectedSuppliers = useWatch({ control, name: 'selectedSuppliers' });
    const { data: options } = useQuoteRequestSuppliers();

    const getGroupByStatus = React.useCallback(
        (preference: SupplierPreference): 'all' | 'indeterminate' | 'none' => {
            const selectedIds = new Set(selectedSuppliers.map((s) => s.id));
            const suppliersInGroup = selectedSuppliers.filter((s) => s.preference === preference);

            if (suppliersInGroup.length === 0) {
                return 'none';
            }

            const allSelected = suppliersInGroup.every((s) => selectedIds.has(s.id));
            if (allSelected) {
                return 'all';
            }

            const someSelected = suppliersInGroup.some((s) => selectedIds.has(s.id));
            if (someSelected) {
                return 'indeterminate';
            }
            return 'none';
        },
        [selectedSuppliers],
    );

    const toggleGroup = React.useCallback(
        (preference: SupplierPreference, currentStatus: 'all' | 'indeterminate' | 'none') => {
            const updateSuppliers = (suppliers: typeof selectedSuppliers) => {
                setValue('selectedSuppliers', suppliers, { shouldValidate: true });
            };

            if (currentStatus === 'all') {
                const filteredSuppliers = uniqBy(
                    selectedSuppliers.filter((s) => s.preference !== preference),
                    (s) => s.id,
                );
                updateSuppliers(filteredSuppliers);
            } else {
                const suppliersToAdd = options?.filter((s) => s.preference === preference) ?? [];
                const mergedSuppliers = uniqBy([...selectedSuppliers, ...suppliersToAdd], (s) => s.id);
                updateSuppliers(mergedSuppliers);
            }
        },
        [selectedSuppliers, setValue, options],
    );

    return (
        <FormItem label={t`Suppliers`}>
            <FieldMultiSelectControlled
                name="selectedSuppliers"
                control={control}
                FieldProps={{
                    chipBoxProps: {
                        maxHeight: '200px',
                        overflow: 'auto',
                    },
                    options: options ?? [],
                    disabled: !isPresent(options),
                    getOptionLabel: (option) => formatSupplierDTO(option),
                    getOptionSublabel: (option) => option.supplier_number,
                    placeholder: selectedSuppliers.length === 0 ? t`No suppliers` : undefined,
                    getOptionKey: (supplier) => supplier.id,
                    virtualized: true,
                    disableCloseOnSelect: true,
                    groupBy: (supplier): SupplierPreference => {
                        return supplier.preference;
                    },
                    renderGroupLabel: (params) => {
                        //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const groupByKey = params.group as SupplierPreference;
                        const status = getGroupByStatus(groupByKey);

                        return (
                            <Flexbox gap={8} paddingLeft={'12px'} alignItems={'center'}>
                                <Checkbox
                                    checked={status === 'all'}
                                    indeterminate={status === 'indeterminate'}
                                    size={'small'}
                                    onClick={() => toggleGroup(groupByKey, status)}
                                    disabled={groupByKey === SupplierPreference.NotApproved}
                                />
                                <Text variant="h4">{transEnum(groupByKey, supplierPreferenceTranslations)}</Text>
                            </Flexbox>
                        );
                    },
                }}
            />
        </FormItem>
    );
};
