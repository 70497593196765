import { Trans, t } from '@lingui/macro';
import {
    Column,
    Flexbox,
    HeaderCheckbox,
    Row,
    RowCheckbox,
    SecondaryIconButton,
    Tooltip,
    colorSystem,
} from '@luminovo/design-system';
import {
    ColumnName,
    OriginSetMethod,
    isCustomComponentFull,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { LifecycleTooltip } from '@luminovo/sourcing-core';
import { Delete, Info } from '@mui/icons-material';
import { ApprovalStatusSelect } from '../../../../components/ApprovalStatusSelect';
import { iconForStatus } from '../../../../components/Icons/icons';
import { PartOptionAvailabilityView } from '../../../../components/PartAvailabilityView';
import { PartComplianceView } from '../../../../components/PartComplianceView';
import { EmissionsTooltip } from '../../../../components/PartEmissionsView/EmissionsTooltip';
import { PartEmissionsView } from '../../../../components/PartEmissionsView/PartEmissionsView';
import { PartLifecycleView } from '../../../../components/PartLifecycleView';
import { PartOptionOriginTag } from '../../../../components/PartOptionOriginTag';
import {
    CpnView,
    RenderDescription,
    RenderIpn,
    RenderManufacturer,
    RenderMpn,
    RenderPackage,
    StickyTableCell,
} from '../../../../components/partColumns';
import { RenderDatasheet } from '../../../../components/partColumns/RenderDatasheet';
import { StickyRightHeaderTableCell } from '../../../../components/partColumns/StickyHeadTableCell';
import { TableCell } from '../../../../components/partColumns/TableCell';
import { isComponentRemoved } from '../../../../components/partColumns/isComponentRemoved';
import { PartOptionWithFullPart } from '../../../../resources/designItem/sortPartOptionsAndSuggestions';
import { ManufacturerTooltip } from '../AddParts/PartialMatches/ManufacturerTooltip';
import { PartOptionsContext } from './types';

enum partOptColumnIds {
    columnSelection = 'selection',
    columnIpn = 'ipn',
    columnCpn = 'cpn-rev',
    columnMpn = 'mpn',
    columnManufacturer = 'manufacturer',
    columnDescription = 'description',
    columnPackage = 'package',
    columnDatasheet = 'datasheet',
    columnAvailability = 'availability',
    columnLifecycle = 'lifecycle',
    columnCompliance = 'compliance',
    columnEmissions = 'emissions',
    columnStatus = 'status',
}

export const columnSelection: Column<PartOptionWithFullPart, PartOptionsContext> = {
    id: partOptColumnIds.columnSelection,
    label: '',
    renderHead: ({ items, filteredItems, ...rest }) => (
        <HeaderCheckbox {...rest} items={items} filteredItems={filteredItems} disabled={false} />
    ),
    render: (data) => {
        return <RowCheckbox data={data} />;
    },
};

const columnIpn = {
    id: partOptColumnIds.columnIpn,
    label: <Trans>IPN</Trans>,
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        const isIpnOrigin = data.partOption.origin?.column === ColumnName.IPN;
        return <RenderIpn part={data.fullPart} query={isIpnOrigin ? [data.fullPart.id] : []} />;
    },
};

const columnCpn = {
    id: partOptColumnIds.columnCpn,
    label: <Trans>CPN • Rev</Trans>,
    render: ({ data }: Row<PartOptionWithFullPart>, { candidateCpns }: PartOptionsContext): JSX.Element => {
        return (
            <TableCell>
                <CpnView part={data.fullPart} candidateCpns={candidateCpns} />
            </TableCell>
        );
    },
};

const columnMpn = {
    id: partOptColumnIds.columnMpn,
    label: <Trans>MPN</Trans>,
    render: ({ data }: Row<PartOptionWithFullPart>, { candidateMpns }: PartOptionsContext): JSX.Element => {
        return <RenderMpn part={data.fullPart} candidateMpns={candidateMpns} />;
    },
};

const columnManufacturer = {
    id: partOptColumnIds.columnManufacturer,
    label: (
        <Flexbox alignItems={'center'}>
            <Trans>Manufacturer</Trans>
            <ManufacturerTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </ManufacturerTooltip>
        </Flexbox>
    ),
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        return <RenderManufacturer part={data.fullPart} />;
    },
};

const columnDescription = {
    id: partOptColumnIds.columnDescription,
    label: <Trans>Description</Trans>,
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        return <RenderDescription part={data.fullPart} />;
    },
};

const columnPackage = {
    id: partOptColumnIds.columnPackage,
    label: <Trans>Package</Trans>,
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        return <RenderPackage part={data.fullPart} />;
    },
};

const columnDatasheet = {
    id: partOptColumnIds.columnDatasheet,
    label: '',
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        return <RenderDatasheet part={data.fullPart} />;
    },
};

const columnAvailability = {
    id: partOptColumnIds.columnAvailability,
    label: <Trans>Availability</Trans>,
    render: (
        { data }: Row<PartOptionWithFullPart>,
        { bomItem, rfqId, assemblyId }: PartOptionsContext,
    ): JSX.Element => {
        const isComponentRemoved =
            (isOtsComponentFull(data.fullPart) || isCustomComponentFull(data.fullPart)) &&
            data.fullPart.state === 'Removed';
        return (
            <TableCell>
                <PartOptionAvailabilityView
                    assemblyId={assemblyId}
                    bomItem={bomItem}
                    part={data.fullPart}
                    rfqId={rfqId}
                    disabled={isComponentRemoved}
                />
            </TableCell>
        );
    },
};

const columnLifecycle = {
    id: partOptColumnIds.columnLifecycle,
    label: (
        <Flexbox alignItems="center">
            <Trans>Lifecycle</Trans>
            <LifecycleTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </LifecycleTooltip>
        </Flexbox>
    ),
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        const isComponentRemoved =
            (isOtsComponentFull(data.fullPart) || isCustomComponentFull(data.fullPart)) &&
            data.fullPart.state === 'Removed';
        return (
            <TableCell>
                <PartLifecycleView part={data.fullPart} disabled={isComponentRemoved} />
            </TableCell>
        );
    },
};

const columnCompliance = {
    id: partOptColumnIds.columnCompliance,
    label: <Trans>Compliance</Trans>,
    render: ({ data }: Row<PartOptionWithFullPart>, { assemblyIndustry }: PartOptionsContext): JSX.Element => {
        const isComponentRemoved =
            (isOtsComponentFull(data.fullPart) || isCustomComponentFull(data.fullPart)) &&
            data.fullPart.state === 'Removed';
        return (
            <TableCell>
                <PartComplianceView
                    part={data.fullPart}
                    assemblyIndustry={assemblyIndustry}
                    disabled={isComponentRemoved}
                />
            </TableCell>
        );
    },
};

const columnEmissions = {
    id: partOptColumnIds.columnEmissions,
    label: '',
    renderHead: () => (
        <TableCell>
            <Flexbox alignItems="center" color={colorSystem.neutral[7]}>
                <Trans>kgCO2e</Trans>
                <EmissionsTooltip>
                    <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
                </EmissionsTooltip>
            </Flexbox>
        </TableCell>
    ),
    render: ({ data }: Row<PartOptionWithFullPart>): JSX.Element => {
        const isComponentRemoved =
            (isOtsComponentFull(data.fullPart) || isCustomComponentFull(data.fullPart)) &&
            data.fullPart.state === 'Removed';
        return (
            <Tooltip
                title={
                    isOtsFullPart(data.fullPart) && !data.fullPart.emissions_data
                        ? t`Open part details view to request sustainability data for this part.`
                        : ''
                }
            >
                <TableCell isComponentRemoved={isComponentRemoved}>
                    <PartEmissionsView part={data.fullPart} />
                </TableCell>
            </Tooltip>
        );
    },
};

const columnStatus = {
    id: partOptColumnIds.columnStatus,
    label: <Trans>Status</Trans>,
    overrides: {
        HeaderTableCell: StickyRightHeaderTableCell,
    },
    render: (
        { data }: Row<PartOptionWithFullPart>,
        { handleUpdatePartOption, isRfqEditable, isSubmitting, handleRemovePartOption }: PartOptionsContext,
    ): JSX.Element => {
        const { partOption, fullPart } = data;
        const isDisabled = (isOtsComponentFull(fullPart) && fullPart.state === 'Removed') || isSubmitting;

        const isRemoved = isComponentRemoved(fullPart);

        if (!isRfqEditable) {
            return (
                <StickyTableCell isPartRemoved={isRemoved}>
                    <Flexbox gap="8px" alignItems="center">
                        {iconForStatus({ status: partOption.approval_status })}
                        {partOption.origin && <PartOptionOriginTag origin={partOption.origin} />}
                    </Flexbox>
                </StickyTableCell>
            );
        }
        return (
            <StickyTableCell isPartRemoved={isRemoved}>
                <Flexbox gap="8px" alignItems="center" justifyContent="space-between">
                    <Flexbox gap="8px" alignItems="center">
                        <ApprovalStatusSelect
                            disabled={isDisabled}
                            value={partOption.approval_status}
                            onChange={(status) => {
                                handleUpdatePartOption({
                                    ...partOption,
                                    approval_status: status,
                                    origin: { set_method: OriginSetMethod.Manual, reasons: [] },
                                });
                            }}
                        />

                        {partOption.origin && <PartOptionOriginTag origin={partOption.origin} />}
                    </Flexbox>
                    <Tooltip title={t`Remove part`}>
                        <span>
                            <SecondaryIconButton
                                disabled={isSubmitting}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemovePartOption(partOption.part.data);
                                }}
                                size="small"
                            >
                                <Delete fontSize="inherit" />
                            </SecondaryIconButton>
                        </span>
                    </Tooltip>
                </Flexbox>
            </StickyTableCell>
        );
    },
};

const allPartOptColumns = [
    columnSelection,
    columnIpn,
    columnCpn,
    columnMpn,
    columnManufacturer,
    columnDescription,
    columnPackage,
    columnDatasheet,
    columnAvailability,
    columnLifecycle,
    columnCompliance,
    columnEmissions,
    columnStatus,
];

export const getPartOptionsColumns = ({
    hasPartOptionsWithCpns,
    isPartSelectionActive,
}: {
    hasPartOptionsWithCpns: boolean;
    isPartSelectionActive: boolean;
}) => {
    return allPartOptColumns.filter((col) => {
        if (!isPartSelectionActive && col.id === partOptColumnIds.columnSelection) {
            return false;
        }
        if (!hasPartOptionsWithCpns && col.id === partOptColumnIds.columnCpn) {
            return false;
        }
        return true;
    });
};
