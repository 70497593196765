import {
    ApprovalStatus,
    CustomFullPart,
    CustomOptionDTO,
    CustomOptionTypes,
    CustomPartType,
    DesignItemResponseDTO,
    OriginSetMethod,
} from '@luminovo/http-client';
import { useCallback } from 'react';
import { AddCustomPart } from './AddCustomPart';
import { useCustomPartSuggestions } from './useCustomPartSuggestions';

export const AddCustomPartTab = ({
    individualDesignItems,
    handleAppendPartOption,
    selectedPartType,
}: {
    individualDesignItems: DesignItemResponseDTO[];
    handleAppendPartOption: (part: CustomOptionDTO) => void;
    selectedPartType?: CustomPartType;
}) => {
    const descriptionSuggestions = useCustomPartSuggestions({ individualDesignItems });

    const handleAppendCustomPart = useCallback(
        (newPart: CustomFullPart) => {
            handleAppendPartOption({
                part: { data: newPart.id, type: CustomOptionTypes.CustomPart },

                approval_status: ApprovalStatus.Approved,

                origin: { set_method: OriginSetMethod.Manual, reasons: [] },
            });
        },
        [handleAppendPartOption],
    );

    return (
        <AddCustomPart
            descriptionSuggestions={descriptionSuggestions}
            handleAppendCustomPart={handleAppendCustomPart}
            selectedPartType={selectedPartType}
        />
    );
};
