import { Trans, t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FieldDateControlled,
    FieldNumericControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    PrimaryButton,
    Text,
} from '@luminovo/design-system';
import { RegisteredHttpEndpoint, http } from '@luminovo/http-client';
import { InfoLabel } from '@luminovo/manufacturing-core';
import { useQueries } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { route } from '../../../utils/routes';
import { typed } from '../../../utils/typingUtils';
import { useAssemblyOverviewPage } from '../../Manufacturing/AssemblyOverview/useAssemblyOverviewPage';

export const ExportQuotationButton = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const { openDialog } = useExportQuotationDialog({ rfqId });
    const { assemblyOverviews, isLoading } = useAssemblyOverviewPage(rfqId);
    const doSomeAssembliesHaveNoManufacturingDetails = assemblyOverviews.some(
        (assemblyOverview) => assemblyOverview.manufacturingAssemblyDetailsId === undefined,
    );

    const assemblyOverviewResult = useQueries({
        queries: assemblyOverviews.map((assembly) => {
            return {
                queryKey: [
                    typed<RegisteredHttpEndpoint>('GET /calculations/calculation-assembly-details'),
                    {
                        queryParams: { assembly_id: assembly.assemblyId },
                    },
                ],
                queryFn: () =>
                    http(
                        'GET /calculations/calculation-assembly-details',
                        {
                            queryParams: { assembly_id: assembly.assemblyId },
                        },
                        getToken(),
                    ),
                enabled: !isLoading && assemblyOverviews.length > 0 && !doSomeAssembliesHaveNoManufacturingDetails,
            };
        }),
    });

    const areSomeAssembliesNotCalculated = assemblyOverviewResult.some((result) => result.data?.data[0] === undefined);

    return (
        <PrimaryButton
            onClick={openDialog}
            size="medium"
            disabled={areSomeAssembliesNotCalculated || doSomeAssembliesHaveNoManufacturingDetails || isLoading}
        >
            <Trans>Generate quotation</Trans>
        </PrimaryButton>
    );
};

export const useExportQuotationDialog = ({ rfqId }: { rfqId: string }) => {
    const { setDialog, closeDialog } = useDialogContext();

    const dialog = (
        <Dialog open={true} onClose={closeDialog} maxWidth="sm">
            <DialogTitle title={t`Generate quotation`} handleClose={closeDialog} />
            <DialogContent>
                <ExportQuotationForm rfqId={rfqId} />
            </DialogContent>
        </Dialog>
    );

    const openDialog = () => setDialog(dialog);

    return { openDialog };
};

interface ExportQuotationFormState {
    validUntil: string | undefined;
    customText: string | undefined;
    additionalLeadTimeDays: number | undefined;
    quotationNumber: string | undefined;
    footerRight: string | undefined;
    footerLeft: string | undefined;
    footerCenter: string | undefined;
}

const DEFAULT_EXPORT_QUOTATION_FORM: ExportQuotationFormState = {
    validUntil: undefined,
    customText: undefined,
    additionalLeadTimeDays: undefined,
    quotationNumber: undefined,
    footerRight: undefined,
    footerLeft: undefined,
    footerCenter: undefined,
};

const ExportQuotationForm = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const onSubmit = (formState: ExportQuotationFormState) => {
        const printUrl = route(
            '/rfqs/:rfqId/calculation/print',
            { rfqId },
            {
                validUntil: formState.validUntil ?? '',
                customText: formState.customText ?? '',
                additionalLeadTimeDays: formState.additionalLeadTimeDays?.toString() ?? '',
                quotationNumber: formState.quotationNumber ?? '',
                footerRight: formState.footerRight ?? '',
                footerLeft: formState.footerLeft ?? '',
                footerCenter: formState.footerCenter ?? '',
            },
        );

        window.open(printUrl, '_blank');
    };

    return (
        <FormContainer defaultValues={DEFAULT_EXPORT_QUOTATION_FORM} onSubmit={onSubmit} reValidateMode="onBlur">
            <ExportQuotationFormInner />
        </FormContainer>
    );
};

const ExportQuotationFormInner = (): JSX.Element => {
    const { control } = useFormContext<ExportQuotationFormState>();

    return (
        <Flexbox flexDirection="column" gap="24px">
            <Flexbox flexDirection="column" gap="24px">
                <FormItem label={t`Valid until`} required>
                    <FieldDateControlled control={control} name="validUntil" required />
                </FormItem>

                <FormItem label={t`Custom text`}>
                    <FieldTextControlled
                        control={control}
                        name="customText"
                        FieldProps={{
                            multiline: true,
                            rows: 4,
                        }}
                    />
                </FormItem>
                <FormItem
                    label={
                        <InfoLabel
                            label={t`Additional lead time`}
                            info={t`Additional lead time added to the sourcing and (where applicable), manufacturing lead times.`}
                            textProps={{
                                variant: 'h4',
                            }}
                        />
                    }
                    required
                >
                    <Flexbox gap="8px" alignItems="center">
                        <FieldNumericControlled control={control} name="additionalLeadTimeDays" min={0} required />
                        <Text>
                            <Trans>days</Trans>
                        </Text>
                    </Flexbox>
                </FormItem>

                <FormItem label={t`Quotation number`} required>
                    <FieldTextControlled required control={control} name="quotationNumber" />
                </FormItem>

                <FormItem label={t`Footer text`}>
                    <Flexbox gap={'24px'} justifyContent={'space-around'}>
                        <FieldTextControlled
                            control={control}
                            name="footerLeft"
                            FieldProps={{
                                multiline: true,
                                rows: 4,
                                sx: {
                                    '& textarea': {
                                        whiteSpace: 'nowrap',
                                    },
                                },
                                placeholder: t`Footer left`,
                            }}
                        />
                        <FieldTextControlled
                            control={control}
                            name="footerCenter"
                            FieldProps={{
                                multiline: true,
                                rows: 4,
                                sx: {
                                    '& textarea': {
                                        whiteSpace: 'nowrap',
                                    },
                                },
                                placeholder: t`Footer center`,
                            }}
                        />
                        <FieldTextControlled
                            control={control}
                            name="footerRight"
                            FieldProps={{
                                multiline: true,
                                rows: 4,
                                sx: {
                                    '& textarea': {
                                        whiteSpace: 'nowrap',
                                    },
                                },
                                placeholder: t`Footer right`,
                            }}
                        />
                    </Flexbox>
                </FormItem>
            </Flexbox>

            <Flexbox justifyContent="end">
                <SubmitButton label={t`Open preview`} />
            </Flexbox>
        </Flexbox>
    );
};
