import { Trans, t } from '@lingui/macro';
import {
    formatDays,
    formatDecimal,
    formatLongDateTime,
    formatRelativeTime,
    isPresent,
    throwErrorUnlessProduction,
    transEnum,
} from '@luminovo/commons';
import {
    Chip,
    Flexbox,
    Radio,
    SecondaryButton,
    SupportedFilterFn,
    Tag,
    Text,
    Tooltip,
    colorSystem,
    createColumnHelper,
} from '@luminovo/design-system';
import {
    AllOrigins,
    CustomComponentOfferDTO,
    CustomPartOfferDTO,
    InternalPartNumberOfferDTO,
    OffTheShelfOfferDTO,
    OfferOriginEnum,
    Packaging,
    PriceType,
    SolutionStatus,
    SolutionTag,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import {
    AvailabilityChip,
    InventorySiteChip,
    LabelPart,
    SolutionErrorTags,
    SolutionNotificationTags,
    SolutionStatusIcon,
    SolutionStatusInformation,
    SolutionWarningTags,
    SupplierAndStockLocationChip,
    TcoCostTooltip,
    UnitOfMeasurementTag,
    extractAccumulatedOneTimeCosts,
    extractAdjustedTcoUnitPrice,
    extractAdjustedUnitPrice,
    extractExcessMaterial,
    extractMoq,
    extractMpq,
    extractSolutionStatus,
    extractSolutionTagColor,
    formatOfferOrigin,
    formatPackaging,
    formatPart,
    formatSolutionStatus,
    formatSupplierAndStockLocationDTO,
    hasSolutionConfigurationManualCost,
    hasSolutionTag,
    isStandardPartMarketOffer,
    isStandardPartOffer,
    leadTimeDaysExtractor,
    priceTypeTranslations,
    solutionTagTranslations,
} from '@luminovo/sourcing-core';
import { Launch, Notes } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import React from 'react';
import { isTotalCostOfOwnershipEnabled } from '../../../featureFlags';
import {
    QUERY_KEY_MUTATION_PATCH_SOLUTION_CONFIGURATION,
    useMutationUpdateSolutionConfiguration,
} from '../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { analytics } from '../../../utils/analytics';
import { formatMonetaryValue } from '../../../utils/formatMonetaryValue';
import { useManualCostWarningDialog } from './Sidebar/ButtonSolutionSelection';
import { SolutionTableData, SolutionTableSharedContext } from './types';

const NotesIcon: React.FunctionComponent<{ notes: string | null | undefined }> = ({ notes }) => {
    if (!Boolean(notes) || !isPresent(notes)) {
        return <></>;
    }

    return (
        <Flexbox justifyContent={'center'}>
            <Tooltip
                variant={'white'}
                title={
                    <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Notes</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[8]}>
                            {notes}
                        </Text>
                    </Flexbox>
                }
            >
                <Box
                    sx={{
                        color: colorSystem.neutral[6],
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        borderRadius: '24px',
                        padding: '2px 8px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Notes sx={{ fontSize: '16px' }} />
                </Box>
            </Tooltip>
        </Flexbox>
    );
};

export const OriginCell = ({
    offer,
}: {
    offer: CustomPartOfferDTO | CustomComponentOfferDTO | InternalPartNumberOfferDTO | OffTheShelfOfferDTO;
}) => {
    const url = offer.offer_url;

    const supplierOrInventorySite =
        isStandardPartOffer(offer) && isStandardPartMarketOffer(offer)
            ? { supplier: offer.linked_location }
            : 'inventory-site';

    return <OriginCellInternal url={url} supplierOrInventorySite={supplierOrInventorySite} origin={offer.origin} />;
};

export const OriginCellInternal = ({
    url,
    supplierOrInventorySite,
    origin,
}: {
    url: string | null;
    supplierOrInventorySite: { supplier: SupplierAndStockLocationDTO } | 'inventory-site';
    origin: AllOrigins;
}) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const hasUrl = isPresent(url);

    const supplier =
        supplierOrInventorySite === 'inventory-site'
            ? 'InventorySite'
            : formatSupplierAndStockLocationDTO(supplierOrInventorySite.supplier);

    return (
        <span
            onClick={(e) => e.stopPropagation()}
            onMouseOver={() => setIsHovered(hasUrl)}
            onMouseOut={() => setIsHovered(false)}
            style={{ cursor: 'pointer' }}
        >
            {!isHovered ? (
                <Tag color="neutral" attention="low" label={formatOfferOrigin(origin)} />
            ) : (
                hasUrl && (
                    <SecondaryButton
                        size="small"
                        startIcon={<Launch />}
                        onClick={(e) => {
                            e.stopPropagation();
                            analytics.track('click_on_supplier_offer_link', {
                                triggered_on_screen: 'SolutionManager',
                                supplier,
                            });
                            window.open(url, '_blank', 'noopener noreferrer');
                        }}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <Trans>Go to offer</Trans>
                    </SecondaryButton>
                )
            )}
        </span>
    );
};

function SelectedSolutionTableCell({ data }: { data: SolutionTableData }): JSX.Element {
    const isDisabled = useIsMutating({ mutationKey: QUERY_KEY_MUTATION_PATCH_SOLUTION_CONFIGURATION }) > 0;

    const { mutateAsync, isPending: isLoading } = useMutationUpdateSolutionConfiguration(data.solutionConfiguration.id);

    const { openDialog } = useManualCostWarningDialog();

    return (
        <span
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
                e.stopPropagation();
                if (!isDisabled) {
                    if (hasSolutionConfigurationManualCost(data.solutionConfiguration)) {
                        openDialog(() => mutateAsync({ solutionToken: data.solution.token }));
                    } else {
                        mutateAsync({ solutionToken: data.solution.token });
                    }
                }
            }}
        >
            {isLoading ? (
                <CircularProgress size="20px" />
            ) : (
                <Radio value={true} checked={data.isSelected} disabled={isDisabled} />
            )}
        </span>
    );
}

const columnHelper = createColumnHelper<SolutionTableData, SolutionTableSharedContext>();

export const columnSelection = columnHelper.action({
    id: 'selection',
    size: 50,
    cell: ({ row }) => <SelectedSolutionTableCell data={row.original} />,
});
export const columnLinkedPart = columnHelper.enum((row) => formatPart(row.linkedPart), {
    id: 'part',
    size: 180,
    label: () => t`Part`,
    renderType: 'text',
    getOptionLabel: (opt) => opt,
    cell: ({ row }) => <LabelPart part={row.original.linkedPart} />,
});
export const columnSupplier = columnHelper.enum(
    (row) => {
        if (row.linkedLocation.type === 'InventorySite') {
            return row.linkedLocation.name;
        }
        if (row.linkedLocation.type === 'SupplierAndStockLocation') {
            return formatSupplierAndStockLocationDTO(row.linkedLocation);
        }
        throwErrorUnlessProduction(new Error('Unknown location type'));
        return null;
    },
    {
        id: 'supplier',
        size: 150,
        label: () => t`Supplier`,
        getOptionLabel: (option) => option ?? t`Unknown`,
        cell: ({ row }) => {
            if (row.original.linkedLocation.type === 'InventorySite') {
                return (
                    <InventorySiteChip
                        isPreferred={hasSolutionTag(row.original.solution, SolutionTag.SupplierPreferred)}
                        isApproved={hasSolutionTag(row.original.solution, SolutionTag.SupplierApproved)}
                        site={row.original.linkedLocation}
                        displaySiteName={row.original.showInventorySiteName}
                    />
                );
            }

            return (
                <SupplierAndStockLocationChip
                    isPreferred={hasSolutionTag(row.original.solution, SolutionTag.SupplierPreferred)}
                    isApproved={hasSolutionTag(row.original.solution, SolutionTag.SupplierApproved)}
                    supplier={row.original.linkedLocation}
                />
            );
        },
    },
);
export const columnAwardedSolutions = columnHelper.number('awardedSolutions', {
    id: 'awardedSolutions',
    label: () => t`Awarded solutions`,
    size: 100,
    description: () => t`Number of solutions selected with the same supplier`,
    initialVisibility: false,
    cell: (item) => formatDecimal(item.getValue() === 0 ? null : item.getValue(), { ifAbsent: '-' }),
});

export const columnSkuNumber = columnHelper.text('offer.supplier_part_number', {
    label: () => t`SKU`,
    size: 100,
    cell: (info) => {
        if (!isPresent(info.getValue())) {
            return (
                <Text variant="inherit" color={colorSystem.neutral[6]}>
                    <Trans>Unknown</Trans>
                </Text>
            );
        }
        return info.getValue();
    },
});
export const columnPackaging = columnHelper.enum((row) => row.offer.packaging, {
    id: 'package',
    label: () => t`Packaging`,
    size: 90,
    options: [...Object.values(Packaging), null],
    getOptionLabel: (option) => formatPackaging(option),
    cell: (item) => {
        if (!isPresent(item.getValue())) {
            return (
                <Text variant="inherit" color={colorSystem.neutral[6]}>
                    <Trans>Unknown</Trans>
                </Text>
            );
        }
        return <Tag color={'neutral'} attention={'low'} label={formatPackaging(item.getValue())} />;
    },
});
export const columnMOQ = columnHelper.number((row) => extractMoq(row), {
    id: 'moq',
    label: () => t`MOQ`,
    size: 60,
    cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
});
export const columnMPQ = columnHelper.number((row) => extractMpq(row), {
    id: 'mpq',
    label: () => t`MPQ`,
    size: 60,
    initialVisibility: false,
    cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
});
export const columnOfferLeadTime = columnHelper.number(
    (row) => {
        const shippingTimeInDays = row.solution.availability?.shipping_time_in_days ?? 0;
        return leadTimeDaysExtractor(row.solution.availability) + shippingTimeInDays;
    },
    {
        id: 'leadTime',
        label: () => t`Lead time`,
        size: 90,
        align: 'center',
        cell: ({ row }) => {
            return <AvailabilityChip solution={row.original.solution} />;
        },
    },
);
export const columnOfferStock = columnHelper.number('offer.available_prices.stock', {
    id: 'stock',
    label: () => t`Stock`,
    size: 80,
    cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    quickFilters: [
        {
            label: () => t`With stock`,
            value: {
                filterFn: SupportedFilterFn.inNumberRange,
                value: [1, null],
            },
        },
    ],
});
export const columnUnitPrice = columnHelper.monetaryValue((row) => extractAdjustedUnitPrice(row), {
    id: 'unitPrice',
    label: () => t`Unit price`,
    size: 90,
    cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
});
export const columnOneTimeCosts = ({ initialVisibility = false }: { initialVisibility?: boolean }) =>
    columnHelper.monetaryValue((row) => extractAccumulatedOneTimeCosts(row), {
        id: 'oneTimeCost',
        label: () => t`One-time costs`,
        size: 120,
        initialVisibility,
        cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '-' }),
    });
export const columnExcessMaterial = columnHelper.monetaryValue((row) => extractExcessMaterial(row), {
    id: 'excessMaterial',
    label: () => t`Excess material`,
    size: 120,
    initialVisibility: false,
    cell: (item) => formatMonetaryValue(item.getValue(), 'default', { ifAbsent: '-' }),
});
export const columnTotalPrice = columnHelper.monetaryValue('solution.totalPrice.original_total_price', {
    id: 'totalPrice',
    label: () => t`Total price`,
    size: 100,
    cell: (item) => formatMonetaryValue(item.getValue()),
});
export const columnTcoUnitPrice = columnHelper.monetaryValue(
    (row) => (isPresent(row.solution) ? extractAdjustedTcoUnitPrice({ solution: row.solution }) : null),
    {
        id: 'landed-unit-price',
        initialVisibility: isTotalCostOfOwnershipEnabled(),
        enableHiding: isTotalCostOfOwnershipEnabled(),
        label: () => t`TCO unit price`,
        size: 100,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' }),
    },
);
export const columnUnitTcoCost = columnHelper.monetaryValue('solution.unitTcoCost.cost', {
    id: 'unit-tco-cost',
    initialVisibility: isTotalCostOfOwnershipEnabled(),
    enableHiding: isTotalCostOfOwnershipEnabled(),
    size: 100,
    formatAs: 'unit-price',
    label: () => t`TCO cost`,
    cell: ({ row, getValue, sharedContext }) => {
        const unitTcoCost = row.original.solution?.unitTcoCost;
        return (
            <Tooltip
                title={
                    <TcoCostTooltip tcoCost={unitTcoCost ?? undefined} tcoRuleNameMap={sharedContext.tcoRuleNameMap} />
                }
                variant="white"
                disableMaxWidth={true}
            >
                <span>{formatMonetaryValue(getValue(), 'unit-price', { ifAbsent: '-' })}</span>
            </Tooltip>
        );
    },
});

export const columnCurrency = columnHelper.enum(
    (row) => extractAdjustedUnitPrice(row, 'scaled', 'original')?.currency,
    {
        id: 'currency',
        label: () => t`Currency`,
        size: 90,
        initialVisibility: false,
        getOptionLabel: (opt) => opt ?? t`Unknown`,
        cell: (item) => <Tag color={'neutral'} attention={'low'} label={item.getValue() ?? t`Unknown`} />,
    },
);
export const columnUnitPriceOriginalCurrency = columnHelper.monetaryValue(
    (row) => extractAdjustedUnitPrice(row, 'scaled', 'original'),
    {
        id: 'unitPriceOriginalCurrency',
        label: () => t`Unit price (original currency)`,
        size: 130,
        initialVisibility: false,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    },
);
export const columnTotalPriceOriginalCurrency = columnHelper.monetaryValue(
    'solution.totalPrice.original_currency_total_price',
    {
        id: 'totalPriceOriginalCurrency',
        label: () => t`Total price (original currency)`,
        size: 160,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue()),
    },
);
export const columnOfferPriceType = columnHelper.enum('offer.price_type', {
    id: 'priceType',
    label: () => t`Price type`,
    size: 90,
    initialVisibility: false,
    options: Object.values(PriceType),
    getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
    cell: (item) => <Tag color="neutral" attention="low" label={transEnum(item.getValue(), priceTypeTranslations)} />,
    quickFilters: [
        {
            label: () => t`Only inventory offers`,
            value: {
                filterFn: SupportedFilterFn.equalsAny,
                value: [PriceType.PurchasePrice, PriceType.StandardPrice],
            },
        },
    ],
});

export const columnUnitOfMeasurement = columnHelper.number('offer.unit_of_measurement.quantity_unit.quantity', {
    id: 'unitOfMeasurement',
    label: () => t`Unit of measurement`,
    size: 90,
    initialVisibility: false,
    renderType: 'generic',
    cell: ({ row }) => <UnitOfMeasurementTag unitOfMeasurement={row.original.offer.unit_of_measurement} />,
});

export const columnFactoryLeadTime = columnHelper.number(
    (row) => (isStandardPartOffer(row.offer) ? row.offer.available_prices.factory_lead_time_days : null),
    {
        id: 'factoryLeadTime',
        label: () => t`Standard factory lead time`,
        size: 90,
        initialVisibility: false,
        cell: (item) => formatDays(item.getValue()),
    },
);

export const ColumnOfferNotes = ({ initialVisibility = false }: { initialVisibility?: boolean }) =>
    columnHelper.text('offer.notes', {
        id: 'notes',
        label: () => t`Notes`,
        size: 100,
        initialVisibility,
        cell: (item) => <NotesIcon notes={item.getValue()} />,
    });
export const columnOrigin = columnHelper.enum((row) => row.offer.origin.origin, {
    id: 'origin',
    size: 130,
    label: () => t`Origin`,
    enableOnRowClick: false,
    options: ({ facetedValues }) => [OfferOriginEnum.Manual, ...facetedValues],
    getOptionLabel: (origin) => formatOfferOrigin({ origin }) ?? `Orbweaver`,
    cell: ({ row }) => <OriginCell key={Math.random()} offer={row.original.offer} />,
    quickFilters: [
        {
            label: () => t`Show only manual offers`,
            value: {
                filterFn: SupportedFilterFn.equalsAny,
                value: [OfferOriginEnum.Manual],
            },
        },
    ],
});
export const columnOfferCreationDate = columnHelper.date('offer.creation_date', {
    id: 'created',
    size: 100,
    enableColumnFilter: false,
    label: () => t`Updated`,
    cell: (item) => (
        <Tooltip title={formatLongDateTime(item.getValue())}>
            <Text variant="inherit" color={colorSystem.neutral[6]}>
                {formatRelativeTime(item.getValue())}
            </Text>
        </Tooltip>
    ),
});

export const columnOfferNumber = columnHelper.date('offer.offer_number', {
    id: 'offerNumber',
    size: 100,
    enableColumnFilter: false,
    initialVisibility: false,
    label: () => t`Offer number`,
    cell: (item) => (
        <Text variant="inherit" color={colorSystem.neutral[6]}>
            {item.getValue()}
        </Text>
    ),
});

export const columnSourcingBatchSize = columnHelper.number('offer.sourcing_batch_size', {
    id: 'offerSourcingBatchSize',
    size: 100,
    enableColumnFilter: false,
    initialVisibility: true,
    label: () => t`Sourcing batch size`,
    cell: (item) => (
        <Text variant="inherit" color={colorSystem.neutral[6]}>
            {item.getValue()}
        </Text>
    ),
});

export const columnSolutionTags = columnHelper.array((row) => row.solution.solutionTags.map((t) => t.tag), {
    id: 'solutionTags',
    label: () => t`Solution tags`,
    size: 100,
    enableHiding: false,
    initialVisibility: false,
    options: ({ facetedValues }) =>
        [
            SolutionTag.Inventory,
            SolutionTag.SupplierPreferred,
            SolutionTag.SupplierApproved,
            ...SolutionErrorTags,
            ...SolutionWarningTags,
            ...SolutionNotificationTags,
        ].filter((tag) => facetedValues.includes(tag)),
    renderOption: (tag) => (
        <Chip color={extractSolutionTagColor({ solutionTag: tag })} label={transEnum(tag, solutionTagTranslations)} />
    ),
    getOptionLabel: (tag) => transEnum(tag, solutionTagTranslations),
    cell: () => {
        throw new Error('Not implemented');
    },
});
export const columnStatus = columnHelper.enum((row) => extractSolutionStatus(row.solution, 'original'), {
    id: 'status',
    label: () => t`Status`,
    size: 80,
    align: 'center',
    options: Object.values(SolutionStatus),
    getOptionLabel: (status) => formatSolutionStatus(status),
    cell: ({ row }) => (
        <Tooltip
            variant="white"
            title={
                <SolutionStatusInformation
                    solution={{
                        solutionTags: row.original.solution.solutionTags.filter(
                            ({ tag }) => tag !== SolutionTag.ManualStatus,
                        ),
                    }}
                />
            }
        >
            <span>
                <SolutionStatusIcon status={extractSolutionStatus(row.original.solution, 'original')} />
            </span>
        </Tooltip>
    ),
    quickFilters: [
        {
            label: () => t`Exclude errors`,
            value: {
                filterFn: SupportedFilterFn.equalsAny,
                value: [SolutionStatus.Good, SolutionStatus.Warning],
            },
        },
    ],
});

export const columnVolumeInM2 = columnHelper.number(
    (row) => (row.partType === 'CustomPart' ? row.offerVolumeInM2 : null),
    {
        id: 'offerVolumeInM2',
        size: 100,
        label: () => t`Offer volume in sqm`,
        initialVisibility: false,
        cell: (item) => {
            return (
                <Text variant="inherit">
                    {item.getValue() ? `${formatDecimal(item.getValue(), { ifAbsent: '-' })} m2` : '-'}
                </Text>
            );
        },
    },
);

export const columnPricePerMeterSquare = columnHelper.monetaryValue(
    (row) => (row.partType === 'CustomPart' ? row.pricePerMeterSquare : null),
    {
        id: 'pricePerMeterSquare',
        size: 100,
        label: () => t`Price per sqm`,
        initialVisibility: false,
        cell: (item) => {
            return (
                <Text variant="inherit">{formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' })}</Text>
            );
        },
    },
);

export const columnPanelPrice = columnHelper.monetaryValue(
    (row) => {
        return row.partType === 'CustomPart'
            ? extractAdjustedUnitPrice(
                  {
                      solution: row.solution,
                  },
                  'non-scaled',
              )
            : null;
    },
    {
        id: 'panelPrice',
        size: 100,
        label: () => t`Panel price`,
        initialVisibility: false,
        cell: (item) => {
            return (
                <Text variant="inherit">{formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' })}</Text>
            );
        },
    },
);
