import * as r from 'runtypes';
import { ConfigurationsNotesPatchRuntype, ConfigurationsPatchRuntype } from '../activityConfiguration';
import { ExpenseConfigurationRuntype, ExpenseConfigurationsPostRuntype } from '../expenses';
import { endpoint } from '../http/endpoint';

export const expenseConfigurationEndpoints = {
    'PATCH /expense-configurations/:expenseConfigurationId': endpoint({
        description: 'Edit expense configuration. If driver_count is null the count is reset.',
        pathParams: r.Record({ expenseConfigurationId: r.String }),
        queryParams: r.Record({
            apply_to_all_activities_and_expenses: r.Boolean,
        }),
        requestBody: ConfigurationsPatchRuntype,
        responseBody: r.Record({
            data: ExpenseConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
    'POST /expense-configurations/bulk': endpoint({
        description: 'Add expenses to a manufacturing scenario',
        pathParams: r.Record({}),
        queryParams: r.Record({}),
        requestBody: ExpenseConfigurationsPostRuntype,
        responseBody: r.Record({
            data: r.Array(ExpenseConfigurationRuntype),
        }),
        invalidates: ['GET /manufacturing-scenarios'],
    }),
    'PATCH /expense-configurations/:expenseConfigurationId/notes': endpoint({
        description: 'Edits the notes only of a specific expense configuration',
        pathParams: r.Record({ expenseConfigurationId: r.String }),
        queryParams: r.Record({}),
        requestBody: ConfigurationsNotesPatchRuntype,
        responseBody: r.Record({
            data: ExpenseConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'DELETE /expense-configurations/bulk': endpoint({
        description: 'Deletes expense configurations from a manufacturing scenario',
        pathParams: r.Undefined,
        queryParams: r.Record({
            ids: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),

    'PATCH /expense-configurations/:expenseConfigurationId/rank': endpoint({
        description: 'Updates the rank of an expense configuration',
        pathParams: r.Record({ expenseConfigurationId: r.String }),
        queryParams: r.Record({}),
        requestBody: r.Record({ rank: r.Number }),
        responseBody: r.Record({
            data: ExpenseConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
};
