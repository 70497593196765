import { t } from '@lingui/macro';
import { Currency, formatToIso8601Date } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import {
    ExtractRequestBody,
    Packaging,
    PriceType,
    SupplierAndStockLocationDTO,
    SupplierTag,
} from '@luminovo/http-client';
import { ImporterConfig, UniversalImporter } from '@luminovo/universal-importer';
import { GlobalField } from '@luminovo/universal-importer/src/types';
import { useSnackbar } from 'notistack';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';
export function OfferImporter() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { onImportBatch } = useImportOffers();

    const { data: manufacturers } = useManufacturerNames();

    const { data: suppliers = [] } = useSuspenseHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        { select: (res) => res.data.filter((supplier) => Boolean(supplier.supplier_number)) },
    );

    const { data: defaultCurrency = Currency.EUR } = useSuspenseHttpQuery(
        'GET /organization-settings/organization-currency-settings',
        {},
        { select: (res) => res.data.currency },
    );

    const decimalSeparator = '.';

    return (
        <UniversalImporter
            title={t`Offer importer`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Offers imported successfully`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                navigate(-1);
            }}
            onImportBatch={onImportBatch}
            config={createImporterConfig({
                manufacturers: manufacturers?.manufacturers ?? [],
                manufacturerNames: manufacturers?.manufacturerNames ?? new Set(),
                decimalSeparator,
                defaultCurrency,
                suppliers,
            })}
        />
    );
}

function useImportOffers() {
    const { mutateAsync: importOffers } = useHttpMutation('POST /offers/import', { snackbarMessage: null });

    const onImportBatch = async (
        batch: Array<{
            data: {
                mpn: string | null;
                manufacturer: string | null;
                ipn: string | null;
                notes: string;
                availableStock: number;
                totalStock: number;
                packaging: Packaging;
                unitPrice: number;
                pricePer: number;
                currency: Currency;
                moq: number;
                mpq: number;
                leadTimeWeeks: number;
                supplierNumber: string;
                customerName: string | undefined;
                customerNumber: string | undefined;
                validFrom: string | undefined | null;
                validUntil: string | undefined | null;
            };
        }>,
        globalFields: GlobalField[] | undefined,
    ) => {
        type Offer = ExtractRequestBody<'POST /offers/import'>[0];
        const requestBody: Offer[] = Object.values(batch).map((row): Offer => {
            const customerName = row.data.customerName;
            const customerNumber = row.data.customerNumber;
            const customer =
                customerNumber && customerNumber.length > 0
                    ? { name: customerName, number: customerNumber }
                    : undefined;

            type Part = ExtractRequestBody<'POST /offers/import'>[0]['part'];

            const internal_part_number: string | undefined = row.data.ipn ?? undefined;
            const manufacturer = row.data.manufacturer;
            const manufacturer_part_number = row.data.mpn;
            const part: Part =
                manufacturer && manufacturer_part_number
                    ? { manufacturer, manufacturer_part_number, internal_part_number }
                    : { internal_part_number: internal_part_number! };

            return {
                part,
                prices: [
                    {
                        unit_price: {
                            amount: String(row.data.unitPrice / row.data.pricePer),
                            currency: row.data.currency,
                        },
                        moq: row.data.moq,
                        mpq: row.data.mpq,
                    },
                ],
                price_type:
                    globalFields && globalFields.length > 0 && globalFields[0].value?.id
                        ? (globalFields[0].value.id as PriceType)
                        : undefined,
                availability: {
                    available_stock: row.data.availableStock,
                    total_stock: row.data.totalStock,
                    lead_time: row.data.leadTimeWeeks * 7,
                },
                notes: row.data.notes,
                packaging: row.data.packaging,
                supplier:
                    row.data.supplierNumber && row.data.supplierNumber.length > 0
                        ? {
                              type: 'External',
                              supplier_number: row.data.supplierNumber,
                          }
                        : {
                              type: 'Internal',
                          },
                customer,
                valid_from: row.data.validFrom ? formatToIso8601Date(row.data.validFrom) : undefined,
                valid_until: row.data.validUntil ? formatToIso8601Date(row.data.validUntil) : undefined,
            };
        });

        return importOffers({
            requestBody,
        })
            .then((results) => {
                return batch.map((batchItem, index) => {
                    const resultItem = results.find((result) => {
                        const ipnMatch = result.part?.internal_part_number === batchItem.data.ipn;
                        const mpnMatch = result.part?.manufacturer_part_number === batchItem.data.mpn;
                        const manufacturerMatch = result.part?.manufacturer === batchItem.data.manufacturer;
                        return ipnMatch || (mpnMatch && manufacturerMatch);
                    });

                    if (!resultItem) {
                        return {
                            success: false as const,
                            message: t`Unknown error`,
                        };
                    }

                    if (resultItem.status > 299) {
                        return {
                            success: false as const,
                            message: resultItem.description ?? t`Unknown error`,
                        };
                    }

                    return {
                        success: true as const,
                        message: resultItem.description,
                    };
                });
            })
            .catch((error) => {
                return batch.map((row) => {
                    return {
                        success: false as const,
                        message: formatError(error),
                    };
                });
            });
    };
    return { onImportBatch };
}

const createImporterConfig = ({
    manufacturers,
    manufacturerNames,
    decimalSeparator,
    defaultCurrency,
    suppliers,
}: {
    manufacturers: Array<{ id: string; label: string; description: string }>;
    manufacturerNames: Set<string>;
    decimalSeparator: '.' | ',';
    defaultCurrency: Currency;
    suppliers: SupplierAndStockLocationDTO[];
}) => {
    const config = {
        globalFields: [
            {
                id: 'priceType' as const,
                label: t`Price type`,
                description: t`The price type of the offer`,
                required: true,
                parser: { type: 'priceType', options: { trim: true } },
            },
        ],
        fields: [
            {
                id: 'mpn' as const,
                columnIndices: [],
                required: { color: 'green', label: t`For MPN offers` },
                parser: { type: 'string', options: { trim: true, minLength: 1 } },
                label: t`MPN`,
                description: t`The manufacturer part number. Only required for MPN offers.`,
                defaultValue: { id: null, label: '' },
            },
            {
                id: 'manufacturer' as const,
                columnIndices: [],
                required: { color: 'green', label: t`For MPN offers` },
                parser: { type: 'manufacturer.name', options: { manufacturers, manufacturerNames } },
                label: t`Manufacturer`,
                description: t`The name of the manufacturer. Only required for MPN offers.`,
                defaultValue: { id: null, label: '' },
            },
            {
                id: 'ipn' as const,
                columnIndices: [],
                required: { color: 'violet', label: t`For IPN offers` },
                parser: { type: 'ipn', options: { ipns: [] } },
                label: t`IPN`,
                description: t`The internal part number. Only required for IPN offers.`,
                defaultValue: { id: null, label: '' },
            },
            {
                id: 'unitPrice' as const,
                columnIndices: [],
                required: true,
                parser: {
                    type: 'number',
                    options: {
                        decimalSeparator,
                    },
                },
                label: t`Unit price`,
                description: t`The price of a single unit.`,
            },
            {
                id: 'pricePer' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 1 } },
                label: t`Price per`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`Is the unit pricer per 1s, 10s, 100s, 1000s, etc.`,
            },
            {
                id: 'currency' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'currency', options: {} },
                label: t`Currency`,
                defaultValue: { id: defaultCurrency, label: defaultCurrency },
                description: t`The unit price's currency.`,
            },
            {
                id: 'moq' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`MOQ`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`The minimum order quantity.`,
            },
            {
                id: 'mpq' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 1, decimalSeparator } },
                label: t`MPQ`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`The minimum package quantity.`,
            },
            {
                id: 'packaging' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'packaging', options: {} },
                label: t`Packaging`,
                defaultValue: { id: null, label: t`Unknown` },
                description: t`The packaging in which the part is delivered (e.g. reel, tape, etc.).`,
            },
            {
                id: 'availableStock' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: 0, label: `0` },
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`Available stock`,
                description: t`The available stock.`,
            },
            {
                id: 'totalStock' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: null, label: t`Unknown` },
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`Total stock`,
                description: t`The total amount of stock. Some of it might be reserved or not available for use.`,
            },
            {
                id: 'leadTimeWeeks' as const,
                columnIndices: [],
                required: false,
                label: t`Lead time (weeks)`,
                parser: { type: 'leadTimeWeeks', options: { decimalSeparator } },
                description: t`The standard manufacturer lead time.`,
                defaultValue: { id: null, label: t`Unknown`, description: '' },
            },
            {
                id: 'supplierNumber' as const,
                columnIndices: [],
                required: false,
                parser: {
                    type: 'supplier.number',
                    options: { suppliers: suppliers.map(toParsedValue), warnOnSystemSupplier: false },
                },
                label: t`Supplier number`,
                description: t`The number that identifies the supplier of this offer. Defaults to 'inventory', meaning the offer is for internal stock.`,
                defaultValue: { id: null, label: t`Inventory` },
            },
            {
                id: 'notes' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: '', label: ``, description: '' },
                parser: { type: 'notes', options: { min: 0 } },
                label: t`Notes`,
                description: t`Any additional information you want to add.`,
            },
            {
                id: 'customerName' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'string', options: { trim: false } },
                label: t`Customer name`,
                description: t`The name of the customer this offer is for. Leave blank if the offer is not linked to a specific customer.`,
            },
            {
                id: 'customerNumber' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'string', options: { trim: false } },
                label: t`Customer number`,
                description: t`The number of the customer this offer is for. Leave blank if the offer is not linked to a specific customer.`,
            },
            {
                id: 'validFrom' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'date', options: { parseEmptyAsNull: true } },
                label: t`Valid from`,
                description: t`The date from which the offer is valid.`,
                defaultValue: { id: undefined, label: t`Unknown` },
            },
            {
                id: 'validUntil' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'date', options: { parseEmptyAsNull: true } },
                label: t`Valid until`,
                description: t`The date until which the offer is valid.`,
                defaultValue: { id: undefined, label: t`Unknown` },
            },
        ],
    } satisfies ImporterConfig;
    return config;
};

function useManufacturerNames() {
    return useSuspenseHttpQuery(
        'GET /manufacturers',
        { queryParams: { high_quality: false } },
        {
            select: (res) => {
                const manufacturersWithAlternatives = res.data.flatMap((man) => {
                    const preferredName = man.name;
                    const alternativeNames = man.alternative_names;

                    return [preferredName, ...alternativeNames].map((name) => {
                        return {
                            // Note that we purposely treat the name as the id as this is used for the
                            // manufacturer NAME parser, in which the id is actually the name.
                            id: name,
                            label: name,
                            description: '',
                        };
                    });
                });

                const manufacturerNames = new Set(manufacturersWithAlternatives.map((man) => man.id.toLowerCase()));
                return {
                    manufacturers: manufacturersWithAlternatives,
                    manufacturerNames,
                };
            },
        },
    );
}

function toParsedValue(supplier: SupplierAndStockLocationDTO) {
    return {
        id: supplier.supplier_number ?? '',
        label: String(supplier.supplier_number),
        description: supplier.supplier.name,
        existing: true,
        isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
    };
}
