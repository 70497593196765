import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type AvnetEbvLocaleCurrencyDTO = r.Static<typeof AvnetEbvLocaleCurrencyDTORuntype>;
export const AvnetEbvLocaleCurrencyDTORuntype = r.Union(
    r.Literal('EUR'),
    r.Literal('USD'),
    r.Literal('GBP'),
    r.Literal('JPY'),
);

export interface AvnetEbvCredentialsDTO extends r.Static<typeof AvnetEbvCredentialsDTORuntype> {}
export const AvnetEbvCredentialsDTORuntype = r.Union(
    r.Record({
        username: r.String,
        password: r.String,
        currency: AvnetEbvLocaleCurrencyDTORuntype,
    }),
);

export interface AvnetEbvCredentialsInputDTO extends r.Static<typeof AvnetEbvCredentialsInputDTORuntype> {}
export const AvnetEbvCredentialsInputDTORuntype = r.Record({
    username: r.String.optional(),
    password: r.String.optional(),
    currency: AvnetEbvLocaleCurrencyDTORuntype.optional(),
});

export interface AvnetEbvResponseBodyDTO extends r.Static<typeof AvnetEbvResponseBodyDTORuntype> {}
export const AvnetEbvResponseBodyDTORuntype = r.Record({
    data: AvnetEbvCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
