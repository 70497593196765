import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { Currency } from '../currencyBackendTypes';
import { ManufacturerDTORuntype, ManufacturerWithPartCountDTORuntype } from '../manufacturer';
import { UserDTORuntype } from '../user';

export enum SupplierOrigin {
    System = 'System',
    Erp = 'Erp',
    Manual = 'Manual',
}

export enum SupplierType {
    Distributor = 'Distributor',
    Manufacturer = 'Manufacturer',
    Ems = 'Ems',
}

export enum SupplierPartType {
    OffTheShelf = 'OffTheShelf',
    Custom = 'Custom',
    Pcb = 'Pcb',
    Assembly = 'Assembly',
}

export interface SupplierDTO extends r.Static<typeof SupplierDTORuntype> {}
export const SupplierDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    alternative_names: r.Array(r.String),
    origin: runtypeFromEnum(SupplierOrigin),
    supplier_type: runtypeFromEnum(SupplierType),
    supplier_number: r.String.optional().nullable(),
    supplier_part_type: runtypeFromEnum(SupplierPartType),
    default_currency: runtypeFromEnum(Currency).nullable(),
});

export interface LineCardModificationDTO extends r.Static<typeof LineCardModificationDTORuntype> {}
export const LineCardModificationDTORuntype = r.Record({
    user: UserDTORuntype.pick('id', 'first_name', 'last_name', 'email').optional(),
    manufacturer: ManufacturerDTORuntype,
    action: r.Union(r.Literal('Add'), r.Literal('Remove')),
    updated_at: r.String,
    created_at: r.String,
});

export type SupplierLineCardItemSourceDTO = r.Static<typeof SupplierLineCardItemSourceRuntype>;
export const SupplierLineCardItemSourceRuntype = r.Union(
    r.Record({
        type: r.Literal('System'),
    }),
    r.Record({
        type: r.Literal('Tenant'),
        value: UserDTORuntype.pick('id', 'first_name', 'last_name', 'email').nullable(),
    }),
);

export type SupplierLineCardManufacturerDTO = r.Static<typeof SupplierLineCardManufacturerRuntype>;
export const SupplierLineCardManufacturerRuntype = ManufacturerWithPartCountDTORuntype.extend({
    source: SupplierLineCardItemSourceRuntype,
});

export interface SupplierLineCardDTO extends r.Static<typeof SupplierLineCardDTORuntype> {}
export const SupplierLineCardDTORuntype = r.Record({
    supplier: r.String,
    manufacturers: r.Array(SupplierLineCardManufacturerRuntype),
    last_updated_at: r.String.nullable(),
    last_updated_by: SupplierLineCardItemSourceRuntype.nullable(),
});

export const MinimalSupplierLineCardDTORuntype = r.Record({
    supplier: r.String,
    manufacturers: r.Array(r.String),
});

export type SupplierLineCardPatchDTO = r.Static<typeof SupplierLineCardPatchDTORuntype>;
export const SupplierLineCardPatchDTORuntype = r.Record({
    manufacturers: r.Array(r.String),
});

export const SupplierLineCardRulesImportDTORunType = r.Record({
    items: r.Array(
        r.Record({
            supplier_id: r.String,
            manufacturer_id: r.String,
        }),
    ),
});

export type SupplierLineCardRuleImportResponseDTO = r.Static<typeof SupplierLineCardRuleImportResponseRuntype>;
export const SupplierLineCardRuleImportResponseRuntype = r.Record({
    items: r.Array(
        r.Record({
            status: r.Number,
            message: r.String,
            data: r.Union(
                SupplierLineCardDTORuntype,
                r.Record({
                    supplier_id: r.String,
                    manufacturer_id: r.String,
                }),
            ),
        }),
    ),
});
