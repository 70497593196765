import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useSupplierContactsFromSupplier } from '@/resources/supplierContact/supplierContactHandler';
import { t } from '@lingui/macro';
import { languageTranslations, transEnum } from '@luminovo/commons';
import {
    CenteredLayout,
    Flexbox,
    MenuButton,
    MenuItem,
    NonIdealState,
    StatusChip,
    Switch,
    TanStackTable,
    Tooltip,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { SupplierContactDTO } from '@luminovo/http-client';
import { Add as AddIcon, Delete, EditRounded, MoreHoriz, ReplyRounded } from '@mui/icons-material';
import { ButtonAddSupplierContacts } from './ButtonAddSupplierContacts';
import { useDialogAddSupplierContact } from './SupplierContactDialogs/AddSupplierContactDialog';
import { useDialogDeleteSupplierContact } from './SupplierContactDialogs/DeleteSupplierContactDialog';
import { useDialogEditSupplierContact } from './SupplierContactDialogs/EditSupplierContactDialog';

type SupplierContactSharedContext = {
    supplierId: string;
};

function SupplierContactDefaultContactCell({ supplierContact }: { supplierContact: SupplierContactDTO }): JSX.Element {
    const { id: supplierContactId } = supplierContact;

    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /supplier-contacts/:supplierContactId', {
        snackbarMessage: t`Default contacts successfully updated`,
    });

    return (
        <Flexbox justifyContent={'center'}>
            <Tooltip title={t`Default contact`} arrow={false}>
                <Switch
                    checked={supplierContact.is_main_contact}
                    disabled={isLoading}
                    onChange={() =>
                        mutateAsync({
                            pathParams: { supplierContactId },
                            requestBody: { is_main_contact: !supplierContact.is_main_contact },
                        })
                    }
                    name="isMainContact"
                    color="primary"
                />
            </Tooltip>
        </Flexbox>
    );
}

export function SupplierContactInviteStatusCell({
    supplierContact,
}: {
    supplierContact: SupplierContactDTO;
}): JSX.Element {
    switch (supplierContact.state.state) {
        case 'Uninvited':
            return <StatusChip color="neutral" label={t`Uninvited`} />;
        case 'Invited':
            return <StatusChip color="yellow" label={t`Invited`} />;
        case 'User':
            return <StatusChip color="green" label={t`Registered`} />;
    }
}

function RowActionButton({ supplierContact }: { supplierContact: SupplierContactDTO }): JSX.Element {
    const { is_public } = supplierContact;

    const { openDialog: openEditDialog } = useDialogEditSupplierContact({
        supplierContact,
    });
    const { openDialog: openDeleteDialog } = useDialogDeleteSupplierContact({
        supplierContact,
    });

    const { mutateAsync } = useHttpMutation('POST /supplier-contacts/:id/invite', {
        snackbarMessage: t`Invitation sent`,
    });
    return (
        <MenuButton appearance="tertiary" icon={<MoreHoriz />} size={'small'} style={{ margin: 0 }}>
            <MenuItem
                label={t`Invite to supplier portal`}
                onClick={() => mutateAsync({ pathParams: { id: supplierContact.id } })}
                startIcon={<ReplyRounded />}
                disabled={supplierContact.state.state === 'User'}
            />
            <Tooltip title={is_public ? t`This contact is public and cannot be edited` : ''} arrow={false}>
                <span>
                    <MenuItem
                        label={t`Edit contact`}
                        onClick={() => openEditDialog()}
                        startIcon={<EditRounded />}
                        disabled={is_public}
                    />

                    <MenuItem
                        label={t`Delete contact`}
                        onClick={() => openDeleteDialog()}
                        variant={'destructive'}
                        startIcon={<Delete />}
                        disabled={is_public}
                    />
                </span>
            </Tooltip>
        </MenuButton>
    );
}

function ActionButton({ sharedContext }: { sharedContext: SupplierContactSharedContext }) {
    return <ButtonAddSupplierContacts appearance="primary" showLabel supplierId={sharedContext.supplierId} />;
}

function EmptyPlaceholder({ sharedContext }: { sharedContext: SupplierContactSharedContext }) {
    const { openDialog } = useDialogAddSupplierContact(sharedContext.supplierId);
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`This supplier has no contacts yet.`}
                description={''}
                action={{
                    onClick: () => openDialog(),
                    startIcon: <AddIcon />,
                    children: t`Add contact`,
                }}
            />
        </CenteredLayout>
    );
}

const columnHelper = createColumnHelper<SupplierContactDTO>();

const columns = [
    columnHelper.text('first_name', {
        size: 150,
        label: () => t`First name`,
    }),
    columnHelper.text('last_name', {
        size: 150,
        label: () => t`Last name`,
    }),
    columnHelper.text('email', {
        size: 200,
        label: () => t`Email`,
    }),
    columnHelper.enum('user_language', {
        size: 100,
        label: () => t`Language`,
        getOptionLabel: (option) => transEnum(option, languageTranslations),
        cell: (item) => transEnum(item.getValue(), languageTranslations),
    }),
    columnHelper.text('position', {
        size: 80,
        label: () => t`Position`,
        cell: (item) => item.getValue() ?? '-',
    }),
    columnHelper.enum('is_main_contact', {
        size: 100,
        label: () => t`Default contact`,
        getOptionLabel: (option) => (option ? t`Yes` : t`No`),
        cell: (item) => <SupplierContactDefaultContactCell supplierContact={item.row.original} />,
    }),
    columnHelper.text('state', {
        size: 100,
        label: () => t`Status`,
        enableColumnFilter: false,
        description: () => t`Status of the invitation to the quote portal.`,
        cell: (item) => <SupplierContactInviteStatusCell supplierContact={item.row.original} />,
    }),
    columnHelper.action({
        id: 'actionMenu',
        size: 48,
        cell: ({ row }) => <RowActionButton supplierContact={row.original} />,
    }),
];

export function SupplierContactsTable({ supplierId }: { supplierId: string }) {
    const { data } = useSupplierContactsFromSupplier(supplierId);

    const { table } = useTanStackTable({
        columns,
        data,
        sharedContext: { supplierId },
    });

    return (
        <TanStackTable table={table} size={'medium'} EmptyPlaceholder={EmptyPlaceholder} ActionButton={ActionButton} />
    );
}
