import * as r from 'runtypes';

const ManufacturingScenarioTemplateStatusRuntype = r.Union(r.Literal('Active'), r.Literal('Inactive'));
export type ManufacturingScenarioTemplateStatusDTO = r.Static<typeof ManufacturingScenarioTemplateStatusRuntype>;

export const IdWithIndexRuntype = r.Record({
    id: r.String,
    index: r.Number,
});
export type IdWithIndex = r.Static<typeof IdWithIndexRuntype>;

export const ManufacturingScenarioTemplateDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    notes: r.String.nullable(),
    status: ManufacturingScenarioTemplateStatusRuntype,
    manufacturing_lead_time: r.Number.nullable(),

    ordered_activities: r.Array(IdWithIndexRuntype),

    ordered_expenses: r.Array(IdWithIndexRuntype),
    sites: r.Array(r.String),
});

export const ManufacturingScenarioTemplatePostDTORuntype = ManufacturingScenarioTemplateDTORuntype.omit('id', 'sites');
export const ManufacturingScenarioTemplatePatchDTORuntype = ManufacturingScenarioTemplatePostDTORuntype.asPartial();

export type ManufacturingScenarioTemplateDTO = r.Static<typeof ManufacturingScenarioTemplateDTORuntype>;
export type ManufacturingScenarioTemplatePostDTO = r.Static<typeof ManufacturingScenarioTemplatePostDTORuntype>;
export type ManufacturingScenarioTemplatePatchDTO = r.Static<typeof ManufacturingScenarioTemplatePatchDTORuntype>;
