import { assertUnreachable, Currency } from '@luminovo/commons';
import {
    AssemblyResponseDTO,
    DemandDTO,
    LimitType,
    OrganizationSolutionPreferenceDTO,
    SourcingPreferenceOptions,
    SourcingScenarioDTO,
    SourcingScenarioPostDTO,
    SupplierAndStockLocationDTO,
    SupplierTag,
} from '@luminovo/http-client';
import { hasSupplierTag } from '@luminovo/sourcing-core';
import {
    convertDemandFormStateToRequestBody,
    createDemandFormStateDefaultValues,
    DemandFormState,
} from './DemandDialog/DemandFormItems';

export type SourcingScenarioFormValues = Omit<SourcingScenarioPostDTO, 'assembly_demands'> & {
    demands: Array<
        | {
              kind: 'existing';
              data: DemandDTO;
          }
        | {
              kind: 'new';
              data: DemandFormState;
          }
    >;
};

const filterSupplierIdsBy = ({
    supplierAndStockLocations,
    supplierIds,
    filterFn,
}: {
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
    supplierIds: string[] | null;
    filterFn: (s: SupplierAndStockLocationDTO) => boolean;
}) => {
    if (supplierIds === null) return [];
    const filteredSupplierIds = supplierAndStockLocations.filter(filterFn).map((s) => s.id);
    return supplierIds.filter((sId) => filteredSupplierIds.includes(sId));
};

export function createSourcingScenarioFormDefaultValue({
    rfqId,
    topLevelAssemblies,
    organizationSolutionPreferences,
    supplierAndStockLocations,
}: {
    rfqId: string;
    topLevelAssemblies: AssemblyResponseDTO[];
    organizationSolutionPreferences: OrganizationSolutionPreferenceDTO;
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
}): SourcingScenarioFormValues {
    const areAllAssembliesPcbOnly = topLevelAssemblies.every((assembly) => assembly.type.type === 'PcbOnly');
    const filterFn = areAllAssembliesPcbOnly
        ? (supplierLocation: SupplierAndStockLocationDTO) => hasSupplierTag(supplierLocation, SupplierTag.PcbSupplier)
        : () => true;

    return {
        rfq: rfqId,
        name: '',
        solution_preference: {
            lead_time_preference: { type: SourcingPreferenceOptions.Fastest },
            use_unit_price: organizationSolutionPreferences.use_unit_price,
            preferred_suppliers_and_stock_locations: filterSupplierIdsBy({
                supplierIds: organizationSolutionPreferences.preferred_suppliers_and_stock_locations,
                supplierAndStockLocations,
                filterFn,
            }),
            approved_suppliers_and_stock_locations: filterSupplierIdsBy({
                supplierIds: organizationSolutionPreferences.approved_suppliers_and_stock_locations,
                supplierAndStockLocations,
                filterFn,
            }),
            preferred_inventory_sites: organizationSolutionPreferences.preferred_inventory_sites,
            approved_inventory_sites: organizationSolutionPreferences.approved_inventory_sites,
            excess_preference: {
                max_ratio_percent: 0,
                minimum_savings: {
                    amount: '0',
                    currency: Currency.EUR,
                },
            },
            offer_age_limit: {
                expiring_before_validity_date: { type: LimitType.Never },
                expired_after_validity_date: { type: LimitType.Never },
                old_after_update: { type: LimitType.Never },
                outdated_after_update: { type: LimitType.Never },
            },
            low_stock_warning_factor: 2.0,
            inventory_preference: organizationSolutionPreferences.inventory_preference,
            packaging_preference: organizationSolutionPreferences.packaging_preference,
            prefer_direct_api_integrations: organizationSolutionPreferences.prefer_direct_api_integrations,
            exclude_mismatched_price_points: organizationSolutionPreferences.exclude_mismatched_price_points,
            prioritize_preferred_manufacturers: organizationSolutionPreferences.prioritize_preferred_manufacturers,
            excluded_price_types: organizationSolutionPreferences.excluded_price_types,
            prefer_customer_negotiated_prices: organizationSolutionPreferences.prefer_customer_negotiated_prices,
        },
        demands: topLevelAssemblies.map((assembly) => ({
            kind: 'new',
            data: createDemandFormStateDefaultValues(rfqId, { assembly }),
        })),
    };
}

export function convertToSourcingScenarioFormValue(input: SourcingScenarioDTO): SourcingScenarioFormValues {
    return {
        ...input,
        demands: input.assembly_demands.map((demand) => ({
            kind: 'existing',
            data: demand,
        })),
    };
}

export function convertExtractedValuesToRequestBody(values: SourcingScenarioFormValues): SourcingScenarioPostDTO {
    const { demands, ...rest } = values;
    return {
        ...rest,
        assembly_demands: demands.map((demand) => {
            switch (demand.kind) {
                case 'existing':
                    return { kind: 'existing', data: demand.data };
                case 'new':
                    return {
                        kind: 'new',
                        data: convertDemandFormStateToRequestBody(demand.data),
                    };
                default:
                    return assertUnreachable(demand);
            }
        }),
    };
}
