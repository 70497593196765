import { t } from '@lingui/macro';
import { DestructiveSecondaryButton, useNavigate } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { Delete } from '@mui/icons-material';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useSuspendedQuoteRequest } from '../hooks/negotiationHandlers';
export function ButtonDeleteQuoteRequest({ quoteRequestId }: { quoteRequestId: string }) {
    const navigate = useNavigate();

    const { mutateAsync, isPending: isLoading } = useHttpMutation('DELETE /quote-request/:id', {
        snackbarMessage: t`Quote request deleted`,
        onSuccess: () => navigate(-1),
    });

    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    if (quoteRequest.status !== QuoteRequestStatus.NotSent) {
        return null;
    }

    return (
        <DestructiveSecondaryButton
            size="medium"
            startIcon={<Delete />}
            isLoading={isLoading}
            onClick={() => mutateAsync({ pathParams: { id: quoteRequestId } })}
        >
            {t`Delete`}
        </DestructiveSecondaryButton>
    );
}
