/* eslint-disable spellcheck/spell-checker */
import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import {
    ApiNotSetupErrorKind,
    FileLifecycle,
    PCBV2BoardProperties,
    PCBV2LayerStackProperties,
    PCBV2MechanicalProperties,
    PCBV2SpecificationCapabilities,
    PanelErrorKind,
    PcbWorkflowStep,
} from '@luminovo/http-client';

type CapabilityNames =
    | keyof PCBV2SpecificationCapabilities['board']
    | keyof PCBV2SpecificationCapabilities['layerStack']
    | keyof PCBV2SpecificationCapabilities['mechanical']
    | keyof PCBV2SpecificationCapabilities['markings']
    | keyof PCBV2SpecificationCapabilities['miscellaneous']
    | keyof PCBV2SpecificationCapabilities['reports'];

export const capabilitiesTranslations: Record<CapabilityNames, MessageDescriptor> = {
    layercount: defineMessage({ message: 'Number of layers' }),
    minViaDiameter: defineMessage({ message: 'Minimum via diameter' }),
    boardHeight: defineMessage({ message: 'Board height' }),
    boardWidth: defineMessage({ message: 'Board width' }),
    outerCopperThickness: defineMessage({ message: 'Finished copper thickness (outer l.)' }),
    innerCopperThickness: defineMessage({ message: 'Finished copper thickness (inner l.)' }),
    minOuterLayerStructure: defineMessage({ message: 'Min. outer layer structure' }),
    minInnerLayerStructure: defineMessage({ message: 'Min. inner layer structure' }),
    soldermaskColor: defineMessage({ message: 'Soldermask color' }),
    soldermaskSide: defineMessage({ message: 'Soldermask side' }),
    silkscreenColor: defineMessage({ message: 'Silkscreen color' }),
    silkscreenSide: defineMessage({ message: 'Silkscreen side' }),
    surfaceFinish: defineMessage({ message: 'Surface finish' }),
    enigThickness: defineMessage({ message: 'Enig Thickness' }),
    ipc600Class: defineMessage({ message: 'IPC class' }),
    baseMaterial: defineMessage({ message: 'Base material' }),
    finalThickness: defineMessage({ message: 'PCB thickness', id: 'PCB thickness capabilities' }),
    eTest: defineMessage({ message: 'E-test' }),
    ulLayerStack: defineMessage({ message: 'UL certified Stackup' }),
    ulMarkingType: defineMessage({ message: 'UL marking' }),
    viaFilling: defineMessage({ message: 'Via filling' }),
    pressFit: defineMessage({ message: 'Press-fit technology' }),
    impedanceTested: defineMessage({ message: 'Impedance tested' }),
    peelableMask: defineMessage({ message: 'Peelable mask' }),
    captonTape: defineMessage({ message: 'Capton tape' }),
    blindVias: defineMessage({ message: 'Blind vias' }),
    buriedVias: defineMessage({ message: 'Buried vias' }),
    chamfering: defineMessage({ message: 'Chamfering' }),
    hardGold: defineMessage({ message: 'Hard gold' }),
    maxXOutsAllowed: defineMessage({ message: 'Max X-outs allowed' }),
    halogenFree: defineMessage({ message: 'Halogen Free' }),
    reports: defineMessage({ message: 'Reports' }),
    itar: defineMessage({ message: 'Itar' }),
    applicationType: defineMessage({ message: 'Application type' }),
    hardGoldArea: defineMessage({ message: 'Hard gold area' }),
    carbonPrint: defineMessage({ message: 'Carbon print' }),
    edgeMetalization: defineMessage({ message: 'Edge metalization' }),
    layerstackThicknessTolerance: defineMessage({ message: 'Layerstack thickness tolerance' }),
    tgValue: defineMessage({ message: 'Tg value' }),
    rohsCompilant: defineMessage({ message: 'ROHS compilant' }),
    flammabilityRating: defineMessage({ message: 'Flammability rating' }),
    layerstackType: defineMessage({ message: 'Layerstack type' }),
    ctiClass: defineMessage({ message: 'Cti class' }),
    aspectRatio: defineMessage({ message: 'Aspect ratio' }),
    soldermaskDam: defineMessage({ message: 'Soldermask dam' }),
    soldermaskClearance: defineMessage({ message: 'Soldermask clearance' }),
    exposedCopperArea: defineMessage({ message: 'Exposed copper area' }),
    exposedCopperAreaTop: defineMessage({ message: 'Exposed copper area top' }),
    exposedCopperAreaBottom: defineMessage({ message: 'Exposed copper area bottom' }),
    totalDrillCount: defineMessage({ message: 'Total drill count' }),
    microVias: defineMessage({ message: 'Micro vias' }),
    phCount: defineMessage({ message: 'Ph count' }),
    traceWidth: defineMessage({ message: 'Trace width' }),
    phToolCount: defineMessage({ message: 'Ph tool count' }),
    nphCount: defineMessage({ message: 'Nph count' }),
    phMinSize: defineMessage({ message: 'Ph min size' }),
    nphMaxSize: defineMessage({ message: 'Nph max size' }),
    phMaxSize: defineMessage({ message: 'Ph max size' }),
    nphToolCount: defineMessage({ message: 'Nph tool count' }),
    copperClearance: defineMessage({ message: 'Copper clearance' }),
    phAnnularRing: defineMessage({ message: 'Ph annular ring' }),
    nphMinSize: defineMessage({ message: 'Nph min size' }),
    placementSide: defineMessage({ message: 'Placement side' }),
    phSurfaceArea: defineMessage({ message: 'Ph surface area' }),
    hardGoldThickness: defineMessage({ message: 'Hard gold thickness' }),
    pressFitTechnology: defineMessage({ message: 'Press fit technology' }),
    impedanceTolerance: defineMessage({ message: 'Impedance tolerance' }),
    crossSection: defineMessage({ message: 'Cross section' }),
    xRayMeasurement: defineMessage({ message: 'X-ray measurement' }),
    xRayMeasurementPoints: defineMessage({ message: 'X-ray measurement points' }),
    firstArticleInspection: defineMessage({ message: 'First article inspection report' }),
    certificateOfConformance: defineMessage({ message: 'Certificate of conformance' }),
    dateCode: defineMessage({ message: 'Date code' }),
    fabricatorLogo: defineMessage({ message: 'Fabricator logo' }),
    datamatrixCode: defineMessage({ message: 'Datamatrix code' }),
    manufacturingInformation: defineMessage({ message: 'Manufacturing information' }),
};

export const pdfExtractionTranslations: Record<string, MessageDescriptor> = {
    ...capabilitiesTranslations,
    color: defineMessage({ message: 'Color' }),
    side: defineMessage({ message: 'Side' }),
    millimeter: defineMessage({ message: 'Millimeter' }),
    micrometer: defineMessage({ message: 'Micrometer' }),
    soldermaskKeyword: defineMessage({ message: 'Soldermask' }),
    solderMaskDam: defineMessage({ message: 'Soldermask dam' }),
    silkscreenKeyword: defineMessage({ message: 'Silkscreen' }),
    peelableMaskKeyword: defineMessage({ message: 'Peelable mask' }),
    finalThicknessKeyword: defineMessage({ message: 'Final thickness' }),
    copperThicknessKeyword: defineMessage({ message: 'Copper thickness' }),
    innerCopperThicknessKeyword: defineMessage({ message: 'Inner copper thickness' }),
    outerCopperThicknessKeyword: defineMessage({ message: 'Outer copper thickness' }),
    eTestKeyword: defineMessage({ message: 'E-test' }),
    ulLayerStackKeyword: defineMessage({ message: 'UL layerstack' }),
    ulMarkingKeyword: defineMessage({ message: 'UL marking' }),
};

export const propertyDescriptionTranslations: Record<CapabilityNames, MessageDescriptor> = {
    layercount: defineMessage({ message: 'The amount of copper layers' }),
    minViaDiameter: defineMessage({ message: 'Diameter of the smallest via' }),
    boardHeight: defineMessage({ message: 'The height of a single PCB in mm' }),
    boardWidth: defineMessage({ message: 'The width of a single PCB in mm' }),
    outerCopperThickness: defineMessage({ message: 'The finished copper thickness on outer layers in μm' }),
    innerCopperThickness: defineMessage({ message: 'The finished copper thickness on inner layers in μm' }),
    minOuterLayerStructure: defineMessage({ message: 'Minimum of clearance and trace width on the outer layers' }),
    minInnerLayerStructure: defineMessage({ message: 'Minimum of clearance and trace width on the inner layers' }),
    soldermaskColor: defineMessage({ message: 'Specifies the solder mask color' }),
    soldermaskSide: defineMessage({ message: 'Specifies on which side the solder mask should be put on' }),
    silkscreenColor: defineMessage({ message: 'Specifies the silkscreen color' }),
    silkscreenSide: defineMessage({ message: 'Specifies on which side the silkscreen should be printed on' }),
    surfaceFinish: defineMessage({
        message: 'Specifies the type of surface finish that will be applied on the PCB surface',
    }),
    enigThickness: defineMessage({ message: 'Plating thickness of the enig finish' }),
    ipc600Class: defineMessage({ message: 'Product quality acceptance according to IPC-A-600' }),

    baseMaterial: defineMessage({ message: 'The base material used to build up the layer stack' }),
    finalThickness: defineMessage({ message: 'Thickness of the produced PCB' }),
    eTest: defineMessage({ message: 'Specifies if E-Test is required' }),
    ulLayerStack: defineMessage({ message: 'Layerstack with UL certification' }),
    ulMarkingType: defineMessage({ message: 'UL marking' }),
    viaFilling: defineMessage({ message: 'Via filling type according to IPC-4761' }),
    pressFit: defineMessage({ message: 'Specifies whether press-fit components will be used' }),
    impedanceTested: defineMessage({ message: 'Specifies whether an impedance control is required' }),
    peelableMask: defineMessage({ message: 'Defines whether a peelable solder mask is required' }),
    captonTape: defineMessage({ message: 'Defines whether capton tape is required' }),
    blindVias: defineMessage({ message: 'Indicates whether the PCB has blind vias or not' }),
    buriedVias: defineMessage({ message: 'Indicates whether the PCB has buried vias or not' }),
    chamfering: defineMessage({ message: 'Describes the type of chamfering' }),
    hardGold: defineMessage({ message: 'Defines whether galvanic hard gold is required' }),
    maxXOutsAllowed: defineMessage({ message: 'The number of accepted X-outs per delivery/customer panel' }),
    halogenFree: defineMessage({ message: 'Use only halogen-free material in this PCB' }),
    reports: defineMessage({ message: 'undefined' }),
    itar: defineMessage({ message: 'undefined' }),
    applicationType: defineMessage({ message: 'The type of application the PCB will be used for' }),
    hardGoldArea: defineMessage({ message: 'Define the area for galvanic hard gold in mm²' }),
    carbonPrint: defineMessage({ message: 'Defines whether carbon print is required' }),
    edgeMetalization: defineMessage({ message: 'Metalization of the PCB edges with copper and the final finish' }),
    layerstackThicknessTolerance: defineMessage({ message: 'Thickness tolerance of the final pcb thickness in %' }),
    tgValue: defineMessage({ message: 'The minimum Tg value of the base material in C°' }),
    rohsCompilant: defineMessage({ message: 'Indicates if the product must be compliant with RoHS' }),
    flammabilityRating: defineMessage({ message: 'The flammability rating of the base material according to UL 94' }),
    layerstackType: defineMessage({ message: 'The technology or type of materials used to build the layerstack' }),
    ctiClass: defineMessage({
        message: 'Describes the electrical breakdown product class according to IEC 60601-1:2005',
    }),
    aspectRatio: defineMessage({ message: 'The smallest aspect-ratio found on the PCB' }),
    soldermaskDam: defineMessage({
        message: 'The smallest dam in the solder mask layer between openings on the solder mask.',
    }),
    soldermaskClearance: defineMessage({
        message: 'The smallest clearance in the solder mask layer between exposed copper (pad) and the solder mask',
    }),
    exposedCopperArea: defineMessage({
        message: 'The total exposed copper area in mm² (exposed_copper_area_top + exposed_copper_area_bottom)',
    }),
    exposedCopperAreaTop: defineMessage({ message: 'Exposed copper area on the top side in mm²' }),
    exposedCopperAreaBottom: defineMessage({ message: 'Exposed copper area on the bottom side in mm²' }),
    totalDrillCount: defineMessage({ message: 'Number of drills on the PCB' }),
    microVias: defineMessage({ message: 'Indicates whether the PCB has micro vias or not' }),
    phCount: defineMessage({ message: 'The amount of plated holes (vias)' }),
    traceWidth: defineMessage({
        message: 'The smallest trace width of all copper elements in mm, found on all layers of the PCB',
    }),
    phToolCount: defineMessage({ message: 'The amount of different tools used for plated holes' }),
    nphCount: defineMessage({ message: 'The amount of non-plated holes (drills)' }),
    phMinSize: defineMessage({ message: 'The smallest plated hole diameter in mm' }),
    nphMaxSize: defineMessage({ message: 'The largest non-plated hole diameter in mm' }),
    phMaxSize: defineMessage({ message: 'The largest plated hole diameter in mm' }),
    nphToolCount: defineMessage({ message: 'The amount of different tools used for non-plated holes' }),
    copperClearance: defineMessage({
        message: 'The smallest distance between copper in mm, found on all layers of the PCB',
    }),
    phAnnularRing: defineMessage({
        message: 'The smallest annular ring between plated holes and copper in mm, found on all layers of the PCB',
    }),
    nphMinSize: defineMessage({ message: 'The smallest non-plated hole diameter in mm' }),
    placementSide: defineMessage({
        message: 'Please indicate if the parts are mounted on one side only or on both sides.',
    }),
    phSurfaceArea: defineMessage({ message: 'The total surface area of all plated holes in mm²' }),
    hardGoldThickness: defineMessage({ message: 'The thickness of the hard gold plating' }),
    pressFitTechnology: defineMessage({ message: 'The type of press-fit technology used' }),
    impedanceTolerance: defineMessage({ message: 'The tolerance of the impedance testing in %' }),
    crossSection: defineMessage({ message: 'The cross-section of the PCB' }),
    xRayMeasurement: defineMessage({ message: 'The type of X-ray measurement used' }),
    xRayMeasurementPoints: defineMessage({ message: 'The number of points used for X-ray measurement' }),
    firstArticleInspection: defineMessage({ message: 'The first article inspection report' }),
    certificateOfConformance: defineMessage({ message: 'The certificate of conformance' }),
    dateCode: defineMessage({ message: 'The date code' }),
    fabricatorLogo: defineMessage({ message: 'The fabricator logo' }),
    datamatrixCode: defineMessage({ message: 'The datamatrix code' }),
    manufacturingInformation: defineMessage({ message: 'The manufacturing information' }),
};

export const priceRadarErrorsTranslations: Record<string, MessageDescriptor> = {
    ...capabilitiesTranslations,
    // Capability errors from the pcb-server have slighly different names...
    layerCount: capabilitiesTranslations.layercount,
    ipcA600Class: capabilitiesTranslations.ipc600Class,
    viaFillingType: capabilitiesTranslations.viaFilling,
    nphMinDiameter: capabilitiesTranslations.nphMinSize,
    quantity: defineMessage({ message: 'Quantity' }),
    requestedQuantity: defineMessage({ message: 'Quantity' }),
    customStackUp: defineMessage({ message: 'Custom stackup' }),

    ph_covered_both_sides: defineMessage({ message: 'PH covered both sides' }),

    ph_covered_one_side: defineMessage({ message: 'PH covered one side' }),

    ph_uncovered: defineMessage({ message: 'PH uncovered' }),

    nph_covered_both_sides: defineMessage({ message: 'NPH covered both sides' }),

    nph_covered_one_side: defineMessage({ message: 'NPH covered one side' }),

    nph_uncovered: defineMessage({ message: 'NPH uncovered' }),
};

export const loadingLifecycleTranslations: Partial<Record<FileLifecycle['name'], MessageDescriptor>> = {
    'customer-panel': defineMessage({ message: 'Customer panel' }),
    dfmanalysis: defineMessage({ message: 'DFM Analysis' }),
    composition: defineMessage({ message: 'Composition' }),
    dfm: defineMessage({ message: 'DFM' }),
    main: defineMessage({ message: 'Loading' }),
    reconciliation: defineMessage({ message: 'Reconciliation' }),
    analysis: defineMessage({ message: 'Analysing' }),
    files: defineMessage({ message: 'Processing files' }),
    fileanalysis: defineMessage({ message: 'Analysing files' }),
    layerstack: defineMessage({ message: 'Layer stack' }),
    render: defineMessage({ message: 'Rendering' }),
    initialization: defineMessage({ message: 'Initialization' }),
    'production-analysis': defineMessage({ message: 'Production analysis' }),
    'reconciled-specification-render': defineMessage({ message: `Rendering` }),
    'specification-render': defineMessage({ message: `Rendering` }),
};

type Side = Exclude<PCBV2BoardProperties['soldermaskSide'], undefined>;

export const sideTranslations: Record<Side, MessageDescriptor> = {
    both: defineMessage({ message: 'both' }),
    bottom: defineMessage({ message: 'bottom' }),
    none: defineMessage({ message: 'none' }),
    top: defineMessage({ message: 'top' }),
};

type Chamfering = Exclude<PCBV2MechanicalProperties['chamfering'], undefined>;

export const chamferingTranslations: Record<Chamfering, MessageDescriptor> = {
    none: defineMessage({ message: 'None' }),
    '30': defineMessage({ message: '30°' }),
    '20-pci': defineMessage({ message: '20° PCI card' }),
    '45-isa': defineMessage({ message: '45° ISA card' }),
};

type SurfaceFinish = Exclude<PCBV2BoardProperties['surfaceFinish'], undefined>;

export const surfaceFinishTranslations: Record<SurfaceFinish, MessageDescriptor> = {
    none: defineMessage({ message: 'None' }),
    'hal-pb': defineMessage({ message: 'HAL (lead)' }),
    'hal-pb-free': defineMessage({ message: 'HAL (lead free)' }),
    asig: defineMessage({ message: 'ASIG' }),
    enag: defineMessage({ message: 'ENAG' }),
    enepig: defineMessage({ message: 'ENEPIG' }),
    enig: defineMessage({ message: 'ENIG' }),
    enipig: defineMessage({ message: 'ENIPIG' }),
    ep: defineMessage({ message: 'EP' }),
    epag: defineMessage({ message: 'EPAG' }),
    epig: defineMessage({ message: 'EPIG' }),
    is: defineMessage({ message: 'IS (Immersion silver)' }),
    isig: defineMessage({ message: 'ISIG' }),
    it: defineMessage({ message: 'IT (Immersion tin)' }),
    osp: defineMessage({ message: 'OSP' }),
};

type baseMaterial = Exclude<PCBV2LayerStackProperties['baseMaterial'], undefined>;

export const baseMaterialTranslations: Record<baseMaterial, MessageDescriptor> = {
    'fr-2': defineMessage({ message: 'FR-2' }),
    'fr-3': defineMessage({ message: 'FR-3' }),
    'fr-4': defineMessage({ message: 'FR-4' }),
    cem1: defineMessage({ message: 'CEM1' }),
    cem2: defineMessage({ message: 'CEM2' }),
    cem3: defineMessage({ message: 'CEM3' }),
    polyimide: defineMessage({ message: 'Polyimide' }),
};

type viaFilling = Exclude<PCBV2MechanicalProperties['viaFilling'], undefined>;

export const viaFillingTranslations: Record<viaFilling, MessageDescriptor> = {
    none: defineMessage({ message: 'none' }),
    ia: defineMessage({ message: 'Tented - single-sided (Ia)' }),
    ib: defineMessage({ message: 'Tented - double-sided (Ib)' }),
    iib: defineMessage({ message: 'Tented and covered - Double-sided (IIB)' }),
    iiia: defineMessage({ message: 'Plugged - single-sided (IIIa)' }),
    iiib: defineMessage({ message: 'Plugged - double-sided (IIIb)' }),
    iva: defineMessage({ message: 'Plugged and covered - single-sided (IVa)' }),
    ivb: defineMessage({ message: 'Plugged and covered - double-sided (IVb)' }),
    v: defineMessage({ message: 'Filled (fully plugged) (V)' }),
    vi: defineMessage({ message: 'Filled and covered (VI)' }),
    vii: defineMessage({ message: 'Filled and capped (VII)' }),
};

type Ipc600Class = Exclude<PCBV2BoardProperties['ipc600Class'], undefined>;

export const ipc600ClassTranslations: Record<Ipc600Class, MessageDescriptor> = {
    ipcnone: defineMessage({ message: 'None' }),
    ipc1: defineMessage({ message: 'Class 1' }),
    ipc2: defineMessage({ message: 'Class 2' }),
    ipc3: defineMessage({ message: 'Class 3' }),
};

type UlMarkingType = Exclude<PCBV2LayerStackProperties['ulMarkingType'], undefined>;

export const ulMarkingTranslations: Record<UlMarkingType, MessageDescriptor> = {
    'no-marking': defineMessage({ message: 'No marking' }),
    'default-marking': defineMessage({ message: 'Default marking' }),
    'custom-marking': defineMessage({ message: 'Custom marking' }),
};

type layerStackType = Exclude<PCBV2LayerStackProperties['layerstackType'], undefined>;

export const layerStackTypeTranslations: Record<layerStackType, MessageDescriptor> = {
    flex: defineMessage({ message: 'Flex' }),
    ims: defineMessage({ message: 'IMS' }),
    rigid: defineMessage({ message: 'Rigid' }),
    'rigid-flex': defineMessage({ message: 'Rigid-Flex' }),
};

export const panelErrorTranslations: Record<PanelErrorKind, MessageDescriptor> = {
    [PanelErrorKind.WidthExceedsDeliveryPanel]: defineMessage({
        message: 'The configured panel width exceeds the maximum dimension that the supplier supports.',
    }),
    [PanelErrorKind.HeightExceedsDeliveryPanel]: defineMessage({
        message: 'The configured panel height exceeds the maximum dimension that the supplier supports.',
    }),
    [PanelErrorKind.WidthBelowDeliveryPanelMinimum]: defineMessage({
        message: 'The configured panel width is below the minimum dimension that the supplier supports.',
    }),
    [PanelErrorKind.HeightBelowDeliveryPanelMinimum]: defineMessage({
        message: 'The configured panel height is below the minimum dimension that the supplier supports.',
    }),
    [PanelErrorKind.UnknownError]: defineMessage({
        message:
            'An unexpected error occurred while generating the panel. Please try again or contact support if the issue persists.',
    }),
};

export const apiNotSetupErrorTranslations: Record<ApiNotSetupErrorKind, MessageDescriptor> = {
    [ApiNotSetupErrorKind.MissingGatemaCustomerId]: defineMessage({ message: 'Missing Gatema customer ID' }),
};

export const pcbStepTranslations: Record<PcbWorkflowStep, MessageDescriptor> = {
    [PcbWorkflowStep.FileManager]: defineMessage({ message: 'File manager' }),
    [PcbWorkflowStep.DrillManager]: defineMessage({ message: 'Drill manager' }),
    [PcbWorkflowStep.Stackup]: defineMessage({ message: 'Stackup' }),
    [PcbWorkflowStep.Specification]: defineMessage({ message: 'PCB specification' }),
};
