import { LanguageEnum } from '@luminovo/commons';
import { createTheme as muiCreateTheme, Theme } from '@mui/material';
import { deDE, enUS, esES, frFR } from '@mui/material/locale';

export const INTER = "'Inter var', 'Inter', system-ui, sans-serif";

export const NAVBAR_HEIGHT = '48px';
export const MAX_WIDTH = '90rem';

// Colors should match the ones in Figma
export const colorSystem = {
    primary: {
        1: '#F5F8FF',
        2: '#E8EDFF',
        3: '#D9E0FF',
        4: '#B8C4FF',
        5: '#6E7EF7',
        6: '#5767EC',
        7: '#6166D1',
        8: '#4B54B0',
    },
    red: {
        1: '#FFF7F7',
        2: '#FDE8EC',
        3: '#FFD7DA',
        4: '#FFC2CA',
        5: '#FA6B7E',
        6: '#E85E6C',
        7: '#CD475D',
        8: '#9C3641',
        9: '#872F37',
    },
    yellow: {
        1: '#FFF8F0',
        2: '#FFF0DB',
        3: '#FFDDB7',
        4: '#FFCEA3',
        5: '#F9B686',
        6: '#F69D55',
        7: '#B76024',
        8: '#A54D12',
    },
    green: {
        1: '#F4FCF2',
        2: '#DDF8DD',
        3: '#C4EBC0',
        4: '#A5E3C4',
        5: '#02BE9E',
        6: '#0FA98F',
        7: '#2A847E',
        8: '#1B695B',
    },
    neutral: {
        white: '#FFFFFF',
        0: '#FCFCFD',
        1: '#F5F6FA',
        2: '#E9ECF4',
        3: '#D8DEEE',
        4: '#D1D5E0',
        5: '#B6BBC9',
        6: '#858CA0',
        7: '#676D7E',
        8: '#4C5366',
        9: '#2A3147',
    },
    blue: {
        1: '#F5FBFF',
        2: '#DBF1FF',
        3: '#CAE9FF',
        4: '#8CCBFF',
        5: '#599FFD',
        6: '#1871DC',
        7: '#3570B7',
        8: '#2D5F9B',
    },
    violet: {
        1: '#FDF8FE',
        2: '#F8E9FB',
        3: '#F0D3F6',
        4: '#E2B7F1',
        5: '#CE7EE9',
        6: '#AB4CCB',
        7: '#9F4CBB',
        8: '#7D3D8F',
    },
    teal: {
        1: '#F5FCFE',
        2: '#DFF5FB',
        3: '#C5EAF7',
        4: '#93D8EC',
        5: '#57C2E6',
        6: '#0BA3CB',
        7: '#047A97',
        8: '#04667E',
    },
};

export type Colors = keyof typeof colorSystem;

export const h1 = {
    fontFamily: INTER,
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '-0.02em',
    fontVariationSettings: '"opsz" 32',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 550,
};

export const h2 = {
    fontFamily: INTER,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
    fontVariationSettings: '"opsz" 32',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 600,
};

export const h3 = {
    fontFamily: INTER,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    fontVariationSettings: '"opsz" 32',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 600,
};

export const h4 = {
    fontFamily: INTER,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    fontVariationSettings: '"opsz" 32',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 600,
};
export const h5 = {
    fontFamily: INTER,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.01em',
    fontVariationSettings: '"opsz" 32',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 600,
};
export const body = {
    fontFamily: INTER,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.01em',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 440,
};

export const bodySemiBold = {
    ...h4,
};

export const bodySmall = {
    fontFamily: INTER,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.01em',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 440,
};

export const bodySmallSemiBold = {
    ...h5,
};

export const caption = {
    fontFamily: INTER,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '-0.01em',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1',
    fontWeight: 500,
};

// TODO not sure if the style is correct
export const code = {
    fontFamily: INTER,
    fontSize: '14px',
    lineHeight: '24px',
    fontFeatureSettings: '"ss04" 1, "cv01" 1, "cv03" 1, "cv04" 1, "cv06" 1, "zero" 1',
    fontWeight: 500,
};

export const focusedStyles = {
    border: `1px solid ${colorSystem.primary[5]}`,
    boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    backgroundColor: colorSystem.neutral.white,
};
export const hoveredStyles = { border: `1px solid ${colorSystem.primary[5]}` };

export const disabledStyles = {
    backgroundColor: colorSystem.neutral[1],
    border: `1px solid ${colorSystem.neutral[3]}`,
};

export const createTheme = (locale: LanguageEnum): Theme =>
    muiCreateTheme(
        {
            palette: {
                mode: 'light',
                primary: {
                    main: colorSystem.primary[6],
                    light: colorSystem.primary[2],
                    dark: colorSystem.primary[7],
                },
                secondary: {
                    main: colorSystem.primary[5],
                    light: colorSystem.primary[4],
                },
                error: {
                    main: colorSystem.red[6],
                    light: colorSystem.red[2],
                    dark: colorSystem.red[8],
                },
                warning: {
                    main: colorSystem.yellow[6],
                    light: colorSystem.yellow[2],
                    dark: colorSystem.yellow[8],
                },
                success: {
                    main: colorSystem.green[6],
                    light: colorSystem.green[2],
                    dark: colorSystem.green[8],
                },
                background: {
                    default: colorSystem.neutral.white,
                },
                action: {
                    disabledBackground: colorSystem.neutral[5],
                    disabled: colorSystem.neutral[1],
                },
                text: {
                    primary: colorSystem.neutral[9],
                    secondary: colorSystem.neutral[8],
                    disabled: colorSystem.neutral[5],
                },
                grey: {
                    '100': colorSystem.neutral[1],
                    '200': colorSystem.neutral[2],
                    '300': colorSystem.neutral[3],
                    '400': colorSystem.neutral[4],
                    '500': colorSystem.neutral[5],
                    '600': colorSystem.neutral[6],
                    '700': colorSystem.neutral[7],
                    '800': colorSystem.neutral[8],
                    '900': colorSystem.neutral[9],
                },
            },
            typography: {
                fontFamily: INTER,
                h1: h1,
                h2: h2,
                h3: h3,
                h4: h4,
                h5: h5,
                subtitle1: bodySmall, // body small in Figma
                subtitle2: bodySmallSemiBold, // body small SemiBold in Figma
                body1: body, // body in Figma
                body2: bodySemiBold, // body SemiBold in Figma
                caption: caption,
                button: {
                    textTransform: 'none',
                },
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        '*, *::before, *::after': {
                            boxSizing: 'border-box',
                        },
                    },
                },
                MuiMenu: {
                    defaultProps: {
                        elevation: 1,
                    },
                },
                MuiFormControlLabel: {
                    styleOverrides: {
                        root: {
                            width: 'fit-content',
                        },
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        variant: 'outlined',
                        size: 'small',
                    },
                    styleOverrides: {
                        root: {
                            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                                {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },

                            '& input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        },
                    },
                },
                MuiBadge: {
                    styleOverrides: {
                        badge: {
                            fontSize: '12px !important',
                            padding: '0 4px !important',
                            margin: '0 !important',
                            minWidth: '18px !important',
                            height: '18px !important',
                        },
                    },
                },
                MuiTooltip: {
                    defaultProps: {
                        arrow: true,
                        placement: 'bottom',
                    },
                    styleOverrides: {
                        tooltip: {
                            color: colorSystem.neutral[1],
                            backgroundColor: colorSystem.neutral[9],
                        },
                        arrow: {
                            color: colorSystem.neutral[9],
                        },
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableRipple: true,
                        size: 'large',
                    },
                    styleOverrides: {
                        root: {
                            '&$disabled': {
                                border: 'none',
                            },
                        },
                        text: {
                            padding: '6px 12px',
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            fontSize: '18px',
                            lineHeight: '20px',
                            padding: '11px',
                            '&$disabled': {
                                color: colorSystem.neutral[5],
                            },
                        },
                        sizeSmall: {
                            height: '32px',
                            width: '32px',
                            fontSize: '16px',
                            padding: '10px',
                        },
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paperWidthSm: {
                            maxWidth: '44rem',
                        },
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            padding: '20px 24px',
                        },
                    },
                },
                MuiCheckbox: {
                    defaultProps: {
                        disableRipple: true,
                    },
                    styleOverrides: {
                        colorPrimary: {
                            color: colorSystem.neutral[3],
                            background: 'none',
                            '&:hover': {
                                color: colorSystem.primary[3],
                                background: 'none',
                            },
                            '&$checked': {
                                color: colorSystem.primary[5],
                                '&:hover': {
                                    color: colorSystem.primary[7],
                                },
                            },
                            '&$disabled': {
                                color: colorSystem.neutral[3],
                            },
                        },
                        colorSecondary: {
                            '&$disabled': {
                                color: colorSystem.neutral[5],
                            },
                        },
                    },
                },
                MuiListItem: {
                    styleOverrides: {
                        root: {
                            boxSizing: 'border-box',
                            background: colorSystem.neutral.white,
                            '&:hover': {
                                background: colorSystem.neutral[1],
                            },
                            '&$selected': {
                                backgroundColor: colorSystem.primary[3],
                            },
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            boxSizing: 'border-box',
                            background: colorSystem.neutral.white,
                            '&:hover': {
                                background: colorSystem.neutral[1],
                            },
                            '&$selected': {
                                backgroundColor: colorSystem.primary[3],
                            },
                        },
                    },
                },
                MuiListItemIcon: {
                    styleOverrides: {
                        root: {
                            minWidth: 32,
                            paddingLeft: '16px',
                        },
                    },
                },
                MuiChip: {
                    defaultProps: {
                        size: 'small',
                    },
                    styleOverrides: {
                        root: {
                            borderRadius: 24,
                            backgroundColor: colorSystem.neutral[2],
                            color: colorSystem.neutral[7],
                        },
                        colorPrimary: {
                            backgroundColor: colorSystem.primary[2],
                            color: colorSystem.primary[7],
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        stickyHeader: {
                            left: 'unset',
                            top: 0,
                        },
                        root: {
                            padding: '16px',
                            fontWeight: 460,
                        },
                    },
                },
                MuiDivider: {
                    styleOverrides: {
                        root: {
                            borderColor: colorSystem.neutral[2],
                            backgroundColor: colorSystem.neutral[2],
                            marginBottom: 4,
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        inputSizeSmall: {
                            paddingTop: '11.75px',
                            paddingBottom: '11.75px',
                        },
                        input: {
                            paddingTop: `11.75px`,
                            paddingBottom: `11.75px`,
                        },
                        notchedOutline: {
                            borderColor: colorSystem.neutral[3],
                        },
                    },
                },
                MuiSelect: {
                    defaultProps: {
                        variant: 'outlined',
                    },
                    styleOverrides: {
                        select: {
                            minHeight: 'unset',
                        },
                    },
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        popupIndicator: {
                            color: colorSystem.neutral[6],
                        },
                        option: {
                            padding: '8px',
                            margin: '4px 2px',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: colorSystem.neutral[2],
                            },
                            '&.Mui-focused:not([aria-selected="true"])': {
                                backgroundColor: colorSystem.neutral[2],
                            },
                            '&[aria-selected="true"]': {
                                backgroundColor: colorSystem.neutral[1],
                                '&:hover': {
                                    backgroundColor: colorSystem.neutral[3],
                                },
                                '&.Mui-focused': {
                                    backgroundColor: colorSystem.neutral[3],
                                },
                            },
                        },
                        inputRoot: {
                            borderRadius: '6px',
                            padding: '6px',
                        },
                        listbox: {
                            borderRadius: '8px',
                            padding: '2px',
                        },
                    },
                },

                MuiRadio: {
                    styleOverrides: {
                        root: {
                            color: colorSystem.neutral[3],
                            '&$checked': {
                                color: colorSystem.primary[5],
                            },
                        },
                    },
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            padding: 0,
                            minHeight: 36,
                        },
                    },
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            minHeight: 'unset',
                        },
                    },
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            ...body,
                            color: colorSystem.blue[6],
                            textDecoration: 'none',
                        },
                    },
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            '&.Mui-expanded': {
                                border: '2px solid rgba(94, 129, 244, 0.4)',
                                boxShadow: '0px 4px 8px rgba(158, 158, 158, 0.15)',
                            },
                        },
                    },
                },
            },
        },
        convertToMuiLocale(locale),
    );

function convertToMuiLocale(locale: LanguageEnum) {
    switch (locale) {
        case 'en':
            return enUS;
        case 'de':
            return deDE;
        case 'es':
            return esES;
        case 'fr':
            return frFR;
    }
}
