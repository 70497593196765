import { omit } from '@luminovo/commons';
import * as React from 'react';
import { Radio, RadioProps } from '../../Radio/Radio';

export type FieldRadioProps<TValue> = Omit<RadioProps, 'onChange'> & {
    fieldValue: TValue;
    onChange: (value: TValue) => void;

    /**
     * Callback that is called after the value has changed.
     *
     * Do not use this to update the value, this is already done by `onChange`.
     */
    onValueChange?: (value: TValue) => void;
};

function FieldRadioInner<TValue>(
    { value, fieldValue, onChange, onValueChange, placeholder, ...rest }: FieldRadioProps<TValue>,
    outerRef: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element {
    return (
        <Radio
            ref={outerRef}
            checked={fieldValue === value}
            value={fieldValue}
            onChange={() => {
                onChange(fieldValue);
                onValueChange?.(fieldValue);
            }}
            {...omit(rest, 'error', 'helperText')}
        />
    );
}

export const FieldRadio = React.forwardRef(FieldRadioInner) as <TValue>(
    props: FieldRadioProps<TValue> & { ref?: React.ForwardedRef<HTMLButtonElement> },
) => JSX.Element;
