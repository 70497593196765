import { t } from '@lingui/macro';
import * as icons from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { colorSystem } from '../../theme';
import { TextField } from '../TextField';

export function CommandBarInput({
    isOpen,
    onOpen,
    onClose,
}: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}): JSX.Element {
    return (
        <TextField
            size="small"
            onClick={onOpen}
            autoFocus={false}
            value={''}
            onChange={() => {
                onOpen();
            }}
            sx={{
                '& fieldset': {
                    boxShadow: `none !important`,
                },
            }}
            autoComplete="off"
            slotProps={{
                input: {
                    sx: {
                        borderRadius: 4,
                        background: `${colorSystem.neutral[1]} !important`,
                        padding: '0px',
                        width: '140px',
                    },

                    startAdornment: (
                        <InputAdornment position="start">
                            <icons.Search style={{ color: colorSystem.neutral[6] }} fontSize="small" />
                        </InputAdornment>
                    ),
                },
            }}
            placeholder={t`Search` + '  (Ctrl+K)'}
        />
    );
}
