import { t } from '@lingui/macro';
import { formatMonetaryValue } from '@luminovo/commons';
import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import {
    CostDTO,
    TcoCostSummaryBreakdownDTO,
    TcoCostSummaryDTO,
    TotalCostOfOwnershipRuleCategory,
} from '@luminovo/http-client';
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TotaCostOfOwnershipRuleCategoryTag } from '../tags';

export const TcoCostSummaryTooltip = ({
    summary,
    show,
}: {
    summary: TcoCostSummaryDTO;
    show: keyof TcoCostSummaryDTO;
}): JSX.Element => {
    return (
        <Tooltip
            title={<TcoTooltipTitle summary={summary} />}
            variant="white"
            disableMaxWidth
            onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            }}
        >
            <span>{formatMonetaryValue(summary[show].cost?.preferred, 'unit-price', { ifAbsent: '' })}</span>
        </Tooltip>
    );
};

type Column = {
    key: keyof TcoCostSummaryDTO;
    label: string;
};

type Row = {
    key: keyof TcoCostSummaryBreakdownDTO;
    category: TotalCostOfOwnershipRuleCategory;
};

const StyledCell = styled(TableCell)(() => ({
    paddingTop: 4,
    paddingBottom: 4,
    height: '100%',
    borderBottom: 'none',
    verticalAlign: 'top',
}));

const CostCell = ({ cost, isTotal }: { cost: CostDTO | null | undefined; isTotal?: boolean }): JSX.Element => {
    return (
        <StyledCell align="right">
            <Text tabularNums variant="body-small" color={isTotal ? colorSystem.primary[7] : colorSystem.neutral[9]}>
                {formatMonetaryValue(cost?.preferred, 'unit-price', { ifAbsent: '-' })}
            </Text>
        </StyledCell>
    );
};

const TcoTooltipTitle = ({ summary }: { summary: TcoCostSummaryDTO }): JSX.Element => {
    const columns: Column[] = [
        { key: 'unit', label: t`Unit` },
        { key: 'scrap', label: t`Scrap` },
        { key: 'excess', label: t`Excess` },
        { key: 'total', label: t`Total` },
    ];

    const rows: Row[] = [
        { key: 'packaging_cost', category: TotalCostOfOwnershipRuleCategory.Packaging },
        { key: 'discount', category: TotalCostOfOwnershipRuleCategory.Discount },
        { key: 'shipping_cost', category: TotalCostOfOwnershipRuleCategory.Shipping },
        { key: 'customs_cost', category: TotalCostOfOwnershipRuleCategory.Customs },
        { key: 'other_cost', category: TotalCostOfOwnershipRuleCategory.Other },
    ];

    return (
        <Box style={{ border: `1px solid ${colorSystem.neutral[2]}`, borderRadius: '8px', margin: '10px' }}>
            <Table size="small">
                <TableHead style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}>
                    <TableRow>
                        <StyledCell />
                        {columns.map((column) => (
                            <StyledCell key={column.key}>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    {column.label}
                                </Text>
                            </StyledCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow>
                            <StyledCell key="category">
                                <TotaCostOfOwnershipRuleCategoryTag category={row.category} />
                            </StyledCell>
                            {columns.map((column) => (
                                <CostCell
                                    key={column.key}
                                    cost={summary[column.key]?.breakdown[row.key]}
                                    isTotal={column.key === 'total'}
                                />
                            ))}
                        </TableRow>
                    ))}
                    <TableRow>
                        <StyledCell>
                            <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>{t`Total`}</Text>
                        </StyledCell>
                        {columns.map((column) => (
                            <CostCell key={column.key} cost={summary[column.key]?.cost} isTotal />
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};
