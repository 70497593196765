import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface AlElektronikCredentialsDTO extends r.Static<typeof AlElektronikCredentialsDTORuntype> {}
export const AlElektronikCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface AlElektronikCredentialsInputDTO extends r.Static<typeof AlElektronikCredentialsInputDTORuntype> {}
export const AlElektronikCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface AlElektronikResponseBodyDTO extends r.Static<typeof AlElektronikResponseBodyDTORuntype> {}
export const AlElektronikResponseBodyDTORuntype = r.Record({
    data: AlElektronikCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
