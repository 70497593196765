import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { Currency } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import {
    CostComponentFixedCost,
    CostComponentFormulaCost,
    CostComponentLinearCost,
    ExpensePostDTO,
    ExpensePostRuntype,
    ExpensesCostComponent,
    http,
    MonetaryValueBackend,
} from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import Yup from 'yup';
import { usePageParams } from '../../../../resources/hooks';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../../resources/http/httpQueryKey';
import { assertAgainstRuntype } from '../../../../utils/customConsole';
import { deprecatedYupResolver } from '../../../../utils/deprecated_yupResolver';
import { route } from '../../../../utils/routes';
import { assertUnreachable, nullIfEmptyOrUndefined, RecursivePartial } from '../../../../utils/typingUtils';
import { AddExpenseFormInputs, CostInput } from './AddExpenseFormTypes';

export const internalErrorAmountIsUndefined = 'amount is undefined';

const createFixedCostForLinearCost = (input: CostInput, currency: Currency): MonetaryValueBackend | null => {
    if (input.amount === null || input.amount === undefined) {
        return null;
    }
    return {
        amount: input.amount,
        currency: currency,
    };
};

const createFixedCost = (formInput: AddExpenseFormInputs): CostComponentFixedCost => {
    if (formInput.fixedCostCalculation.fixedCost.amount === undefined) {
        throw new Error(internalErrorAmountIsUndefined);
    }

    return {
        type: 'Fixed',
        details: {
            fixed_cost: {
                amount: formInput.fixedCostCalculation.fixedCost.amount,
                currency: formInput.expenseCurrency,
            },
        },
    };
};

const createLinearCost = (formInput: AddExpenseFormInputs): CostComponentLinearCost => {
    if (formInput.linearCostCalculation.variableCost.variableUnit.amount === undefined) {
        throw new Error(internalErrorAmountIsUndefined);
    }

    return {
        type: 'Linear',
        details: {
            fixed_cost: createFixedCostForLinearCost(
                formInput.linearCostCalculation.fixedCost,
                formInput.expenseCurrency,
            ),
            variable_cost: {
                driver: formInput.linearCostCalculation.variableCost.driverId,
                variable_unit: {
                    currency: formInput.expenseCurrency,
                    amount: formInput.linearCostCalculation.variableCost.variableUnit.amount,
                },
            },
        },
    };
};

const createFormulaCost = (formInput: AddExpenseFormInputs): CostComponentFormulaCost => {
    return {
        type: 'Formula',
        details: {
            currency: formInput.formulaCostCalculation.currency,
            formula: formInput.formulaCostCalculation.formula,
        },
    };
};

export const createCostComponents = (formInput: AddExpenseFormInputs): ExpensesCostComponent => {
    switch (formInput.selectedCostCalculationType) {
        case 'Fixed':
            return createFixedCost(formInput);
        case 'Linear':
            return createLinearCost(formInput);
        case 'Formula':
            return createFormulaCost(formInput);
        default:
            assertUnreachable(formInput.selectedCostCalculationType);
    }
};

const createParsedData = (formInput: AddExpenseFormInputs): ExpensePostDTO => {
    const postBody: ExpensePostDTO = {
        name: formInput.expense.name,
        category: formInput.expense.category,
        process: formInput.expense.process,
        level: formInput.expense.level,
        description: nullIfEmptyOrUndefined(formInput.expense.description),
        cost_components: createCostComponents(formInput),
        internal_number: nullIfEmptyOrUndefined(formInput.expense.internalExpenseNumber),
        site_id: nullIfEmptyOrUndefined(formInput.expense.siteId),
    };
    assertAgainstRuntype(postBody, ExpensePostRuntype);
    return postBody;
};

export const useExpenseFormFunction = (
    schema: Yup.ObjectSchema,
    defaultValues: RecursivePartial<AddExpenseFormInputs>,
): {
    formResult: UseFormReturn<AddExpenseFormInputs>;
    onSubmitPost: SubmitHandler<AddExpenseFormInputs>;
    onSubmitPatch: SubmitHandler<AddExpenseFormInputs>;
} => {
    const token = getToken();
    const debugErrorHandler = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { expenseId } = usePageParams<'/manufacturing/expense/:expenseId/edit'>();

    const formResult = useForm<AddExpenseFormInputs>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: deprecatedYupResolver(schema),
        reValidateMode: 'onChange',
    });

    const { mutateAsync } = useMutation({
        mutationFn: (info: { values: ExpensePostDTO }) => {
            return http('POST /expenses', { requestBody: info.values }, token);
        },
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /expenses') });
            enqueueSnackbar(t`Expense created`, { variant: 'success' });
            navigate(route('/manufacturing/expense'));
        },
    });

    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { values: ExpensePostDTO }) => {
            return http('PATCH /expenses/:expenseId', { requestBody: info.values, pathParams: { expenseId } }, token);
        },
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /expenses') });
            enqueueSnackbar(t`Expense updated`, { variant: 'info' });
            navigate(route('/manufacturing/expense'));
        },
    });

    const onSubmitPost: SubmitHandler<AddExpenseFormInputs> = async (formInput: AddExpenseFormInputs) => {
        const parsedData: ExpensePostDTO = createParsedData(formInput);
        return mutateAsync({
            values: parsedData,
        });
    };

    const onSubmitPatch: SubmitHandler<AddExpenseFormInputs> = async (formInput: AddExpenseFormInputs) => {
        const parsedData: ExpensePostDTO = createParsedData(formInput);
        return mutateAsyncPatch({
            values: parsedData,
        });
    };

    return { formResult, onSubmitPost, onSubmitPatch };
};
