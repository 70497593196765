import { compareByStringKey, isPresent } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import {
    MonetaryValueBackend,
    OtsFullPart,
    RfqContext,
    SolutionTag,
    StandardPartMarketOfferDTO,
    StandardPartOfferWithSolutionsDTO,
    SupplierAndStockLocationDTO,
    SupplierPreference,
} from '@luminovo/http-client';
import {
    isOffTheShelfPartOffer,
    isStandardPartMarketOffer,
    leadTimeDaysExtractor,
    parseSolution,
    Solution,
} from '@luminovo/sourcing-core';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { convertRfqContext, useOtsPartBulk } from '../../../../../resources/part/partHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';

export const QUANTITY_FOR_AVAILABILITY_INFO = 1;
export const QUANTITY_FOR_UNIT_PRICE = 10_000_000;

export type MarketOfferPartType = { type: 'OffTheShelf'; id: string } | { type: 'Ipn'; ipn: string };

export function extractStock(item: MarketOfferTableData): number {
    return item.offer.available_prices.stock ?? -Infinity;
}

export function extractUnitPrice(item: MarketOfferTableData): number {
    return Number(item.unitPrice?.amount);
}
export interface MarketOfferTableData {
    part: OtsFullPart;
    offer: StandardPartMarketOfferDTO;
    supplier: SupplierAndStockLocationDTO;
    isPreferred: boolean;
    isApproved: boolean;
    requiredQuantity: number | null;
    requiredQuantitySolution: Solution | undefined;
    firstPurchaseOption: Solution['firstPurchaseOption'];
    availability: Solution['availability'];
    solutionTags: Solution['solutionTags'];
    unitPrice: Solution['unitPrice'];
    unitPriceOriginal: MonetaryValueBackend | null;
    totalPrice: Solution['totalPrice'];
    factoryLeadTime: number | null;
}

function createMarketOfferTableData({
    parts,
    offersWithSolutions,
    requiredQuantity,
    preferredSupplier,
    approvedSupplier,
}: {
    parts: OtsFullPart[];
    offersWithSolutions: StandardPartOfferWithSolutionsDTO[];
    requiredQuantity: number | null;
    preferredSupplier: SupplierAndStockLocationDTO[];
    approvedSupplier: SupplierAndStockLocationDTO[];
}): MarketOfferTableData[] {
    return offersWithSolutions.flatMap(({ offer, solutions }): MarketOfferTableData[] => {
        // removes inventory offers
        if (!isStandardPartMarketOffer(offer)) {
            return [];
        }

        const part = parts.find((part) => part.id === offer.linked_part.id);

        if (!isPresent(part)) {
            return [];
        }

        const supplier = offer.linked_location;

        const isPreferred = preferredSupplier.some(({ id }) => id === supplier.id);
        const isApproved = approvedSupplier.some(({ id }) => id === supplier.id);

        const findAndParseFastestSolution = (quantity: number) => {
            const solution = solutions.find((item) => item.quantity === quantity)?.fastest;
            return solution ? parseSolution(solution) : undefined;
        };

        const lowestQuantitySolution = findAndParseFastestSolution(QUANTITY_FOR_AVAILABILITY_INFO);
        const requiredQuantitySolution = findAndParseFastestSolution(requiredQuantity ?? NaN);
        const highestQuantitySolution = findAndParseFastestSolution(QUANTITY_FOR_UNIT_PRICE);
        const requiredOrLowestSolution = requiredQuantitySolution ?? lowestQuantitySolution;
        const requiredOrHighestSolution = requiredQuantitySolution ?? highestQuantitySolution;

        if (!requiredOrLowestSolution || !requiredOrHighestSolution) {
            return [];
        }

        const firstPurchaseOption = requiredOrHighestSolution.firstPurchaseOption;
        const solutionTags = requiredOrLowestSolution.solutionTags;
        const availability = requiredOrLowestSolution.availability;
        const unitPrice = requiredOrHighestSolution.unitPrice;
        const unitPriceOriginal = requiredOrHighestSolution.firstPurchaseOption.unit_price_original;
        const totalPrice = requiredQuantitySolution?.totalPrice || null;

        if (solutionTags.some(({ tag }) => tag === SolutionTag.Expired)) {
            return [];
        }

        if (solutionTags.some(({ tag }) => tag === SolutionTag.Outdated)) {
            return [];
        }

        return [
            {
                part,
                offer,
                supplier,
                isPreferred,
                isApproved,
                requiredQuantity,
                requiredQuantitySolution,
                firstPurchaseOption,
                solutionTags,
                availability,
                unitPrice,
                unitPriceOriginal,
                totalPrice,
                factoryLeadTime: offer.available_prices.factory_lead_time_days,
            },
        ];
    });
}

/**
 * In case the `requiredQuantity` is not defined. We use the `unitPrice` from the highest price break, and the `availability`
 * information from an solution with a purchase quantity of one. We are aware that this combination of unit price and availability is not correct.
 * However, a default value for the required quantity is not practical from a UX point of view.
 */
export function useMarketOfferTableItems({
    marketOfferPart,
    requiredQuantity,
    rfqContext,
}: {
    marketOfferPart: MarketOfferPartType;
    requiredQuantity: number | null;
    rfqContext: RfqContext;
}) {
    const { data: offersWithSolutions } = useHttpQuery(
        'POST /offers/off-the-shelf/with-solutions',
        {
            requestBody: {
                part: marketOfferPart.type === 'OffTheShelf' ? marketOfferPart.id : marketOfferPart.ipn,
                quantities: isPresent(requiredQuantity)
                    ? [requiredQuantity]
                    : [QUANTITY_FOR_AVAILABILITY_INFO, QUANTITY_FOR_UNIT_PRICE],
                ...convertRfqContext(rfqContext),
            },
        },
        { select: (res) => res.data },
    );

    const { data: parts } = useOtsPartBulk(
        offersWithSolutions
            ?.filter(({ offer }) => isStandardPartMarketOffer(offer) && isOffTheShelfPartOffer(offer))
            .map(({ offer }) => offer.linked_part.id),
        rfqContext,
    );
    const { data: suppliersAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    const isLoading = !parts || !offersWithSolutions;

    const data = React.useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        const preferredSupplier = suppliersAndStockLocations.filter(
            (s) => s.preference === SupplierPreference.Preferred,
        );
        const approvedSupplier = suppliersAndStockLocations.filter((s) => s.preference === SupplierPreference.Approved);

        const items = createMarketOfferTableData({
            parts,
            offersWithSolutions,
            requiredQuantity,
            preferredSupplier,
            approvedSupplier,
        });
        return items.sort(
            chainComparators(
                compareByNumber((x: MarketOfferTableData) => (x.isPreferred || x.isApproved ? 0 : 1)),
                compareByNumber((x: MarketOfferTableData) => leadTimeDaysExtractor(x.availability)),
                compareByNumber((x: MarketOfferTableData) => extractUnitPrice(x)),
                compareByNumber((x: MarketOfferTableData) => -extractStock(x)),
                compareByStringKey((x: MarketOfferTableData) => x.offer.id),
            ),
        );
    }, [isLoading, parts, offersWithSolutions, requiredQuantity, suppliersAndStockLocations]);

    return { data, isLoading };
}
