import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';

export const quotationEndpoints = {
    'GET /rfqs/:rfqId/quotation': endpoint({
        description: 'Returns the quotation file of a given rfq',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r
                .Record({
                    url: r.String,
                })
                .nullable(),
        }),
    }),
    'GET /rfqs/:rfqId/quotation/upload': endpoint({
        description: 'Returns an Azure url to upload the quotation file given an rfq',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
        invalidates: ['GET /rfqs/:rfqId/quotation'],
    }),
    'DELETE /rfqs/:rfqId/quotation': endpoint({
        description: 'Deletes the quotation file given its file name and rfqId',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Record({ blob_name: r.String }),
        requestBody: r.Undefined,
        responseBody: r.String,
        removes: ['GET /rfqs/:rfqId/quotation'],
    }),
};
