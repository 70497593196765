import SourcingNegotiationsPage from '@/modules/Sourcing/components/QuoteRequests/SourcingNegotiationsPage/SourcingNegotiationsPage';
import { SourcingQuoteRequestsImporterPage } from '@/modules/Sourcing/components/QuoteRequests/SourcingQuoteRequestImporterPage';
import { SupplierContactsPage } from '@/modules/Suppliers/SupplierContacts/SupplierContactsPage';
import { BulkApprovedVendorListImporter } from '@/modules/Suppliers/SupplierManagement/BulkApprovedVendorListImporterPage';
import { BulkSupplierContactsImporter } from '@/modules/Suppliers/SupplierManagement/BulkSupplierContactsImporterPage';
import { SupplierContactsImporterPage } from '@/modules/Suppliers/SupplierManagement/SupplierContactsImporterPage';
import { SupplierImporterPage } from '@/modules/Suppliers/SupplierManagement/SupplierImporterPage';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import {
    CALCULATION_PERMISSIONS,
    isAssemblyPortalEnabled,
    isCustomerRfQDisabled,
    MANUFACTURING_ADMIN_PERMISSIONS,
    MANUFACTURING_LITE_PERMISSIONS,
    QUOTATIONS_V2_ADMIN_PERMISSIONS,
    useIsPnpImporterActive,
} from '../../featureFlags';
import { LazyCustomerDetailsPage, LazyCustomerManagementPage } from '../../modules/Customers';
import { CustomersImporterPage } from '../../modules/Customers/CustomersImporterPage';
import CalculationTeaserPage from '../../modules/Manufacturing/shared/TeaserPages/CalculationTeaserPage';
import {
    LazyNegotiationCreatePage,
    LazyNegotiationDemandsPage,
    LazyNegotiationLineItemDetailsPage,
    LazyNegotiationListPage,
    LazyNegotiationQuoteImporterPage,
    LazyNegotiationQuoteRequestsPage,
    LazyNegotiationScenariosPage,
    LazyNegotiationSummaryPage,
    LazyQuoteRequestAssignPage,
    LazyQuoteRequestEmailPreviewPage,
    LazySupplierPortalPage,
    LazySupplierPortalQuoteRequestPage,
} from '../../modules/Negotiations';
import { QuoteRequestDetailsPage } from '../../modules/Negotiations/pages/QuoteRequestDetailsPage';
import { LazyPartLibraryRouteController } from '../../modules/PartLibrary';
import { ComponentsImporterPage } from '../../modules/PartLibrary/ComponentsImporterPage';
import { DemandImporterPage } from '../../modules/PartLibrary/Demands/DemandsImporter';
import { InventoryOfferImporter } from '../../modules/PartLibrary/InventoryOfferImporter';
import { OfferImporter } from '../../modules/PartLibrary/OfferImporter';
import { SourceListImporterPage } from '../../modules/PartLibrary/SourceListImporter';
import { PcbPanelizationPage } from '../../modules/Pcb';
import { LazyCreatePurchaseOrdersPage, LazyUpdatePurchaseOrdersPage } from '../../modules/PurchaseOrders';
import { AssemblyCalculationPage, CalculationPage } from '../../modules/RfqCalculation';
import { CalculationPrintPage } from '../../modules/RfqCalculation/QuotationExport/CalculationPrintablePage';
import { LazyOrganizationSettingsPage } from '../../modules/Settings/OrganizationSettings';
import { UsersImporterPage } from '../../modules/Settings/OrganizationSettings/pages/UsersImporterPage';
import { LazySnapshotComparisonPage } from '../../modules/Snapshot';
import { SnapshotComparisonPageContext } from '../../modules/Snapshot/contexts/SnapshotComparisonPageContext';
import QuoteRequestAssignPage from '../../modules/Sourcing/components/QuoteRequests/QuoteRequestAssignPage';
import { SourcingNegotiationQuoteRequestDetailsPage } from '../../modules/Sourcing/components/QuoteRequests/QuoteRequestDetailsPage';
import QuoteRequestEmailPreviewPage from '../../modules/Sourcing/components/QuoteRequests/QuoteRequestEmailPreviewPage';
import SourcingNegotiationLineItemsPage from '../../modules/Sourcing/components/QuoteRequests/SourcingNegotiationLineItemsPage';
import SourcingNegotiationQuoteRequestsPage from '../../modules/Sourcing/components/QuoteRequests/SourcingNegotiationQuoteRequestsPage';
import { SolutionConfigurationPage2 } from '../../modules/Sourcing/components/SolutionConfigurations/SolutionConfigurationPage';
import { QuoteDetailsPage } from '../../modules/SupplierPortal/SupplierQuotePage/QuoteDetailsPage';
import { Deprecated_SupplierPortalPage } from '../../modules/SupplierPortal/SupplierQuotePage/SupplierPortalPage';
import { SupplierQuotesPage } from '../../modules/SupplierPortal/SupplierQuotePage/SupplierQuotesPage';
import DigikeyRedirectPage from '../../pages/3rdparty/digikey/callback';
import AssembliesOverviewPage from '../../pages/assemblies';
import AssemblyDashboardPage from '../../pages/assemblies/[assemblyId]/dashboard';
import AssemblyDetailsPage from '../../pages/assemblies/[assemblyId]/details';
import AssemblyOverviewEditPage from '../../pages/assemblies/[assemblyId]/edit';
import AssemblyOverviewPcbRedirectPage from '../../pages/assemblies/[assemblyId]/pcb';
import AssemblyOverviewPcbCadViewerPage from '../../pages/assemblies/[assemblyId]/pcb/cad-viewer';
import AssemblyOverviewPcbDrillPage from '../../pages/assemblies/[assemblyId]/pcb/drills';
import AssemblyOverviewPcbFilePage from '../../pages/assemblies/[assemblyId]/pcb/files';
import AssemblyOverviewPanelizationPage from '../../pages/assemblies/[assemblyId]/pcb/panelization';
import AssemblyOverviewPcbSpecificationPage from '../../pages/assemblies/[assemblyId]/pcb/specification';
import AssemblyOverviewStackupPage from '../../pages/assemblies/[assemblyId]/pcb/stackup';
import AssemblyOverviewAddAssemblyPage from '../../pages/assemblies/add-assembly';
import CalculationTemplatesViewPage from '../../pages/calculation/templates';
import CalculationEditTemplatePage from '../../pages/calculation/templates/[templateId]/edit';
import CalculationAddTemplatePage from '../../pages/calculation/templates/add-template';
import ManufacturerManagementPage from '../../pages/manufacturer';
import ManufacturerDetailsPage from '../../pages/manufacturer/[manufacturerId]';
import ActivitiesViewPage from '../../pages/manufacturing/activity';
import DuplicateActivityForm from '../../pages/manufacturing/activity/[activityId]/duplicate';
import EditActivityForm from '../../pages/manufacturing/activity/[activityId]/edit';
import AddActivityForm from '../../pages/manufacturing/add-activity';
import DriverFormPage from '../../pages/manufacturing/add-driver';
import AddExpensePage from '../../pages/manufacturing/add-expense';
import AddResourceForm from '../../pages/manufacturing/add-resource';
import DriversViewPage from '../../pages/manufacturing/driver';
import DuplicateDriverForm from '../../pages/manufacturing/driver/[driverId]/duplicate';
import EditDriverForm from '../../pages/manufacturing/driver/[driverId]/edit';
import ExpensesViewPage from '../../pages/manufacturing/expense';
import DuplicateExpensePage from '../../pages/manufacturing/expense/[expenseId]/duplicate';
import EditExpensePage from '../../pages/manufacturing/expense/[expenseId]/edit';
import ResourcesViewPage from '../../pages/manufacturing/resource';
import DuplicateResourceForm from '../../pages/manufacturing/resource/[resourceId]/duplicate';
import EditResourceForm from '../../pages/manufacturing/resource/[resourceId]/edit';
import ManufacturingScenarioTemplateManagementPage from '../../pages/manufacturing/template/[templateId]';
import NotFoundPage from '../../pages/notFound';
import RfqCreationPage from '../../pages/rfq_creation';
import RfqOverviewPage from '../../pages/rfqs';
import TopLevelAssembliesPage from '../../pages/rfqs/[rfqId]/bom';
import AssemblyPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]';
import BomEditPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/details';
import EditAssemblyPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/edit';
import PcbRedirectPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pcb';
import PcbCadViewerPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pcb/cad-viewer';
import PcbDrillManagementPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pcb/drills';
import PcbLayerStackPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pcb/files';
import PcbSpecificationPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pcb/specification';
import PcbStackUpPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pcb/stackup';
import PnpImporterPage from '../../pages/rfqs/[rfqId]/bom/assembly/[assemblyId]/pnp-importer';
import AddAssemblyPage from '../../pages/rfqs/[rfqId]/bom/assembly/add-assembly';
import DashboardPage from '../../pages/rfqs/[rfqId]/dashboard';
import RfqEditPage from '../../pages/rfqs/[rfqId]/edit';
import AssemblyOverviewPage from '../../pages/rfqs/[rfqId]/manufacturing';
import ManufacturingAssemblyDetailsPage from '../../pages/rfqs/[rfqId]/manufacturing/[manufacturingAssemblyDetailsId]';
import ManufacturingAssemblyScenarioDetailsPage from '../../pages/rfqs/[rfqId]/manufacturing/[manufacturingAssemblyDetailsId]/[manufacturingScenarioId]/activity';
import ManufacturingTeaserPage from '../../pages/rfqs/[rfqId]/manufacturing/teaser';
import OrderPage from '../../pages/rfqs/[rfqId]/order';
import OrderConfirmationPage from '../../pages/rfqs/[rfqId]/order/confirmation';
import QuotationsPage from '../../pages/rfqs/[rfqId]/quotation';
import SourcingPage from '../../pages/rfqs/[rfqId]/sourcing';
import SolutionManagerPage from '../../pages/rfqs/[rfqId]/sourcing/[solutionConfigurationId]';
import AssemblySourcingPage from '../../pages/rfqs/[rfqId]/sourcing/assembly/[assemblyId]';
import EditCustomComponentOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/custom-component/[offerId]/edit';
import AddCustomComponentOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/custom-component/add/[partId]';
import DuplicateCustomPartOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/custom-part/[offerId]/duplicate';
import EditCustomPartOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/custom-part/[offerId]/edit';
import AddCustomPartOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/custom-part/add/[partId]';
import DuplicateInternalPartNumberOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/internal-part-number/[offerId]/duplicate';
import EditInternalPartNumberOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/internal-part-number/[offerId]/edit';
import AddInternalPartNumberOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/internal-part-number/add/[partId]';
import DuplicateOffTheShelfOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/off-the-shelf/[offerId]/duplicate';
import EditOffTheShelfOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/off-the-shelf/[offerId]/edit';
import AddOffTheShelfOfferPage from '../../pages/rfqs/[rfqId]/sourcing/offer/off-the-shelf/add/[partId]';
import ExcelQuoteImporterPage from '../../pages/rfqs/[rfqId]/sourcing/quote-importer';
import QuoteTrackingPage from '../../pages/rfqs/[rfqId]/sourcing/quote-tracking';
import SolutionConfigurationPage from '../../pages/rfqs/[rfqId]/sourcing/scenarios/[sourcingScenarioId]';
import EditSourcingScenarioPage from '../../pages/rfqs/[rfqId]/sourcing/scenarios/[sourcingScenarioId]/edit';
import AddSourcingScenarioPage from '../../pages/rfqs/[rfqId]/sourcing/scenarios/new';
import CompanySettingsPage from '../../pages/settings/company';
import SupplierManagementPage from '../../pages/supplier';
import SupplierDetailsPage from '../../pages/supplier/[supplierAndStockLocationId]';
import { ProtectedRoute } from '../../permissions/ProtectedRoute';
import history from '../../utils/applicationHistory';
import { route } from '../../utils/routes';
import { PageLayout } from '../PageLayout';
import { useUserType } from '../contexts/CurrentUserDetailsContext';

const PagesRouter: React.FunctionComponent = (): JSX.Element => {
    const { isPnpImporterActive } = useIsPnpImporterActive();
    const userType = useUserType();

    return (
        <Router history={history}>
            <Switch>
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/"
                    render={() => {
                        switch (userType) {
                            case 'Internal':
                                return <Redirect to={route('/rfqs')} />;
                            case 'Customer':
                                return isCustomerRfQDisabled() ? (
                                    <Redirect to={route('/assemblies')} />
                                ) : (
                                    <Redirect to={route('/rfqs')} />
                                );
                            case 'Supplier':
                                return <Redirect to={route('/quote-portal')} />;
                        }
                    }}
                />
                <ProtectedRoute
                    requiredPermissions={['view:rfq']}
                    exact
                    path={'/rfqs'}
                    render={(): JSX.Element => <RfqOverviewPage />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/assemblies'}
                    render={(): JSX.Element => {
                        if (isAssemblyPortalEnabled() || userType === 'Internal') return <AssembliesOverviewPage />;
                        else return <Redirect to={route('/')} />;
                    }}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/add-assembly"
                    render={(urlParams) => <AssemblyOverviewAddAssemblyPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations"
                    render={(): JSX.Element => <LazyNegotiationListPage />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/quote-importer"
                    render={(urlParams): JSX.Element => <LazyNegotiationQuoteImporterPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/quote-requests/preview-email"
                    render={(urlParams) => <LazyQuoteRequestEmailPreviewPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/quote-requests/assign"
                    render={(urlParams) => <LazyQuoteRequestAssignPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/quote-requests/:quoteRequestId"
                    render={(urlParams) => <QuoteRequestDetailsPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/line-items/:lineItemId"
                    render={(urlParams) => <LazyNegotiationLineItemDetailsPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/create"
                    render={(): JSX.Element => <LazyNegotiationCreatePage />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    path="/negotiations/:negotiationId/summary"
                    render={(props): JSX.Element => <LazyNegotiationSummaryPage {...props} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/negotiations/:negotiationId/quote-requests"
                    render={(props): JSX.Element => <LazyNegotiationQuoteRequestsPage {...props} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/demands"
                    render={(props): JSX.Element => <LazyNegotiationDemandsPage {...props} />}
                />

                <ProtectedRoute
                    requiredPermissions={() => userType === 'Internal'}
                    exact
                    path="/negotiations/:negotiationId/scenarios"
                    render={(props): JSX.Element => <LazyNegotiationScenariosPage {...props} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/edit"
                    render={(urlParams) => <AssemblyOverviewEditPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb"
                    render={(urlParams) => <AssemblyOverviewPcbRedirectPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb/cad-viewer"
                    render={(urlParams) => <AssemblyOverviewPcbCadViewerPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb/specification"
                    render={(urlParams) => <AssemblyOverviewPcbSpecificationPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb/files"
                    render={(urlParams) => <AssemblyOverviewPcbFilePage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb/drills"
                    render={(urlParams) => <AssemblyOverviewPcbDrillPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb/stackup"
                    render={(urlParams) => <AssemblyOverviewStackupPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/pcb/panelization"
                    render={(urlParams) => <AssemblyOverviewPanelizationPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path="/assemblies/:assemblyId/details"
                    render={(urlParams): JSX.Element => <AssemblyDetailsPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    path={
                        '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId'
                    }
                    render={(urlParams): JSX.Element => (
                        <SnapshotComparisonPageContext>
                            <LazySnapshotComparisonPage {...urlParams} />
                        </SnapshotComparisonPageContext>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/details'}
                    render={(urlParams): JSX.Element => <BomEditPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb'}
                    render={(urlParams): JSX.Element => <PcbRedirectPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer'}
                    render={(urlParams): JSX.Element => <PcbCadViewerPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification'}
                    render={(urlParams): JSX.Element => <PcbSpecificationPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files'}
                    render={(urlParams): JSX.Element => <PcbLayerStackPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills'}
                    render={(urlParams): JSX.Element => <PcbDrillManagementPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup'}
                    render={(urlParams): JSX.Element => <PcbStackUpPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization'}
                    render={(urlParams): JSX.Element => <PcbPanelizationPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/edit'}
                    render={(urlParams): JSX.Element => <EditAssemblyPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/add-assembly'}
                    render={(urlParams): JSX.Element => <AddAssemblyPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/quote-tracking`}
                    render={(urlParams): JSX.Element => <QuoteTrackingPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/quote-importer`}
                    render={(urlParams): JSX.Element => <ExcelQuoteImporterPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/offers/mpn-offer-importer`}
                    render={(): JSX.Element => <OfferImporter />}
                />
                <ProtectedRoute
                    requiredPermissions={['create:inventory']}
                    path={`/offers/ipn-offer-importer`}
                    exact
                    render={(): JSX.Element => <InventoryOfferImporter />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId`}
                    render={(urlParams): JSX.Element => <AddCustomPartOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/edit'}
                    render={(urlParams): JSX.Element => <EditCustomPartOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/duplicate'}
                    render={(urlParams): JSX.Element => <DuplicateCustomPartOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/custom-component/add/:partId'}
                    render={(urlParams): JSX.Element => <AddCustomComponentOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/custom-component/:offerId/edit'}
                    render={(urlParams): JSX.Element => <EditCustomComponentOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/off-the-shelf/add/:partId'}
                    render={(urlParams): JSX.Element => <AddOffTheShelfOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/edit'}
                    render={(urlParams): JSX.Element => <EditOffTheShelfOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/duplicate'}
                    render={(urlParams): JSX.Element => <DuplicateOffTheShelfOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/internal-part-number/add/:partId'}
                    render={(urlParams): JSX.Element => <AddInternalPartNumberOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/edit'}
                    render={(urlParams): JSX.Element => <EditInternalPartNumberOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/duplicate'}
                    render={(urlParams): JSX.Element => <DuplicateInternalPartNumberOfferPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:part_library']}
                    path={`/parts/components/component-importer`}
                    exact
                    render={(): JSX.Element => <ComponentsImporterPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:part_library']}
                    path={`/parts/demand/demand-importer`}
                    exact
                    render={(): JSX.Element => <DemandImporterPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:part_library']}
                    path={`/parts/source-list-importer`}
                    exact
                    render={(): JSX.Element => <SourceListImporterPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:part_library']}
                    path={`/parts`}
                    render={(): JSX.Element => <LazyPartLibraryRouteController />}
                />
                <ProtectedRoute
                    requiredPermissions={() => {
                        return isPnpImporterActive;
                    }}
                    exact
                    path={'/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer'}
                    render={(props): JSX.Element => <PnpImporterPage {...props} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    path={`/rfqs/:rfqId/bom/assembly/:assemblyId`}
                    render={(urlParams): JSX.Element => <AssemblyPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    path="/assemblies/:assemblyId/dashboard"
                    render={(urlParams): JSX.Element => <AssemblyDashboardPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={`/rfqs/:rfqId/bom`}
                    render={(props): JSX.Element => <TopLevelAssembliesPage {...props} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/dashboard'}
                    render={(pageParams): JSX.Element => <DashboardPage {...pageParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['create:order']}
                    exact
                    path={'/rfqs/:rfqId/order'}
                    render={(pageParams): JSX.Element => <OrderPage {...pageParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['create:order']}
                    exact
                    path={'/rfqs/:rfqId/order/confirmation'}
                    render={(pageParams): JSX.Element => <OrderConfirmationPage {...pageParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/rfqs/:rfqId/edit'}
                    render={(pageParams): JSX.Element => <RfqEditPage {...pageParams.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/scenarios/new`}
                    render={(urlParams): JSX.Element => <AddSourcingScenarioPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`}
                    render={(urlParams): JSX.Element => <EditSourcingScenarioPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    path={`/purchase-orders`}
                    render={(urlParams): JSX.Element => <LazyUpdatePurchaseOrdersPage {...urlParams.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders`}
                    render={(urlParams): JSX.Element => <LazyCreatePurchaseOrdersPage {...urlParams.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/quote-portal'}
                    // eslint-disable-next-line react/jsx-pascal-case
                    render={(urlParams): JSX.Element => <Deprecated_SupplierPortalPage {...urlParams.pathParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/supplier-portal'}
                    render={(urlParams): JSX.Element => <LazySupplierPortalPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/supplier-portal/quote-requests/:quoteRequestId'}
                    render={(urlParams): JSX.Element => <LazySupplierPortalQuoteRequestPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/quote-portal/quote-tracking/:quoteTrackingId'}
                    render={(urlParams): JSX.Element => <QuoteDetailsPage {...urlParams.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/quote-portal/rfq/:rfqId'}
                    render={(urlParams): JSX.Element => <SupplierQuotesPage {...urlParams.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders/:purchaseOrderId`}
                    render={(urlParams): JSX.Element => <LazyCreatePurchaseOrdersPage {...urlParams.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing`}
                    render={(urlParams): JSX.Element => <SourcingPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/negotiations'}
                    render={(urlParams): JSX.Element => <SourcingNegotiationsPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items'}
                    render={(urlParams): JSX.Element => <SourcingNegotiationLineItemsPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests`}
                    render={(urlParams): JSX.Element => <SourcingNegotiationQuoteRequestsPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/assign`}
                    render={(urlParams): JSX.Element => <QuoteRequestAssignPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email`}
                    render={(urlParams): JSX.Element => <QuoteRequestEmailPreviewPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId`}
                    render={(urlParams): JSX.Element => <SourcingNegotiationQuoteRequestDetailsPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/negotiations/quote-requests/importer`}
                    render={(urlParams): JSX.Element => <SourcingQuoteRequestsImporterPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`}
                    render={(params): JSX.Element => <SolutionConfigurationPage {...params} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing-new/scenarios/:sourcingScenarioId`}
                    render={(params): JSX.Element => <SolutionConfigurationPage2 {...params} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/:solutionConfigurationId`}
                    render={(params): JSX.Element => <SolutionManagerPage {...params} />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:quotation']}
                    exact
                    path={`/rfqs/:rfqId/quotation`}
                    render={(): JSX.Element => <QuotationsPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:sourcing']}
                    exact
                    path={`/rfqs/:rfqId/sourcing/assembly/:assemblyId`}
                    render={(urlParams): JSX.Element => <AssemblySourcingPage {...urlParams} />}
                />

                <ProtectedRoute
                    exact
                    requiredPermissions={[]}
                    path={'/rfqs/:rfqId/calculation/teaser'}
                    key={route('/rfqs/:rfqId/calculation/teaser')}
                    render={(): JSX.Element => <CalculationTeaserPage />}
                />
                <ProtectedRoute
                    exact
                    requiredPermissions={[]}
                    path={'/rfqs/:rfqId/calculation/print'}
                    key={route('/rfqs/:rfqId/calculation/print')}
                    render={(urlParams): JSX.Element => <CalculationPrintPage {...urlParams} />}
                />
                <ProtectedRoute
                    path={'/rfqs/:rfqId/calculation/:assemblyId/calculation'}
                    requiredPermissions={CALCULATION_PERMISSIONS}
                    render={(urlParams): JSX.Element => <AssemblyCalculationPage {...urlParams} />}
                />
                <ProtectedRoute
                    path={'/rfqs/:rfqId/calculation'}
                    requiredPermissions={CALCULATION_PERMISSIONS}
                    render={(urlParams): JSX.Element => <CalculationPage {...urlParams} />}
                />

                <ProtectedRoute
                    requiredPermissions={(permissions) =>
                        permissions.includes('create:rfq:customer') || permissions.includes('create:rfq:ems')
                    }
                    exact
                    path={'/rfq_creation'}
                    render={(): JSX.Element => <RfqCreationPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['edit:customer']}
                    path={'/customers/importer'}
                    render={(): JSX.Element => <CustomersImporterPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['edit:customer']}
                    path={'/customers/:customerId'}
                    render={(urlParams): JSX.Element => <LazyCustomerDetailsPage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={['edit:customer']}
                    path={'/customers'}
                    render={(props): JSX.Element => <LazyCustomerManagementPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['edit:user:organization']}
                    exact
                    path={'/settings/organization/users/importer'}
                    render={() => <UsersImporterPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:organization_settings']}
                    path={'/settings/organization'}
                    render={(): JSX.Element => <LazyOrganizationSettingsPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:organization_settings']}
                    path={'/settings/user/account-security'}
                    render={(): JSX.Element => <LazyOrganizationSettingsPage />}
                />
                <ProtectedRoute
                    requiredPermissions={['view:organization_settings']}
                    path={'/settings/company'}
                    render={(): JSX.Element => <CompanySettingsPage />}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/3rdparty/digikey/callback'}
                    render={(): JSX.Element => <DigikeyRedirectPage />}
                />
                <ProtectedRoute
                    exact
                    requiredPermissions={[]}
                    path={'/rfqs/:rfqId/manufacturing/teaser'}
                    key={route('/rfqs/:rfqId/manufacturing/teaser')}
                    render={(): JSX.Element => <ManufacturingTeaserPage />}
                />
                <ProtectedRoute
                    exact
                    path={'/supplier/importer'}
                    requiredPermissions={['view:supplier']}
                    render={(params): JSX.Element => <SupplierImporterPage {...params} />}
                />
                <ProtectedRoute
                    exact
                    path={'/suppliers/supplier-contacts'}
                    requiredPermissions={['view:supplier']}
                    render={(): JSX.Element => <SupplierContactsPage />}
                />
                <ProtectedRoute
                    exact
                    path={'/suppliers/supplier-contacts-importer/bulk'}
                    requiredPermissions={['view:supplier']}
                    render={(params): JSX.Element => <BulkSupplierContactsImporter {...params} />}
                />
                <ProtectedRoute
                    exact
                    path={'/suppliers/line-card-rules/importer'}
                    requiredPermissions={['view:supplier']}
                    render={(params): JSX.Element => <BulkApprovedVendorListImporter {...params} />}
                />
                <ProtectedRoute
                    exact
                    path={'/suppliers/:supplierId/supplier-contacts-importer'}
                    requiredPermissions={['view:supplier']}
                    render={(params): JSX.Element => <SupplierContactsImporterPage {...params} />}
                />
                <ProtectedRoute
                    exact
                    path={'/suppliers/:supplierAndStockLocationId'}
                    requiredPermissions={['view:supplier']}
                    render={(urlParams): JSX.Element => <SupplierDetailsPage {...urlParams} />}
                />

                <ProtectedRoute
                    exact
                    path={'/suppliers'}
                    requiredPermissions={['view:supplier']}
                    render={(): JSX.Element => <SupplierManagementPage />}
                />
                <ProtectedRoute
                    exact
                    path={'/manufacturer/:manufacturerId'}
                    requiredPermissions={['view:supplier']}
                    render={(urlParams): JSX.Element => <ManufacturerDetailsPage {...urlParams} />}
                />
                <ProtectedRoute
                    path={'/manufacturer'}
                    requiredPermissions={['view:supplier']}
                    render={(): JSX.Element => <ManufacturerManagementPage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/manufacturing/activity'}
                    render={(): JSX.Element => <ActivitiesViewPage />}
                    key={route('/manufacturing/activity')}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/activity/:activityId/duplicate'}
                    key={route('/manufacturing/activity/:activityId/duplicate')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <DuplicateActivityForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/activity/:activityId/edit'}
                    key={route('/manufacturing/activity/:activityId/edit')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <EditActivityForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/add-activity'}
                    key={route('/manufacturing/add-activity')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <AddActivityForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/resource/:resourceId/duplicate'}
                    key={route('/manufacturing/resource/:resourceId/duplicate')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <DuplicateResourceForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/resource/:resourceId/edit'}
                    key={route('/manufacturing/resource/:resourceId/edit')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <EditResourceForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/driver/:driverId/duplicate'}
                    key={route('/manufacturing/driver/:driverId/duplicate')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <DuplicateDriverForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/driver/:driverId/edit'}
                    key={route('/manufacturing/driver/:driverId/edit')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <EditDriverForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/manufacturing/resource'}
                    key={route('/manufacturing/resource')}
                    render={(): JSX.Element => <ResourcesViewPage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/manufacturing/driver'}
                    key={route('/manufacturing/driver')}
                    render={(): JSX.Element => <DriversViewPage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/manufacturing/expense'}
                    key={route('/manufacturing/expense')}
                    render={(): JSX.Element => <ExpensesViewPage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/add-resource'}
                    key={route('/manufacturing/add-resource')}
                    render={(): JSX.Element => (
                        <PageLayout layout="full-width">
                            <AddResourceForm />
                        </PageLayout>
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/manufacturing/template/:templateId'}
                    key={route('/manufacturing/template/:templateId')}
                    render={(props): JSX.Element => <ManufacturingScenarioTemplateManagementPage {...props} />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/manufacturing/template'}
                    key={route('/manufacturing/template')}
                    render={(props): JSX.Element => <ManufacturingScenarioTemplateManagementPage {...props} />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/add-driver'}
                    key={route('/manufacturing/add-driver')}
                    render={(): JSX.Element => <DriverFormPage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/add-expense'}
                    key={route('/manufacturing/add-expense')}
                    render={(): JSX.Element => <AddExpensePage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/expense/:expenseId/duplicate'}
                    key={route('/manufacturing/expense/:expenseId/duplicate')}
                    render={(urlParams): JSX.Element => <DuplicateExpensePage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_ADMIN_PERMISSIONS}
                    exact
                    path={'/manufacturing/expense/:expenseId/edit'}
                    key={route('/manufacturing/expense/:expenseId/edit')}
                    render={(urlParams): JSX.Element => <EditExpensePage {...urlParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={CALCULATION_PERMISSIONS}
                    exact
                    path={'/calculation/templates'}
                    key={route('/calculation/templates')}
                    render={(): JSX.Element => <CalculationTemplatesViewPage />}
                />
                <ProtectedRoute
                    requiredPermissions={QUOTATIONS_V2_ADMIN_PERMISSIONS}
                    exact
                    path={'/calculation/templates/add-template'}
                    key={route('/calculation/templates/add-template')}
                    render={(): JSX.Element => <CalculationAddTemplatePage />}
                />
                <ProtectedRoute
                    requiredPermissions={QUOTATIONS_V2_ADMIN_PERMISSIONS}
                    exact
                    path={'/calculation/templates/:templateId/edit'}
                    key={route('/calculation/templates/:templateId/edit')}
                    render={(): JSX.Element => <CalculationEditTemplatePage />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId'}
                    key={route('/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId')}
                    render={(props): JSX.Element => <ManufacturingAssemblyDetailsPage {...props.pathParams} />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={
                        '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity'
                    }
                    key={route(
                        '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity',
                    )}
                    render={(): JSX.Element => <ManufacturingAssemblyScenarioDetailsPage entity={'activity'} />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense'}
                    key={route(
                        '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense',
                    )}
                    render={(): JSX.Element => <ManufacturingAssemblyScenarioDetailsPage entity={'expense'} />}
                />
                <ProtectedRoute
                    requiredPermissions={MANUFACTURING_LITE_PERMISSIONS}
                    exact
                    path={'/rfqs/:rfqId/manufacturing'}
                    key={route('/rfqs/:rfqId/manufacturing')}
                    render={(params): JSX.Element => (
                        <AssemblyOverviewPage
                            rfqId={params.pathParams.rfqId}
                            forwardIfSingleAssembly={Boolean(params.queryParams.forwardIfSingleAssembly)}
                        />
                    )}
                />
                <ProtectedRoute
                    requiredPermissions={[]}
                    exact
                    path={'/notFound'}
                    render={(): JSX.Element => <NotFoundPage />}
                />
                <Route>
                    <NotFoundPage />
                </Route>
            </Switch>
        </Router>
    );
};

export default PagesRouter;
