import { t } from '@lingui/macro';
import { Dialog, DialogTitle } from '@luminovo/design-system';
import { ManufacturingScenarioOverviewDTO } from '@luminovo/http-client';
import { DialogContent } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { EditManufacturingScenarioForm } from './EditManufacturingScenarioForm';
import { BaseManufacturingScenarioFormState } from './types';

export const useDuplicateManufacturingScenarioDialog = ({
    rfqId,
    assemblyId,
    manufacturingScenario,
}: {
    rfqId: string;
    assemblyId: string;
    manufacturingScenario: ManufacturingScenarioOverviewDTO;
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation('POST /manufacturing-scenarios/:manufacturingScenarioId/duplicate', {
        snackbarMessage: t`Manufacturing scenario successfully duplicated`,
        onSuccess: async () => closeDialog(),
    });

    const onSubmit = async (form: BaseManufacturingScenarioFormState) => {
        await mutateAsync({
            requestBody: {
                name: form.manufacturingScenarioName,

                batch_sizes: form.batchSizes,
                sourcing_scenario: form.sourcingScenarioId ?? null,
            },
            pathParams: {
                manufacturingScenarioId: manufacturingScenario.id,
            },
        });
    };

    const defaultValues: BaseManufacturingScenarioFormState = {
        manufacturingScenarioName: t`Copy of` + ' ' + manufacturingScenario.name,
        batchSizes: manufacturingScenario.batch_sizes,
        sourcingScenarioId: manufacturingScenario.sourcing_scenario?.id ?? undefined,
        notes: manufacturingScenario.notes ?? undefined,
    };

    const dialog = (
        <Dialog open={true} maxWidth="xs" style={{ margin: '24px' }}>
            <DialogTitle title={t`Duplicate manufacturing scenario`} handleClose={closeDialog} />
            <DialogContent>
                <EditManufacturingScenarioForm
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                />
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};
