import { Trans } from '@lingui/macro';
import {
    colorSystem,
    DestructiveTertiaryIconButton,
    FieldTextControlled,
    Flexbox,
    SecondaryButton,
    TertiaryButton,
    TertiaryIconButton,
    Text,
} from '@luminovo/design-system';
import { SolutionConfigurationDTO } from '@luminovo/http-client';
import { Add, Delete, Edit, Notes } from '@mui/icons-material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useMutationUpdateSolutionConfiguration } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';

type FormState = { notes: string };

const FormInner: React.FunctionComponent<{ onCancel: () => void }> = ({ onCancel }): JSX.Element => {
    const { control } = useFormContext<FormState>();

    return (
        <>
            <FieldTextControlled name={'notes'} control={control} max={1_000} FieldProps={{ multiline: true }} />
            <Flexbox gap={4} paddingTop={'12px'} justifyContent="flex-end">
                <SecondaryButton size={'small'} onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton size="small" style={{ width: 'fit-content' }} />
            </Flexbox>
        </>
    );
};

export const SolutionConfigurationNote: React.FunctionComponent<{
    solutionConfiguration: SolutionConfigurationDTO;
}> = ({ solutionConfiguration }): JSX.Element => {
    const [isEditable, setIsEditable] = React.useState<boolean>(false);
    const { mutateAsync } = useMutationUpdateSolutionConfiguration(solutionConfiguration.id);

    const onSubmit = async (formValues: FormState) => {
        const notes = Boolean(formValues.notes) ? formValues.notes : null;
        await mutateAsync({ notes });
        setIsEditable(false);
    };

    if (!isEditable && !solutionConfiguration.notes) {
        return (
            <TertiaryButton size="small" startIcon={<Add />} onClick={() => setIsEditable(true)}>
                <Trans>Add notes</Trans>
            </TertiaryButton>
        );
    }

    if (isEditable) {
        return (
            <Flexbox flexDirection={'column'} gap={8} width={'100%'}>
                <Flexbox justifyContent={'space-between'} width={'100%'}>
                    <Flexbox gap={4} alignItems="center" minHeight={'24px'}>
                        <Notes fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Notes</Trans>
                        </Text>
                    </Flexbox>
                    <DestructiveTertiaryIconButton size="small" onClick={() => onSubmit({ notes: '' })}>
                        <Delete fontSize="inherit" />
                    </DestructiveTertiaryIconButton>
                </Flexbox>
                <FormContainer defaultValues={{ notes: solutionConfiguration.notes ?? '' }} onSubmit={onSubmit}>
                    <FormInner onCancel={() => setIsEditable(false)} />
                </FormContainer>
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection={'column'} gap={8} width={'100%'}>
            <Flexbox justifyContent={'space-between'} width={'100%'}>
                <Flexbox gap={4} alignItems="center">
                    <Notes fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                    <Text variant="h5" color={colorSystem.neutral[8]}>
                        <Trans>Notes</Trans>
                    </Text>
                </Flexbox>
                <TertiaryIconButton size="small" onClick={() => setIsEditable(true)}>
                    <Edit fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>
            <Text variant="body" color={colorSystem.neutral[8]} style={{ wordBreak: 'break-word' }}>
                {solutionConfiguration.notes}
            </Text>
        </Flexbox>
    );
};
