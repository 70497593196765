import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SourcengineCredentialsDTO extends r.Static<typeof SourcengineCredentialsDTORuntype> {}
export const SourcengineCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface SourcengineCredentialsInputDTO extends r.Static<typeof SourcengineCredentialsInputDTORuntype> {}
export const SourcengineCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface SourcengineResponseBodyDTO extends r.Static<typeof SourcengineResponseBodyDTORuntype> {}
export const SourcengineResponseBodyDTORuntype = r.Record({
    data: SourcengineCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
