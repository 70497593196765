import { t } from '@lingui/macro';
import { assertUnreachable, formatMonetaryValue, isPresent, MonetaryValue } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { CostFromRuleDTO, EvaluatedTcoCostDTO, TcoCostDTO as TcoSolutionCostDTO } from '@luminovo/http-client';
import { CostType, extractEvaluatedCostForType } from '../../extractors';
import { CostTag } from '../tags/AdditionalCostTag';

const extractCost = (evaluatedCost: EvaluatedTcoCostDTO): MonetaryValue | null => {
    const { data, type } = evaluatedCost;
    switch (type) {
        case 'Automatic':
            return data.cost;
        case 'Manual':
            return data.manual;
        default:
            assertUnreachable(type);
    }
};

export const CostFromRule = ({
    cost,
    tcoRuleNameMap,
}: {
    cost: CostFromRuleDTO;
    tcoRuleNameMap: Record<string, string>;
}): JSX.Element => {
    const tcoRuleName = tcoRuleNameMap[cost.total_cost_of_ownership_rule] ?? t`Unkown rule`;

    return (
        <Flexbox gap={8} width="100%" justifyContent="space-between">
            <Text variant="body-small" color={colorSystem.neutral[7]}>
                {tcoRuleName}
            </Text>
            <Text variant="body-small" color={colorSystem.neutral[7]}>
                {formatMonetaryValue(cost.cost, 'unit-price')}
            </Text>
        </Flexbox>
    );
};

export const AutomaticCost = ({
    costs,
    tcoRuleNameMap,
}: {
    costs: EvaluatedTcoCostDTO | null;

    tcoRuleNameMap: Record<string, string>;
}): JSX.Element => {
    if (!isPresent(costs) || costs.type !== 'Automatic') {
        return <></>;
    }

    return (
        <Flexbox flexDirection="column" gap={8}>
            {costs.data.costs_from_rules.map((costFromRule) => (
                <CostFromRule
                    key={costFromRule.total_cost_of_ownership_rule}
                    cost={costFromRule}
                    tcoRuleNameMap={tcoRuleNameMap}
                />
            ))}
        </Flexbox>
    );
};

const CostRow = ({
    cost,
    costType,
    tcoRuleNameMap,
}: {
    cost: EvaluatedTcoCostDTO | null;
    costType: CostType;
    tcoRuleNameMap: Record<string, string>;
}): JSX.Element => {
    const costForRow = cost ? extractCost(cost) : undefined;

    return (
        <Flexbox flexDirection="column" gap={8}>
            <Flexbox justifyContent="space-between" minWidth={200} alignItems="center">
                <CostTag costType={costType} />
                {isPresent(cost) ? `${formatMonetaryValue(costForRow, 'unit-price')} ${t`per unit`}` : '-'}
            </Flexbox>
            <AutomaticCost costs={cost} tcoRuleNameMap={tcoRuleNameMap} />
        </Flexbox>
    );
};

export const SimpleCostRow = ({ cost, costType }: { cost: MonetaryValue | null; costType: CostType }): JSX.Element => {
    return (
        <Flexbox justifyContent="space-between" minWidth={200}>
            <CostTag costType={costType} />
            {isPresent(cost) ? `${formatMonetaryValue(cost)} ${t`per unit`}` : '-'}
        </Flexbox>
    );
};

export const TcoCostTooltip = ({
    tcoCost,
    tcoRuleNameMap,
}: {
    tcoCost: TcoSolutionCostDTO | undefined;
    tcoRuleNameMap: Record<string, string>;
}): JSX.Element => {
    return (
        <Flexbox flexDirection="column" gap={16} padding={1}>
            <CostRow
                cost={extractEvaluatedCostForType('packaging', { unitTcoCost: tcoCost ?? null })}
                costType="packaging"
                tcoRuleNameMap={tcoRuleNameMap}
            />
            <CostRow
                cost={extractEvaluatedCostForType('discount', { unitTcoCost: tcoCost ?? null })}
                costType="discount"
                tcoRuleNameMap={tcoRuleNameMap}
            />
            <CostRow
                cost={extractEvaluatedCostForType('customs', { unitTcoCost: tcoCost ?? null })}
                costType="customs"
                tcoRuleNameMap={tcoRuleNameMap}
            />
            <CostRow
                cost={extractEvaluatedCostForType('shipping', { unitTcoCost: tcoCost ?? null })}
                costType="shipping"
                tcoRuleNameMap={tcoRuleNameMap}
            />
            <CostRow
                cost={extractEvaluatedCostForType('other', { unitTcoCost: tcoCost ?? null })}
                costType="other"
                tcoRuleNameMap={tcoRuleNameMap}
            />
        </Flexbox>
    );
};
