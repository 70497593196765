import { useDialogAddSupplierAndStockLocation } from '@/modules/Suppliers/SupplierManagement/components/SupplierDialogs/AddSupplierDialog';
import { Plural, t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { assertUnreachable, Currency, formatRelativeTime, isPresent } from '@luminovo/commons';
import {
    DestructiveTertiaryIconButton,
    Dropzone,
    FieldCheckboxControlled,
    FieldController,
    FieldDateControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Link,
    Message,
    SecondaryButton,
    TertiaryButton,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import {
    CustomerDTO,
    ItemClass,
    OneTimeCostInputDTO,
    OnOrderDTO,
    Option,
    Packaging,
    PriceType,
    QuantityUnit,
    QuantityUnitType,
    RegionsEnum,
    RfqContext,
    RfqListItemDTO,
    StandardPartOfferInputDTO,
    StandardPartOfferLinkedPartType,
    SupplierAndStockLocationDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
} from '@luminovo/http-client';
import {
    FieldPackaging,
    formatSupplierAndStockLocationDTO,
    itemClassTranslations,
    priceTypeTranslations,
    QuantityUnitRecordPlural,
    QuantityUnitRecordSingular,
    supplierPreferenceTranslations,
} from '@luminovo/sourcing-core';
import { Add, Close } from '@mui/icons-material';
import { Box, CircularProgress, Divider, InputAdornment, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { transEnum } from '../../../components/localization/TransEnum';
import { useIpnDetailsDrawer } from '../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { inputCurrenciesPublicTranslations } from '../../../resources/currencyInputTypes';
import { useCustomers } from '../../../resources/customer/customerHandler';
import { AttachmentFile } from '../../../resources/http/useHttpFileUpload';
import { useIpn, useOtsFullPart } from '../../../resources/part/partHandler';
import { useRfqs } from '../../../resources/rfq/rfqHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../../utils/routes';

export type PriceBreakInput = {
    moq: number;
    unitPrice: number | null;
    mpq: number;
};

export type StandardPartOfferFormValues = {
    linkedPart: StandardPartOfferInputDTO['linked_part'];
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    supplierPartNumber?: Option<string>;
    packaging?: Option<Packaging>;
    priceType: PriceType;
    quantity: number;
    quantityUnit: QuantityUnitType;

    stock?: Option<number>;
    factoryQuantity?: Option<number>;
    factoryLeadTime?: Option<number>;
    onOrder: OnOrderDTO[];

    currency: Currency;
    priceBreaks: PriceBreakInput[];
    oneTimeCosts: OneTimeCostInputDTO[];

    offerNumber?: Option<string>;
    validUntilDate?: string;
    validFromDate?: string;
    validForRfq: {
        enabled: boolean;
        data: Option<RfqListItemDTO>;
    };
    validForCustomer: {
        enabled: boolean;
        data: Option<CustomerDTO>;
    };

    itemClass?: Option<ItemClass>;
    ncnr?: Option<boolean>;
    cancellationWindowInDays?: Option<number>;

    notes?: Option<string>;
    files: AttachmentFile[];
};

export function StandardPartOfferForm({
    onSubmit,
    defaultValues,
    rfqContext,
    disabledQuantityUnit,
    rfqId,
    sourcingScenarioId,
}: {
    onSubmit: (form: StandardPartOfferFormValues) => Promise<void>;
    defaultValues: StandardPartOfferFormValues;
    rfqContext: RfqContext;
    disabledQuantityUnit: boolean;
    rfqId?: string;
    sourcingScenarioId?: string;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} UNSAFE_disableStablePropCheck>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`Offer information`}>
                    <FormItemStandardPart rfqContext={rfqContext} />
                    <FormItemSupplier rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />
                    <FormItemPriceType />
                    <FormItemUnitOfMeasurement disabledQuantityUnit={disabledQuantityUnit} />
                    <FormItemSku />
                    <FormItemPackaging />
                </FormSection>
                <Divider />
                <FormSection title={t`Pricing information`}>
                    <FormItemCurrency />
                    <FormItemPriceBreaks />
                    <FormItemOneTimeCosts />
                </FormSection>
                <Divider />
                <FormSection title={t`Availability`}>
                    <FormItemStock />
                    <FormItemFactoryQuantity />
                    <FormItemFactoryLeadTime />
                    <FormItemOnOrder />
                </FormSection>
                <Divider />
                <FormSection title={t`Offer validity`}>
                    <FormItemOfferNumber />
                    <FormItemValidFromDate />
                    <FormItemValidUntilDate />
                    <FormItemValidForRfq />
                    <FormItemValidForCustomer />
                </FormSection>
                <Divider />
                <FormSection title={t`Terms`}>
                    <FormItemItemClass />
                    <FormItemNcnr />
                    <FormItemCancellationWindow />
                </FormSection>
                <Divider />
                <FormSection title={t`Additional information`}>
                    <FormItemNotes />
                    {/** TODO Add support for internal part number */}
                    {defaultValues.linkedPart.type === 'OffTheShelf' && <FormItemFiles />}
                </FormSection>
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end" marginTop={4}>
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function CancelButton() {
    const navigate = useNavigate();
    const handleClose = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemStandardPart({ rfqContext }: { rfqContext: RfqContext }) {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const linkedPart = useWatch({ control, name: 'linkedPart' });

    if (linkedPart.type === StandardPartOfferLinkedPartType.OffTheShelf) {
        return <FormItemOffTheShelfPart partId={linkedPart.id} rfqContext={rfqContext} />;
    }

    if (linkedPart.type === StandardPartOfferLinkedPartType.InternalPartNumber) {
        return <FormItemInternalPartNumberPart ipnId={linkedPart.id} rfqContext={rfqContext} />;
    }

    return assertUnreachable(linkedPart.type);
}

function FormItemOffTheShelfPart({ partId, rfqContext }: { partId: string; rfqContext: RfqContext }) {
    const { data: part, isLoading } = useOtsFullPart({ partOptionId: partId, rfqContext });
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();

    if (!part || isLoading) {
        return <CircularProgress />;
    }

    return (
        <FormItem label={t`Part`}>
            <Link
                attention="high"
                onClick={() => {
                    openOtsPartDrawer({ part, rfqContext });
                }}
            >
                {part.manufacturer.name}, {part.mpn}
            </Link>
        </FormItem>
    );
}

function FormItemInternalPartNumberPart({ ipnId, rfqContext }: { ipnId: string; rfqContext: RfqContext }) {
    const { data: part, isLoading } = useIpn(ipnId, rfqContext);
    const { openDrawer: openIpnPartDrawer } = useIpnDetailsDrawer();

    if (!part || isLoading) {
        return <CircularProgress />;
    }

    return (
        <FormItem label={t`IPN`}>
            <Link
                attention="high"
                onClick={() => {
                    openIpnPartDrawer({ ipnId, rfqContext });
                }}
            >
                {part.id}
            </Link>
        </FormItem>
    );
}

function FormItemSupplier({ rfqId, sourcingScenarioId }: { rfqId?: string; sourcingScenarioId?: string }) {
    const { control, setValue } = useFormContext<StandardPartOfferFormValues>();
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();
    const hasCreateSupplierPermission = useHasPermission(['create:supplier']);

    const { openDialog } = useDialogAddSupplierAndStockLocation({
        onSuccessCallback: (s) => {
            setValue('supplierAndStockLocation', s, { shouldValidate: true });
        },
        disableSupplierPreferenceField: true,
    });

    return (
        <FormItem
            label={t`Supplier`}
            required
            actions={
                isPresent(rfqId) && isPresent(sourcingScenarioId) ? (
                    <TertiaryButton
                        size={'small'}
                        onClick={() => {
                            window.open(
                                route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`, {
                                    rfqId,
                                    sourcingScenarioId,
                                }),
                                '_blank',
                                'noopener noreferrer',
                            );
                        }}
                    >
                        Edit supplier preferences
                    </TertiaryButton>
                ) : undefined
            }
        >
            <FieldSelectControlled
                control={control}
                name="supplierAndStockLocation"
                required
                FieldProps={{
                    options: supplierAndStockLocations,
                    getOptionKey: (option) => option.id,
                    getOptionLabel: (option) => formatSupplierAndStockLocationDTO(option),
                    getOptionSublabel: (option) => option.supplier_number,
                    groupBy: (option) => transEnum(option.preference, supplierPreferenceTranslations),
                    action: {
                        label: t`Add new supplier`,
                        disabled: !hasCreateSupplierPermission,
                        onClick: () => {
                            openDialog({
                                name: '',
                                stockLocation: RegionsEnum.Unknown,
                                supplierNumber: '',
                                supplierPreference: SupplierPreference.NotApproved,
                                supplierType: SupplierType.Distributor,
                                supplierPartType: SupplierPartType.OffTheShelf,
                            });
                        },
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemSku() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Supplier part number`}>
            <FieldTextControlled
                name="supplierPartNumber"
                control={control}
                FieldProps={{
                    placeholder: t`Supplier part number`,
                }}
            />
        </FormItem>
    );
}

function FormItemPackaging() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Packaging`}>
            <FieldController control={control} name="packaging" Field={FieldPackaging} />
        </FormItem>
    );
}

function FormItemPriceType() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Type`} required>
            <FieldSelectControlled
                control={control}
                name="priceType"
                required
                FieldProps={{
                    options: Object.values(PriceType).filter(
                        (x) => x !== PriceType.PurchasePrice && x !== PriceType.StandardPrice,
                    ),
                    getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemUnitOfMeasurement({ disabledQuantityUnit }: { disabledQuantityUnit: boolean }) {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const [editMode, setEditMode] = React.useState(false);

    return (
        <FormItem
            label={t`Unit of measurement`}
            required
            description={
                <Trans>
                    All other quantities are multiplied by the unit of measurement to obtain the total quantity. For
                    example, if the unit of measurement is “10 meters” and you have “2” in stock, then we calculate this
                    as 2 * 10 meters = 20 meters in stock
                </Trans>
            }
            actions={
                <TertiaryButton
                    size="small"
                    onClick={() => setEditMode((disabledQuantityUnit) => !disabledQuantityUnit)}
                >
                    <Trans>Edit unit of measurement</Trans>
                </TertiaryButton>
            }
        >
            {editMode && (
                <Message
                    size={'small'}
                    variant={'yellow'}
                    attention={'low'}
                    message={t`Modifying the unit of measurement will affect both quantity and stock`}
                />
            )}
            <Box display={'grid'} gridTemplateColumns="1fr 120px" columnGap={'8px'}>
                <FieldNumericControlled
                    control={control}
                    name="quantity"
                    required={true}
                    min={1}
                    FieldProps={{
                        helperText: disabledQuantityUnit
                            ? t`The design item does not support a different unit of measurement.`
                            : undefined,
                        disabled: !editMode,
                    }}
                />
                <FieldSelectControlled
                    control={control}
                    name="quantityUnit"
                    FieldProps={{
                        options: Object.values(QuantityUnit),
                        getOptionLabel: (option) => transEnum(option, QuantityUnitRecordSingular),
                        disabled: disabledQuantityUnit || !editMode,
                    }}
                />
            </Box>
        </FormItem>
    );
}

function FormItemFactoryLeadTime() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const factoryLeadTimeInDays = useWatch({ control, name: 'factoryLeadTime' });
    const weeks = Math.floor((factoryLeadTimeInDays ?? 0) / 7);
    const remainingDays = (factoryLeadTimeInDays ?? 0) % 7;

    return (
        <FormItem
            label={t`Standard factory lead time`}
            description={t`The estimated time between placing an order, and the manufacturer completing the order. Leave empty if unknown.`}
        >
            <FieldNumericControlled
                control={control}
                name="factoryLeadTime"
                min={1}
                isInteger
                FieldProps={{
                    disabled: !isPresent(unitOfMeasurement),
                    placeholder: t`Standard factory lead time`,
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography>
                                    <Trans>Days</Trans>
                                </Typography>
                            </InputAdornment>
                        ),
                    },
                }}
            />
            {weeks > 0 && (
                <Typography>
                    <Plural value={weeks} one={`# week`} other={`# weeks`} />{' '}
                    {remainingDays > 0 && <Plural value={remainingDays} one={`and # day`} other={`and # days`} />}
                </Typography>
            )}
        </FormItem>
    );
}

function FormItemCurrency() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Currency`} required>
            <FieldSelectControlled
                control={control}
                name="currency"
                required
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemStock() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    return (
        <FormItem label={t`Stock`} description={t`Enter the quantity available in stock.`}>
            <FieldNumericControlled
                control={control}
                name="stock"
                min={0}
                isInteger
                FieldProps={{
                    placeholder: t`Stock`,
                    InputProps: {
                        endAdornment:
                            disabled || unitOfMeasurement === 1 ? undefined : (
                                <InputAdornment position="end">
                                    {`× ${unitOfMeasurement} ${transEnum(unit, QuantityUnitRecordPlural)}`}
                                </InputAdornment>
                            ),
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemFactoryQuantity() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    return (
        <FormItem label={t`Factory quantity`}>
            <FieldNumericControlled
                control={control}
                name="factoryQuantity"
                min={0}
                isInteger
                FieldProps={{
                    disabled: disabled,
                    placeholder: t`Factory quantity`,
                    InputProps: {
                        endAdornment:
                            disabled || unitOfMeasurement === 1 ? undefined : (
                                <InputAdornment position="end">
                                    {`× ${unitOfMeasurement} ${transEnum(unit, QuantityUnitRecordPlural)}`}
                                </InputAdornment>
                            ),
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemOnOrder() {
    const { control } = useFormContext<StandardPartOfferFormValues>();

    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);
    const { fields, append, remove } = useFieldArray({ control, name: 'onOrder' });

    return (
        <FormItem label={t`On order`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns="1fr 1fr auto"
                rowGap={'16px'}
                columnGap={'16px'}
            >
                {fields.length > 0 && (
                    <>
                        <Typography color="textSecondary">
                            <Trans>Quantity</Trans>*
                        </Typography>
                        <Typography color="textSecondary">
                            <Trans>Date</Trans>
                        </Typography>
                        <span />
                    </>
                )}
                {fields.map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FieldNumericControlled
                                control={control}
                                name={`onOrder.${index}.quantity`}
                                min={1}
                                required
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldDateControlled control={control} name={`onOrder.${index}.date`} />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            quantity: 0,
                            date: null,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add on order quantity</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemPriceBreaks() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const unitOfMeasurement = useWatch({ control, name: 'quantity' });
    const unit = useWatch({ control, name: 'quantityUnit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    const { fields, append, remove } = useFieldArray({ control, name: 'priceBreaks' });

    return (
        <FormItem label={t`Price breaks`}>
            <Box
                display={'grid'}
                alignItems="top"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>MOQ</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Unit price</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>MPQ</Trans>*
                </Typography>
                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.moq`}
                                min={1}
                                required
                                FieldProps={{
                                    placeholder: t`MOQ`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.unitPrice`}
                                min={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography>{currency}</Typography>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.mpq`}
                                min={1}
                                required
                                FieldProps={{
                                    placeholder: t`MPQ`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            moq: fields.map((x) => x.moq).reduce((a, b) => Math.max(a, b), 1),
                            mpq: fields.map((x) => x.mpq).reduce((a, b) => Math.max(a, b), 1),
                            unitPrice: 0,
                        });
                    }}
                    size="medium"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Trans>Add price break</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemOneTimeCosts() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });

    const { fields, append, remove } = useFieldArray({ control, name: 'oneTimeCosts' });

    return (
        <FormItem label={t`One-time costs`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Cost</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Description</Trans>
                </Typography>
                <span />
                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`oneTimeCosts.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldTextControlled control={control} name={`oneTimeCosts.${index}.description`} />
                            <span />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            amount: String(1),
                            description: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add one-time cost</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemValidFromDate() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const validFromDate = useWatch({ control, name: 'validFromDate' });

    return (
        <FormItem label={t`Valid from`}>
            <FieldDateControlled control={control} name={'validFromDate'} />
            {validFromDate && <Typography>{formatRelativeTime(validFromDate)}.</Typography>}
        </FormItem>
    );
}

function FormItemValidUntilDate() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const validUntilDate = useWatch({ control, name: 'validUntilDate' });

    return (
        <FormItem label={t`Valid until`}>
            <FieldDateControlled control={control} name={'validUntilDate'} />
            {validUntilDate && <Typography>{formatRelativeTime(validUntilDate)}.</Typography>}
        </FormItem>
    );
}

function FormItemValidForRfq() {
    const { control, trigger } = useFormContext<StandardPartOfferFormValues>();
    const { data: options = [], isLoading } = useRfqs();
    const enabled = useWatch({ control, name: 'validForRfq.enabled' });

    // Replace that with an onAfterChange call from the FieldCheckboxControlled
    React.useEffect(() => {
        trigger('validForRfq');
    }, [enabled, trigger]);

    return (
        <Flexbox gap={8} flexDirection={'column'}>
            <Flexbox alignItems="center" gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="validForRfq.enabled"
                    FieldProps={{
                        color: 'primary',
                    }}
                />
                <Trans>Only valid for this RfQ</Trans>
            </Flexbox>
            <FieldSelectControlled
                control={control}
                name="validForRfq.data"
                required={enabled}
                FieldProps={{
                    options,
                    virtualized: true,
                    placeholder: t`Select Rfq`,
                    getOptionLabel: (option) => option.name,
                    getOptionKey: (option) => option.id,
                    disabled: isLoading || !enabled,
                }}
            />
        </Flexbox>
    );
}

function FormItemValidForCustomer() {
    const { control, trigger } = useFormContext<StandardPartOfferFormValues>();
    const { data: options } = useCustomers();
    const enabled = useWatch({ control, name: 'validForCustomer.enabled' });

    // Replace that with an onAfterChange call from the FieldCheckboxControlled
    React.useEffect(() => {
        trigger('validForCustomer');
    }, [enabled, trigger]);

    return (
        <Flexbox gap={8} flexDirection="column">
            <Flexbox alignItems="center" gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="validForCustomer.enabled"
                    FieldProps={{
                        color: 'primary',
                    }}
                />
                <Trans>Only valid for customer</Trans>
            </Flexbox>
            <FieldSelectControlled
                control={control}
                name="validForCustomer.data"
                required={enabled}
                FieldProps={{
                    options,
                    virtualized: true,
                    placeholder: t`Select customer`,
                    getOptionLabel: (option) => option.name,
                    getOptionKey: (option) => option.id,
                    disabled: !enabled,
                }}
            />
        </Flexbox>
    );
}

function FormItemOfferNumber() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Offer number`} description={t`The unique identifier for the offer in your system.`}>
            <FieldTextControlled control={control} name="offerNumber" />
        </FormItem>
    );
}

function FormItemNcnr() {
    const { control } = useFormContext<StandardPartOfferFormValues>();

    return (
        <Flexbox alignItems="center" gap={8}>
            <FieldCheckboxControlled name="ncnr" control={control} />
            <Text>
                <Trans>NCNR</Trans>
            </Text>
        </Flexbox>
    );
}

function FormItemItemClass() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Item class`}>
            <FieldSelectControlled
                control={control}
                name="itemClass"
                FieldProps={{
                    placeholder: t`Select item class`,
                    options: Object.values(ItemClass),
                    getOptionLabel: (option) => transEnum(option, itemClassTranslations),
                    getOptionKey: (option) => option,
                }}
            />
        </FormItem>
    );
}

function FormItemCancellationWindow() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    const cancellationWindowInDays = useWatch({ control, name: 'cancellationWindowInDays' });
    const weeks = Math.floor((cancellationWindowInDays ?? 0) / 7);
    const remainingDays = (cancellationWindowInDays ?? 0) % 7;

    return (
        <FormItem label={t`Cancellation window`}>
            <FieldNumericControlled
                control={control}
                name="cancellationWindowInDays"
                min={1}
                isInteger
                FieldProps={{
                    placeholder: t`Cancellation window`,
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography>
                                    <Trans>Days</Trans>
                                </Typography>
                            </InputAdornment>
                        ),
                    },
                }}
            />
            {weeks > 0 && (
                <Typography>
                    <Plural value={weeks} one={`# week`} other={`# weeks`} />{' '}
                    {remainingDays > 0 && <Plural value={remainingDays} one={`and # day`} other={`and # days`} />}
                </Typography>
            )}
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<StandardPartOfferFormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled control={control} name="notes" FieldProps={{ multiline: true, minRows: 2 }} />
        </FormItem>
    );
}

const FormItemFiles = () => {
    const { control, setValue } = useFormContext<StandardPartOfferFormValues>();
    const files = useWatch({ control, name: 'files' });

    const handleChangeDropZone = (newFiles: File[]) => {
        const filesWithUrl = newFiles.map(
            (file): AttachmentFile => ({
                file: file,
                url: URL.createObjectURL(file),
                name: file.name,
                state: 'PendingUpload',
            }),
        );
        setValue('files', [...files, ...filesWithUrl]);
    };

    const handleDelete = async (removedFile: AttachmentFile) => {
        setValue(
            'files',
            files.map((file) => {
                if (file.url === removedFile.url) {
                    if (file.file) {
                        file.state = 'RemovedLocal';
                    } else {
                        file.state = 'PendingRemoval';
                    }
                }
                return file;
            }),
        );
    };

    const renderFiles = files.filter((file) => file.state === 'StoredInAzure' || file.state === 'PendingUpload');
    return (
        <FormItem label={t`Files`}>
            <Dropzone
                accept={null}
                title={''}
                subHeader={t`You can upload additional files to your offer`}
                onDropAccepted={handleChangeDropZone}
                multiple={true}
                overrides={{ Container }}
                persistentFiles={renderFiles.map((file) => ({
                    name: file.name,
                    onClick: () => window.open(file.url, '_blank'),
                    onDelete: () => handleDelete(file),
                }))}
            />
        </FormItem>
    );
};

const Container = styled(Flexbox)({
    flexDirection: 'column',
});
