import { isPresent, uniq } from '@luminovo/commons';
import { BomFileDTO, DesignItemOriginTypes, DesignItemResponseDTO, RowRecord } from '@luminovo/http-client';
import { useBomFiles } from '../../../../../resources/bomImporter/bomImporterHandler';

function useIndexLookup({ bomFileHeaders }: { bomFileHeaders: BomFileDTO[] }) {
    if (bomFileHeaders.length === 0) {
        return [];
    }

    const extractedIndexes: number[] = [];

    bomFileHeaders.forEach((item) => {
        const columnMap = item.column_map;
        if (!columnMap) {
            return;
        }

        extractedIndexes.push(
            ...columnMap.technical_parameters,
            ...columnMap.mpn,
            ...columnMap.manufacturer,
            ...columnMap.notes,
        );
    });

    return Array.from(new Set(extractedIndexes));
}

const extractRawOriginalLines = ({ designItems }: { designItems: DesignItemResponseDTO[] }) => {
    const extractedLines: RowRecord[] = [];

    designItems.forEach((item) => {
        if (item.origin.type === DesignItemOriginTypes.ExcelFile) {
            const { excel_lines } = item.origin.data;
            if (!excel_lines) {
                return;
            }

            excel_lines.forEach((line) => {
                if (line.raw_original_line) {
                    extractedLines.push(line.raw_original_line);
                }
            });
        }
    });

    return extractedLines;
};

function extractValue({ value }: { value: string | undefined }) {
    if (!value) {
        return null;
    }
    return uniq(value.split('\n'));
}

function extractValuesByIndexes({ rawOriginalLines, indexes }: { rawOriginalLines: RowRecord[]; indexes: number[] }) {
    const originalLines = rawOriginalLines.flatMap((line) => {
        return indexes
            .map((index) => extractValue({ value: line[index]?.trim() }))
            .flat()
            .filter(isPresent);
    });
    return uniq(originalLines).sort();
}

export const useCustomPartSuggestions = ({
    individualDesignItems,
}: {
    individualDesignItems: DesignItemResponseDTO[];
}): string[] => {
    const { data: bomFileHeaders = [] } = useBomFiles(individualDesignItems);

    const indexes = useIndexLookup({ bomFileHeaders });
    const rawOriginalLines = extractRawOriginalLines({ designItems: individualDesignItems });

    return extractValuesByIndexes({ rawOriginalLines, indexes });
};
