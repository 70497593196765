import * as r from 'runtypes';

export interface SignupFormState {
    first_name: string;
    last_name: string;
    email: string;
    clear_token: string;
    password: string;
    password_confirmation: string;
    phone_number?: number;
    country_code: number;
    customer_id: string | null;
    position: string;
    language: string;
    return_to?: string | null;
}

export const SignUpPayloadRuntype = r.Record({
    email: r.String,

    customer_id: r.String.nullable(),

    clear_token: r.String,
    tenant: r.String,

    first_name: r.String.optional(),

    last_name: r.String.optional(),
    language: r.String.optional(),

    return_to: r.String.optional().nullable(),
});

export interface SignUpPayload extends r.Static<typeof SignUpPayloadRuntype> {}
