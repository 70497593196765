import { t } from '@lingui/macro';
import { groupBy, uniqueBy } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import { ExtractRequestBody } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
export function ComponentsImporterPage() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { mutateAsync: importComponents } = useHttpMutation('POST /components/import', { snackbarMessage: null });

    return (
        <UniversalImporter
            title={t`Import components`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(
                    t`Import successfully uploaded: We will start linking your MPNs and manufacturers to parts during the night`,
                    {
                        variant: 'success',
                        anchorOrigin: {
                            horizontal: 'center',
                            vertical: 'top',
                        },
                    },
                );
                navigate(-1);
            }}
            onImportBatch={async (batch) => {
                const requestBody: ExtractRequestBody<'POST /components/import'> = Object.entries(
                    groupBy(batch, (row) => `${row.data.ipn}-${row.data.revision}`),
                ).map(([_unused, group]) => {
                    const ipn = group[0].data.ipn;
                    const revision = group[0].data.revision;
                    const category = group[0].data.category;
                    return {
                        component: {
                            type: 'OffTheShelf' as const,
                            data: {
                                parts: group
                                    .map((row) => ({
                                        manufacturer: row.data.manufacturer || undefined,
                                        mpn: row.data.mpn || undefined,
                                    }))
                                    .filter((row) => row.manufacturer || row.mpn),
                            },
                        },
                        customer_part_numbers: [],
                        description: uniqueBy(
                            group.map((row) => row.data.description),
                            (item) => item,
                        ).join('\n'),
                        category,
                        internal_part_number: {
                            revision: revision || undefined,
                            value: ipn,
                        },
                        is_preferred: false,
                        is_restricted_to_customers: false,
                    };
                });

                await importComponents({
                    requestBody,
                    queryParams: { live: true },
                });

                return batch.map((row) => {
                    return {
                        success: true as const,
                    };
                });
            }}
            config={{
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number`,
                    },
                    {
                        id: 'revision' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Revision`,
                        defaultValue: { id: '', label: '-', description: '' },
                        description: t`The IPN revision or version`,
                    },
                    {
                        id: 'manufacturer' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: '', label: '-', description: '' },
                        parser: { type: 'string', options: { trim: true, minLength: 0 } },
                        label: t`Manufacturer`,
                        description: t`The name of the manufacturer`,
                    },
                    {
                        id: 'mpn' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: '', label: '-', description: '' },
                        parser: { type: 'string', options: { trim: true, minLength: 0 } },
                        label: t`MPN`,
                        description: t`The manufacturer part number`,
                    },
                    {
                        id: 'description' as const,
                        columnIndices: [],
                        required: false,
                        allowMultipleColumns: true,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Description`,
                        description: t`The description of the part`,
                        defaultValue: { id: '', label: '-', description: '' },
                    },
                    {
                        id: 'category' as const,
                        columnIndices: [],
                        required: false,
                        allowMultipleColumns: false,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Category`,
                        description: t`The category of the part`,
                        defaultValue: { id: '', label: '-', description: '' },
                    },
                ],
            }}
        />
    );
}
