import * as r from 'runtypes';
import { CurrencyRuntype } from '../backendTypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type Element14LocaleSiteDTO = r.Static<typeof Element14LocaleSiteDTORuntype>;
const Element14LocaleSiteDTORuntype = r.Union(
    r.Literal('AU'),
    r.Literal('CN'),
    r.Literal('HK'),
    r.Literal('IN'),
    r.Literal('KR'),
    r.Literal('MY'),
    r.Literal('NZ'),
    r.Literal('PH'),
    r.Literal('SG'),
    r.Literal('TH'),
    r.Literal('TW'),
    r.Literal('VN'),
);

export interface Element14CredentialsDTO extends r.Static<typeof Element14CredentialsDTORuntype> {}
export const Element14CredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String.optional(),
        customer_id: r.String.nullable().optional(),
        secret_key: r.String.nullable().optional(),
        store_location: Element14LocaleSiteDTORuntype,
        list_price_currency: CurrencyRuntype,
        contract_price_currency: CurrencyRuntype,
        status: ApiIntegrationStatusRuntype,
    }),
);

export interface Element14CredentialsInputDTO extends r.Static<typeof Element14CredentialsInputDTORuntype> {}
export const Element14CredentialsInputDTORuntype = r.Record({
    api_key: r.String,
    customer_id: r.String.nullable(),
    secret_key: r.String.nullable(),
    store_location: Element14LocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
});

export interface Element14SupportedSettingDTO extends r.Static<typeof Element14SupportedSettingDTORuntype> {}
export const Element14SupportedSettingDTORuntype = r.Record({
    store_location: Element14LocaleSiteDTORuntype,
    list_price_currency: r.Array(CurrencyRuntype),
    contract_price_currency: r.Array(CurrencyRuntype),
});
