import { t, Trans } from '@lingui/macro';
import { groupBy } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { route } from '../../../../utils/routes';
import { SolutionConfigurationMiniTable } from '../SolutionConfigurations/SolutionConfigurationTable2';
import {
    SolutionConfigurationsTable2Context,
    SolutionConfigurationTable2Data,
} from '../SolutionConfigurations/SolutionConfigurationTypes2';
import { ChartSpec } from './ChartSpec';

function ChartContainer(props: React.PropsWithChildren<{}>) {
    return (
        <Box
            display="grid"
            style={{
                minWidth: 1000,
                placeItems: 'center',
                background: colorSystem.neutral[0],
                borderRadius: 8,
                padding: 20,
                border: `1px solid ${colorSystem.neutral[2]}`,
            }}
        >
            {props.children}
        </Box>
    );
}

export function ChartSolutionConfiguration<TKeys, TDatum extends { id: string[] }>({
    rfqId,
    chartSpec,
    data,
    sharedContext,
}: {
    rfqId: string;
    chartSpec: ChartSpec<TKeys, TDatum>;
    data: SolutionConfigurationTable2Data[];
    sharedContext: SolutionConfigurationsTable2Context;
}) {
    const Chart = chartSpec.render;

    const { setDialog, closeDialog } = useDialogContext();

    const history = useHistory();
    const onSelectSolutionConfig = React.useCallback(
        (solutionConfigurationId: string) => {
            history.push(
                route('/rfqs/:rfqId/sourcing/:solutionConfigurationId', { rfqId, solutionConfigurationId }, {}),
            );
        },
        [history, rfqId],
    );

    const handleSelectItem = ({ id }: { id: string[] }) => {
        if (id.length === 1) {
            onSelectSolutionConfig(id[0]);
        }
        if (id.length > 1) {
            setDialog(
                <Dialog open={true} onClose={closeDialog} maxWidth="md">
                    <DialogTitle title={t`Select solution`} />
                    <DialogContent style={{ height: '40vh' }}>
                        <SolutionConfigurationMiniTable
                            data={data.filter((item) => id.includes(item.id))}
                            sharedContext={sharedContext}
                        />
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={closeDialog}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                    </DialogActions>
                </Dialog>,
            );
        }
    };

    const [selectedGroupingFunction, setSelectedGroupingFunction] = React.useState(chartSpec.groupBy[0]);

    const chartData = Object.values(groupBy(data, selectedGroupingFunction.extractor))
        .map((group) => {
            return group
                .map((item) => chartSpec.map(item, selectedGroupingFunction.extractor))
                .reduce(chartSpec.aggregate);
        })
        .sort(chartSpec.orderBy);

    return (
        <ChartContainer>
            <Flexbox width="100%" alignItems="center" gap={8}>
                <Text variant="h4">{chartSpec.title}</Text>
                <ButtonGroup size="small">
                    {chartSpec.groupBy.map((g, i) => (
                        <ButtonGroupItem
                            key={i}
                            onClick={() => setSelectedGroupingFunction(g)}
                            selected={selectedGroupingFunction === g}
                        >
                            {g.label}
                        </ButtonGroupItem>
                    ))}
                </ButtonGroup>
            </Flexbox>
            <Chart rfqId={rfqId} keys={chartSpec.keys} data={chartData} onSelectDatum={handleSelectItem} />
        </ChartContainer>
    );
}
