import * as r from 'runtypes';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { DriverIdRuntype } from '../driver';
import { CategoryRuntype, ManufacturingEntityStatusRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';

export const ExpenseCostCalculationTypeRuntype = r.Union(r.Literal('Linear'), r.Literal('Fixed'), r.Literal('Formula'));
export type ExpenseCostCalculationType = r.Static<typeof ExpenseCostCalculationTypeRuntype>;

const LinearVariableCost = r.Record({
    driver: DriverIdRuntype,
    variable_unit: MonetaryValueBackendRuntype,
});

const LinearCostComponentRuntype = r.Record({
    type: r.Literal('Linear'),
    details: r.Record({
        fixed_cost: MonetaryValueBackendRuntype.nullable(),
        variable_cost: LinearVariableCost,
    }),
});

const FormulaCostComponentRuntype = r.Record({
    type: r.Literal('Formula'),
    details: r.Record({
        formula: r.String,
        currency: CurrencyRuntype,
    }),
});

const FixedCostComponentRuntype = r.Record({
    type: r.Literal('Fixed'),
    details: r.Record({
        fixed_cost: MonetaryValueBackendRuntype,
    }),
});

export type CostComponentFixedCost = r.Static<typeof FixedCostComponentRuntype>;
export type CostComponentLinearCost = r.Static<typeof LinearCostComponentRuntype>;
export type CostComponentFormulaCost = r.Static<typeof FormulaCostComponentRuntype>;

export const ExpenseLevelRuntype = r.Union(r.Literal('Project'), r.Literal('Unit'), r.Literal('Batch'));
export const ALL_EXPENSE_LEVELS = ExpenseLevelRuntype.alternatives.map((alternative) => alternative.value);

export type ExpenseLevel = r.Static<typeof ExpenseLevelRuntype>;

//follows a similar structure to TimeComponentsRuntype but for costs
const ExpensesCostComponentRuntype = r.Union(
    FixedCostComponentRuntype,
    LinearCostComponentRuntype,
    FormulaCostComponentRuntype,
);

export type ExpensesCostComponent = r.Static<typeof ExpensesCostComponentRuntype>;

export const ExpenseRuntype = r.Record({
    id: r.String,
    name: r.String,
    internal_number: r.String.nullable(),
    description: r.String.nullable(),
    level: ExpenseLevelRuntype,
    process: ProcessRuntype,
    category: CategoryRuntype,
    cost_components: ExpensesCostComponentRuntype,
    status: ManufacturingEntityStatusRuntype,
    site_id: r.String.nullable(),
});

export type ExpenseDTO = r.Static<typeof ExpenseRuntype>;

export const ExpensePostRuntype = ExpenseRuntype.omit('id', 'status');
export type ExpensePostDTO = r.Static<typeof ExpensePostRuntype>;
