import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { ColumnMap } from '@luminovo/http-client';

export const columnNameTranslations: Record<keyof ColumnMap, MessageDescriptor> = {
    designators: defineMessage({ message: 'Designators' }),
    quantity: defineMessage({ message: 'Quantity' }),
    unit: defineMessage({ message: 'Unit' }),
    dnp: defineMessage({ message: 'Do not place' }),
    manufacturer_free: defineMessage({ message: 'Manufacturer Free' }),
    generic_part_type: defineMessage({ message: 'Generic Part Type' }),
    manufacturer: defineMessage({ message: 'Manufacturer' }),
    mpn: defineMessage({ message: 'MPN' }),
    supplier_part_number: defineMessage({ message: 'Supplier part number' }),
    cpn: defineMessage({ message: 'CPN' }),
    cpn_revision: defineMessage({ message: 'CPN revision' }),
    technical_parameters: defineMessage({ message: 'Tech. Parameters' }),
    notes: defineMessage({ message: 'Notes' }),
    enumeration: defineMessage({ message: 'Enumeration' }),
    ipn: defineMessage({ message: 'IPN' }),
    consigned: defineMessage({ message: 'Consigned' }),
    export_pass_through: defineMessage({ message: 'Copy to BOM export' }),
    level: defineMessage({ message: 'Level' }),
};
