import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    DigikeyIsAuthenticatedDTORuntype,
    DigikeyRedirectUrlDTORuntype,
    DigiKeySettingDTORuntype,
    DigiKeySupportedSettingDTORuntype,
} from './digikeyBackendTypes';

export const digikeyEndpoints = {
    'GET /3rdparty/digikey/is-authenticated': endpoint({
        description: 'Returns whether or not the user is already authenticated with Digikey',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: DigikeyIsAuthenticatedDTORuntype }),
    }),
    'GET /3rdparty/digikey/auth-redirect-uri': endpoint({
        description: 'Redirects user to the Digikey authorization page',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: DigikeyRedirectUrlDTORuntype }),
    }),
    'GET /3rdparty/digikey/acquire-access-token-from-code': endpoint({
        description: 'Returns auth token',
        pathParams: r.Undefined,
        queryParams: r.Record({ auth_code: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),
    'DELETE /3rdparty/digikey/delete-token': endpoint({
        description: 'Deletes digikey token',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),
    'GET /3rdparty/digikey/store-locations': endpoint({
        description: 'Returns possible store locations and currencies',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(DigiKeySupportedSettingDTORuntype),
    }),
    'GET /3rdparty/digikey/settings': endpoint({
        description: 'Returns the settings',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: DigiKeySettingDTORuntype,
    }),
    'PATCH /3rdparty/digikey/settings': endpoint({
        description: 'Updates the settings',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DigiKeySettingDTORuntype,
        responseBody: r.Unknown,
    }),
};
