import { useDialogContext } from '@/components/contexts/ModalContext';
import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useSupplierContactsFromSupplier } from '@/resources/supplierContact/supplierContactHandler';
import { analytics } from '@/utils/analytics';
import { t } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { Dialog, DialogContent, DialogTitle, Flexbox } from '@luminovo/design-system';
import { SupplierContactCreationDTO, SupplierContactDTO } from '@luminovo/http-client';
import {
    FormItemEmail,
    FormItemFirstName,
    FormItemLastName,
    FormItemPosition,
    FormItemUserLanguage,
} from './FormItems';

export function useDialogAddSupplierContact(supplierId: string, onSuccess?: (data: SupplierContactDTO) => void) {
    const { setDialog, closeDialog } = useDialogContext();

    const dialog = (
        <Dialog open={true} onClose={() => closeDialog()}>
            <DialogTitle title={t`Add contact`} handleClose={() => closeDialog()} />
            <DialogContent>
                <AddSupplierContactForm
                    supplierId={supplierId}
                    handleClose={() => closeDialog()}
                    onSuccess={onSuccess}
                />
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => {
            setDialog(dialog);
        },
    };
}

function AddSupplierContactForm({
    supplierId,
    handleClose,
    onSuccess,
}: {
    supplierId: string;
    handleClose: () => void;
    onSuccess?: (data: SupplierContactDTO) => void;
}) {
    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(supplierId);
    const { mutateAsync } = useHttpMutation('POST /supplier-contacts', {
        snackbarMessage: t`Contact successfully created`,
        onSuccess: (res) => {
            analytics.track('add_supplier_contact');
            onSuccess?.(res.data);
            handleClose();
        },
    });

    const defaultValues: SupplierContactCreationDTO = {
        first_name: '',
        last_name: '',
        email: '',
        supplier: supplierId,
        user_language: getLocale(),
        position: null,
        is_main_contact: false,
    };

    const onSubmit = async (form: SupplierContactCreationDTO) => {
        await mutateAsync({ requestBody: form });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection="column" gap={28}>
                <Flexbox gap={32}>
                    <FormItemFirstName />
                    <FormItemLastName />
                </Flexbox>
                <FormItemEmail existingSupplierContacts={supplierContacts} />
                <FormItemUserLanguage />
                <FormItemPosition />
                <Flexbox justifyContent="flex-end" style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                    <SubmitButton label={t`Save contact`} />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}
