import * as r from 'runtypes';
import { LanguageEnumRuntype } from '../../utils/i18n';

export interface SupplierContactDTO extends r.Static<typeof SupplierContactDTORuntype> {}
export const SupplierContactDTORuntype = r.Record({
    id: r.String,
    supplier: r.String,
    first_name: r.String,
    last_name: r.String,
    email: r.String,
    user_language: LanguageEnumRuntype,
    position: r.String.nullable(),
    is_main_contact: r.Boolean,
    is_public: r.Boolean,
    state: r.Union(
        r.Record({ state: r.Literal('Uninvited') }),
        r.Record({ state: r.Literal('Invited') }),
        r.Record({ state: r.Literal('User') }),
    ),
});

export interface SupplierContactCreationDTO extends r.Static<typeof SupplierContactCreationDTORuntype> {}
export const SupplierContactCreationDTORuntype = SupplierContactDTORuntype.omit('id', 'is_public', 'state');

export interface SupplierContactUpdateDTO extends r.Static<typeof SupplierContactUpdateDTORuntype> {}
export const SupplierContactUpdateDTORuntype = SupplierContactDTORuntype.omit(
    'id',
    'is_public',
    'supplier',
    'email',
).asPartial();

export const ValidatedSupplierContactInsert = SupplierContactDTORuntype.omit('id', 'state');
