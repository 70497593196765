import { useNonExcludedSupplierAndStockLocations } from '@/resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useSupplierContacts } from '@/resources/supplierContact/supplierContactHandler';
import { t } from '@lingui/macro';
import { compareByStringKey, formatDecimal, isPresent, transEnum, uniqBy } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    Dialog,
    DialogContent,
    DialogTitle,
    Flexbox,
    StatusChip,
    SupportedFilterFn,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import { SupplierDTO, SupplierPartType, SupplierPreference, SupplierTag } from '@luminovo/http-client';
import {
    formatSupplierDTO,
    hasSupplierTag,
    supplierOriginTranslations,
    supplierPartTypeTranslations,
    supplierPreferenceTranslations,
    supplierTypeTranslations,
} from '@luminovo/sourcing-core';
import { PersonRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { QuoteRequestAssignmentFormValues, QuoteRequestSupplier } from './types';

const columnHelper = createColumnHelper<QuoteRequestSupplier>();

const columns = [
    columnHelper.text((row) => formatSupplierDTO(row), {
        id: 'supplier.name',
        size: 250,
        label: () => t`Supplier name`,
    }),

    columnHelper.text('supplier_number', {
        size: 140,
        label: () => t`Supplier number`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.enum('preference', {
        size: 160,
        label: () => t`Preference`,
        options: [SupplierPreference.Preferred, SupplierPreference.Approved, SupplierPreference.NotApproved],
        getOptionLabel: (option) => transEnum(option, supplierPreferenceTranslations),
        cell: (item) => {
            switch (item.getValue()) {
                case 'Preferred':
                    return <StatusChip color={'primary'} label={t`Preferred`} />;
                case 'Approved':
                    return <StatusChip color={'green'} label={t`Approved`} />;
                case 'NotApproved':
                    return <StatusChip color={'neutral'} label={t`Non-approved`} />;
            }
        },
        quickFilters: [
            {
                label: () => t`Preferred or approved`,
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [SupplierPreference.Preferred, SupplierPreference.Approved],
                },
            },
        ],
    }),
    columnHelper.enum('supplier_part_type', {
        size: 160,
        label: () => t`Part type`,
        options: [SupplierPartType.OffTheShelf, SupplierPartType.Custom, SupplierPartType.Pcb],
        getOptionLabel: (option) => transEnum(option, supplierPartTypeTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierPartTypeTranslations)} />
        ),
    }),
    columnHelper.enum('origin', {
        size: 80,
        label: () => t`Origin`,
        getOptionLabel: (option) => transEnum(option, supplierOriginTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierOriginTranslations)} />
        ),
    }),
    columnHelper.enum((row) => row.supplierAndStockLocations.some((s) => hasSupplierTag(s, SupplierTag.QuotePartner)), {
        id: 'quotePartner',
        size: 80,
        label: () => t`Quote partner`,
        initialVisibility: false,
        description: () =>
            t`Quote partner are pre-qualified distributors that are not connected via API, but easy to request quotes`,
        options: [true, false],
        getOptionLabel: (option) => (option ? t`Yes` : t`No`),
        cell: (item) => (item.getValue() ? <Tag attention="low" color={'teal'} label={t`Quote partner`} /> : null),
        quickFilters: [
            {
                label: () => t`Quote partner`,
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [true],
                },
            },
        ],
    }),
    columnHelper.enum('supplier_type', {
        size: 160,
        label: () => t`Supplier type`,
        getOptionLabel: (option) => transEnum(option, supplierTypeTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierTypeTranslations)} />
        ),
        initialVisibility: false,
    }),
    columnHelper.text((row) => row.supplierContacts.map((c) => `${c.first_name} ${c.last_name} ${c.email}`).join(' '), {
        id: 'supplierContacts',
        size: 100,
        label: () => t`Contacts`,
        enableGlobalFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
            <Tooltip
                variant={'white'}
                title={
                    <>
                        {row.original.supplierContacts.length === 0 && (
                            <Text variant="body-small">{t`No supplier contacts`}</Text>
                        )}
                        {row.original.supplierContacts.length > 0 && (
                            <Flexbox flexDirection={'column'} gap={4}>
                                <Text variant="body-small">{t`Supplier contacts`}</Text>
                                <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                                    {row.original.supplierContacts.map((p) => (
                                        <li key={p.id}>{`${p.first_name} ${p.last_name}`}</li>
                                    ))}
                                </ul>
                            </Flexbox>
                        )}
                    </>
                }
            >
                <span>
                    <Flexbox gap={4} alignItems={'center'}>
                        <PersonRounded fontSize={'small'} style={{ color: colorSystem.neutral[4] }} />
                        {formatDecimal(row.original.supplierContacts.length)}
                    </Flexbox>
                </span>
            </Tooltip>
        ),
    }),
];

export function TableQuoteRequestSupplier({
    context,
}: {
    context: UseFormReturn<QuoteRequestAssignmentFormValues, any>;
}) {
    const { setValue } = context;
    const selectedSuppliers = useWatch({ control: context.control, name: 'selectedQuoteRequestSuppliers' });
    const { data } = useQuoteRequestSuppliers();

    const initialRowSelection = React.useMemo(() => {
        return selectedSuppliers.reduce(
            (acc, supplier) => {
                acc[String(supplier.id)] = true;
                return acc;
            },
            {} as Record<string, boolean>,
        );
    }, [selectedSuppliers]);

    const handleRowSelectionChange = React.useCallback(
        (value: Record<string, boolean>) => {
            const selectedSuppliers = data?.filter((row) => value[row.id]) ?? [];
            setValue('selectedQuoteRequestSuppliers', selectedSuppliers);
        },
        [data, setValue],
    );

    const { table } = useTanStackTable<QuoteRequestSupplier, any>({
        data,
        columns,
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.id,
            onRowSelectionChange: handleRowSelectionChange,
        },
        initialState: {
            rowSelection: initialRowSelection,

            columnFilters: [
                {
                    id: 'preference',
                    value: {
                        filterFn: SupportedFilterFn.equalsAny,
                        value: [SupplierPreference.Preferred, SupplierPreference.Approved],
                    },
                },
            ],
        },
    });

    return (
        <Box height={600} paddingTop={'8px'}>
            <TanStackTable table={table} />
        </Box>
    );
}

export function useQuoteRequestSuppliers(): { data: QuoteRequestSupplier[] | undefined } {
    const { data: suppliersAndStockLocations } = useNonExcludedSupplierAndStockLocations();
    const { data: supplierContacts } = useSupplierContacts();

    return React.useMemo(() => {
        if (!isPresent(supplierContacts)) {
            return {
                data: undefined,
            };
        }

        const convertToQuoteRequestSupplier = (supplier: SupplierDTO, preference: SupplierPreference) => {
            return {
                ...supplier,
                preference,
                supplierContacts: supplierContacts.filter((c) => c.supplier === supplier.id),
                supplierAndStockLocations: suppliersAndStockLocations.filter(
                    (sasl) => sasl.supplier.id === supplier.id,
                ),
            };
        };

        const unfilteredPreferredSuppliers = suppliersAndStockLocations
            .filter((sasl) => sasl.preference === SupplierPreference.Preferred)
            .map((sasl) => convertToQuoteRequestSupplier(sasl.supplier, SupplierPreference.Preferred))
            .sort(compareByStringKey((supplier) => formatSupplierDTO(supplier), { type: 'ascending' }));

        const unfilteredApprovedSuppliers = suppliersAndStockLocations
            .filter((sasl) => sasl.preference === SupplierPreference.Approved)
            .map((sasl) => convertToQuoteRequestSupplier(sasl.supplier, SupplierPreference.Approved))
            .sort(compareByStringKey((supplier) => formatSupplierDTO(supplier), { type: 'ascending' }));

        const unfilteredOtherSuppliers = suppliersAndStockLocations
            .filter((sasl) => sasl.preference === SupplierPreference.NotApproved)
            .map((sasl) => convertToQuoteRequestSupplier(sasl.supplier, SupplierPreference.NotApproved))
            .sort(compareByStringKey((supplier) => formatSupplierDTO(supplier), { type: 'ascending' }));

        const preferredSuppliers = unfilteredPreferredSuppliers;
        const approvedSuppliers = unfilteredApprovedSuppliers.filter(
            (supplier) => !preferredSuppliers.some((s) => s.id === supplier.id),
        );
        const otherSuppliers = unfilteredOtherSuppliers
            .filter((supplier) => !preferredSuppliers.some((s) => s.id === supplier.id))
            .filter((supplier) => !approvedSuppliers.some((s) => s.id === supplier.id));

        const data = uniqBy(
            [...preferredSuppliers, ...approvedSuppliers, ...otherSuppliers],
            (supplier) => supplier.id,
        );

        return { data };
    }, [suppliersAndStockLocations, supplierContacts]);
}

export function useDialogAddQuoteRequestSupplier() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (context: UseFormReturn<QuoteRequestAssignmentFormValues>) =>
            setDialog(
                <Dialog maxWidth="lg" fullWidth open={true} onClose={closeDialog}>
                    <DialogTitle title={'Select suppliers'} handleClose={closeDialog} />
                    <DialogContent>
                        <TableQuoteRequestSupplier context={context} />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
