import { colorSystem, MenuItem, TertiaryIconButton, Text } from '@luminovo/design-system';
import * as icons from '@mui/icons-material';
import { Box, Divider, Menu, styled } from '@mui/material';
import type { IHeaderParams, SortDirection } from 'ag-grid-community';
import { useState } from 'react';
import { useColumnFilter, useColumnSort } from './DataGridContextProvider';
import { MenuItemClearAllValues } from './MenuItemClearAllValues';
import { MenuItemCounts } from './MenuItemCounts';
import { MenuItemFilter } from './MenuItemFilter';
import { MenuItemHideColumn } from './MenuItemHideColumn';

export function HeaderComponent({ displayName, column, setSort, api }: IHeaderParams): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const filter = useColumnFilter<{ filterType: string; type: 'blank' }>(column.getColId());
    const currentSort = useColumnSort(column.getColId());
    const handleClose = () => {
        setAnchorEl(null);
    };
    const iconSize = 20;

    const toggleSort = (): SortDirection => {
        if (currentSort === 'asc') {
            return 'desc';
        }
        if (currentSort === 'desc') {
            return null;
        }
        return 'asc';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                flex: 1,
            }}
        >
            <Menu autoFocus={false} elevation={1} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItemFilter column={column} api={api} />
                <Divider />
                <MenuItemCounts column={column} />
                <MenuItem
                    selected={currentSort === 'asc'}
                    onClick={() => {
                        setSort('asc');
                        handleClose();
                    }}
                    startIcon={<icons.ArrowUpward />}
                    label="Sort A-Z"
                />
                <MenuItem
                    selected={currentSort === 'desc'}
                    onClick={() => {
                        setSort('desc');
                        handleClose();
                    }}
                    startIcon={<icons.ArrowDownward />}
                    label="Sort Z-A"
                />
                {currentSort && (
                    <MenuItem
                        onClick={() => {
                            setSort(null);
                            handleClose();
                        }}
                        label="Clear sort"
                    />
                )}
                {column.getColDef().editable && (
                    <>
                        <Divider />
                        <MenuItemHideColumn onClose={handleClose} column={column} api={api} />
                        <MenuItemClearAllValues onClose={handleClose} column={column} api={api} />
                    </>
                )}
            </Menu>
            <Text
                style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: colorSystem.neutral[9],
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setSort(toggleSort());
                }}
            >
                {displayName}
            </Text>
            <div style={{ flex: 1 }} />

            <icons.ArrowUpward
                onClick={() => setSort(toggleSort())}
                sx={{
                    color: colorSystem.neutral[7],
                    marginLeft: 1,
                    backgroundColor: colorSystem.neutral[1],
                    borderRadius: '50%',
                    padding: '2px',
                    width: iconSize,
                    height: iconSize,
                    opacity: currentSort ? 1 : 0,
                    transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
                    transform: currentSort === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
            />

            <TertiaryIconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                {filter ? (
                    <ActiveFilterIcon
                        style={{
                            width: iconSize,
                            height: iconSize,
                        }}
                    />
                ) : (
                    <DefaultFilterIcon
                        style={{
                            width: iconSize,
                            height: iconSize,
                        }}
                    />
                )}
            </TertiaryIconButton>
            <div style={{ width: 2 }} />
        </Box>
    );
}

const DefaultFilterIcon = styled(icons.FilterListRounded)(() => ({
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.neutral[1],
    borderRadius: '50%',
    color: colorSystem.neutral[7],
    padding: '2px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.neutral[2],
    },
}));

const ActiveFilterIcon = styled(icons.FilterListRounded)(() => ({
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.primary[2],
    color: colorSystem.primary[6],
    borderRadius: '50%',
    padding: '2px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.primary[3],
        color: colorSystem.primary[7],
    },
}));
