import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';

export enum Incoterms {
    EXW = 'EXW',
    FCA = 'FCA',
    CPT = 'CPT',
    CIP = 'CIP',
    DAP = 'DAP',
    DPU = 'DPU',
    DDP = 'DDP',
    FAS = 'FAS',
    FOB = 'FOB',
    CFR = 'CFR',
    CIF = 'CIF',
}

export interface SupplierTermsDTO extends r.Static<typeof SupplierTermsDTORuntype> {}
export const SupplierTermsDTORuntype = r.Record({
    id: r.String,
    supplier: r.String,
    site: r.String.nullable(),
    incoterms: runtypeFromEnum(Incoterms).nullable(),
    incoterms_named_place: r.String.nullable(),
    net_payment_due_days: r.Number.nullable(),
    early_payment_due_days: r.Number.nullable(),
    early_payment_discount_percent: r.Number.nullable(),
});

export interface SupplierTermsCreationDTO extends r.Static<typeof SupplierTermsCreationDTORuntype> {}
export const SupplierTermsCreationDTORuntype = SupplierTermsDTORuntype.omit('id', 'supplier');

export interface SupplierTermsUpdateDTO extends r.Static<typeof SupplierTermsUpdateDTORuntype> {}
export const SupplierTermsUpdateDTORuntype = SupplierTermsDTORuntype.omit('id', 'supplier').asPartial();
