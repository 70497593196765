import { t } from '@lingui/macro';
import { assertPresent, formatToIso8601Date } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import { ExtractRequestBody, QuantityUnit } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';
import { formatError } from '../../Error/formatError';
const getDeliveryDates = (start: string, end: string | undefined): { start: string; end: string } => {
    const startDate = formatToIso8601Date(start);
    const endDate = formatToIso8601Date(end);
    return {
        start: startDate,
        end: end && end.length !== 0 ? endDate : startDate,
    };
};

const formatProductionStartDate = (date: string | undefined): string | undefined => {
    if (date === '' || date === undefined) {
        return undefined;
    }
    return formatToIso8601Date(date);
};

export function DemandImporterPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { data: customers } = useSuspenseHttpQuery(
        'GET /customers',
        {},
        { refetchOnWindowFocus: true, select: (res) => res.data },
    );
    const { data: sites } = useSuspenseHttpQuery(
        'GET /organization-settings/sites',
        {},
        {
            refetchOnWindowFocus: true,
            select: (res) => res.data.sites,
        },
    );
    const { mutateAsync: importDemand } = useHttpMutation('POST /demand/import', { snackbarMessage: null });
    const navigate = useNavigate();
    return (
        <UniversalImporter
            title={t`Import demand`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Import successful`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                navigate(-1);
            }}
            onImportBatch={async (batch, globalFields) => {
                const demandItems: ExtractRequestBody<'POST /demand/import'>['items'] = batch.map((row) => {
                    const { start: delivery_start_date, end: delivery_end_date } = getDeliveryDates(
                        assertPresent(row.data.delivery_start_date),
                        row.data.delivery_end_date ?? undefined,
                    );
                    return {
                        type: 'gross',
                        delivery_start_date,
                        delivery_end_date,
                        internal_part_number: {
                            value: row.data.ipn,
                        },
                        production_start_date: row.data.production_start_date
                            ? formatProductionStartDate(row.data.production_start_date)
                            : undefined,
                        quantity: {
                            quantity: row.data.quantity,
                            unit: QuantityUnit.Pieces,
                        },
                        ship_to_site_id:
                            row.data['ship-to-site-id'].length === 0 ? undefined : row.data['ship-to-site-id'],
                        supplier_site_id:
                            row.data['supplier-site-id'].length === 0 ? undefined : row.data['supplier-site-id'],
                        end_customer_id:
                            row.data['customer-id'].length === 0 || row.data['customer-id'] === '-'
                                ? undefined
                                : row.data['customer-id'],
                    };
                });

                return importDemand({
                    requestBody: {
                        items: demandItems,
                        context:
                            globalFields && globalFields.length > 0 && globalFields[0].value?.id
                                ? globalFields[0].value.id
                                : '',
                    },
                })
                    .then((results) => {
                        return batch.map((batchItem) => {
                            const resultItem = results.find(
                                (resultItem) => batchItem.data.ipn === resultItem.internal_part_number.value,
                            );

                            if (!resultItem) {
                                return {
                                    success: false as const,
                                    message: t`Unknown error`,
                                };
                            }

                            if (resultItem.status > 299) {
                                return {
                                    success: false as const,
                                    message: resultItem.description ?? t`Unknown error`,
                                };
                            }

                            return {
                                success: true as const,
                            };
                        });
                    })
                    .catch((error) => {
                        return batch.map((row) => {
                            return {
                                success: false as const,
                                message: formatError(error),
                            };
                        });
                    });
            }}
            config={{
                globalFields: [
                    {
                        id: 'context' as const,
                        label: t`Context`,
                        description: t`Use context to distinguish between different imports.`,
                        required: true,
                        parser: { type: 'string', options: { trim: true } },
                    },
                ],
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number. Can be for an assembly or a component.`,
                    },
                    {
                        id: 'quantity' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Quantity`,
                    },
                    {
                        id: 'delivery_start_date' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'date', options: { trim: true, required: true } },
                        label: t`Delivery start date`,
                        description: t`The start and end date indicate the time range for which the given quantity is needed. E.g. 2025-01-01 for the start date and 2025-12-31 for the end date indicates an annual demand for 2025. Using the same date for start and end date indicates that the given quantity is needed on that specific date.`,
                    },
                    {
                        id: 'delivery_end_date' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'date', options: { trim: true } },
                        label: t`Delivery end date`,
                        description: t`If not specified, the delivery start date will be used.`,
                    },
                    {
                        id: 'production_start_date' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'date', options: { trim: true } },
                        label: t`Production start date`,
                        description: t`Only used for assembly demands. The production start date will be used as the delivery start date for the calculated demands of components from this assembly (because you need the components a little earlier to finish the assembly and fulfill the delivery start date of the assembly). If no production start date is given, the delivery start date is used for the calculated component demands.`,
                    },
                    {
                        id: 'supplier-site-id' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'site.number-or-name', options: { sites: sites ?? [] } },
                        label: t`Supplier (site)`,
                        description: t`The name or site number of the site that will supply the item. Only used for assembly demands. Can be an internal or external (EMS) site that manufactures the assembly. The supplier site of an assembly will be used as the Ship to site for the calculated component demands (as the site that does the assembly needs to receive the components).`,
                    },
                    {
                        id: 'ship-to-site-id' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'site.number-or-name', options: { sites: sites ?? [] } },
                        label: t`Ship to (site)`,
                        description: t`The name or site number of the site that will receive the item. Can be an internal or external (EMS) site.`,
                    },
                    {
                        id: 'customer-id' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'customer.number-or-name', options: { customers: customers ?? [] } },
                        label: t`End customer`,
                        description: t`The customer number or name for the recipient of the finished good, regardless of any intermediary recipient (like internal or external sites used for assembly).`,
                    },
                ],
            }}
        />
    );
}
