import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SamtecCredentialsDTO extends r.Static<typeof SamtecCredentialsDTORuntype> {}
export const SamtecCredentialsDTORuntype = r.Union(
    r.Record({
        token: r.String,
    }),
);

export interface SamtecCredentialsInputDTO extends r.Static<typeof SamtecCredentialsInputDTORuntype> {}
export const SamtecCredentialsInputDTORuntype = r.Record({
    token: r.String.optional(),
});

export interface SamtecResponseBodyDTO extends r.Static<typeof SamtecResponseBodyDTORuntype> {}
export const SamtecResponseBodyDTORuntype = r.Record({
    data: SamtecCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
