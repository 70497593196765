import { Trans, t } from '@lingui/macro';
import { difference, formatRelativeTime, id, transEnum } from '@luminovo/commons';
import {
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldMultiSelectControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Text,
    colorSystem,
    useNavigate,
} from '@luminovo/design-system';
import { Packaging, PriceType, SourcingPreferenceOptions } from '@luminovo/http-client';
import { packagingTranslations, sourcingPreferenceTranslations } from '@luminovo/sourcing-core';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useOrganizationSolutionPreferences } from '../../../../resources/organizationSettings/organizationSolutionPreferenceHandler';
import { useSourcingScenario } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { analytics } from '../../../../utils/analytics';
import { route } from '../../../../utils/routes';
import { PriceTypeController } from '../../../SourcingScenarioForm/components/SourcingScenarioForm';
import { FormState } from './types';
export function SourcingFiltersSidebar({ sourcingScenarioId }: { sourcingScenarioId: string }) {
    const { data: solutionPreference } = useSourcingScenario(sourcingScenarioId);

    const { mutateAsync } = useHttpMutation('PATCH /sourcing-scenarios/:sourcingScenarioId', {
        snackbarMessage: t`Sourcing scenario updated`,
    });

    if (!solutionPreference) {
        return null;
    }

    // TODO: Nicolas will change the backend type so we can remove the null hack. (2023-05-08 @ Rene)

    const defaultValues: FormState = {
        ...solutionPreference,
        solution_preference: {
            ...solutionPreference.solution_preference,
            preferred_suppliers_and_stock_locations:
                solutionPreference.solution_preference.preferred_suppliers_and_stock_locations ?? [],
        },
    };

    return (
        <FormContainer
            defaultValues={defaultValues}
            onSubmit={(form) =>
                mutateAsync({
                    pathParams: { sourcingScenarioId },
                    requestBody: {
                        name: form.name,

                        solution_preference: form.solution_preference,
                    },
                })
            }
        >
            <Flexbox
                paddingX="16px"
                flexDirection={'column'}
                gap={16}
                style={{
                    background: colorSystem.neutral[1],
                    position: 'relative',
                }}
            >
                <Container>
                    <FormItemLeadTimePreference />
                </Container>

                <Container>
                    <FormItemSupplierPreferences
                        rfqId={solutionPreference.rfq}
                        sourcingScenarioId={sourcingScenarioId}
                    />
                </Container>

                <Container>
                    <FormItemExcludePriceType />
                </Container>

                <Container>
                    <FormItemInventorySitePreferences
                        rfqId={solutionPreference.rfq}
                        sourcingScenarioId={sourcingScenarioId}
                    />
                </Container>

                <Container>
                    <FormItemInventoryPreference />
                </Container>

                <Container>
                    <FormItemPackagingPreference />
                </Container>

                <Container>
                    <FormItemOtherPreference />
                </Container>

                <Flexbox
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={16}
                    style={{
                        position: 'sticky',
                        left: 0,
                        bottom: 0,
                        paddingTop: '0',
                        paddingBottom: 16,
                        boxSizing: 'border-box',
                        background: colorSystem.neutral[1],
                    }}
                >
                    <ApplyChangesButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function FormItemLeadTimePreference(): JSX.Element {
    const { control, setValue, watch } = useFormContext<FormState>();

    const leadTimeDate = watch('solution_preference.lead_time_preference.target');
    const leadTimePrefType = watch('solution_preference.lead_time_preference.type');

    React.useEffect(() => {
        if (leadTimePrefType !== SourcingPreferenceOptions.BestPriceBy) {
            setValue('solution_preference.lead_time_preference.target', undefined, {
                shouldTouch: true,
                shouldValidate: true,
            });
        }
    }, [setValue, leadTimePrefType]);

    return (
        <FormItem label={t`Lead time preference`} LabelProps={{ variant: 'h5' }}>
            <Flexbox flexDirection="column" gap={'4px'}>
                <FieldSelectControlled
                    control={control}
                    name="solution_preference.lead_time_preference.type"
                    FieldProps={{
                        disableClearable: true,
                        options: Object.values(SourcingPreferenceOptions),
                        getOptionLabel: (option) => transEnum(option, sourcingPreferenceTranslations),
                        size: 'small',
                    }}
                />
                {leadTimePrefType === SourcingPreferenceOptions.BestPriceBy && (
                    <FieldDateControlled
                        control={control}
                        name="solution_preference.lead_time_preference.target"
                        required={true}
                        inFuture={true}
                        FieldProps={{ size: 'small' }}
                    />
                )}
                {leadTimeDate && <Text variant={'body'}>{formatRelativeTime(leadTimeDate)}</Text>}
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.use_unit_price"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Optimize for unit price</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function FormItemSupplierPreferences({
    rfqId,
    sourcingScenarioId,
}: {
    rfqId: string;
    sourcingScenarioId: string;
}): JSX.Element {
    const { control } = useFormContext<FormState>();
    const navigate = useNavigate();

    const { data: organizationSolutionPreferences } = useOrganizationSolutionPreferences();

    const preferred_suppliers_and_stock_locations = useWatch({
        name: 'solution_preference.preferred_suppliers_and_stock_locations',
        control,
    });

    const approved_suppliers_and_stock_locations = useWatch({
        name: 'solution_preference.approved_suppliers_and_stock_locations',
        control,
    });

    const preferredSupplierDiff =
        difference(
            organizationSolutionPreferences?.preferred_suppliers_and_stock_locations ?? [],
            preferred_suppliers_and_stock_locations ?? [],
        ).length +
        difference(
            preferred_suppliers_and_stock_locations ?? [],
            organizationSolutionPreferences?.preferred_suppliers_and_stock_locations ?? [],
        ).length;

    const approvedSupplierDiff =
        difference(
            organizationSolutionPreferences?.approved_suppliers_and_stock_locations ?? [],
            approved_suppliers_and_stock_locations ?? [],
        ).length +
        difference(
            approved_suppliers_and_stock_locations ?? [],
            organizationSolutionPreferences?.approved_suppliers_and_stock_locations ?? [],
        ).length;

    const hasDiff = preferredSupplierDiff + approvedSupplierDiff > 0;

    return (
        <FormItem
            label={t`Supplier preferences`}
            LabelProps={{ variant: 'h5' }}
            actions={
                <SecondaryButton
                    size="small"
                    onClick={() => {
                        navigate(
                            route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`, {
                                rfqId,
                                sourcingScenarioId,
                            }),
                        );
                    }}
                >
                    <Trans>Edit</Trans>
                </SecondaryButton>
            }
        >
            <Flexbox flexDirection="column" gap={'4px'}>
                <Text variant="body-semibold">
                    <Trans>{preferredSupplierDiff + approvedSupplierDiff} Edits</Trans>
                </Text>
                {!hasDiff && (
                    <Text variant="caption">
                        <Trans>You did not make any changes to the global supplier preferences for this project.</Trans>
                    </Text>
                )}
            </Flexbox>
        </FormItem>
    );
}

function FormItemInventorySitePreferences({
    rfqId,
    sourcingScenarioId,
}: {
    rfqId: string;
    sourcingScenarioId: string;
}): JSX.Element {
    const { control } = useFormContext<FormState>();
    const navigate = useNavigate();

    const { data: organizationSolutionPreferences } = useOrganizationSolutionPreferences();

    const preferred_inventory_sites = useWatch({
        name: 'solution_preference.preferred_inventory_sites',
        control,
    });

    const approved_inventory_sites = useWatch({
        name: 'solution_preference.approved_inventory_sites',
        control,
    });

    const preferredInventorySiteDiff =
        difference(organizationSolutionPreferences?.preferred_inventory_sites ?? [], preferred_inventory_sites ?? [])
            .length +
        difference(preferred_inventory_sites ?? [], organizationSolutionPreferences?.preferred_inventory_sites ?? [])
            .length;

    const approvedInventorySiteDiff =
        difference(organizationSolutionPreferences?.approved_inventory_sites ?? [], approved_inventory_sites ?? [])
            .length +
        difference(approved_inventory_sites ?? [], organizationSolutionPreferences?.approved_inventory_sites ?? [])
            .length;

    const hasDiff = preferredInventorySiteDiff + approvedInventorySiteDiff > 0;

    return (
        <FormItem
            label={t`Inventory site preferences`}
            LabelProps={{ variant: 'h5' }}
            actions={
                <SecondaryButton
                    size="small"
                    onClick={() => {
                        navigate(
                            route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`, {
                                rfqId,
                                sourcingScenarioId,
                            }),
                        );
                    }}
                >
                    <Trans>Edit</Trans>
                </SecondaryButton>
            }
        >
            <Flexbox flexDirection="column" gap={'4px'}>
                <Text variant="body-semibold">
                    <Trans>{preferredInventorySiteDiff + approvedInventorySiteDiff} Edits</Trans>
                </Text>
                {!hasDiff && (
                    <Text variant="caption">
                        <Trans>
                            You did not make any changes to the global inventory site preferences for this project.
                        </Trans>
                    </Text>
                )}
            </Flexbox>
        </FormItem>
    );
}

function FormItemExcludePriceType(): JSX.Element {
    return (
        <FormItem label={t`Exclude price type`} LabelProps={{ variant: 'h5' }}>
            <Flexbox flexDirection={'column'} gap={8}>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.ListPrice} />
                    <Text variant="body">
                        <Trans>List prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.ContractPrice} />
                    <Text variant="body">
                        <Trans>Contract prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.QuotePrice} />
                    <Text variant="body">
                        <Trans>Quote prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.CustomerNegotiatedPrice} />
                    <Text variant="body">
                        <Trans>Customer negotiated prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.PurchasePrice} />
                    <Text variant="body">
                        <Trans>Purchase prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.StandardPrice} />
                    <Text variant="body">
                        <Trans>Standard prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.TargetPrice} />
                    <Text variant="body">
                        <Trans>Target prices</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function FormItemInventoryPreference(): JSX.Element {
    const { control } = useFormContext<FormState>();

    return (
        <FormItem
            label={t`Which offers should be prioritized?`}
            LabelProps={{ variant: 'h5' }}
            description={t`Given similar offers from your own inventory and the external market, you can choose here which one has more weight. If Neutral is selected, both inventory and market offers will be treated equally. If Market offers is selected, inventory will only be chosen if all other offers are red.`}
        >
            <Flexbox flexDirection={'column'} gap={8}>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'PreferInventory', size: 'small' }}
                    />
                    <Text>
                        <Trans>Inventory</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'Neutral', size: 'small' }}
                    />
                    <Text>
                        <Trans>No prioritization</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'PreferMarketOffers', size: 'small' }}
                    />
                    <Text>
                        <Trans>Market offers</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function FormItemPackagingPreference(): JSX.Element {
    const { control } = useFormContext<FormState>();

    return (
        <FormItem
            label={t`Packaging preference`}
            description={t`Select which packaging types you would like to prefer. If no specific packaging should be preferred, leave the selection empty.`}
            LabelProps={{ variant: 'h5' }}
        >
            <FieldMultiSelectControlled
                control={control}
                name="solution_preference.packaging_preference"
                FieldProps={{
                    size: 'small',
                    getOptionKey: (option) => option,
                    options: Object.values(Packaging),
                    getOptionLabel: (option) => transEnum(option, packagingTranslations),
                }}
            />
        </FormItem>
    );
}

function FormItemOtherPreference(): JSX.Element {
    const { control } = useFormContext<FormState>();

    return (
        <FormItem label={t`Other preferences`} LabelProps={{ variant: 'h5' }}>
            <Flexbox flexDirection={'column'} gap={8}>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.prefer_direct_api_integrations"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Prefer direct API integrations</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.exclude_mismatched_price_points"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Exclude mismatched price points</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.prioritize_preferred_manufacturers"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Prioritize preferred manufacturers</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.prefer_customer_negotiated_prices"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Prioritize customer negotiated prices</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function Container({ children }: React.PropsWithChildren<{}>): JSX.Element {
    return (
        <Flexbox
            flexDirection="column"
            gap={8}
            bgcolor={colorSystem.neutral.white}
            borderRadius={'8px'}
            padding={'16px'}
        >
            {children}
        </Flexbox>
    );
}

function ApplyChangesButton() {
    const { formState } = useFormContext<FormState>();
    const { isDirty } = formState;

    return (
        <SubmitButton
            onClick={() => {
                analytics.track(id('sourcing/apply_sourcing_scenario-changes'));
            }}
            label={t`Apply changes`}
            disabled={!isDirty}
        />
    );
}
