import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface LcscCredentialsDTO extends r.Static<typeof LcscCredentialsDTORuntype> {}
export const LcscCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
        api_secret: r.String,
    }),
);

export interface LcscCredentialsInputDTO extends r.Static<typeof LcscCredentialsInputDTORuntype> {}
export const LcscCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
    api_secret: r.String.optional(),
});

export interface LcscResponseBodyDTO extends r.Static<typeof LcscResponseBodyDTORuntype> {}
export const LcscResponseBodyDTORuntype = r.Record({
    data: LcscCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
