import * as r from 'runtypes';
import { CurrencyRuntype } from '../backendTypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type FarnellLocaleSiteDTO = r.Static<typeof FarnellLocaleSiteDTORuntype>;
const FarnellLocaleSiteDTORuntype = r.Union(
    r.Literal('AT'),
    r.Literal('BE'),
    r.Literal('BG'),
    r.Literal('CH'),
    r.Literal('CZ'),
    r.Literal('DE'),
    r.Literal('DK'),
    r.Literal('EE'),
    r.Literal('ES'),
    r.Literal('FI'),
    r.Literal('FR'),
    r.Literal('HU'),
    r.Literal('IE'),
    r.Literal('IL'),
    r.Literal('IT'),
    r.Literal('LT'),
    r.Literal('LV'),
    r.Literal('NL'),
    r.Literal('NO'),
    r.Literal('PL'),
    r.Literal('PT'),
    r.Literal('RO'),
    r.Literal('RU'),
    r.Literal('SE'),
    r.Literal('SI'),
    r.Literal('SK'),
    r.Literal('TR'),
    r.Literal('UK'),
    r.Literal('EXPORT'),
);

export interface FarnellCredentialsDTO extends r.Static<typeof FarnellCredentialsDTORuntype> {}
export const FarnellCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String.optional(),
        customer_id: r.String.nullable().optional(),
        secret_key: r.String.nullable().optional(),
        store_location: FarnellLocaleSiteDTORuntype,
        list_price_currency: CurrencyRuntype,
        contract_price_currency: CurrencyRuntype,
        status: ApiIntegrationStatusRuntype,
    }),
);

export interface FarnellCredentialsInputDTO extends r.Static<typeof FarnellCredentialsInputDTORuntype> {}
export const FarnellCredentialsInputDTORuntype = r.Record({
    api_key: r.String,
    customer_id: r.String.nullable(),
    secret_key: r.String.nullable(),
    store_location: FarnellLocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
});

export interface FarnellSupportedSettingDTO extends r.Static<typeof FarnellSupportedSettingDTORuntype> {}
export const FarnellSupportedSettingDTORuntype = r.Record({
    store_location: FarnellLocaleSiteDTORuntype,
    list_price_currency: r.Array(CurrencyRuntype),
    contract_price_currency: r.Array(CurrencyRuntype),
});
