/* eslint-disable spellcheck/spell-checker */
import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { colorSystem, Dialog, WarningIcon } from '@luminovo/design-system';
import {
    BomLineBuildingOutput,
    BomLineBuildingOutputRuntype,
    BomScreeningSheet,
    ColumnMap,
    PreviousLinesSearchVariant,
    ScreenerHandlerOutput,
    SingleOriginalExcelRow,
    TaskAcceptedResponseRuntype,
} from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsCustomer } from '../../components/contexts/CurrentUserDetailsContext';
import InformationDialogBox from '../../components/dialogBox/InformationDialogBox';
import { postToLineBuilder } from '../../resources/bomImporter/bomImporterHandler';
import { IssueNameEnum, Level } from '../../resources/bomImporter/bomImporterIssuesEnum';
import { helpHero } from '../../utils/analytics';
import { ViewContext } from '../Bom/components/ModuleTableData';
import {
    ColumnTagsSheetsAction,
    ColumnTagsSheetsState,
} from '../DesignItemDetails/components/AutocompleteColumnTags/types';
import { getValidationErrors } from '../DesignItemDetails/components/AutocompleteColumnTags/validation';
import { BomTable } from '../DesignItemDetails/components/BomTable/BomTable';
import BomImporterLoadingDialog from './BomImporterLoadingDialog';
import { BomImporterNavbar } from './components/BomImporterNavbar';
import { BomImporterScreeningLayout } from './components/BomImporterScreeningLayout';
import { BomImporterSidebar } from './components/BomImporterSidebar';
import { Transition } from './StyledDialog';
import { BomImporterScreeningDialogState, isAssemblyRecognitionError, isScreenerHandlerOutput } from './utils';

export function BomImporterScreeningDialog({
    onClose,
    screeningOutputMultiData,
    goToBomImporterLinesDialog,
    errorAndResetStateCallback,
    tagsState,
    dispatch,
    excelRows,
    taskResponseData,
    setTaskResponseData,
    haveBomImporterLinesChanged,
    setHaveBomImporterLinesChanged,
    columnMap,
    rfqId,
    assemblyId,
    viewContext,
}: {
    screeningOutputMultiData: ScreenerHandlerOutput;
    onClose: () => void;
    goToBomImporterLinesDialog: (data: BomLineBuildingOutput) => void;
    errorAndResetStateCallback: (message: string) => void;
    tagsState: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
    excelRows: SingleOriginalExcelRow[];
    taskResponseData: BomLineBuildingOutput | null;
    setTaskResponseData: React.Dispatch<React.SetStateAction<BomLineBuildingOutput | null>>;
    haveBomImporterLinesChanged: boolean;
    setHaveBomImporterLinesChanged: React.Dispatch<React.SetStateAction<boolean>>;
    columnMap: ColumnMap;
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
}) {
    const sheetIndex = tagsState.selectedSheetIndex;
    const bomScreeningSheet: BomScreeningSheet = screeningOutputMultiData.sheets[sheetIndex];
    const location = useLocation<BomImporterScreeningDialogState | undefined>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [taskEndpoint, setTaskEndpoint] = useState<string | null>(null);
    const [isLoadingSpinnerShown, setIsLoadingSpinnerShown] = useState<boolean>(false);
    const [isErrorsInBomWarningModalOpen, setIsErrorsInBomWarningModalOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (location.state?.outputWithIssues) {
            // bom warning modal opens if there are duplicated designators after trying to import
            setIsErrorsInBomWarningModalOpen(true);
        }
    }, [location.state]);

    const isCustomer = useIsCustomer();

    const outputWithIssues = location.state?.outputWithIssues;
    const validationErrors = getValidationErrors(tagsState, isCustomer);

    const isPreviousLinesSearchColumnsEmpty =
        tagsState.previousLinesSearch.variant === PreviousLinesSearchVariant.SelectedColumns &&
        tagsState.previousLinesSearch.columns.length === 0;
    const isImportDisabled =
        validationErrors.some((error) => error.level === Level.Error) ||
        isPreviousLinesSearchColumnsEmpty ||
        outputWithIssues
            ? true
            : false;

    const lineBuildingTaskFinishedCallback = (data: unknown) => {
        setIsUploading(false);
        setTaskEndpoint(null);
        if (BomLineBuildingOutputRuntype.guard(data)) {
            setTaskResponseData(data);
            goToBomImporterLinesDialog(data);
        }
    };

    const taskCleanUpCallback = () => {
        setTaskEndpoint(null);
        setIsUploading(false);
    };

    const closeErrorsInBomWarningModal = () => {
        setIsErrorsInBomWarningModalOpen(false);
    };

    React.useEffect(() => {
        const associatedTags = tagsState.sheets[sheetIndex].tags.filter((tag) => tag.associatedTo.length > 0);
        const hasMpnTag = associatedTags.some((tag) => tag.id === 'mpn');
        const hasTechnicalParametersTag = associatedTags.some((tag) => tag.id === 'technical_parameters');
        const hasConsignedTag = associatedTags.some((tag) => tag.id === 'consigned');
        // Will only send a request if the properties have changed
        helpHero?.update({ hasMpnTag, hasTechnicalParametersTag, hasConsignedTag });
    }, [tagsState.sheets, sheetIndex]);

    const handleImportBom = useCallback(async () => {
        if (
            !tagsState.hasChanged &&
            taskResponseData !== null &&
            BomLineBuildingOutputRuntype.guard(taskResponseData)
        ) {
            goToBomImporterLinesDialog(taskResponseData);
            return;
        }
        setIsLoadingSpinnerShown(true);
        const lineBuilderTask = await postToLineBuilder({
            excelLines: bomScreeningSheet.excel_lines,
            columnMap,
            fileName: screeningOutputMultiData.file_name,
            sheetName: bomScreeningSheet.sheet_name,
            headerMetaData: bomScreeningSheet.header_metadata,
            rfqId: rfqId,
            token: getToken(),
            importingAssembly: assemblyId,
            previousLinesSearchColumns: tagsState.previousLinesSearch,
        });
        if (TaskAcceptedResponseRuntype.guard(lineBuilderTask)) {
            setIsUploading(true);
            setTaskEndpoint(lineBuilderTask.task_endpoint);
        } else {
            errorAndResetStateCallback(t`Something went wrong during BOM import.`);
        }
        setIsLoadingSpinnerShown(false);
    }, [
        tagsState,
        taskResponseData,
        bomScreeningSheet.excel_lines,
        bomScreeningSheet.sheet_name,
        bomScreeningSheet.header_metadata,
        screeningOutputMultiData.file_name,
        rfqId,
        assemblyId,
        columnMap,
        goToBomImporterLinesDialog,
        errorAndResetStateCallback,
    ]);

    const screeningOutputWithIssues = React.useMemo(() => {
        if (isScreenerHandlerOutput(outputWithIssues)) {
            return outputWithIssues;
        }
    }, [outputWithIssues]);

    const bomScreeningOutput = React.useMemo(() => {
        if (screeningOutputWithIssues) {
            return screeningOutputWithIssues;
        }
        return screeningOutputMultiData;
    }, [screeningOutputWithIssues, screeningOutputMultiData]);

    const assemblyRecognitionErrorMessage = React.useMemo(() => {
        if (!isAssemblyRecognitionError(outputWithIssues)) return undefined;
        const errorType = outputWithIssues.error_type;

        if (errorType === 'UnreachableAssemblyState') {
            return (
                <>
                    <Trans>We failed to recognize the structure in the Multi level BOM</Trans>
                    {outputWithIssues.context ?? ''}
                </>
            );
        }
        if (errorType === 'UnrecognizedTopLevelAssembly') {
            return (
                <>
                    <Trans>We failed to recognize the top level assembly in the Multilevel BOM</Trans>{' '}
                    {outputWithIssues.context ?? ''}
                </>
            );
        }
    }, [outputWithIssues]);

    const hasDuplicatedDesignators = React.useMemo(() => {
        return bomScreeningOutput.sheets[sheetIndex].excel_lines.some((row) =>
            row.issues.some((issue) => issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND),
        );
    }, [bomScreeningOutput.sheets, sheetIndex]);

    const errorCount =
        validationErrors.filter((error) => error.level === Level.Error).length + (hasDuplicatedDesignators ? 1 : 0);

    const hasLevelTagAssigned = tagsState.sheets[sheetIndex].tags.some(
        (tag) => tag.id === 'level' && tag.associatedTo.length > 0,
    );

    return (
        <>
            {taskEndpoint && isUploading && (
                <BomImporterLoadingDialog
                    taskFinishedCallback={lineBuildingTaskFinishedCallback}
                    taskEndpoint={taskEndpoint}
                    viewContext={viewContext}
                    taskCleanUpCallback={taskCleanUpCallback}
                    defaultMessage={t`Extracting BOM items... This could take a few minutes.`}
                    rfqId={rfqId}
                    dispatch={dispatch}
                    assemblyId={assemblyId}
                />
            )}
            <Dialog
                fullScreen
                maxWidth={'md'}
                open={true}
                onClose={(_, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose();
                    }
                }}
                TransitionComponent={Transition}
                disableEscapeKeyDown={true}
                disableEnforceFocus={true}
                PaperProps={{ style: { backgroundColor: colorSystem.neutral[1], overflow: 'hidden' } }}
            >
                <BomImporterScreeningLayout
                    navbar={
                        <BomImporterNavbar
                            onClose={onClose}
                            handleImportBom={handleImportBom}
                            isReusedColumnMapping={bomScreeningSheet.reused_column_map}
                            isImportDisabled={isImportDisabled}
                            isLoadingSpinnerShown={isLoadingSpinnerShown}
                            fileName={bomScreeningOutput.file_name}
                            hasLevelTagAssigned={hasLevelTagAssigned}
                        />
                    }
                    sidebarTitle={t`BOM import details`}
                    sidebar={
                        <BomImporterSidebar
                            bomScreeningOutput={bomScreeningOutput}
                            sheetIndex={screeningOutputWithIssues ? 0 : sheetIndex}
                            validationErrors={validationErrors}
                            tagsState={tagsState}
                            dispatch={dispatch}
                        />
                    }
                    badgeCount={errorCount}
                >
                    <BomTable
                        excelRows={
                            screeningOutputWithIssues ? screeningOutputWithIssues.sheets[0].excel_lines : excelRows
                        }
                        headerRowJson={bomScreeningSheet.header_metadata?.raw_header_row}
                        columnTags={{
                            tagsState,
                            dispatch,
                        }}
                        haveBomImporterLinesChanged={haveBomImporterLinesChanged}
                        setHaveBomImporterLinesChanged={setHaveBomImporterLinesChanged}
                    />
                    <InformationDialogBox
                        title={
                            <Box display={'flex'} alignItems={'center'}>
                                <WarningIcon height={24} width={24} style={{ paddingRight: 8 }} />
                                <Trans>BOM contains errors</Trans>
                            </Box>
                        }
                        isDialogOpen={isErrorsInBomWarningModalOpen}
                        onReject={closeErrorsInBomWarningModal}
                        buttonText={'OK'}
                    >
                        <Typography>
                            <Trans>
                                You cannot edit or import the BOM because it contains errors. Please fix the original
                                file and upload it again.
                            </Trans>
                            {assemblyRecognitionErrorMessage}
                        </Typography>
                    </InformationDialogBox>
                </BomImporterScreeningLayout>
            </Dialog>
        </>
    );
}
