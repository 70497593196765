import { compareByString } from '@luminovo/commons';
import { SolutionConfigurationSourcingDTO } from '@luminovo/http-client';
import { extractAggregatedBreadcrumbs } from '@luminovo/sourcing-core';
import React from 'react';
import { ViewCommentsButton, useCommunicationsDrawer } from '../../../../components/CommentsDrawer';
import { DesignItemPath } from '../../../Sourcing/components/SolutionConfigurations/SolutionConfigurationTypes2';

export const SolutionManagerCommentsButton = React.memo(function SolutionManagerCommentsButton({
    solutionConfigurationSourcing,
    iconButtonOnly,
    size,
    rfqId,
}: {
    solutionConfigurationSourcing: SolutionConfigurationSourcingDTO;
    iconButtonOnly?: boolean;
    size?: 'small' | 'medium' | 'large';
    rfqId: string;
}) {
    const designItemIds = solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items.map(
        (item) => item.design_item_id,
    );
    const groupedDesignators = extractAggregatedBreadcrumbs(solutionConfigurationSourcing);

    const { openDrawer } = useCommunicationsDrawer({
        threads: Object.entries(groupedDesignators ?? {}).flatMap(([key, value]) => {
            const parentAssemblies = value[0].parent_assemblies.map((pa) => pa.name).join(' > ');
            const designators = value
                .map((val) => val.designator)
                .sort(compareByString)
                .join(', ');

            return [
                {
                    commentType: 'DesignItem',
                    category: 'Internal',
                    typeIds: value.map((val) => val.design_item_id),
                    group: {
                        label: `${parentAssemblies} > ${designators}`,
                        groupId: key,
                    },
                    rfqId,
                },
                {
                    commentType: 'DesignItem',
                    category: 'Public',
                    typeIds: value.map((val) => val.design_item_id),
                    group: {
                        label: `${parentAssemblies} > ${designators}`,
                        groupId: key,
                    },
                    rfqId,
                },
            ];
        }),
    });

    return (
        <ViewCommentsButton
            eventEntity={{ type: 'DesignItem', data: designItemIds }}
            onClick={() => openDrawer()}
            iconButtonOnly={iconButtonOnly}
            size={size}
        />
    );
});

export const SolutionConfigurationCommentsButton = React.memo(function SolutionManagerCommentsButton({
    rfqId,
    designItemIds,
    designItemPaths,
    iconButtonOnly,
    size,
}: {
    rfqId: string;
    designItemIds: string[];
    designItemPaths: DesignItemPath[];
    iconButtonOnly?: boolean;
    size?: 'small' | 'medium' | 'large';
}) {
    const { openDrawer } = useCommunicationsDrawer({
        threads: designItemPaths.flatMap((designItemPath) => {
            const assemblyPath = designItemPath.path;
            const designators = designItemPath.designators.sort(compareByString).join(', ');

            const designItemIds = designItemPath.designItemIds;

            return [
                {
                    rfqId,
                    commentType: 'DesignItem',
                    category: 'Internal',
                    typeIds: designItemIds,
                    group: {
                        label: `${assemblyPath} > ${designators}`,
                        groupId: assemblyPath,
                    },
                },
                {
                    rfqId,
                    commentType: 'DesignItem',
                    category: 'Public',
                    typeIds: designItemIds,
                    group: {
                        label: `${assemblyPath} > ${designators}`,
                        groupId: assemblyPath,
                    },
                },
            ];
        }),
    });

    return (
        <ViewCommentsButton
            eventEntity={{ type: 'DesignItem', data: designItemIds }}
            onClick={() => openDrawer()}
            iconButtonOnly={iconButtonOnly}
            size={size}
        />
    );
});
