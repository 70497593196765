import { PageLayout } from '../../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import {
    useSourcingCalculation,
    useSourcingFull,
    useSourcingScenario,
} from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams } from '../../../../utils/routes';
import SolutionConfigurations from './SolutionConfigurations';

export function SolutionConfigurationPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId'>) {
    const { rfqId, sourcingScenarioId } = pathParams;
    const { assemblyId } = queryParams;

    const { data: sourcingScenarioDTO, isLoading: isLoadingSourcingScenario } = useSourcingScenario(sourcingScenarioId);
    const { data: fullSourcingDTO, isLoading: isLoadingSourcingFull } = useSourcingFull(sourcingScenarioId);

    if (isLoadingSourcingScenario || isLoadingSourcingFull) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }

    if (!fullSourcingDTO || !sourcingScenarioDTO) {
        return null;
    }

    return (
        <SolutionConfigurations
            rfqId={rfqId}
            sourcingScenarioDTO={sourcingScenarioDTO}
            sourcingCalculationDTO={{ type: 'old', data: fullSourcingDTO }}
            filteredAssemblyId={assemblyId ?? undefined}
        />
    );
}

export function SolutionConfigurationPage2({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId'>) {
    const { rfqId, sourcingScenarioId } = pathParams;
    const { assemblyId } = queryParams;

    const { data: sourcingScenarioDTO, isLoading: isLoadingSourcingScenario } = useSourcingScenario(sourcingScenarioId);
    const { data: sourcingCalculationDTO, isLoading: isLoadingSourcingCalculation } =
        useSourcingCalculation(sourcingScenarioId);

    if (isLoadingSourcingScenario || isLoadingSourcingCalculation) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }

    if (!sourcingCalculationDTO || !sourcingScenarioDTO) {
        return null;
    }

    return (
        <SolutionConfigurations
            rfqId={rfqId}
            sourcingScenarioDTO={sourcingScenarioDTO}
            sourcingCalculationDTO={{ type: 'new', data: sourcingCalculationDTO }}
            filteredAssemblyId={assemblyId ?? undefined}
        />
    );
}
