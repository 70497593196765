import { Trans, t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import {
    Flexbox,
    Link,
    SecondaryButton,
    TertiaryIconButton,
    Text,
    colorSystem,
    useNavigate,
} from '@luminovo/design-system';
import { DetailedCustomPartOffers, SupplierPreference } from '@luminovo/http-client';
import { Launch, WarningRounded } from '@mui/icons-material';
import { Box, Tooltip, styled } from '@mui/material';
import React from 'react';
import { convertDataForPcbAnalytics } from '../../../../../resources/pcb/analytics/analytic';
import { priceRadarErrorsTranslations } from '../../../../../resources/pcb/i18n';
import { useForcedOfferUpdate } from '../../../../../resources/rfq/rfqHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { analytics } from '../../../../../utils/analytics';
import { route } from '../../../../../utils/routes';
import { CollapsibleSection } from '../../CollapsibleSection';
import { convertOffersToSupplierData } from '../utils/convertOffersToSupplierData';
import { PcbSupplierOffer } from '../utils/pcbSupplierOfferType';
import { OfferChip } from './OfferChip';

const commonStyle: React.CSSProperties = {
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
};

const StyledBox = styled(Box)({
    ...commonStyle,
    background: colorSystem.neutral.white,
});

const WarningStyleBox = styled(Box)({
    ...commonStyle,
    background: colorSystem.yellow[1],
    border: `2px solid ${colorSystem.yellow[3]}`,
});

// All, instant & manual request offers
const PcbSuppliersOfferList = ({ suppliers, showFlag }: { suppliers: PcbSupplierOffer[]; showFlag?: boolean }) => {
    return (
        <Flexbox gap={8} flexWrap={'wrap'}>
            {suppliers.map((supplier, index) => (
                <OfferChip key={index} supplier={supplier} showFlag={showFlag} />
            ))}
        </Flexbox>
    );
};

const OutdatedPcbSuppliersList = ({ suppliers, rfqId }: { suppliers: PcbSupplierOffer[]; rfqId: string }) => {
    const { mutateAsync, isPending: isUpdatingOffers } = useForcedOfferUpdate(rfqId);
    const handleRefreshOffers = async () => {
        if (isUpdatingOffers) {
            return;
        }
        await mutateAsync();
    };

    return (
        <CollapsibleSection
            label={t`Some suppliers are not evaluated`}
            labelIcon={<WarningRounded fontSize="small" style={{ color: colorSystem.yellow[7] }} />}
            ContentStyle={{
                width: '85%',
                padding: '8px 10px 24px 44px',
            }}
        >
            <Text variant="body">
                <Trans>
                    Supplier preferences were updated. There suppliers are not considered for the project yet:
                </Trans>
            </Text>

            <PcbSuppliersOfferList suppliers={suppliers} />

            <SecondaryButton size="small" onClick={handleRefreshOffers}>
                <Trans>Refresh offers</Trans>
            </SecondaryButton>
        </CollapsibleSection>
    );
};

const InstantOfferPcbSuppliersList = ({
    suppliers,
    rfqId,
    assemblyId,
    pcbId,
}: {
    suppliers: PcbSupplierOffer[];
    rfqId: string;
    assemblyId: string;
    pcbId: string;
}) => {
    const handleInstantOfferClick = () => {
        analytics.track(
            'click_instant_offers',
            convertDataForPcbAnalytics({
                rfqId,
                assemblyId,
                pcbId,
            }),
        );
        window.open(route('/rfqs/:rfqId/sourcing', { rfqId }), '_blank', 'noopener noreferrer');
    };

    return (
        <CollapsibleSection
            label={t`Instant offers`}
            description={t`Suppliers are capable to produce the PCB and instant prices are available`}
            tooltipProps={{ variant: 'dark' }}
            action={
                <Tooltip title={t`Go to sourcing`} placement="bottom-start" arrow>
                    <TertiaryIconButton size="small" onClick={handleInstantOfferClick}>
                        <Launch fontSize="small" />
                    </TertiaryIconButton>
                </Tooltip>
            }
        >
            <PcbSuppliersOfferList suppliers={suppliers} />
        </CollapsibleSection>
    );
};

const InteractivePcbSuppliersList = ({
    sectionLabel,
    sectionDescription,
    sectionCaption,
    suppliers,
    rfqId,
    assemblyId,
    pcbId,
}: {
    sectionLabel: string;
    sectionDescription: string;
    sectionCaption?: React.ReactNode;
    suppliers: PcbSupplierOffer[];
    rfqId: string;
    assemblyId: string;
    pcbId: string;
}) => {
    const [selectedSupplier, setSelectedSupplier] = React.useState<PcbSupplierOffer | null>(null);

    const isSelected = (supplier: PcbSupplierOffer) => {
        return selectedSupplier !== null && selectedSupplier.id === supplier.id;
    };
    const selectedSupplierViolations = selectedSupplier?.error ? selectedSupplier.error : undefined;

    const handleSupplierClick = (supplier: PcbSupplierOffer) => {
        setSelectedSupplier((initialSupplier) => {
            if (initialSupplier === null || initialSupplier.id !== supplier.id) {
                return supplier;
            }
            return null;
        });
        analytics.track(
            'view_supplier_violations',
            convertDataForPcbAnalytics({
                rfqId,
                assemblyId,
                pcbId,
            }),
        );
    };

    React.useEffect(() => {
        /**
         * Whenever there is a change to the suppliers list, we want to reset the selected supplier
         * This is necessary because the selected supplier might not be in the new list anymore
         */
        setSelectedSupplier(null);
    }, [suppliers]);

    return (
        <CollapsibleSection
            label={sectionLabel}
            description={sectionDescription}
            tooltipProps={{ variant: 'dark' }}
            ContentStyle={{ paddingBlockStart: 0 }}
        >
            {sectionCaption}
            <Flexbox gap={8} flexWrap={'wrap'}>
                {suppliers.map((supplier, index) => (
                    <OfferChip
                        key={index}
                        supplier={supplier}
                        onClick={() => handleSupplierClick(supplier)}
                        style={{
                            cursor: 'pointer',
                            border:
                                isSelected(supplier) === true
                                    ? `1px solid ${colorSystem.red[3]}`
                                    : `1px solid ${colorSystem.neutral[3]}`,
                            background: isSelected(supplier) === true ? colorSystem.red[1] : undefined,
                        }}
                    />
                ))}
            </Flexbox>

            {selectedSupplierViolations !== undefined && (
                <ul
                    style={{
                        margin: 0,
                        paddingInlineStart: '16px',
                    }}
                >
                    {typeof selectedSupplierViolations !== 'string' ? (
                        selectedSupplierViolations.map((error, index) => (
                            <li key={index} style={{ marginBlockEnd: '16px' }}>
                                <Text
                                    variant="body-small"
                                    style={{
                                        color: colorSystem.red[7],
                                        background: colorSystem.neutral[1],
                                        border: `1px solid ${colorSystem.neutral[2]}`,
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginInlineEnd: '1ch',
                                    }}
                                >
                                    {transEnum(error.name, priceRadarErrorsTranslations)}
                                </Text>
                                <Text variant="body-small">{error.error}</Text>
                            </li>
                        ))
                    ) : (
                        <li>
                            <Text variant="body-small">{selectedSupplierViolations}</Text>
                        </li>
                    )}
                </ul>
            )}
        </CollapsibleSection>
    );
};

export const PcbDetailedOffers = ({
    offers,
    rfqId,
    assemblyId,
    pcbId,
}: {
    offers: DetailedCustomPartOffers;
    rfqId: string;
    assemblyId: string;
    pcbId: string;
}) => {
    const navigate = useNavigate();
    const { data: suppliersAndStockLocations } = useNonExcludedSupplierAndStockLocations();
    const approvedSupplierIds: string[] = suppliersAndStockLocations
        .filter((s) => s.preference === SupplierPreference.Approved || s.preference === SupplierPreference.Preferred)
        .map((s) => s.supplier.id);

    const suppliers = convertOffersToSupplierData(
        offers.data.filter((data) => data.status.type !== 'Pending'),
        approvedSupplierIds,
    );
    const settledSuppliers = suppliers.filter(({ status }) => status !== 'neutral');

    const instantOfferSuppliers = settledSuppliers.filter(({ status }) => status === 'green');
    const manualRequestSuppliers = settledSuppliers.filter(({ status }) => status === 'yellow');
    const notCapableSuppliers = settledSuppliers.filter(({ status }) => status === 'red');
    const needRefresh = suppliers.filter(({ status }) => status === 'neutral');

    return (
        <>
            {needRefresh.length > 0 && (
                <WarningStyleBox>
                    <OutdatedPcbSuppliersList suppliers={needRefresh} rfqId={rfqId} />
                </WarningStyleBox>
            )}

            <>
                {instantOfferSuppliers.length > 0 && (
                    <StyledBox>
                        <InstantOfferPcbSuppliersList
                            suppliers={instantOfferSuppliers}
                            rfqId={rfqId}
                            assemblyId={assemblyId}
                            pcbId={pcbId}
                        />
                    </StyledBox>
                )}

                {manualRequestSuppliers.length > 0 && (
                    <StyledBox>
                        <InteractivePcbSuppliersList
                            sectionLabel={t`Manual requests`}
                            sectionDescription={t`Suppliers are capable to produce that PCB but we are not getting any instant prices. Use the manual quote workflow to request prices`}
                            suppliers={manualRequestSuppliers}
                            rfqId={rfqId}
                            assemblyId={assemblyId}
                            pcbId={pcbId}
                        />
                    </StyledBox>
                )}

                {notCapableSuppliers.length > 0 && (
                    <StyledBox>
                        <InteractivePcbSuppliersList
                            sectionLabel={t`Not capable`}
                            sectionDescription={t`These suppliers are either not capable to produce this PCB or simply cannot offer prices via API for it. Click on the manufacturer name to see more details.`}
                            sectionCaption={
                                <Text variant="caption">
                                    <Trans>Click on manufacturers name to see their violations</Trans>
                                </Text>
                            }
                            suppliers={notCapableSuppliers}
                            rfqId={rfqId}
                            assemblyId={assemblyId}
                            pcbId={pcbId}
                        />
                    </StyledBox>
                )}
            </>

            <Text variant="caption" style={{ color: colorSystem.neutral[6], width: '65%' }}>
                <Trans>
                    Only approved suppliers can be displayed here. Visit{' '}
                    <Link variant="caption" onClick={() => navigate(route('/suppliers'))}>
                        suppliers page
                    </Link>{' '}
                    to make any changes.
                </Trans>
            </Text>
        </>
    );
};
