import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { VenkelCredentialsInputDTORuntype, VenkelResponseBodyDTORuntype } from './venkelBackendTypes';

export const venkelEndpoints = {
    'GET /3rdparty/venkel/credentials': endpoint({
        description: 'Returns credentials for venkel integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: VenkelResponseBodyDTORuntype,
    }),
    'POST /3rdparty/venkel/credentials': endpoint({
        description: 'Uploads credentials for venkel API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: VenkelCredentialsInputDTORuntype,
        responseBody: VenkelResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/venkel/credentials'],
    }),
    'PATCH /3rdparty/venkel/credentials': endpoint({
        description: 'Updates credentials for venkel API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: VenkelCredentialsInputDTORuntype,
        responseBody: VenkelResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/venkel/credentials'],
    }),
    'DELETE /3rdparty/venkel/credentials': endpoint({
        description: 'Deletes existing credentials for venkel API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/venkel/credentials'],
    }),
};
