import { Trans, t } from '@lingui/macro';
import {
    compareByDate,
    compareByStringKey,
    formatMonetaryValue,
    formatToLongDate,
    isPresent,
    transEnum,
    uniq,
} from '@luminovo/commons';
import {
    Flexbox,
    SecondaryButton,
    TanStackTable,
    Text,
    Tooltip,
    chainComparators,
    createColumnHelper,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    AssemblyResponseDTO,
    AssemblySourcingSummaryDTO,
    SolutionStatus,
    SourcingCalculationDTO,
    SourcingPreferenceOptions,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import {
    formatAvailability,
    formatSolutionStatus,
    leadTimeDaysExtractor,
    sourcingPreferenceTranslations,
} from '@luminovo/sourcing-core';
import { Add, Circle } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { LabelScenarioOrderSize } from '../../../../../components/LabelScenarioOrderSize';
import { isTotalCostOfOwnershipEnabled } from '../../../../../featureFlags';
import { useAssemblies } from '../../../../../resources/assembly/assemblyHandler';
import { calculateTotalOrderSize } from '../../../../../resources/sourcingScenario/calculateTotalOrderSize';
import {
    useSourcingCalculationBulk,
    useSourcingScenarios,
} from '../../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { assertPresent } from '../../../../../utils/assertPresent';
import { route } from '../../../../../utils/routes';
import { StatusSummaries } from '../../SourcingScenarios/StatusSummaries';
import { AdditionalTotalAndUnitCostTooltip } from './components/AdditionalTotalAndUnitCostTooltip';
import { AssemblyTitle } from './components/AssemblyTitle';
import { AssemblyCentricTableType, SourcingScenarioTableSharedContext } from './utils/types';

const columnHelper = createColumnHelper<AssemblyCentricTableType, SourcingScenarioTableSharedContext>();

const columns = [
    columnHelper.text((row) => (row.type === 'subRow' ? row.data.sourcingScenario.name : row.assembly.designator), {
        id: 'name',
        size: 160,
        label: () => t`Name`,
        cell: ({ row }) => {
            if (row.original.type === 'groupRow') {
                return <AssemblyTitle assembly={row.original.assembly} />;
            } else
                return (
                    <Flexbox alignItems="center" style={{ paddingInlineStart: '16px' }} gap={'8px'}>
                        <Circle style={{ fontSize: '4px' }} />
                        {row.original.data.sourcingScenario.name}
                    </Flexbox>
                );
        },
    }),
    columnHelper.number(
        (row) => (row.type === 'subRow' ? calculateTotalOrderSize(row.data.sourcingScenario) : undefined),
        {
            id: 'orderSize',
            size: 100,
            label: () => t`Order size`,
            cell: ({ row }) =>
                row.original.type === 'groupRow' ? (
                    <></>
                ) : (
                    <LabelScenarioOrderSize
                        sourcingScenario={row.original.data.sourcingScenario}
                        assemblyId={row.original.assembly.id}
                    />
                ),
        },
    ),
    columnHelper.enum(
        (row) =>
            row.type === 'subRow' ? row.data.sourcingScenario.solution_preference.lead_time_preference.type : undefined,
        {
            id: 'LeadTimePreference',
            size: 120,
            label: () => t`Lead time preference`,
            options: [
                SourcingPreferenceOptions.Fastest,
                SourcingPreferenceOptions.BestPrice,
                SourcingPreferenceOptions.BestPriceBy,
            ],
            getOptionLabel: (opt) => transEnum(opt, sourcingPreferenceTranslations),
            cell: ({ row }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    const { type, target } =
                        row.original.data.sourcingScenario.solution_preference.lead_time_preference;
                    const bestDateBy =
                        type === SourcingPreferenceOptions.BestPriceBy && target ? formatToLongDate(target) : '';

                    return (
                        <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block' }}>
                            {`${transEnum(type, sourcingPreferenceTranslations)} ${bestDateBy}`.trim()}
                        </Text>
                    );
                }
            },
            sortingFn: chainComparators(
                compareByStringKey((row) =>
                    row.original.type === 'groupRow'
                        ? ''
                        : transEnum(
                              row.original.data.sourcingScenario.solution_preference.lead_time_preference.type,
                              sourcingPreferenceTranslations,
                          ),
                ),
                compareByDate((row) =>
                    row.original.type === 'groupRow'
                        ? ''
                        : (row.original.data.sourcingScenario.solution_preference.lead_time_preference.target ?? ''),
                ),
            ),
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'subRow' ? row.data.assemblySourcingInformation?.unit_price : undefined),
        {
            id: 'unitPrice',
            size: 100,
            label: () => t`Unit price`,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    return !isPresent(row.original.data.assemblySourcingInformation) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'subRow' ? row.data.assemblySourcingInformation?.total_one_time_costs : undefined),
        {
            id: 'OneTimeCosts',
            size: 100,
            label: () => t`One-time costs`,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    return !isPresent(row.original.data.assemblySourcingInformation) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'subRow' ? row.data.assemblySourcingInformation?.total_price : undefined),
        {
            id: 'totalPrice',
            size: 100,
            label: () => t`Total price`,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    return !isPresent(row.original.data.assemblySourcingInformation) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'subRow' ? row.data.assemblySourcingInformation?.total_excess_material : undefined),
        {
            id: 'excessMaterial',
            size: 100,
            label: () => t`Excess material`,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    return !isPresent(row.original.data.assemblySourcingInformation) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),
    columnHelper.number(
        (row) =>
            row.type === 'subRow'
                ? leadTimeDaysExtractor(row.data?.assemblySourcingInformation?.availability ?? null)
                : undefined,
        {
            id: 'leadTime',
            label: () => t`Lead time`,
            size: 90,
            cell: ({ row }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    return !isPresent(row.original.data.assemblySourcingInformation) ? (
                        <Skeleton />
                    ) : (
                        formatAvailability(row.original.data.assemblySourcingInformation.availability)
                    );
                }
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'subRow' ? row.data.assemblySourcingInformation?.total_scrap_costs : undefined),
        {
            id: 'totalScrapCosts',
            size: 100,
            label: () => t`Scrap costs`,
            initialVisibility: false,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'groupRow') {
                    return <></>;
                } else {
                    return !isPresent(row.original.data.assemblySourcingInformation) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'subRow' ? row.data.assemblySourcingInformation?.total_tco_costs : undefined),
        {
            id: 'additionalCosting',
            size: 100,
            initialVisibility: isTotalCostOfOwnershipEnabled(),
            enableHiding: isTotalCostOfOwnershipEnabled(),
            label: () => t`Additional cost`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow') {
                    return <></>;
                }

                if (!isPresent(item.row.original.data.assemblySourcingInformation)) return <Skeleton />;

                const totalAdditionalCosts = item.row.original.data.assemblySourcingInformation?.total_tco_costs.total;
                const unitAdditionalCosts = item.row.original.data.assemblySourcingInformation?.total_tco_costs.unit;

                return (
                    <Tooltip
                        title={
                            <AdditionalTotalAndUnitCostTooltip
                                totalAdditionalCosts={totalAdditionalCosts}
                                unitAdditionalCosts={unitAdditionalCosts}
                            />
                        }
                        variant="white"
                        disableMaxWidth={true}
                    >
                        <span>
                            {formatMonetaryValue(totalAdditionalCosts?.cost?.preferred, 'default', { ifAbsent: '' })}
                        </span>
                    </Tooltip>
                );
            },
        },
    ),
    columnHelper.array(
        (row) =>
            row.type === 'subRow'
                ? uniq([
                      ...Array(row.data.sourcingCalculation?.status_count.number_of_ok).fill(SolutionStatus.Good),
                      ...Array(row.data.sourcingCalculation?.status_count.number_of_warning).fill(
                          SolutionStatus.Warning,
                      ),
                      ...Array(row.data.sourcingCalculation?.status_count.number_of_error).fill(SolutionStatus.Error),
                  ])
                : undefined,
        {
            id: 'solutionStatus',
            size: 180,
            align: 'center',
            enableSorting: false,
            enableOnRowClick: false,
            enableHiding: false,
            options: Object.values(SolutionStatus),
            getOptionLabel: (status) => formatSolutionStatus(status),
            label: () => t`Solution status`,
            cell: ({ row }) => {
                if (row.original.type === 'groupRow') {
                    if (isPresent(row.original.assembly.rfq) && row.original.assembly.type.type === 'PcbOnly') {
                        return (
                            <AssemblyButtonLink
                                assemblyId={row.original.assembly.id}
                                rfqId={row.original.assembly.rfq}
                            />
                        );
                    } else {
                        return <></>;
                    }
                } else {
                    return !isPresent(row.original.data.sourcingCalculation) ? (
                        <Skeleton />
                    ) : (
                        <StatusSummaries sourcingCalculation={row.original.data.sourcingCalculation} />
                    );
                }
            },
        },
    ),
];

const AssemblyButtonLink = ({ assemblyId, rfqId }: { assemblyId: string; rfqId: string }) => {
    const navigate = useNavigate();
    return (
        <SecondaryButton
            onClick={() => {
                navigate(
                    route('/rfqs/:rfqId/sourcing/assembly/:assemblyId', {
                        rfqId,
                        assemblyId,
                    }),
                );
            }}
            size="small"
        >
            <Trans>Go to part view</Trans>
        </SecondaryButton>
    );
};

export type AssemblyWithSourcingScenarios = AssemblyResponseDTO & {
    sourcingScenarios: SourcingScenarioDTO[];
};

export const useGroupScenariosByAssembly = (
    sourcingScenarioIds: string[],
): {
    isLoading: boolean;
    data: AssemblyWithSourcingScenarios[] | undefined;
} => {
    const group = new Map<string, SourcingScenarioDTO[]>();
    const { data: sourcingScenariosDTOs, isLoading: isLoadingSourcingScenario } =
        useSourcingScenarios(sourcingScenarioIds);
    (sourcingScenariosDTOs ?? []).forEach((sourcingScenario) => {
        const assemblyIds = sourcingScenario?.assembly_quantities.items.map((item) => item.assembly);
        if (assemblyIds) {
            assemblyIds.forEach((assemblyId) => {
                if (group.has(assemblyId) === false) {
                    group.set(assemblyId, []);
                }
                group.get(assemblyId)?.push(sourcingScenario);
            });
        }
    });

    const assemblyIds = Array.from(group.keys());
    const { data: assemblyDetails, isLoading: isLoadingAssembly } = useAssemblies(assemblyIds);

    if (isLoadingAssembly || isLoadingSourcingScenario || !isPresent(assemblyDetails))
        return {
            isLoading: true,
            data: undefined,
        };

    const result = Array.from(group, ([assemblyId, scenarios]) => ({
        ...assertPresent(assemblyDetails.find((a) => a.id === assemblyId)),
        sourcingScenarios: scenarios,
    }));

    return {
        isLoading: false,
        data: result,
    };
};

export const useSourcingScenariosGroupedByAssembly = (
    sourcingScenarioIds: string[],
): AssemblyCentricTableType[] | undefined => {
    const { data: sourcingCalculationDTOs } = useSourcingCalculationBulk(sourcingScenarioIds);
    const { data: sourcingScenarioGroupedData, isLoading } = useGroupScenariosByAssembly(sourcingScenarioIds);

    if (isLoading || !isPresent(sourcingScenarioGroupedData)) return undefined;

    const result: AssemblyCentricTableType[] = sourcingScenarioGroupedData.map(
        ({ sourcingScenarios, ...assembly }) => ({
            type: 'groupRow',
            assembly,
            scenarios: sourcingScenarios.map((s) => {
                const sourcingCalculation = (sourcingCalculationDTOs ?? []).find(
                    (sc) => sc.sourcing_scenario_id === s.id,
                );
                const assemblySourcingInformation = sourcingCalculation
                    ? getAssemblySourcingSummary(assembly.id, sourcingCalculation)
                    : undefined;

                return {
                    type: 'subRow',
                    assembly,
                    data: {
                        sourcingScenario: s,
                        sourcingCalculation,
                        assemblySourcingInformation,
                        id: assembly.id,
                    },
                    scenarios: undefined,
                };
            }),
            data: undefined,
        }),
    );

    return result;
};

const getAssemblySourcingSummary = (
    assemblyId: string,
    sourcingCalculationDTO: SourcingCalculationDTO,
): (AssemblySourcingSummaryDTO & { id: string }) | undefined => {
    const result = Object.entries(sourcingCalculationDTO.aggregated_top_level_assembly_information).find(
        ([id]) => id === assemblyId,
    );

    if (!result) return undefined;

    /*eslint-disable*/
    if (!result) {
        return {
            id: assemblyId,
            availability: null,
            has_missing_solutions: false,
            total_tco_costs: {
                unit: {
                    cost: null,
                    breakdown: {
                        packaging_cost: null,
                        discount: null,
                        shipping_cost: null,
                        customs_cost: null,
                        other_cost: null,
                    },
                },
                aggregated: {
                    cost: null,
                    breakdown: {
                        packaging_cost: null,
                        discount: null,
                        shipping_cost: null,
                        customs_cost: null,
                        other_cost: null,
                    },
                },
                scrap: {
                    cost: null,
                    breakdown: {
                        packaging_cost: null,
                        discount: null,
                        shipping_cost: null,
                        customs_cost: null,
                        other_cost: null,
                    },
                },
                required: {
                    cost: null,
                    breakdown: {
                        packaging_cost: null,
                        discount: null,
                        shipping_cost: null,
                        customs_cost: null,
                        other_cost: null,
                    },
                },
                excess: {
                    cost: null,
                    breakdown: {
                        packaging_cost: null,
                        discount: null,
                        shipping_cost: null,
                        customs_cost: null,
                        other_cost: null,
                    },
                },
                total: {
                    cost: null,
                    breakdown: {
                        packaging_cost: null,
                        discount: null,
                        shipping_cost: null,
                        customs_cost: null,
                        other_cost: null,
                    },
                },
            },
            total_excess_material: null,
            total_one_time_costs: null,
            total_price: null,
            total_scrap_costs: null,
            unit_price: null,
        };
    }
    /*eslint-enable*/
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_id, assemblySourcingSummary] = result;
    return {
        ...assemblySourcingSummary,
        id: assemblyId,
    };
};

const ActionButton: React.FunctionComponent<{
    sharedContext: SourcingScenarioTableSharedContext;
}> = ({ sharedContext }) => {
    return (
        <SecondaryButton
            size="medium"
            startIcon={<Add />}
            href={route(`/rfqs/:rfqId/sourcing/scenarios/new`, { rfqId: sharedContext.rfqId })}
        >
            <Trans>Add scenario</Trans>
        </SecondaryButton>
    );
};

export const AssemblyCentricSourcingScenarioTable: React.FunctionComponent<{
    sourcingScenarioIds: string[];
    rfqId: string;
}> = ({ sourcingScenarioIds, rfqId }) => {
    const data = useSourcingScenariosGroupedByAssembly(sourcingScenarioIds);

    const navigate = useNavigate();

    const { table } = useTanStackTable({
        columns,
        data,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        getSubRows: (row) => row.scenarios,
        filterFromLeafRows: true,
        sharedContext: {
            rfqId: rfqId,
        },
        onRowClick: (row) => {
            if (row.original.type === 'groupRow') {
                row.toggleExpanded();
            } else {
                navigate(
                    route(
                        `/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`,
                        {
                            rfqId: row.original.data.sourcingScenario.rfq,
                            sourcingScenarioId: row.original.data.sourcingScenario.id,
                        },
                        {
                            assemblyId: row.original.assembly.id,
                        },
                    ),
                );
            }
        },
    });

    return <TanStackTable table={table} size="medium" ActionButton={ActionButton} />;
};
