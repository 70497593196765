import { t } from '@lingui/macro';
import { isPresent, uniq } from '@luminovo/commons';
import { useState } from 'react';
import { runImporter } from '../../model/runImporter';
import { ImportBatchHandler, ImporterConfig, ImporterField, Table, TransformRecordActionHandler } from '../../types';
import { DialogImportDone } from '../DialogImportDone/DialogImportDone';
import { Stepper } from '../Stepper';
import { useStepperState } from '../Stepper/Stepper';
import { Step } from '../Stepper/types';
import { StepCheck } from './components/check/StepCheck';
import { StepCheckHelpDialog } from './components/check/StepCheckHelpDialog';
import { FieldDescriptions, StepLinkColumns } from './components/link/StepLinkColumns';
import { StepPreview } from './components/preview/StepPreview';
import { StepPreviewHelpDialog } from './components/preview/StepPreviewHelpDialog';
import { StepUploadDropzone } from './components/upload/StepUploadDropzone';
import { StepUploadHelpDialog } from './components/upload/StepUploadHelpDialog';
import { UniversalImporterProvider, useUniversalImporter } from './context';
import { useValidateGlobalFields } from './hooks/useValidateGlobalFields';

export interface UniversalImporterProps<TConfig extends ImporterConfig = ImporterConfig> {
    title?: string;
    hrefBack?: string;
    config: TConfig;
    initialTable?: Table;
    /**
     * This function takes the data from a parsed row and outputs the desired action ('insert', 'update', and 'skipped').
     *
     *  An output of `undefined` can be used to relinquish determining action to the `UniversalImporter`
     *
     * This is useful in situation that needs the entire row context to figure out the proper action to be taken by the `UniversalImporter`
     */
    transformRecordAction?: TransformRecordActionHandler<TConfig>;
    onImportBatch: ImportBatchHandler<TConfig>;
    onImportDone?: () => void;
    batchSize?: number;
}

export function UniversalImporter<TConfig extends ImporterConfig = ImporterConfig>(
    props: UniversalImporterProps<TConfig>,
): JSX.Element {
    return (
        <UniversalImporterProvider
            config={props.config}
            initialTable={props.initialTable}
            // This should be safe since we are not modifying the underlying data, so the structure should be
            // consistent with the `ImporterConfig`
            transformRecordAction={props.transformRecordAction as TransformRecordActionHandler<ImporterConfig>}
        >
            <UniversalImporterInner {...props} />
        </UniversalImporterProvider>
    );
}

function UniversalImporterInner<TConfig extends ImporterConfig>({
    title = t`Upload file`,
    hrefBack = '/',
    onImportBatch,
    onImportDone,
    batchSize = 100,
}: UniversalImporterProps<TConfig>): JSX.Element {
    const { state, dispatch } = useUniversalImporter();
    const isGlobalFormValid = useValidateGlobalFields();
    const { table, config, importerTable } = state;
    const rows = importerTable?.getRows() ?? [];
    const isImportFailed = rows.some((r) => r.import?.success === false);
    const [showImportDoneDialog, setShowImportDoneDialog] = useState(false);

    const isReadyForPreview = importerTable?.isReadyForPreview() ?? false;
    const isReadyForImport = importerTable?.isReadyForImport() ?? false;
    const areGlobalFieldsDefined = config.globalFields !== undefined && config.globalFields.length > 0;
    const { stepIndex, setStepIndex } = useStepperState(areGlobalFieldsDefined ? 5 : 4);
    const stepIndexForPreview = 3;

    const allColumnsMapped = checkAllColumnsMapped(config.fields);

    const steps: Step[] = [
        {
            label: t`Upload`,
            content: <StepUploadDropzone onStepIndexChange={setStepIndex} />,
            enabled: !isImportFailed,
            isDone: table !== undefined,
            helpDialog: {
                title: title,
                body: <StepUploadHelpDialog />,
            },
        },
        {
            label: t`Map columns`,
            content: <StepLinkColumns />,
            enabled: Boolean(table) && !isImportFailed,
            isDone: allColumnsMapped && isGlobalFormValid,
        },
        {
            label: t`Check`,
            content: <StepCheck />,
            enabled: allColumnsMapped && !isImportFailed && isGlobalFormValid,
            isDone: isReadyForPreview,
            helpDialog: {
                title: t`Fix errors to continue`,
                body: <StepCheckHelpDialog />,
            },
            helpButton: {
                title: t`Column information`,
                body: <FieldDescriptions fields={config.fields} showLinked={false} useColumnsLabel />,
                buttonText: t`Column information`,
            },
        },
        {
            label: t`Preview`,
            content: <StepPreview />,
            enabled: isReadyForPreview && !isImportFailed && isGlobalFormValid,
            isDone: isReadyForImport && !isImportFailed && stepIndex === stepIndexForPreview,
            helpDialog: {
                title: t`Preview changes`,
                body: <StepPreviewHelpDialog />,
            },
        },
    ].filter(isPresent);

    return (
        <>
            <Stepper
                hrefBack={hrefBack}
                title={title}
                onSubmit={async () => {
                    let table = state.importerTable;
                    const globalFields = state.config.globalFields;
                    if (!table) {
                        return;
                    }
                    for await (const intermediateResult of runImporter({
                        batchSize,
                        table,
                        onImportBatch,
                        globalFields,
                    })) {
                        dispatch({ type: 'setImporterTable', table: intermediateResult });
                        table = intermediateResult;
                    }
                    setShowImportDoneDialog(true);
                    if (table.getImportStatusCount().error === 0) {
                        onImportDone?.();
                    }
                }}
                steps={steps}
                stepIndex={stepIndex}
                onStepIndexChange={(e) => {
                    setStepIndex(e);
                }}
            />

            {showImportDoneDialog && importerTable && (
                <DialogImportDone
                    totalRecords={rows.filter((r) => Boolean(r.record)).length}
                    failedToImport={rows.filter((r) => r.import?.success === false).length}
                    successfullyImported={rows.filter((r) => r.import?.success === true).length}
                    onClose={() => setShowImportDoneDialog(false)}
                    onRestartImport={() => {
                        dispatch({ type: 'restartImport' });
                        setStepIndex(0);
                        setShowImportDoneDialog(false);
                    }}
                    onViewImportedRecords={() => {
                        onImportDone?.();
                    }}
                />
            )}
        </>
    );
}

function checkAllColumnsMapped(allFields: ImporterField[]): boolean {
    // First check that all fields with required=true are mapped
    const areAllRequiredFieldsMapped = allFields
        .filter((field) => field.required === true)
        .every((field) => field.columnIndices.length > 0);

    if (!areAllRequiredFieldsMapped) {
        return false;
    }

    // Then check that at least one field in each required group is mapped
    // Start by calculating the required groups
    const requiredGroups = uniq(allFields.map((field) => getRequiredGroupId(field)).filter(isPresent));
    // Then check that at least one field in each required group is mapped
    const isAtLeastOneRequiredGroupMapped =
        requiredGroups.length === 0
            ? true
            : requiredGroups.some((group) => {
                  const fields = allFields.filter((field) => getRequiredGroupId(field) === group);
                  const isMapped = fields.every((field) => field.columnIndices.length > 0);
                  return isMapped;
              });

    return areAllRequiredFieldsMapped && isAtLeastOneRequiredGroupMapped;
}

function getRequiredGroupId(field: ImporterField) {
    return typeof field.required === 'object' ? field.required.label : undefined;
}
