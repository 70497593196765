/* eslint-disable import/no-unused-modules */

import * as r from 'runtypes';
import { MonetaryValueBackendRuntype } from '../../resources/backendTypes';
import { PackagingRuntype } from '../../resources/offer/Packaging';
import { PackageFamilyRuntype, PartCategoryDTORuntype } from '../part';
import { MountingRuntype } from '../part/Mounting';

export const ScrapRuntype = r.Record({
    leader: r.Number,
    attrition: r.Number,
    minimum: r.Optional(r.Number),
    maximum: r.Optional(r.Number),
});

const UnitPriceFieldRuntype = r.Record({
    lhs: r.Literal('UnitPrice'),
    rhs: MonetaryValueBackendRuntype,
    value: r.Optional(MonetaryValueBackendRuntype),
});

const PackageFieldRuntype = r.Record({
    lhs: r.Literal('Package'),
    rhs: r.Array(PackageFamilyRuntype),
    value: r.Optional(r.Array(PackageFamilyRuntype)),
});

const PartTypeFieldRuntype = r.Record({
    lhs: r.Literal('PartType'),
    rhs: r.String,
    value: r.Optional(r.String),
});

const PartCategoryFieldRuntype = r.Record({
    lhs: r.Literal('PartCategory'),
    rhs: PartCategoryDTORuntype,
    value: r.Optional(r.Array(r.Number)),
});

const PackagingFieldRuntype = r.Record({
    lhs: r.Literal('Packaging'),
    rhs: PackagingRuntype,
    value: r.Optional(PackagingRuntype),
});

const AggregatedQuantityFieldRuntype = r.Record({
    lhs: r.Literal('AggregatedQuantity'),
    rhs: r.Number,
    value: r.Optional(r.Number),
});

const OrderSizeFieldRuntype = r.Record({
    lhs: r.Literal('OrderSize'),
    rhs: r.Number,
    value: r.Optional(r.Number),
});

const MountingFieldRuntype = r.Record({
    lhs: r.Literal('Mounting'),
    rhs: MountingRuntype,
    value: MountingRuntype.optional(),
});

const FieldRuntype = r.Union(
    UnitPriceFieldRuntype,
    PackagingFieldRuntype,
    AggregatedQuantityFieldRuntype,
    OrderSizeFieldRuntype,
    PackageFieldRuntype,
    MountingFieldRuntype,
    PartTypeFieldRuntype,
    PartCategoryFieldRuntype,
);

const OperatorEqRuntype = r
    .Record({
        op: r.Literal('Eq'),
    })
    .And(FieldRuntype);

const OperatorGtRuntype = r
    .Record({
        op: r.Literal('Gt'),
    })
    .And(FieldRuntype);

const OperatorGteRuntype = r
    .Record({
        op: r.Literal('Gte'),
    })
    .And(FieldRuntype);

const OperatorLtRuntype = r
    .Record({
        op: r.Literal('Lt'),
    })
    .And(FieldRuntype);

const OperatorLteRuntype = r
    .Record({
        op: r.Literal('Lte'),
    })
    .And(FieldRuntype);

const OperatorInRuntype = r
    .Record({
        op: r.Literal('In'),
    })
    .And(PackageFieldRuntype);

// TODO: uncomment when implementing ranges
// const OperatorInRangeRuntype = r
//     .Record({
//         op: r.Literal('InRange'),
//     })
//     .And(RangeFieldRuntype);

const OperatorRuntype = r.Union(
    OperatorEqRuntype,
    OperatorGtRuntype,
    OperatorGteRuntype,
    OperatorLtRuntype,
    OperatorLteRuntype,
    OperatorInRuntype,
    // TODO: uncomment when implementing ranges
    // OperatorInRangeRuntype,
);

export type Operator = r.Static<typeof OperatorRuntype>;

export type OperatorId = Operator['op'] | Condition['op'];

export type OperatorField = r.Static<typeof FieldRuntype>['lhs'];

const OperatorOrRuntype = r.Record({
    op: r.Literal('Or'),
    args: r.Array(OperatorRuntype),
});

const OperatorAndRuntype = r.Record({
    op: r.Literal('And'),
    args: r.Array(OperatorRuntype),
});

export const ConditionRuntype = r.Union(OperatorOrRuntype, OperatorAndRuntype);

export type Condition = r.Static<typeof ConditionRuntype>;

const FormulaRuntype = r.Record({
    name: r.String,
    condition: ConditionRuntype,
    scrap_formula: ScrapRuntype,
});

export interface Formula extends r.Static<typeof FormulaRuntype> {}

export const ScrapRulesDTORuntype = r.Record({
    formulae: r.Array(FormulaRuntype),
    default_formula: ScrapRuntype,
});

export interface ScrapRulesDTO extends r.Static<typeof ScrapRulesDTORuntype> {}
