import * as r from 'runtypes';
import { RegionsEnum } from '../supplierAndStockLocation';

export interface PCBCredentialsDTO extends r.Static<typeof PCBCredentialsDTORuntype> {}

export type WuerthRegion = r.Static<typeof WuerthRegionRuntype>;
export const WuerthRegionRuntype = r.Union(
    r.Literal(RegionsEnum.Germany),
    r.Literal(RegionsEnum.France),
    r.Literal(RegionsEnum.Spain),
    r.Literal(RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland),
    r.Literal(RegionsEnum.Switzerland),
    r.Literal(RegionsEnum.Unknown),
);

export const PCBCredentialsDTORuntype = r.Record({
    username: r.String,
    region: WuerthRegionRuntype.nullable(),
    is_valid: r.Boolean,
    error: r.String.nullable(),
});

export interface UserPasswordPCBCredentialsInputDTO extends r.Static<typeof UserPasswordCredentialsInputDTORuntype> {}

export const UserPasswordCredentialsInputDTORuntype = r.Record({
    username: r.String,
    password: r.String,
});
