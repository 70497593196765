import { getToken } from '@luminovo/auth';
import { logToExternalErrorHandlers } from '@luminovo/commons';
import { ExtractResponseBody, HttpOptions, RegisteredHttpEndpoint, http } from '@luminovo/http-client';
import DataLoader from 'dataloader';

type DataLoaderProps<T extends RegisteredHttpEndpoint, V> = {
    idExtractor: (item: V) => string;
    httpOptions: (ids: string[]) => HttpOptions<T>;
    select: (response: ExtractResponseBody<T>) => V[];
};

const dataloaderRegistry = new Map<string, DataLoader<unknown, unknown>>();

/**
 * Schedule batches to a maximum of Xms, meaning all items requested within a Xms range
 * will be batched into a single call.
 */
const batchScheduleFn = (cb: () => void) => setTimeout(cb, 10);

function createDataloader<T extends RegisteredHttpEndpoint, V>(
    endpoint: T,
    { httpOptions, select, idExtractor }: DataLoaderProps<T, V>,
) {
    return new DataLoader<string, V | null>(
        async (keys) => {
            if (keys.length === 0) {
                // don't even hit the network when given an empty list of keys.
                return [];
            }

            const uniques = Array.from(new Set(keys));
            const result = await http(endpoint, httpOptions(uniques), getToken());

            // Group all the V's by their ID.
            const groupedById = new Map<string, V>();
            for (const item of select(result)) {
                groupedById.set(idExtractor(item), item);
            }

            // Then return the items in their original order.
            return keys.map((key) => {
                const result = groupedById.get(key);
                if (result === undefined) {
                    logToExternalErrorHandlers(new Error(`DataLoader: Missing response data for key  in endpoint`), {
                        extra: {
                            endpoint,
                            key,
                        },
                    });
                }
                return result ?? null;
            });
        },
        { batchScheduleFn, cache: false },
    );
}

/**
 * @deprecated please don't use this function
 */
export function getOrCreateDataloader<T extends RegisteredHttpEndpoint, V>(
    endpoint: T,
    { httpOptions, select, idExtractor }: DataLoaderProps<T, V>,
) {
    // Ensure that a dataloader is not shared when the httpOptions function is diffent.
    const registryKey = `${endpoint} | ${JSON.stringify(httpOptions([]))}`;

    const dataloader =
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (dataloaderRegistry.get(registryKey) as DataLoader<string, V | null>) ??
        createDataloader(endpoint, { httpOptions, select, idExtractor });

    if (!dataloaderRegistry.has(registryKey)) {
        dataloaderRegistry.set(registryKey, dataloader);
    }

    return dataloader;
}
