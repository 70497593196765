import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ScrapRulesDTORuntype } from './scrapBackendTypes';

export const scrapEndpoints = {
    'GET /organization-settings/scrap-rules': endpoint({
        description: 'Returns scrap rules',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ScrapRulesDTORuntype,
    }),

    'PATCH /organization-settings/scrap-rules': endpoint({
        description: 'updates scrap rules',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ScrapRulesDTORuntype,
        responseBody: ScrapRulesDTORuntype,
        invalidates: ['GET /organization-settings/scrap-rules'],
    }),
};
