import { CostConfig } from '../../types/conversionTypes';
import { Row } from '../../types/rowTypes';
import { TableColumn } from '../../types/tableColumnTypes';
import { convertScenarioCostsToColumns } from './convertScenarioCostToTableColumns';
import { convertTableColumnsToRows } from './convertTableColumnsToRows';

export const convertCostsToRows = ({
    costs,
    calculationAssemblyDetails,
    preferredCurrency,
    isCalculationWithoutManufacturing,
    projectCostBreakdownConfig,
    excessMaterialBreakdownConfig,
}: CostConfig): Row[] => {
    const columns: TableColumn[] = costs
        .sort((costA, costB) => costA.order_size - costB.order_size)
        .map((body) => convertScenarioCostsToColumns(body, preferredCurrency, calculationAssemblyDetails))
        .flat();
    return convertTableColumnsToRows({
        columns,
        calculationAssemblyDetails,
        preferredCurrency,
        isCalculationWithoutManufacturing,
        projectCostBreakdownConfig,
        excessMaterialBreakdownConfig,
    });
};
