import { compareByStringKey, isPresent } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { DemandDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import React from 'react';
import { useHttpQuery } from '../http/useHttpQuery';
import { useRfQ } from '../rfq/rfqHandler';
import { getTotalQuantity } from './getTotalQuantity';

export function useSourcingScenario(sourcingScenarioId: string | undefined) {
    return useHttpQuery(
        'POST /sourcing-scenarios/bulk',
        {
            requestBody: { ids: [sourcingScenarioId!] },
        },
        {
            select: (res) => res.items[0],
            enabled: !!sourcingScenarioId,
        },
    );
}

export function useSourcingScenarios(sourcingScenarioIds: string[] | undefined) {
    return useHttpQuery(
        'POST /sourcing-scenarios/bulk',
        {
            requestBody: { ids: sourcingScenarioIds! },
        },
        {
            select: (res) =>
                res.items.sort(
                    chainComparators(
                        compareByNumber((x) => -getTotalQuantity(x)),
                        compareByStringKey((item) => item.name),
                    ),
                ),
        },
    );
}

export function useSourcingFull(sourcingScenarioId: string | undefined) {
    return useHttpQuery(
        'POST /sourcing-scenarios/sourcing-full/bulk',
        {
            requestBody: { ids: [sourcingScenarioId!] },
        },
        {
            select: (res) => res.items[0],
            enabled: !!sourcingScenarioId,
        },
    );
}

export function useSourcingFullBulk(sourcingScenarioIds: string[] | undefined) {
    return useHttpQuery(
        'POST /sourcing-scenarios/sourcing-full/bulk',
        {
            requestBody: { ids: sourcingScenarioIds! },
        },
        {
            select: (res) => res.items.sort(compareByStringKey((s) => s.sourcing_scenario_id)),
            enabled: isPresent(sourcingScenarioIds),
        },
    );
}

export function useSourcingCalculation(sourcingScenarioId: string | undefined) {
    return useHttpQuery(
        'POST /sourcing/calculation',
        {
            requestBody: { type: 'SourcingScenarios', data: [sourcingScenarioId!] },
        },
        {
            select: (res) => res.items[0],
            enabled: isPresent(sourcingScenarioId),
        },
    );
}

export function useSourcingCalculationBulk(sourcingScenarioIds: string[] | undefined) {
    return useHttpQuery(
        'POST /sourcing/calculation',
        {
            requestBody: { type: 'SourcingScenarios', data: sourcingScenarioIds ?? [] },
        },
        {
            select: (res) => Array.from(res.items).sort(compareByStringKey((s) => s.sourcing_scenario_id)),
            enabled: isPresent(sourcingScenarioIds),
        },
    );
}

export function useSourcingScenariosOfRfq(rfqId?: string) {
    const { data: rfq } = useRfQ(rfqId ?? '', Boolean(rfqId));
    const sourcingScenarioIds = rfq?.sourcing_scenarios ?? [];
    return useSourcingScenarios(sourcingScenarioIds);
}

export function useDemandsOfRfq(rfqId: string) {
    return useHttpQuery(
        'GET /rfqs/:rfqId/demands',
        {
            pathParams: { rfqId },
        },
        { select: (data) => data.items },
    );
}

export type DemandWithSourcingScenarios = DemandDTO & {
    sourcingScenarios: SourcingScenarioDTO[];
};

export function useDemandsWithSourcingScenarios(rfqId: string) {
    const { data: demands } = useDemandsOfRfq(rfqId);
    const { data: rfq } = useRfQ(rfqId, Boolean(rfqId));
    const { data: sourcingScenarios } = useSourcingScenarios(rfq?.sourcing_scenarios);

    return React.useMemo(() => {
        if (!isPresent(demands) || !isPresent(sourcingScenarios)) {
            return { data: undefined };
        }

        const data = demands.map((demand: DemandDTO) => {
            const linkedScenarios = sourcingScenarios.filter((scenario) =>
                scenario.assembly_demands.some((assemblyDemand: DemandDTO) => assemblyDemand.id === demand.id),
            );

            return {
                ...demand,
                sourcingScenarios: linkedScenarios,
            } as DemandWithSourcingScenarios;
        });

        return { data };
    }, [demands, sourcingScenarios]);
}
