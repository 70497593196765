/* eslint-disable spellcheck/spell-checker */

import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatDecimal, isPresent, MonetaryValue } from '@luminovo/commons';
import { colorSystem, Flexbox, InteractiveCard, Text } from '@luminovo/design-system';
import {
    Availability,
    FullSourcingDTO,
    SolutionConfigurationCalculationDTO,
    SolutionStatusCountDTO,
    SourcingCalculationDTO,
} from '@luminovo/http-client';
import { formatAvailability } from '@luminovo/sourcing-core';
import { FiberManualRecord as CircleIcon, EqualizerRounded, TableChartOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { formatMonetaryValue } from '../../../../utils/formatMonetaryValue';
import { FieldId } from '../ChartSpec';

type SummaryData = {
    statusCount: SolutionStatusCountDTO;
    totalAvailability: Availability | undefined;
    totalUnitPrice: MonetaryValue | undefined;
    totalPrice: MonetaryValue | undefined;
    totalExcessPrice: MonetaryValue | undefined;
    supplierCount: number | undefined;
};

type SummaryBoxData =
    | { type: 'old'; data: FullSourcingDTO; supplierCount: number | undefined }
    | { type: 'new'; data: SourcingCalculationDTO };

const extractSupplierId = (summary: SolutionConfigurationCalculationDTO): string | undefined => {
    const offerSummary = summary.offer_summary;
    const offerSummaryType = offerSummary.type;
    switch (offerSummaryType) {
        case 'NoOffer':
        case 'Consigned':
            return undefined;
        case 'Offer':
            const { data: offer, type } = offerSummary.data;
            switch (type) {
                case 'OffTheShelf':
                case 'CustomPart':
                    return offer.supplier_and_stock_location;
                case 'Inventory':
                    return undefined;
                default:
                    assertUnreachable(type);
            }
        /* eslint-disable-next-line no-fallthrough */
        default:
            assertUnreachable(offerSummaryType);
    }
};

const extractData = (
    sourcingCalculation: SummaryBoxData | undefined,
    sourcingScenarioId: string,
): SummaryData | undefined => {
    if (!isPresent(sourcingCalculation)) {
        return undefined;
    }

    switch (sourcingCalculation.type) {
        case 'old':
            const summary = sourcingCalculation.data;

            return {
                statusCount: summary.status_count,
                totalAvailability: summary.total_availability ?? undefined,
                totalUnitPrice: summary.total_unit_price ?? undefined,
                totalPrice: summary.total_price ?? undefined,
                totalExcessPrice: summary.total_excess_material ?? undefined,
                supplierCount: sourcingCalculation.supplierCount,
            };
        case 'new': {
            const supplierIds = sourcingCalculation.data.solution_configurations
                .map(extractSupplierId)
                .filter(isPresent);
            const supplierCount = new Set(supplierIds).size;

            return {
                statusCount: sourcingCalculation.data.status_count,
                totalAvailability: sourcingCalculation.data.total_availability ?? undefined,
                totalUnitPrice: sourcingCalculation.data.total_unit_price ?? undefined,
                totalPrice: sourcingCalculation.data.total_price ?? undefined,
                totalExcessPrice: sourcingCalculation.data.total_excess_material ?? undefined,
                supplierCount,
            };
        }
    }
};

const SourcingScenarioSummaryBox: React.FunctionComponent<{
    selectedGraphId?: FieldId;
    setSelectedGraphId: (graphId: FieldId) => void;
    sourcingCalculation?: SummaryBoxData;
    sourcingScenarioId: string;
}> = ({ sourcingCalculation, selectedGraphId, setSelectedGraphId, sourcingScenarioId }): JSX.Element => {
    const data = extractData(sourcingCalculation, sourcingScenarioId);

    return (
        <Flexbox flexDirection={'column'}>
            <Box display={'grid'} columnGap="16px" gridTemplateColumns="repeat(5,1fr)" width={'100%'}>
                <IndicatorBox
                    graphId={'table'}
                    title={t`Solution status`}
                    indicator={
                        data && (
                            <Flexbox alignItems={'center'} gap={4}>
                                <CircleIcon style={{ color: colorSystem.green[5], fontSize: 14 }} />
                                <Typography variant="h2">{data.statusCount.number_of_ok}</Typography>

                                <span style={{ marginLeft: 20 }} />

                                <CircleIcon style={{ color: colorSystem.yellow[5], fontSize: 14 }} />
                                <Typography variant="h2">{data.statusCount.number_of_warning}</Typography>

                                <span style={{ marginLeft: 20 }} />

                                <CircleIcon style={{ color: colorSystem.red[5], fontSize: 14 }} />
                                <Typography variant="h2">{data.statusCount.number_of_error}</Typography>
                            </Flexbox>
                        )
                    }
                    setSelectedGraphId={setSelectedGraphId}
                    selectedGraphId={selectedGraphId}
                />

                <IndicatorBox
                    graphId="supplierName"
                    title={t`Suppliers`}
                    indicator={data && formatDecimal(data.supplierCount)}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />

                <IndicatorBox
                    graphId="leadTime"
                    title={t`Lead time`}
                    indicator={data && formatAvailability(data.totalAvailability)}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />

                <IndicatorBox
                    graphId="unitPrice"
                    title={t`Unit price`}
                    indicator={data && formatMonetaryValue(data.totalUnitPrice)}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />

                <IndicatorBox
                    graphId="effectiveTotalPrice"
                    title={t`Total price`}
                    indicator={data && formatMonetaryValue(data.totalPrice)}
                    secondary={data && <LabelExcessMaterial totalExcessPrice={data.totalExcessPrice} />}
                    selectedGraphId={selectedGraphId}
                    setSelectedGraphId={setSelectedGraphId}
                />
            </Box>
        </Flexbox>
    );
};

function LabelExcessMaterial({ totalExcessPrice }: { totalExcessPrice: MonetaryValue | undefined }) {
    if (!totalExcessPrice) {
        return <></>;
    }
    return (
        <Text>
            {formatMonetaryValue(totalExcessPrice)} <Trans>Excess</Trans>
        </Text>
    );
}

export default SourcingScenarioSummaryBox;

const IndicatorHeader: React.FunctionComponent<{
    title: string;
    graphId?: FieldId;
}> = ({ title, graphId }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} alignItems={'baseline'}>
            <Text variant={'h4'} color={colorSystem.neutral[7]}>
                {title}
            </Text>
            <Flexbox gap={4} alignItems={'center'}>
                <Text variant={'body'} color={colorSystem.neutral[6]}>
                    {isPresent(graphId) ? t`Graph` : t`Table`}
                </Text>
                {isPresent(graphId) ? (
                    <EqualizerRounded fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                ) : (
                    <TableChartOutlined fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                )}
            </Flexbox>
        </Flexbox>
    );
};

function IndicatorBox({
    title,
    indicator,
    secondary,
    graphId,
    setSelectedGraphId,
    selectedGraphId,
}: React.PropsWithChildren<{
    title: string;
    indicator?: string | JSX.Element;
    secondary?: JSX.Element;
    graphId: FieldId;
    selectedGraphId?: FieldId;
    setSelectedGraphId: (graphId: FieldId) => void;
}>): JSX.Element {
    const isSelected = graphId === selectedGraphId;

    return (
        <InteractiveCard
            display={'flex'}
            flexDirection={'column'}
            style={{ gap: 8 }}
            selected={isSelected}
            onClick={() => setSelectedGraphId(graphId)}
        >
            <IndicatorHeader title={title} graphId={graphId} />
            <Flexbox alignItems={'center'} gap={8}>
                {!isPresent(indicator) && <Skeleton width={'100%'} height={'30px'} />}
                {isPresent(indicator) && typeof indicator === 'string' ? (
                    <Typography variant="h2">
                        {indicator} {secondary}
                    </Typography>
                ) : (
                    indicator
                )}
            </Flexbox>
        </InteractiveCard>
    );
}
