import { t, Trans } from '@lingui/macro';
import { assertPresent, formatDecimal, formatMonetaryValue, MonetaryValue } from '@luminovo/commons';
import {
    Checkbox,
    colorSystem,
    createColumnHelper,
    EditableCell,
    MenuButton,
    MenuItem,
    StatusChip,
    StyledEditableCell,
    SupportedFilterFn,
    Table,
    TanStackTable,
    Text,
    useTanStackTable,
} from '@luminovo/design-system';
import { Field } from '@luminovo/fields';
import { PartLiteTypes } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Delete, FileCopy, MoreVert } from '@mui/icons-material';
import { Box, styled, TableCell } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { useQuoteRequestLineItemsByQuoteRequest, useSuspendedQuoteRequest } from '../hooks/negotiationHandlers';
import { fields } from '../model/fields/fields';
import { formatPartLite } from '../model/formatPartLite';
import { getQuoteRequestLineItemPrice } from '../model/getQuoteRequestLineItemPrice';
import {
    isCustomPartQuoteRequest,
    scrollToEditableCell,
} from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/model';
import { OfferedPart, RowItem } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/types';
import { useIsQuoteRequestFrozen } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/useIsQuoteRequestFrozen';
import { validateLineItem, validators } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/validateLineItem';
import { DialogSelectPart } from './DialogSelectPart';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';
import { PartLiteLabel } from './PartLiteLabel';

type SetCell = <T extends keyof RowItem>(row: RowItem, columnId: T, value: RowItem[T]) => void;
type SharedContext = {
    setRow: (row: RowItem) => void;
    setCell: SetCell;
    toggleAllSelected: (table: Table<RowItem>) => void;
    quoteRequestId: string;
    isFrozen: boolean;
    duplicateRow: (rowId: string) => void;
    deleteRow: (rowId: string) => void;
};
const columnHelper = createColumnHelper<RowItem, SharedContext>();

const ReadonlyCell = styled(TableCell)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    background: colorSystem.neutral[0],
});
const ipnColumn = columnHelper.enum((row) => row.component_origin ?? '-', {
    id: 'ipn',
    label: () => t`IPN`,
    size: 80,
    description: () => t`Internal part number from the buyer's ERP.`,
    getOptionLabel: (opt) => opt,
    disableTableCell: true,
    cell: ({ row }) => {
        return (
            <ReadonlyCell>
                <NegotiationIpnLabel ipn={row.original.component_origin} />
            </ReadonlyCell>
        );
    },
});

const selectedColumn = columnHelper.enum((row) => row.selected ?? false, {
    id: fields.selected.id,
    label: fields.selected.label,
    size: 90,
    getOptionLabel: fields.selected.formatter,
    description: () =>
        `Determines whether to include the line item in the quote. Note that this differs from the bid status, which is about whether to bid on the part.`,
    initialPinning: 'left',
    header(props) {
        const sharedContext = props.table.options.meta?.sharedContext as SharedContext;
        const allSelected = props.table.getRowModel().rows.every((row) => row.original.selected);
        const noneSelected = props.table.getRowModel().rows.every((row) => !row.original.selected);

        return (
            <Box sx={{ marginRight: 2, position: 'sticky', left: 0, zIndex: 1 }}>
                <Checkbox
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        sharedContext.toggleAllSelected(props.table);
                    }}
                    indeterminate={!allSelected && !noneSelected}
                    checked={allSelected}
                />
            </Box>
        );
    },
    cell: ({ row, sharedContext }) => (
        <Checkbox
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                sharedContext.setCell(row.original, 'selected', !row.original.selected);
            }}
            readOnly={sharedContext.isFrozen}
            checked={row.original.selected}
        />
    ),
});

const requestedPartColumn = columnHelper.enum((row) => formatPartLite(row.requested_part), {
    id: 'requestedPart',
    label: () => t`Requested part`,
    size: 200,
    description: () => `The part for which the customer is asking a quote.`,
    getOptionLabel: (opt) => opt,
    disableTableCell: true,
    cell: ({ row }) => (
        <ReadonlyCell>
            <PartLiteLabel part={row.original.requested_part} />
        </ReadonlyCell>
    ),
});

const descriptionColumn = columnHelper.text((row) => row.description ?? '-', {
    id: 'description',
    label: () => t`Description`,
    size: 200,
    description: () =>
        t`A description of the item. For IPNs, it will include a list of part alternatives (MPNs and manufacturers) approved for this IPN. For generic / manufacturer-free parts, this column will contain the specification of the part. If the MPN and Manufacturer column are empty you can quote any of the parts listed in this description.`,
    disableTableCell: true,

    cell: ({ row }) => {
        const requestedPart = row.original.requested_part;
        const description = requestedPart.kind === PartLiteTypes.Custom ? requestedPart.description : '';
        return (
            <ReadonlyCell>
                <Text showEllipsis style={{ display: 'block' }}>
                    {description || row.original.description || '-'}
                </Text>
            </ReadonlyCell>
        );
    },
});

const requiredQuantityColumn = columnHelper.number(
    (row) => formatQuantity(row.requiredQuantity, { showPiecesUnit: false }),
    {
        id: fields.quantity.id,
        label: () => t`Qty`,
        size: 100,
        description: () => `The quantity of the part for which the customer is asking a quote.`,
        disableTableCell: true,
        cell: ({ row }) => (
            <ReadonlyCell
                style={{
                    textAlign: 'right',
                }}
            >
                {formatQuantity(row.original.requiredQuantity, { showPiecesUnit: false })}
            </ReadonlyCell>
        ),
    },
);
const potentialQuantityColumn = columnHelper.number((row) => row.potentialQuantity.quantity, {
    id: fields.potentialQuantity.id,
    size: 140,
    label: fields.potentialQuantity.label,
    disableTableCell: true,
    description: fields.potentialQuantity.description,
    cell: ({ row }) => (
        <ReadonlyCell
            style={{
                textAlign: 'right',
            }}
        >
            {fields.potentialQuantity.formatter(row.original.potentialQuantity)}
        </ReadonlyCell>
    ),
});
const recipientsColumn = columnHelper.enum((row) => row.recipients ?? '-', {
    id: 'shipTo',
    label: () => t`Ship to`,
    getOptionLabel: (opt) => opt,
    description: () => t`The site the parts will be shipped to.`,
    size: 100,
    disableTableCell: true,
    cell: ({ row }) => (
        <ReadonlyCell
            sx={{
                borderRight: `1px solid ${colorSystem.neutral[3]} !important`,
            }}
        >
            {row.original.recipients || '-'}
        </ReadonlyCell>
    ),
});

const totalPriceColumn = columnHelper.monetaryValue((row) => getQuoteRequestLineItemPrice(row), {
    id: fields.totalPrice.id,
    label: fields.totalPrice.label,
    description: fields.totalPrice.description,
    size: 140,
    disableTableCell: true,
    cell: ({ getValue }) => (
        <ReadonlyCell style={{ background: 'white', textAlign: 'right' }}>
            {formatMonetaryValue(getValue(), 'default', { ifAbsent: '-' })}
        </ReadonlyCell>
    ),
});

const targetPriceColumn = columnHelper.monetaryValue('targetPrice', {
    id: 'targetPrice',
    label: () => t`Target price`,
    size: 140,
    disableTableCell: true,
    cell: ({ row }) => {
        return (
            <ReadonlyCell style={{ textAlign: 'right' }}>
                {formatMonetaryValue(row.original.targetPrice, 'unit-price', { ifAbsent: '-' })}
            </ReadonlyCell>
        );
    },
    description: () => t`Target price to beat`,
});

const offeredPartColumn = columnHelper.text(
    (row) => {
        return formatPartLite(row.offeredPart?.part);
    },
    {
        id: 'offeredPart',
        label: () => t`Offered part`,
        size: 200,
        disableTableCell: true,
        description: () =>
            t`The part that the supplier offers. In most cases, this will be the same as the requested part, but you may offer an alternative part.`,
        cell: function Cell({ row, sharedContext }) {
            const offeredPart: OfferedPart | undefined = row.original.offeredPart;
            const ipn = row.original.component_origin;

            const { setDialog, closeDialog } = useDialogContext();

            const requestedPart = row.original.requested_part;
            const isCustomPartRequested =
                requestedPart.kind === PartLiteTypes.Custom || requestedPart.kind === PartLiteTypes.CustomComponent;

            if (isCustomPartRequested) {
                return (
                    <ReadonlyCell>
                        <PartLiteLabel part={offeredPart?.part} />
                    </ReadonlyCell>
                );
            }

            const validationStatus = validators.offeredPart(offeredPart, row.original);
            return (
                <>
                    <StyledEditableCell
                        data-readonly={sharedContext.isFrozen}
                        onClick={() => {
                            setDialog(
                                <DialogSelectPart
                                    ipn={ipn}
                                    onClose={closeDialog}
                                    description={row.original.description}
                                    requestedPart={row.original.requested_part}
                                    value={offeredPart?.part}
                                    onChange={(newPart) => {
                                        closeDialog();
                                        sharedContext.setCell(
                                            row.original,
                                            'offeredPart',
                                            newPart ? { part: newPart } : undefined,
                                        );
                                    }}
                                />,
                            );
                        }}
                        data-status={validationStatus.status}
                        data-message={validationStatus.message}
                    >
                        <PartLiteLabel part={offeredPart?.part ?? undefined} />
                    </StyledEditableCell>
                </>
            );
        },
    },
);
const bidColumn = createEditableColumn('bid', fields.bid, {
    size: 80,
    initialPinning: 'left',
    description: () =>
        t`Check the box to bid on a part. If not checked, you're indicating that you don't want to bid on this part.`,
});

const unitPriceColumn = createEditableColumn('unitPrice', fields.unitPrice);

const unitPriceQuantityColumn = createEditableColumn('pricePer', fields.pricePer);

const moqColumn = createEditableColumn('moq', fields.moq);
const mpqColumn = createEditableColumn('mpq', fields.mpq);
const stdFactoryLeadTimeColumn = createEditableColumn('stdFactoryLeadTime', fields.stdFactoryLeadTime);
const stdFactoryLeadTimeUnitColumn = createEditableColumn('stdFactoryLeadTimeUnit', fields.stdFactoryLeadTimeUnit);
const cancellationWindowColumn = createEditableColumn('cancellationWindow', fields.cancellationWindow);
const cancellationWindowUnitColumn = createEditableColumn('cancellationWindowUnit', fields.cancellationWindowUnit);
const stockColumn = createEditableColumn('stock', fields.stock);
const validFromColumn = createEditableColumn('validFrom', fields.validFrom);
const validUntilColumn = createEditableColumn('validUntil', fields.validUntil);
const itemClassColumn = createEditableColumn('itemClass', fields.itemClass);
const supplierPartNumberColumn = createEditableColumn('supplierPartNumber', fields.supplierPartNumber);
const packagingColumn = createEditableColumn('packaging', fields.packaging);
const ncnrColumn = createEditableColumn('ncnr', fields.ncnr);
const oneTimeCostsColumn = createEditableColumn('oneTimeCosts', fields.oneTimeCosts);
const notesColumn = createEditableColumn('notes', fields.notes);

const actionsColumn = columnHelper.text((row) => '', {
    id: 'actions',
    label: () => '',
    size: 34,
    initialPinning: 'right',
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableSorting: false,
    cell: ({ row, sharedContext }) => {
        return (
            <MenuButton appearance="secondary" size="medium" icon={<MoreVert />}>
                <MenuItem
                    onClick={() => sharedContext.duplicateRow(row.original.id)}
                    startIcon={<FileCopy />}
                    label="Duplicate"
                />
                <MenuItem
                    onClick={() => sharedContext.deleteRow(row.original.id)}
                    startIcon={<Delete />}
                    label="Delete"
                />
            </MenuButton>
        );
    },
});

type RowStatus = 'skipped' | 'bid' | 'no-bid' | 'error' | 'warning';
const getRowStatus = (row: RowItem): RowStatus => {
    if (!row.selected) {
        return 'skipped' as const;
    }
    if (!row.bid) {
        return 'no-bid';
    }
    const validation = validateLineItem(row);
    if (validation.status === 'error' || validation.status === 'warning') {
        return validation.status;
    }
    return row.bid ? 'bid' : 'no-bid';
};

function formatRowStatus(status: RowStatus): string {
    switch (status) {
        case 'skipped':
            return t`Bid later`;
        case 'bid':
            return t`Bid`;
        case 'no-bid':
            return t`No bid`;
        case 'error':
            return t`Bid invalid`;
        case 'warning':
            return t`Bid at risk`;
    }
}

function getRowStatusColor(status: RowStatus) {
    switch (status) {
        case 'skipped':
            return 'neutral';
        case 'bid':
            return 'green';
        case 'no-bid':
            return 'violet';
        case 'error':
            return 'red';
        case 'warning':
            return 'yellow';
    }
}

const statusColumn = columnHelper.enum((row) => getRowStatus(row), {
    id: 'status',
    label: () => t`Status`,
    size: 100,
    description: () => `The status of the quote request line item.`,
    getOptionLabel: (opt) => formatRowStatus(opt),
    disableTableCell: false,
    initialPinning: 'left',
    quickFilters: (['bid', 'warning', 'error', 'no-bid', 'skipped'] as const).map((status) => ({
        label: () => formatRowStatus(status),
        value: { filterFn: SupportedFilterFn.equalsAny, value: [status] },
        replaceExistingFilters: true,
        showCount: true,
    })),
    cell: function Cell({ row, getValue }) {
        const status = getValue();
        const color = getRowStatusColor(status);
        const isErrorOrWarning = status === 'error' || status === 'warning';
        const ref = React.useRef<HTMLDivElement>(null);
        return (
            <StatusChip
                ref={ref}
                style={{ cursor: isErrorOrWarning ? 'pointer' : 'default' }}
                enableHoverEffect={isErrorOrWarning}
                onClick={() => {
                    if (status === 'error' || status === 'warning') {
                        scrollToEditableCell(status, ref.current);
                    }
                }}
                color={color}
                label={formatRowStatus(status)}
            />
        );
    },
});

const customerNameColumn = columnHelper.text('customerName', {
    id: 'customerName',
    label: () => t`Customer name`,
    size: 100,
    description: () => t`The end customer for which the parts are being purchased.`,
    disableTableCell: true,
    cell: ({ getValue }) => {
        const text = getValue() ?? '-';
        return (
            <ReadonlyCell>
                <Text style={{ display: 'block' }} showEllipsis>
                    {text}
                </Text>
            </ReadonlyCell>
        );
    },
});

function FieldQuoteRequestLineItemTable({
    quoteRequestId,
    value,
    onChange,
    showCustomerName,
    showTargetPrice,
    isFrozen,
    isCustomPartQuote,
}: {
    quoteRequestId: string;
    value: RowItem[];
    onChange: (value: RowItem[]) => void;
    showCustomerName: boolean;
    showTargetPrice: boolean;
    isFrozen: boolean;
    isCustomPartQuote: boolean;
}) {
    const { visibleColumns, numberOfLeftAlignedColumns, numberOfReadonlyColumns } = useColumns({
        isCustomPartQuote,
        showCustomerName,
        showTargetPrice,
    });

    const setRow = React.useCallback(
        (newRow: RowItem) => {
            onChange(value.map((oldRow) => (oldRow.id === newRow.id ? newRow : oldRow)));
        },
        [value, onChange],
    );
    const setCell = React.useCallback(
        (row: RowItem, columnId: keyof RowItem, value: RowItem[keyof RowItem]) => {
            setRow({
                ...row,
                // whenever a row is changed, mark it as selected
                selected: true,
                [columnId]: value,
            });
        },
        [setRow],
    );
    const duplicateRow = React.useCallback(
        (rowId: string) => {
            const indexOfRow = value.findIndex((row) => row.id === rowId);
            if (indexOfRow === -1) {
                return;
            }
            const newRow = { ...value[indexOfRow], id: Math.random().toString(36).substring(2, 15) };
            const newValue = [...value];
            newValue.splice(indexOfRow, 0, newRow);
            onChange(newValue);
        },
        [value, onChange],
    );
    const deleteRow = React.useCallback(
        (rowId: string) => {
            onChange(value.filter((row) => row.id !== rowId));
        },
        [value, onChange],
    );

    const toggleAllSelected = React.useCallback(
        (table: Table<RowItem>) => {
            // Get currently visible rows based on table filters
            const visibleRows = table.getFilteredRowModel().rows;
            const visibleRowIds = new Set(visibleRows.map((row) => row.original.id));

            // Check if all visible rows are selected
            const allVisibleSelected = visibleRows.every((row) => row.original.selected);

            // Toggle selection only for visible rows
            onChange(
                value.map((row) => {
                    if (visibleRowIds.has(row.id)) {
                        return { ...row, selected: !allVisibleSelected };
                    }
                    return row;
                }),
            );
        },
        [value, onChange],
    );

    const StyledTable = React.useMemo(
        () =>
            styled('table')({
                width: '100%',
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                [`& thead td:nth-child(n+${numberOfLeftAlignedColumns + 1}):nth-child(-n+${numberOfReadonlyColumns + numberOfLeftAlignedColumns})`]:
                    {
                        backgroundColor: `${colorSystem.neutral[2]} !important`,
                    },
            }),
        [numberOfReadonlyColumns, numberOfLeftAlignedColumns],
    );

    const { table } = useTanStackTable<RowItem, SharedContext>({
        data: value,
        columns: visibleColumns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        sharedContext: {
            setRow,
            setCell,
            quoteRequestId,
            toggleAllSelected,
            isFrozen,
            duplicateRow,
            deleteRow,
        },
    });

    return (
        <TanStackTable
            table={table}
            enableMenuBar={{
                controlButtons: false,
                resultCount: false,
            }}
            overrides={{
                Table: StyledTable,
            }}
            ActionButton={ActionButton}
        />
    );
}

function ActionButton({ table, sharedContext }: { table: Table<RowItem>; sharedContext: any }) {
    const countSelected = table.getRowModel().rows.filter((row) => row.original.selected).length;
    const countTotal = table.getRowModel().rows.length;
    return (
        <>
            <Text color={colorSystem.neutral[6]}>
                <Trans>
                    {formatDecimal(countSelected)} of {formatDecimal(countTotal)} rows selected
                </Trans>
            </Text>
            {/* <LabelFormErrors table={table} /> */}
        </>
    );
}

export function TableQuoteRequestLineItemsSupplierForm({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequestId);
    const isCustomPartQuote = isCustomPartQuoteRequest(quoteRequestLineItems);
    const isFrozen = useIsQuoteRequestFrozen(quoteRequestId);

    if (!quoteRequest) {
        return null;
    }

    const showCustomerName = quoteRequest.configuration?.hidden_fields?.includes('Customer') === false;
    const showTargetPrice = quoteRequest.configuration?.hidden_fields?.includes('TargetPrice') === false;

    return (
        <FormItemTable
            quoteRequestId={quoteRequest.id}
            showCustomerName={showCustomerName}
            showTargetPrice={showTargetPrice}
            isCustomPartQuote={isCustomPartQuote}
            isFrozen={isFrozen}
        />
    );
}

function FormItemTable({
    showCustomerName,
    showTargetPrice,
    quoteRequestId,
    isFrozen,
    isCustomPartQuote,
}: {
    showCustomerName: boolean;
    showTargetPrice: boolean;
    quoteRequestId: string;
    isFrozen: boolean;
    isCustomPartQuote: boolean;
}) {
    const { control } = useFormContext<{ rows: RowItem[] }>();
    return (
        <Controller
            control={control}
            name="rows"
            rules={{
                validate: (rows) => {
                    if (rows.some((row) => validateLineItem(row).status === 'error')) {
                        return t`Your form contains errors. Please fix them before submitting.`;
                    }
                    return true;
                },
            }}
            render={({ field }) => (
                <>
                    <FieldQuoteRequestLineItemTable
                        quoteRequestId={quoteRequestId}
                        showCustomerName={showCustomerName}
                        showTargetPrice={showTargetPrice}
                        value={field.value}
                        isFrozen={isFrozen}
                        isCustomPartQuote={isCustomPartQuote}
                        onChange={(value) => {
                            field.onChange(value);
                            field.onBlur();
                        }}
                    />
                </>
            )}
        />
    );
}

function createEditableColumn<TKey extends keyof RowItem>(
    key: TKey,
    field: Field<Exclude<RowItem[TKey], undefined | null>>,
    overrides?: { description?: () => string; initialPinning?: 'left' | 'right'; size?: number },
) {
    const description = overrides?.description ?? field.description;
    const size = overrides?.size ?? 140;

    if (field.kind === 'boolean') {
        const identity = <T,>(x: T) => x;
        const mapValue = field.mapValue ?? identity;
        return columnHelper.enum((row) => mapValue(Boolean(row[key])), {
            size,
            id: field.id,
            label: field.label,
            initialPinning: overrides?.initialPinning,
            description,
            getOptionLabel: (value) => field.formatter(value),
            cell: ({ row, sharedContext }) => {
                const identity = <T,>(x: T) => x;
                const mapValue = field.mapValue ?? identity;
                const value = mapValue(
                    // @ts-expect-error
                    row.original[key],
                );
                return (
                    <Checkbox
                        size="small"
                        onChange={(e) => {
                            e.stopPropagation();
                            // @ts-expect-error
                            sharedContext.setCell(row.original, key, !Boolean(value));
                        }}
                        readOnly={sharedContext.isFrozen}
                        checked={Boolean(value)}
                    />
                );
            },
        });
    }

    if (field.kind === 'monetary-value') {
        return columnHelper.monetaryValue(
            (row) =>
                // @ts-expect-error
                row[key] ?? field.defaultValue,
            {
                size,
                id: field.id,
                label: field.label,
                initialPinning: overrides?.initialPinning,
                description,
                align: 'left',
                disableTableCell: true,
                cell: ({ row, sharedContext }) => {
                    const identity = <T,>(x: T) => x;
                    const mapValue = field.mapValue ?? identity;
                    // @ts-expect-error
                    const value = mapValue(row.original[key]);
                    const inputField = assertPresent(field.inputField);

                    const renderFn = field.render ?? field.formatter;

                    const validate = validators[key];

                    return (
                        <EditableCell<MonetaryValue | null>
                            readonly={sharedContext.isFrozen}
                            value={value ?? null}
                            Field={inputField}
                            onChange={(newValue) => {
                                sharedContext.setCell(
                                    row.original,
                                    key,
                                    // @ts-expect-error
                                    newValue ?? undefined,
                                );
                            }}
                            status={validate(
                                // @ts-expect-error
                                value,
                                row.original,
                            )}
                        >
                            {renderFn(value)}
                        </EditableCell>
                    );
                },
            },
        );
    }

    if (field.kind === 'number') {
        return columnHelper.number(
            (row) =>
                field.formatter(
                    // @ts-expect-error
                    row[key] ?? field.defaultValue,
                ),
            {
                size,
                id: field.id,
                label: field.label,
                initialPinning: overrides?.initialPinning,
                description,
                align: 'left',
                disableTableCell: true,
                cell: ({ row, sharedContext }) => {
                    const identity = <T,>(x: T) => x;
                    const mapValue = field.mapValue ?? identity;
                    // @ts-expect-error
                    const value = mapValue(row.original[key]);
                    const inputField = assertPresent(field.inputField);

                    const renderFn = field.render ?? field.formatter;

                    const validate = validators[key];

                    return (
                        <EditableCell
                            readonly={sharedContext.isFrozen}
                            value={value ?? null}
                            Field={inputField}
                            onChange={(newValue) => {
                                sharedContext.setCell(
                                    row.original,
                                    key,
                                    // @ts-expect-error
                                    newValue ?? undefined,
                                );
                            }}
                            status={validate(
                                // @ts-expect-error
                                value,
                                row.original,
                            )}
                        >
                            {renderFn(value)}
                        </EditableCell>
                    );
                },
            },
        );
    }

    return columnHelper.text(
        (row) =>
            field.formatter(
                // @ts-expect-error
                row[key] ?? field.defaultValue,
            ),
        {
            size,
            id: field.id,
            label: field.label,
            description,
            initialPinning: overrides?.initialPinning,
            align: 'left',
            disableTableCell: true,
            cell: ({ row, sharedContext }) => {
                const identity = <T,>(x: T) => x;
                const mapValue = field.mapValue ?? identity;
                // @ts-expect-error
                const value = mapValue(row.original[key]);

                const renderFn = field.render ?? field.formatter;

                const validate = validators[key];
                const inputField = assertPresent(field.inputField);

                return (
                    <EditableCell<any | null>
                        readonly={sharedContext.isFrozen}
                        value={value ?? null}
                        Field={inputField}
                        onChange={(newValue) => {
                            sharedContext.setCell(row.original, key, newValue ?? undefined);
                        }}
                        status={validate(
                            // @ts-expect-error
                            mapValue(row.original[key]),
                            row.original,
                        )}
                    >
                        {/* @ts-expect-error */}
                        {renderFn(value)}
                    </EditableCell>
                );
            },
        },
    );
}

const allColumns = [
    selectedColumn,
    bidColumn,
    statusColumn,
    ipnColumn,
    descriptionColumn,
    requestedPartColumn,
    requiredQuantityColumn,
    potentialQuantityColumn,
    customerNameColumn,
    targetPriceColumn,
    recipientsColumn,
    offeredPartColumn,
    unitPriceColumn,
    unitPriceQuantityColumn,
    totalPriceColumn,
    supplierPartNumberColumn,
    packagingColumn,
    moqColumn,
    mpqColumn,
    stdFactoryLeadTimeColumn,
    stdFactoryLeadTimeUnitColumn,
    cancellationWindowColumn,
    cancellationWindowUnitColumn,
    itemClassColumn,
    ncnrColumn,
    stockColumn,
    oneTimeCostsColumn,
    validFromColumn,
    validUntilColumn,
    notesColumn,
    actionsColumn,
];

function useColumns({
    isCustomPartQuote,
    showCustomerName,
    showTargetPrice,
}: {
    isCustomPartQuote: boolean;
    showCustomerName: boolean;
    showTargetPrice: boolean;
}) {
    const { data: hiddenColumns } = useSuspenseHttpQuery(
        'GET /organization-settings/supplier-portal-settings',
        {},
        {
            select: (data): Record<string, boolean> => {
                if (isCustomPartQuote) {
                    const columns = data.custom_part_quote_columns ?? {};

                    const selected = columns.selected?.hidden ?? false;
                    const ipn = columns.ipn?.hidden ?? true;
                    const moq = columns.moq?.hidden ?? false;
                    const mpq = columns.mpq?.hidden ?? true;
                    const quantity = columns.quantity?.hidden ?? false;
                    const potentialQuantity = columns.potentialQuantity?.hidden ?? true;
                    const unitPrice = columns.unitPrice?.hidden ?? false;
                    const totalPrice = columns.totalPrice?.hidden ?? false;
                    const pricePer = columns.pricePer?.hidden ?? true;
                    const notes = columns.notes?.hidden ?? false;
                    const ncnr = columns.ncnr?.hidden ?? true;
                    const packaging = columns.packaging?.hidden ?? true;
                    const validFrom = columns.validFrom?.hidden ?? false;
                    const validUntil = columns.validUntil?.hidden ?? false;
                    const itemClass = columns.itemClass?.hidden ?? true;
                    const supplierPartNumber = columns.supplierPartNumber?.hidden ?? true;
                    const stock = columns.stock?.hidden ?? true;
                    const bid = columns.bid?.hidden ?? true;
                    const cancellationWindow = columns.cancellationWindow?.hidden ?? true;
                    const cancellationWindowUnit = columns.cancellationWindowUnit?.hidden ?? true;
                    const stdFactoryLeadTime = columns.stdFactoryLeadTime?.hidden ?? false;
                    const stdFactoryLeadTimeUnit = columns.stdFactoryLeadTimeUnit?.hidden ?? false;
                    const oneTimeCosts = columns.oneTimeCosts?.hidden ?? false;
                    const targetPrice = !showTargetPrice;
                    const customerName = !showCustomerName;
                    // always visible for custom part quotes
                    const actions = false;

                    return {
                        bid,
                        ipn,
                        cancellationWindow,
                        cancellationWindowUnit,
                        customerName,
                        itemClass,
                        moq,
                        mpq,
                        ncnr,
                        notes,
                        oneTimeCosts,
                        packaging,
                        potentialQuantity,
                        pricePer,
                        quantity,
                        selected,
                        stdFactoryLeadTime,
                        stdFactoryLeadTimeUnit,
                        stock,
                        supplierPartNumber,
                        targetPrice,
                        totalPrice,
                        unitPrice,
                        validFrom,
                        validUntil,
                        actions,
                    };
                }

                const columns = data.quote_columns ?? {};

                const selected = columns.selected?.hidden ?? false;
                const ipn = columns.ipn?.hidden ?? false;
                const moq = columns.moq?.hidden ?? false;
                const mpq = columns.mpq?.hidden ?? false;
                const quantity = columns.quantity?.hidden ?? false;
                const potentialQuantity = columns.potentialQuantity?.hidden ?? false;
                const unitPrice = columns.unitPrice?.hidden ?? false;
                const totalPrice = columns.totalPrice?.hidden ?? false;
                const pricePer = columns.pricePer?.hidden ?? false;
                const notes = columns.notes?.hidden ?? false;
                const ncnr = columns.ncnr?.hidden ?? false;
                const packaging = columns.packaging?.hidden ?? false;
                const validFrom = columns.validFrom?.hidden ?? false;
                const validUntil = columns.validUntil?.hidden ?? false;
                const itemClass = columns.itemClass?.hidden ?? false;
                const supplierPartNumber = columns.supplierPartNumber?.hidden ?? false;
                const stock = columns.stock?.hidden ?? false;
                const bid = columns.bid?.hidden ?? false;
                const cancellationWindow = columns.cancellationWindow?.hidden ?? false;
                const cancellationWindowUnit = columns.cancellationWindowUnit?.hidden ?? false;
                const stdFactoryLeadTime = columns.stdFactoryLeadTime?.hidden ?? false;
                const stdFactoryLeadTimeUnit = columns.stdFactoryLeadTimeUnit?.hidden ?? false;
                const oneTimeCosts = columns.oneTimeCosts?.hidden ?? true;
                const targetPrice = !showTargetPrice;
                const customerName = !showCustomerName;
                // Never visible for standard part quotes
                const actions = true;
                return {
                    bid,
                    ipn,
                    cancellationWindow,
                    cancellationWindowUnit,
                    customerName,
                    itemClass,
                    moq,
                    mpq,
                    ncnr,
                    notes,
                    oneTimeCosts,
                    packaging,
                    potentialQuantity,
                    pricePer,
                    quantity,
                    selected,
                    stdFactoryLeadTime,
                    stdFactoryLeadTimeUnit,
                    stock,
                    supplierPartNumber,
                    targetPrice,
                    totalPrice,
                    unitPrice,
                    validFrom,
                    validUntil,
                    actions,
                };
            },
        },
    );

    // Validate that all hidden column IDs exist in the actual columns
    const columnIds = new Set(allColumns.map((col) => col.id));
    const invalidHiddenColumns = Object.keys(hiddenColumns).filter((id) => !columnIds.has(id));
    if (invalidHiddenColumns.length > 0) {
        // eslint-disable-next-line no-console
        console.error(`Hidden columns contains invalid column IDs: ${invalidHiddenColumns.join(', ')}`);
    }

    const result = React.useMemo(() => {
        const visibleColumns = allColumns.filter((col) => !hiddenColumns[col.id ?? '']);
        const numberOfLeftAlignedColumns = visibleColumns.filter((col) => col.meta?.initialPinning === 'left').length;
        const numberOfReadonlyColumns = visibleColumns.filter((col) => {
            const readOnlyColumnIds = [
                ipnColumn.id,
                descriptionColumn.id,
                requestedPartColumn.id,
                requiredQuantityColumn.id,
                potentialQuantityColumn.id,
                customerNameColumn.id,
                targetPriceColumn.id,
                recipientsColumn.id,
            ];

            return readOnlyColumnIds.includes(col.id ?? '');
        }).length;

        return {
            numberOfLeftAlignedColumns,
            numberOfReadonlyColumns,
            visibleColumns,
        };
    }, [hiddenColumns]);

    return result;
}
