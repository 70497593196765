import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface EveCredentialsDTO extends r.Static<typeof EveCredentialsDTORuntype> {}
export const EveCredentialsDTORuntype = r.Union(
    r.Record({
        customer_number: r.String,
        api_key: r.String,
    }),
);

export interface EveCredentialsInputDTO extends r.Static<typeof EveCredentialsInputDTORuntype> {}
export const EveCredentialsInputDTORuntype = r.Record({
    customer_number: r.String.optional(),
    api_key: r.String.optional(),
});

export interface EveResponseBodyDTO extends r.Static<typeof EveResponseBodyDTORuntype> {}
export const EveResponseBodyDTORuntype = r.Record({
    data: EveCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
