import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { LimitType, OrganizationSolutionPreferenceDTO, PriceType, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { OrganizationSolutionPreferencesFromValue } from '../../modules/Settings/OrganizationSettings/common/SupplierFormValues';
import { sleep } from '../../utils/sleep';
import { useToken } from '../hooks';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { useSuspenseHttpQuery } from '../http/useHttpQuery';

export function useOrganizationSolutionPreferences() {
    return useSuspenseHttpQuery(
        'GET /organization-settings/organization-solution-preference',
        {},
        {
            staleTime: 60_000,
            select: (res) => res.data,
        },
    );
}

const createOrganizationSolutionPreferenceData = (
    values: OrganizationSolutionPreferencesFromValue,
): OrganizationSolutionPreferenceDTO => {
    const wrapDaysInLimit = (days: number | undefined | null) => {
        if (!isPresent(days)) {
            return {
                type: LimitType.Never,
            };
        }

        return {
            type: LimitType.Days,
            number: days,
        };
    };

    const offerAgeLimit = {
        expiring_before_validity_date: wrapDaysInLimit(values.offerAgeLimit.expiringBeforeValidityDate),
        expired_after_validity_date: wrapDaysInLimit(values.offerAgeLimit.expiredAfterValidityDate),
        old_after_update: wrapDaysInLimit(values.offerAgeLimit.oldAfterUpdateDays),
        outdated_after_update: wrapDaysInLimit(values.offerAgeLimit.outdatedAfterUpdateDays),
    };
    const excludedPriceTypes = [];
    if (values.excludedPriceTypes.listPrice) {
        excludedPriceTypes.push(PriceType.ListPrice);
    }
    if (values.excludedPriceTypes.contractPrice) {
        excludedPriceTypes.push(PriceType.ContractPrice);
    }
    if (values.excludedPriceTypes.quotePrice) {
        excludedPriceTypes.push(PriceType.QuotePrice);
    }
    if (values.excludedPriceTypes.customerNegotiatedPrice) {
        excludedPriceTypes.push(PriceType.CustomerNegotiatedPrice);
    }
    if (values.excludedPriceTypes.purchasePrice) {
        excludedPriceTypes.push(PriceType.PurchasePrice);
    }
    if (values.excludedPriceTypes.standardPrice) {
        excludedPriceTypes.push(PriceType.StandardPrice);
    }
    if (values.excludedPriceTypes.targetPrice) {
        excludedPriceTypes.push(PriceType.TargetPrice);
    }

    return {
        preferred_suppliers_and_stock_locations: values.preferredSuppliersAndStockLocations,
        approved_suppliers_and_stock_locations: values.approvedSuppliersAndStockLocations,
        excluded_suppliers_and_stock_locations: values.excludedSuppliersAndStockLocations,
        preferred_inventory_sites: values.preferredInventorySites,
        approved_inventory_sites: values.approvedInventorySites,
        excluded_inventory_sites: values.excludedInventorySites,
        inventory_preference: values.inventoryPreference,
        use_unit_price: values.useUnitPrice,
        packaging_preference: values.packagingPreference,
        prefer_direct_api_integrations: values.preferDirectApiIntegrations,
        exclude_mismatched_price_points: values.excludeMismatchedPricePoints,
        prioritize_preferred_manufacturers: values.prioritizePreferredManufacturers,
        offer_age_limit: offerAgeLimit,
        excluded_price_types: excludedPriceTypes,
        prefer_customer_negotiated_prices: values.preferCustomerNegotiatedPrices,
    };
};

export const useMutationUpdateOrganizationSolutionPreferences = () => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: OrganizationSolutionPreferencesFromValue) => {
            return http(
                'PATCH /organization-settings/organization-solution-preference',
                {
                    requestBody: createOrganizationSolutionPreferenceData(values),
                },
                token,
            );
        },
        onError,
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /organization-settings/organization-solution-preference'),
                }),
                // Wait for the query to be refetched before showing the success message
                sleep(500),
            ]);

            enqueueSnackbar(t`Global solution preferences updated`, { variant: 'success' });
        },
    });
};
