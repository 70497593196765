import * as r from 'runtypes';

export interface ManufacturerDTO extends r.Static<typeof ManufacturerDTORuntype> {}
export const ManufacturerDTORuntype = r.Record({
    alternative_names: r.Array(r.String),
    id: r.String,
    name: r.String,
});

export interface ManufacturerWithPartCountDTO extends r.Static<typeof ManufacturerWithPartCountDTORuntype> {}
export const ManufacturerWithPartCountDTORuntype = ManufacturerDTORuntype.extend({
    part_count: r.Number,
});

export interface ManufacturerLineCardDTO extends r.Static<typeof ManufacturerLineCardDTORuntype> {}

export const ManufacturerLineCardDTORuntype = r.Record({
    manufacturer_id: r.String,
    supported_by: r.Array(r.String),
});
