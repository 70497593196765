import { MonetaryValue, indexBy, isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { useTanStackTable } from '@luminovo/design-system';
import {
    CustomComponentFull,
    CustomFullPart,
    CustomOptionOfferDTO,
    CustomPartOfferPcbPanelSpecification,
    FullSourcingDTO,
    OtsComponentFull,
    OtsFullPart,
    PCBV2,
    RfqContext,
    SolutionConfigurationDTO,
    SolutionDTO,
    SolutionTag,
    SourcingScenarioDTO,
    StandardPartOfferDTO,
    isCustomComponentFull,
    isCustomFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import {
    Solution,
    hasSolutionTag,
    isCustomComponentOffer,
    isCustomPartOffer,
    isInternalPartNumberOffer,
    isOffTheShelfPartOffer,
    parseSolution,
} from '@luminovo/sourcing-core';
import React from 'react';
import { useInventorySites } from '../../../resources/organizationSettings/sitesHandler';
import {
    useCustomPartsFromOffers,
    usePcbDetailsOfOffer,
    useStandardPartsFromOffers,
} from '../../../resources/part/partHandler';
import { useSolutionsOfSolutionConfiguration } from '../../../resources/solution/solutionHandler';
import { useSolutionConfiguration } from '../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { useSourcingFull } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { getNumberOfPcbsPerPanel, getPanelDimension } from '../../Pcb/PanelizationTab/utils/getPanelDimension';
import { useTotalCostOfOwnershipRules } from '../../Settings/OrganizationSettings/pages/TotalCostOfOwnershipRulesPage/TotalCostOfOwnershipRulesPage';
import { useOfferDrawer } from './OfferDrawer';
import {
    ColumnOfferNotes,
    columnAwardedSolutions,
    columnCurrency,
    columnExcessMaterial,
    columnFactoryLeadTime,
    columnLinkedPart,
    columnMOQ,
    columnMPQ,
    columnOfferCreationDate,
    columnOfferLeadTime,
    columnOfferNumber,
    columnOfferPriceType,
    columnOfferStock,
    columnOneTimeCosts,
    columnOrigin,
    columnPackaging,
    columnPanelPrice,
    columnPricePerMeterSquare,
    columnSelection,
    columnSkuNumber,
    columnSolutionTags,
    columnSourcingBatchSize,
    columnStatus,
    columnSupplier,
    columnTcoUnitPrice,
    columnTotalPrice,
    columnTotalPriceOriginalCurrency,
    columnUnitOfMeasurement,
    columnUnitPrice,
    columnUnitPriceOriginalCurrency,
    columnUnitTcoCost,
    columnVolumeInM2,
} from './columns';
import { SolutionTableData, SolutionTableSharedContext } from './types';

const columns = [
    columnSelection,
    columnLinkedPart,
    columnSupplier,
    columnAwardedSolutions,
    columnSkuNumber,
    columnPackaging,
    columnMOQ,
    columnMPQ,
    columnOfferLeadTime,
    columnOfferStock,
    columnUnitPrice,
    columnUnitTcoCost,
    columnTcoUnitPrice,
    columnOneTimeCosts({ initialVisibility: false }),
    columnExcessMaterial,
    columnTotalPrice,
    columnCurrency,
    columnUnitPriceOriginalCurrency,
    columnTotalPriceOriginalCurrency,
    columnUnitOfMeasurement,
    columnOfferPriceType,
    columnFactoryLeadTime,
    ColumnOfferNotes({ initialVisibility: false }),
    columnOrigin,
    columnOfferCreationDate,
    columnSolutionTags,
    columnStatus,
];

const columnsCustomParts = [
    columnSelection,
    columnLinkedPart,
    columnSupplier,
    columnAwardedSolutions,
    columnSourcingBatchSize,
    columnOfferLeadTime,
    columnOneTimeCosts({ initialVisibility: true }),
    columnUnitPrice,
    columnExcessMaterial,
    columnTotalPrice,
    columnCurrency,
    columnUnitPriceOriginalCurrency,
    columnTotalPriceOriginalCurrency,
    columnUnitOfMeasurement,
    columnOfferPriceType,
    ColumnOfferNotes({ initialVisibility: true }),
    columnOfferNumber,
    columnOrigin,
    columnStatus,
];

const columnsPcbCustomParts = [...columnsCustomParts, columnVolumeInM2, columnPricePerMeterSquare, columnPanelPrice];

function createSolutionTableData({
    solutions,
    standardPartOffersById,
    customPartOffersById,
    solutionConfiguration,
    standardParts,
    customParts,
    showInventorySiteName,
    sourcingFull,
    pcbDetails,
}: {
    standardParts: Array<OtsFullPart | OtsComponentFull>;
    customParts: Array<CustomFullPart | CustomComponentFull>;
    standardPartOffersById: Map<string, StandardPartOfferDTO>;
    customPartOffersById: Map<string, CustomOptionOfferDTO>;
    solutions: SolutionDTO[];
    solutionConfiguration: SolutionConfigurationDTO;
    showInventorySiteName: boolean;
    sourcingFull: FullSourcingDTO | undefined;
    pcbDetails: PCBV2 | undefined;
}): Array<SolutionTableData | undefined> {
    const hasManualSelectedSolution = solutions.some((solution) =>
        hasSolutionTag(parseSolution(solution), SolutionTag.Selected),
    );
    const awardedOffers = isPresent(sourcingFull)
        ? [
              ...sourcingFull.off_the_shelf_offers.items,
              ...sourcingFull.inventory_offers.items,
              ...sourcingFull.custom_part_offers.items,
          ]
        : [];

    const selectedSolution = solutions.find(
        (solution) =>
            hasSolutionTag(parseSolution(solution), SolutionTag.Selected) ||
            hasSolutionTag(parseSolution(solution), SolutionTag.AutoSelected),
    );

    const selectedSupplier = isPresent(selectedSolution)
        ? (standardPartOffersById.get(parseSolution(selectedSolution).firstPurchaseOption.offer)?.linked_location ??
          customPartOffersById.get(parseSolution(selectedSolution).firstPurchaseOption.offer)?.linked_location)
        : undefined;

    return solutions.map((sol): SolutionTableData | undefined => {
        const solution = parseSolution(sol);

        const standardPartOffer = standardPartOffersById.get(solution.firstPurchaseOption.offer);
        const customPartOffer = customPartOffersById.get(solution.firstPurchaseOption.offer);
        const hasSelectedSupplier =
            isPresent(selectedSupplier) &&
            (standardPartOffer?.linked_location.id === selectedSupplier.id ||
                customPartOffer?.linked_location.id === selectedSupplier.id);

        const awardedSolutions =
            awardedOffers.filter((offer) => {
                if (isPresent(standardPartOffer)) {
                    return offer.linked_location.id === standardPartOffer.linked_location.id;
                }
                if (isPresent(customPartOffer)) {
                    return offer.linked_location.id === customPartOffer.linked_location.id;
                }
                return false;
            }).length - (hasSelectedSupplier ? 1 : 0);

        const standardPart = standardParts.find((p) => p.id === standardPartOffer?.linked_part.id);
        const customPart = customParts.find((p) => p.id === customPartOffer?.linked_part.id);

        const isSelected =
            hasSolutionTag(solution, SolutionTag.Selected) ||
            (!hasManualSelectedSolution && hasSolutionTag(solution, SolutionTag.AutoSelected));

        if (isPresent(standardPartOffer) && isOtsFullPart(standardPart) && isOffTheShelfPartOffer(standardPartOffer)) {
            return {
                partType: 'OffTheShelf',
                solutionConfiguration,
                solution,
                offer: standardPartOffer,
                linkedPart: standardPart,
                linkedLocation: standardPartOffer.linked_location,
                isSelected,
                awardedSolutions,
                showInventorySiteName,
            };
        }

        if (
            isPresent(standardPartOffer) &&
            isOtsComponentFull(standardPart) &&
            isInternalPartNumberOffer(standardPartOffer)
        ) {
            return {
                partType: 'InternalPartNumber',
                solutionConfiguration,
                solution,
                offer: standardPartOffer,
                linkedPart: standardPart,
                linkedLocation: standardPartOffer.linked_location,
                isSelected,
                awardedSolutions,
                showInventorySiteName,
            };
        }

        if (isPresent(customPartOffer) && isCustomFullPart(customPart) && isCustomPartOffer(customPartOffer)) {
            const panel = customPartOffer.specification?.data.panel;
            const { offerVolumeInM2, pricePerMeterSquare } =
                pcbDetails && panel
                    ? calculatePricePerSquareMeter({ panel, pcb: pcbDetails, solutionConfiguration, solution })
                    : {
                          offerVolumeInM2: undefined,
                          pricePerMeterSquare: null,
                      };

            return {
                partType: 'CustomPart',
                solutionConfiguration,
                solution,
                offer: { ...customPartOffer, packaging: null, supplier_part_number: null },
                linkedPart: customPart,
                linkedLocation: customPartOffer.linked_location,
                isSelected,
                awardedSolutions,
                showInventorySiteName,
                offerVolumeInM2,
                pricePerMeterSquare,
            };
        }

        if (
            isPresent(customPartOffer) &&
            isCustomComponentFull(customPart) &&
            isCustomComponentOffer(customPartOffer)
        ) {
            return {
                partType: 'CustomComponent',
                solutionConfiguration,
                solution,
                offer: { ...customPartOffer, packaging: null, supplier_part_number: null },
                linkedPart: customPart,
                linkedLocation: customPartOffer.linked_location,
                isSelected,
                awardedSolutions,
                showInventorySiteName,
            };
        }

        return undefined;
    });
}

const calculatePricePerSquareMeter = ({
    panel,
    pcb,
    solutionConfiguration,
    solution,
}: {
    panel: CustomPartOfferPcbPanelSpecification;
    pcb: PCBV2;
    solutionConfiguration: SolutionConfigurationDTO;
    solution: Solution;
}) => {
    if (!panel) {
        return { offerVolumeInM2: undefined, pricePerMeterSquare: null };
    }

    const { x: panelWidth, y: panelHeight } = getPanelDimension(panel, pcb);

    if (!panelWidth || !panelHeight) {
        return { offerVolumeInM2: undefined, pricePerMeterSquare: null };
    }

    const numberOfPcbsPerShippingPanel = getNumberOfPcbsPerPanel(panel);
    const quantity = solutionConfiguration.aggregated_quantity.quantity;
    const offerVolumeInMM2 = panelWidth * panelHeight * (quantity / numberOfPcbsPerShippingPanel);
    const offerVolumeInM2 = offerVolumeInMM2 / 1_000_000;

    const totalPrice = solution.totalPrice?.original_total_price;
    const pricePerMeterSquare: MonetaryValue | null =
        totalPrice && offerVolumeInM2
            ? {
                  amount: (parseFloat(totalPrice.amount) / offerVolumeInM2).toString(),
                  currency: totalPrice.currency,
              }
            : null;

    return { offerVolumeInM2, pricePerMeterSquare };
};

function useSolutionsTableData({
    rfqId,
    solutionConfigurationId,
    sourcingScenarioDTO,
}: {
    rfqId: string;
    solutionConfigurationId: string;
    sourcingScenarioDTO?: SourcingScenarioDTO;
}) {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);
    const { data: solutions } = useSolutionsOfSolutionConfiguration(solutionConfiguration);
    const { data: sourcingFull } = useSourcingFull(sourcingScenarioDTO?.id);
    const { data: standardParts } = useStandardPartsFromOffers(solutions?.standard_part_offers, rfqContext);
    const { data: customParts } = useCustomPartsFromOffers(solutions?.custom_part_offers, rfqContext);
    const { data: inventorySites } = useInventorySites();
    const { data: pcbDetails } = usePcbDetailsOfOffer(solutions?.custom_part_offers);

    const data = React.useMemo(() => {
        if (!solutionConfiguration || !solutions || !standardParts || !customParts || !inventorySites) {
            return undefined;
        }

        const results = createSolutionTableData({
            solutionConfiguration,
            solutions: solutions.data,
            standardPartOffersById: indexBy(solutions.standard_part_offers, (x) => x.id),
            customPartOffersById: indexBy(solutions.custom_part_offers, (x) => x.id),
            standardParts,
            customParts,
            showInventorySiteName: inventorySites.length > 1,
            sourcingFull,
            pcbDetails,
        }).filter(isPresent);

        // Special case for PCB offer - we want to show invalid specification solutions only if all solutions have the InvalidSpecification tag.
        // As soon as there is one valid solution, we want to hide all invalid specification solutions.
        const hasOnlyInvalidSpecification = results.every(({ solution }) =>
            hasSolutionTag(solution, SolutionTag.InvalidSpecification),
        );

        return hasOnlyInvalidSpecification
            ? results
            : results.filter(({ solution }) => !hasSolutionTag(solution, SolutionTag.InvalidSpecification));
    }, [solutionConfiguration, solutions, standardParts, customParts, inventorySites, sourcingFull, pcbDetails]);

    return { data: data, isLoading: !isPresent(data) };
}

function useSolutionsTableSharedContext({
    rfqId,
    solutionConfigurationId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
}): SolutionTableSharedContext {
    const { data: totalCostOfOwnershipRules = [] } = useTotalCostOfOwnershipRules();

    const init: Record<string, string> = {};
    let tcoRuleNameMap = totalCostOfOwnershipRules.reduce((agg, tcoRule) => {
        agg[tcoRule.id] = tcoRule.name;
        return agg;
    }, init);

    return {
        rfqId,
        solutionConfigurationId,
        tcoRuleNameMap,
    };
}

export function useSolutionsTableState({
    rfqId,
    solutionConfigurationId,
    sourcingScenarioDTO,
    columnsKey,
}: {
    rfqId: string;
    solutionConfigurationId: string;
    sourcingScenarioDTO?: SourcingScenarioDTO;
    columnsKey: 'pcb-custom-part-solution-manager' | 'custom-part-solution-manager' | 'standard-part-solution-manager';
}) {
    const sharedContext = useSolutionsTableSharedContext({ solutionConfigurationId, rfqId });
    const { data, isLoading } = useSolutionsTableData({ solutionConfigurationId, rfqId, sourcingScenarioDTO });
    const hasOtsParts = data?.some((item) => item.partType === 'OffTheShelf');
    const hasCustomParts = data?.some((item) => item.partType === 'CustomPart');

    if (isLoading && hasOtsParts && hasCustomParts) {
        throwErrorUnlessProduction(new Error('Solution manager items contain both OffTheShelf and Custom parts'));
    }

    const { openOfferDrawer } = useOfferDrawer();

    const { table } = useTanStackTable({
        columns:
            columnsKey === 'custom-part-solution-manager'
                ? columnsCustomParts
                : columnsKey === 'pcb-custom-part-solution-manager'
                  ? columnsPcbCustomParts
                  : columns,
        data,
        sharedContext,
        columnsKey,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enablePersistentColumnVisibility: true,
        enablePersistentScrollPosition: false,
        enableSaveAsDefault: `solution-manager-${rfqId}`,
        initialState: {
            columnPinning: {
                left: ['selection'],
                right: ['status'],
            },
        },
        onRowClick: (row) => {
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'WithinRfQ', rfq_id: rfqId },
                solution: row.original.solution,
                solutionConfigurationId: solutionConfigurationId,
            });
        },
    });

    return { table, isLoading, items: data };
}
