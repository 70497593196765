import { t } from '@lingui/macro';
import { Dialog, DialogTitle } from '@luminovo/design-system';
import { DialogContent } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { EditManufacturingScenarioForm } from './EditManufacturingScenarioForm';
import { BaseManufacturingScenarioFormState } from './types';

export type ManufacturingScenarioInfo = {
    id: string;
    name: string;
    notes: string | undefined;
    batchSizes: number[];
    sourcingScenarioId: string | undefined;
};

export const useEditManufacturingScenarioDialog = ({
    rfqId,
    assemblyId,
    manufacturingScenario: { id, name, notes, batchSizes, sourcingScenarioId },
}: {
    rfqId: string;
    assemblyId: string;
    manufacturingScenario: ManufacturingScenarioInfo;
}) => {
    const { setDialog, closeDialog } = useDialogContext();
    const { mutateAsync } = useHttpMutation('PATCH /manufacturing-scenarios/:manufacturingScenarioId', {
        snackbarMessage: t`Manufacturing scenario successfully updated`,
        onSuccess: (result) => {
            closeDialog();
        },
    });

    const onSubmit = async (form: BaseManufacturingScenarioFormState) => {
        await mutateAsync({
            requestBody: {
                id,
                name: form.manufacturingScenarioName,

                batch_sizes: form.batchSizes,
                sourcing_scenario: form.sourcingScenarioId,
                notes: form.notes ?? null,
            },
            pathParams: {
                manufacturingScenarioId: id,
            },
        });
    };

    const defaultValues: BaseManufacturingScenarioFormState = {
        manufacturingScenarioName: name,
        batchSizes,
        sourcingScenarioId,
        notes,
    };

    const dialog = (
        <Dialog open={true} maxWidth="xs" style={{ margin: '24px' }}>
            <DialogTitle title={t`Edit manufacturing scenario`} handleClose={closeDialog} />
            <DialogContent>
                <EditManufacturingScenarioForm
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                />
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};
