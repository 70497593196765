import * as r from 'runtypes';
import { FormulaScriptErrorRuntype } from './formulaScriptErrorBackendTypes';

const ErrorResponseRuntype = r.Record({
    result: r.Literal('Error'),
    details: FormulaScriptErrorRuntype,
});

const OkResponseRuntype = r.Record({
    result: r.Literal('Ok'),
    details: r.Record({
        identifiers: r.Array(r.String),
    }),
});
export type FormulaOKResponseDTO = r.Static<typeof OkResponseRuntype>;

export const FormulaResponseRuntype = ErrorResponseRuntype.Or(OkResponseRuntype);

export type FormulaResponseDTO = r.Static<typeof FormulaResponseRuntype>;

export const FormulaPostRuntype = r.Record({
    formula: r.String,
});

export const FormulaTestPostRuntype = r.Record({
    formula: r.String,
    inputs: r.Dictionary(r.String, r.String),
});

const OkTestResponseRuntype = r.Record({
    result: r.Literal('Ok'),
    details: r.Record({
        return_value: r.String,
    }),
});

export const FormulaTestResponseRuntype = ErrorResponseRuntype.Or(OkTestResponseRuntype);
export type FormulaTestResponseDTO = r.Static<typeof FormulaTestResponseRuntype>;
