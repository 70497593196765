import * as r from 'runtypes';

//These are the "known" integrations which we can append an intercom
// article and (if required) a label to.
export const OrbweaverIntegrationKnownRuntype = r.Union(
    r.Literal('Avnet'),
    r.Literal('Heilind'),
    r.Literal('RS Components'),
    r.Literal('SOS'),
);
export type OrbweaverIntegrationKnownDTO = r.Static<typeof OrbweaverIntegrationKnownRuntype>;
export const OrbweaverIntegrationRuntype = OrbweaverIntegrationKnownRuntype.Or(r.String);
export type OrbweaverIntegrationDTO = r.Static<typeof OrbweaverIntegrationRuntype>;

export const isOrbweaverIntegrationKnown = (value: string): value is OrbweaverIntegrationKnownDTO => {
    return OrbweaverIntegrationKnownRuntype.guard(value);
};

export interface OrbweaverLicenseDTO extends r.Static<typeof OrbweaverLicenseDTORuntype> {}
export const OrbweaverLicenseDTORuntype = r.Record({
    configured: r.Boolean,
    active: r.Array(OrbweaverIntegrationRuntype),
    inactive: r.Array(OrbweaverIntegrationRuntype),
});
