import { getTokenIfPresent } from '@luminovo/auth';
import { EndpointRegistry, http, HttpOptions, RegisteredHttpEndpoint } from '@luminovo/http-client';
import {
    QueryClient,
    queryOptions,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
    useSuspenseQuery,
    UseSuspenseQueryOptions,
    UseSuspenseQueryResult,
} from '@tanstack/react-query';
import * as r from 'runtypes';
import { httpQueryKey } from './httpQueryKey';

export type UseHttpQueryOptions<
    T extends RegisteredHttpEndpoint,
    TData = r.Static<EndpointRegistry[T]['responseBody']>,
> = Pick<
    UseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, Error, TData>,
    | 'enabled'
    | 'select'
    | 'placeholderData'
    | 'structuralSharing'
    | 'gcTime'
    | 'staleTime'
    | 'refetchInterval'
    | 'refetchOnMount'
    | 'refetchOnWindowFocus'
    | 'retry'
    | 'retryDelay'
    | 'notifyOnChangeProps'
    | 'meta'
    | 'throwOnError'
>;

export type UseSuspenseHttpQueryOptions<
    T extends RegisteredHttpEndpoint,
    TData = r.Static<EndpointRegistry[T]['responseBody']>,
> = Pick<
    UseSuspenseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, Error, TData>,
    | 'select'
    | 'structuralSharing'
    | 'gcTime'
    | 'staleTime'
    | 'refetchInterval'
    | 'refetchOnMount'
    | 'refetchOnWindowFocus'
    | 'retry'
    | 'retryDelay'
    | 'notifyOnChangeProps'
    | 'meta'
>;

/**
 * `useQuery` adapted for making type-safe HTTP requests
 */

export function httpQuery<T extends RegisteredHttpEndpoint, TData = r.Static<EndpointRegistry[T]['responseBody']>>(
    endpoint: T,
    options: HttpOptions<T>,
    useQueryOptions: UseHttpQueryOptions<T, TData> = {},
): UseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, Error, TData> {
    return queryOptions({
        queryKey: httpQueryKey(endpoint, options),
        queryFn: async () => http(endpoint, options, getTokenIfPresent()),
        ...useQueryOptions,
    });
}

export function suspenseHttpQuery<
    T extends RegisteredHttpEndpoint,
    TData = r.Static<EndpointRegistry[T]['responseBody']>,
>(
    endpoint: T,
    options: HttpOptions<T>,
    useQueryOptions: UseSuspenseHttpQueryOptions<T, TData> = {},
): UseSuspenseQueryOptions<r.Static<EndpointRegistry[T]['responseBody']>, Error, TData> {
    return queryOptions({
        queryKey: httpQueryKey(endpoint, options),
        queryFn: async () => http(endpoint, options, getTokenIfPresent()),
        ...useQueryOptions,
    });
}

/**
 * `useQuery` adapted for making type-safe HTTP requests
 */
export function useHttpQuery<T extends RegisteredHttpEndpoint, TData = r.Static<EndpointRegistry[T]['responseBody']>>(
    endpoint: T,
    options: HttpOptions<T>,
    useQueryOptions: UseHttpQueryOptions<T, TData> = {},
): UseQueryResult<TData, Error> {
    return useQuery(httpQuery(endpoint, options, useQueryOptions));
}

/**
 * `useSuspenseQuery` adapted for making type-safe HTTP requests that trigger suspense
 */
export function useSuspenseHttpQuery<
    T extends RegisteredHttpEndpoint,
    TData = r.Static<EndpointRegistry[T]['responseBody']>,
>(
    endpoint: T,
    options: HttpOptions<T>,
    useQueryOptions: UseSuspenseHttpQueryOptions<T, TData> = {},
): UseSuspenseQueryResult<TData, Error> {
    return useSuspenseQuery(suspenseHttpQuery(endpoint, options, useQueryOptions));
}

/**
 * A type-safe helper to invalidate the React Query cache for a given `endpoint`.
 * Will not compile when the `endpoint` argument is not a valid endpoint registered in `endpointRegistry`.
 *
 * @deprecated use httpQueryKey - Example `queryClient.invalidateQueries(httpQueryKey('GET / rfqs'))`
 */
export async function invalidateAllQueriesForEndpoint(
    endpoint: RegisteredHttpEndpoint,
    queryClient: QueryClient,
): Promise<void> {
    return queryClient.invalidateQueries({ queryKey: [endpoint] });
}

/**
 * A type-safe helper to invalidate the React Query cache for a given pair of `endpoint` and `options`.
 * @deprecated use httpQueryKey - Example `queryClient.invalidateQueries(httpQueryKey('GET / rfqs'))`
 */
export function invalidateQuery<T extends RegisteredHttpEndpoint>(
    endpoint: T,
    options: HttpOptions<T>,
    queryClient: QueryClient,
): Promise<void> {
    return queryClient.invalidateQueries({ queryKey: [endpoint, options] });
}
