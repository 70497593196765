import { omit } from '@luminovo/commons';
import * as React from 'react';
import { Checkbox, CheckboxProps } from '../../Checkbox/Checkbox';

export type FieldCheckboxProps = Omit<CheckboxProps, 'checked' | 'onChange'> & {
    value: boolean;

    onChange: (value: boolean) => void;

    onAfterChange?: (value: boolean) => void;
};

function FieldCheckboxInner(
    { value, onChange, onAfterChange, placeholder, ...rest }: FieldCheckboxProps,
    outerRef: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element {
    return (
        <Checkbox
            ref={outerRef}
            checked={value}
            onChange={(_, value) => {
                onChange(value);
                onAfterChange?.(value);
            }}
            {...omit(rest, 'error', 'helperText')}
        />
    );
}

export const FieldCheckbox = React.forwardRef(FieldCheckboxInner) as (
    props: FieldCheckboxProps & { ref?: React.ForwardedRef<HTMLButtonElement> },
) => JSX.Element;
