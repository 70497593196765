import * as r from 'runtypes';
import { DriverIdParameterRuntype } from '../driver';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import { ExpensePostRuntype, ExpenseRuntype } from './expensesBackendTypes';

export const expensesEndpoints = {
    'GET /expenses': endpoint({
        description: 'Returns an array of all expenses',
        pathParams: r.Undefined,

        queryParams: r.Record({
            active: r.Boolean.optional(),
            driver_id: DriverIdParameterRuntype.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(ExpenseRuntype),
        }),
    }),
    'GET /expenses/:expenseId': endpoint({
        description: 'Returns an expense',
        pathParams: r.Record({ expenseId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ExpenseRuntype,
        }),
    }),

    'POST /expenses': endpoint({
        description: 'Creates an expense',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ExpensePostRuntype,
        responseBody: r.Record({
            data: ExpenseRuntype,
        }),
    }),

    'DELETE /expenses/:expenseId': endpoint({
        description: 'Deletes an expense',
        pathParams: r.Record({ expenseId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),

    'PATCH /expenses/:expenseId': endpoint({
        description: 'Updates an expense',
        pathParams: r.Record({ expenseId: r.String }),
        queryParams: r.Undefined,
        requestBody: ExpensePostRuntype.Or(r.Record({ status: ManufacturingEntityStatusRuntype })),
        responseBody: r.Record({
            data: ExpenseRuntype,
        }),
    }),
};
