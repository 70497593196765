import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatCurrency } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { extractUnitPrice, isCustomPartOffer } from '@luminovo/sourcing-core';
import { HorizontalStackedBarChart, palette } from '@luminovo/viz';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { getTotalQuantity } from '../../../resources/sourcingScenario/getTotalQuantity';
import { ChartSpec } from './ChartSpec';
import { SolutionConfigurationsTableData } from './SolutionConfigurations/solutionConfigurationTypes';

type Datum = {
    id: string[];
    label: string;
    unitPrice?: number;
    consigned?: number;
    noOffers?: number;
    lineValue?: number;
};
type Keys = 'unitPrice' | 'consigned' | 'noOffers' | 'lineValue';

function generateLabel(datum: SolutionConfigurationsTableData) {
    const designatorLabel = datum.designators.join(', ');

    if (designatorLabel === 'PCB') {
        const lastBreadcrumb =
            datum.solutionConfigurationSourcing.aggregated_breadcrumbs.breadcrumbs.items.slice(-1)[0];
        const lastAssembly = lastBreadcrumb.parent_assemblies.slice(-1)[0];
        return `${designatorLabel} (${lastAssembly.name})`;
    }

    return designatorLabel;
}

export const chartSpecUnitPrice: ChartSpec<Keys, Datum> = {
    id: 'unitPrice',
    title: <Trans>Unit price by</Trans>,
    aggregate(a, b) {
        throw new Error("Unit price shouldn't aggregate");
    },
    keys: ['unitPrice', 'consigned', 'noOffers', 'lineValue'],
    groupBy: [
        {
            // Notice that designators can be duplicated in multi-assembly boms,
            // so we actually can't group by the designators.
            extractor: (x) => x.solutionConfigurationSourcing.id,
            label: <Trans>Designator</Trans>,
        },
    ],
    map(datum) {
        const id = [datum.solutionConfigurationSourcing.id];
        const label = generateLabel(datum);

        if (datum.solutionConfigurationSourcing.is_consigned) {
            return {
                id,
                label,
                consigned: 1,
            };
        }
        if (!datum.solution) {
            return {
                id,
                label,
                noOffers: 1,
            };
        }

        const unitPrice =
            datum.offer && isCustomPartOffer(datum.offer)
                ? // For custom part offers, we need to scale the unit price to account for the panel size
                  extractUnitPrice({ solution: datum.solution, extract: 'scaled' })
                : Number(datum.solution.unitPrice?.amount ?? 0);
        const aggregateQuantity = datum.solutionConfigurationSourcing.aggregated_quantity.quantity;
        const sourcingScenarioQuantity = getTotalQuantity(datum.sourcingScenario);
        const quantityPerDesignator = aggregateQuantity / sourcingScenarioQuantity;
        return {
            id,
            label,
            unitPrice: unitPrice,
            lineValue: unitPrice * quantityPerDesignator,
        };
    },
    orderBy: chainComparators(
        compareByNumber((a) => -(a.unitPrice ?? 0)),
        compareByNumber((d) => d.noOffers ?? 0),
        compareByNumber((a) => a.consigned ?? 0),
    ),
    render: ChartUnitPrice,
};

function ChartUnitPrice({
    rfqId,
    keys,
    data,
    onSelectDatum,
}: {
    rfqId: string;
    keys: Keys[];
    data: Datum[];
    onSelectDatum(datum: Datum): void;
}) {
    const { data: rfq } = useRfQ(rfqId);
    const currency = rfq?.currency;

    if (!currency) {
        return null;
    }

    return (
        <HorizontalStackedBarChart
            keys={keys}
            data={data}
            formatValue={(x) => formatCurrency(x, currency)}
            getTooltip={(key) => {
                if (key === 'lineValue') {
                    return [t`Unit price`, t`Designators`, t`Quantity per designator`].join(' × ');
                }
                return undefined;
            }}
            unstackedKey="lineValue"
            formatKey={(key) => {
                if (key === 'consigned') {
                    return t`Consigned`;
                }
                if (key === 'noOffers') {
                    return t`No offers`;
                }
                if (key === 'unitPrice') {
                    return t`Unit price`;
                }
                if (key === 'lineValue') {
                    return t`Line value`;
                }
                assertUnreachable(key);
            }}
            onBarClick={onSelectDatum}
            isMissingData={(datum, key) => {
                if (key === 'consigned' && datum.consigned) {
                    return true;
                }
                if (key === 'noOffers' && datum.noOffers) {
                    return true;
                }
                return false;
            }}
            getColor={(key) => {
                if (key === 'noOffers') {
                    return palette.error.high;
                }
                if (key === 'consigned') {
                    return palette.ok.high;
                }
                if (key === 'unitPrice') {
                    return palette.default[0];
                }
                if (key === 'lineValue') {
                    return palette.default[2];
                }

                assertUnreachable(key);
            }}
            width={800}
        />
    );
}
