import { Trans } from '@lingui/macro';
import * as icons from '@mui/icons-material';
import { Box, Collapse, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';
import { colorSystem } from '../../theme';
import { Dialog } from '../Dialog';
import { Highlight } from '../Highlight';
import { TextField } from '../TextField';
import { enrichCommandBarItems, findSelectedItem } from './model';
import { CommandBarItem, EnrichedCommandbarItem } from './types';

export function CommandBar({
    isOpen,
    onClose,
    onOpen,
    contents = [],
    initialQuery = '',
}: {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    contents?: CommandBarItem[];
    initialQuery?: string;
}): JSX.Element {
    const [selectedIndex, setSelectedIndex] = useState<undefined | number>(undefined);

    const handleClose = useCallback(() => {
        setSelectedIndex(undefined);
        onClose();
    }, [onClose]);

    const [search, setSearch] = useState(initialQuery);
    const navigate = useNavigate();

    const lowerCaseQuery = search.toLowerCase().trim();
    const enrichedContents = enrichCommandBarItems(contents, { query: lowerCaseQuery, selectedIndex });
    const selectedItem = findSelectedItem(enrichedContents);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.metaKey || event.ctrlKey) && (event.key === 'k' || event.key === 'p')) {
                event.preventDefault();
                if (isOpen) {
                    handleClose();
                } else {
                    onOpen();
                }
            } else if (event.key === 'ArrowDown' && isOpen) {
                event.preventDefault();
                setSelectedIndex((x) => (x === undefined ? 0 : x + 1));
            } else if (event.key === 'ArrowUp' && isOpen) {
                event.preventDefault();
                setSelectedIndex((x) => (x === undefined ? 0 : Math.max(0, x - 1)));
            } else if (event.key === 'Enter' && isOpen) {
                event.preventDefault();
                if (selectedItem && selectedItem.href) {
                    const isExternalLink = selectedItem.href.startsWith('http');
                    if (isExternalLink) {
                        window.open(selectedItem.href, '_blank', 'noopener,noreferrer');
                    } else {
                        navigate(selectedItem.href);
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isOpen, handleClose, onOpen, selectedItem, navigate]);

    if (contents.length === 0) {
        // don't render the command bar if there are no contents
        return <></>;
    }

    return (
        <CommandBarContainer isOpen={isOpen} onClose={handleClose}>
            <Box
                onMouseEnter={onOpen}
                sx={{
                    boxSizing: 'border-box',
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    paddingTop: 0,
                    borderRight: `1px solid ${colorSystem.neutral[2]}`,
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    gap: 1,
                    position: 'relative',
                }}
            >
                <TextField
                    sx={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        zIndex: 1,
                        paddingTop: 2,
                    }}
                    autoFocus
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <icons.Search color="primary" />
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">Ctrl+K</InputAdornment>,
                        },
                    }}
                    onFocus={(e) => e.target.select()}
                    type="search"
                    id="sidebar-search"
                    placeholder="Search menu"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    size="small"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setSelectedIndex(0);
                    }}
                />
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {enrichedContents.map((content, i) => {
                        return (
                            <CommandBarItemComponent
                                key={`${i}::${content.title}::${lowerCaseQuery}`}
                                query={lowerCaseQuery}
                                item={content}
                            />
                        );
                    })}
                    <Box sx={{ flex: 1 }} />
                </Box>
            </Box>
        </CommandBarContainer>
    );
}

const commonStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    padding: '4px 8px',
    cursor: 'pointer',
    borderRadius: 1,
    boxSizing: 'border-box',
    color: colorSystem.neutral[8],
    outline: 'none',
    border: `1px solid transparent`,
    '& .enter-icon': {
        opacity: 0,
    },
    '&[data-is-selected-item="true"]': {
        border: `1px solid ${colorSystem.primary[6]}`,
        background: colorSystem.primary[2],
        '& .enter-icon': {
            opacity: 1,
        },
    },
    '&:hover': {
        background: colorSystem.primary[1],
    },
};

function CommandBarItemSeparatorComponent({ item }: { item: EnrichedCommandbarItem<'separator'> }): JSX.Element {
    if (!item.isVisible) {
        return <></>;
    }
    return <Box sx={{ height: 16, flex: item.original.fill ? 1 : undefined }} />;
}

function CommandBarItemCategoryComponent({
    item,
    query,
    icon,
}: {
    item: EnrichedCommandbarItem<'category'>;
    query: string;
    icon: JSX.Element | undefined;
}): JSX.Element {
    const [isCategoryOpen, setIsCategoryOpen] = useState(item.original.defaultOpen ?? false);
    const isSearching = query.length > 0;

    const isOpen =
        isCategoryOpen ||
        isSearching ||
        Boolean(item.children?.length && item.children.some((child) => child.isSelected));

    if (!item.isVisible) {
        return <></>;
    }
    const isActive = false;

    const iconExpandMore = (
        <icons.ExpandMore
            style={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease-in-out',
            }}
            fontSize="small"
        />
    );

    return (
        <>
            <Box
                role="button"
                data-active={isActive}
                sx={{
                    ...commonStyles,
                    background: isActive ? colorSystem.primary[2] : colorSystem.neutral.white,
                    '&:hover': {
                        background: colorSystem.primary[1],
                    },
                    color: colorSystem.neutral[6],
                    marginBottom: '2px',
                }}
                onClick={() => setIsCategoryOpen((x) => !x)}
            >
                {icon}
                <Highlight overrides={{ Container: HighlightContainer }} label={item.title ?? ''} matcher={[query]} />
                <div style={{ flex: 1 }} />
                <EnterIcon />
                {iconExpandMore}
            </Box>
            <Collapse in={isOpen}>
                {item.children?.map((child, index) => {
                    return <CommandBarItemComponent key={index} query={query} item={child} />;
                })}
            </Collapse>
        </>
    );
}

function LinkContainer({
    children,
    href,
    isSelected,
    linkRef,
}: {
    children: React.ReactNode;
    href: string | undefined;
    isSelected: boolean;
    linkRef: React.RefObject<HTMLAnchorElement>;
}): JSX.Element {
    const isExternalLink = (href ?? '').startsWith('http');

    if (isExternalLink) {
        return (
            <a
                ref={linkRef}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                data-is-selected-item={isSelected}
                style={{
                    textDecoration: 'none',
                    outline: 'none',
                }}
            >
                {children}
            </a>
        );
    }

    return (
        <Link
            ref={linkRef}
            to={href ?? ''}
            data-is-selected-item={isSelected}
            style={{
                textDecoration: 'none',
                outline: 'none',
            }}
        >
            {children}
        </Link>
    );
}

function CommandBarItemLinkComponent({
    item,
    query,
    icon,
}: {
    item: EnrichedCommandbarItem<'link'>;
    query: string;
    icon: JSX.Element | undefined;
}): JSX.Element {
    const linkRef = React.useRef<HTMLAnchorElement>(null);
    React.useEffect(() => {
        if (item.isSelected) {
            linkRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [item.isSelected]);

    if (!item.isVisible) {
        return <></>;
    }

    return (
        <LinkContainer href={item.href} isSelected={item.isSelected} linkRef={linkRef}>
            <Box data-active={item.isActive} data-is-selected-item={item.isSelected} sx={commonStyles}>
                {icon}
                <Highlight
                    overrides={{ Container: HighlightContainer, Highlighter }}
                    label={item.title}
                    matcher={[query]}
                />
                <div style={{ flex: 1 }} />
                <EnterIcon />
            </Box>
        </LinkContainer>
    );
}

function CommandBarItemComponent({ query, item }: { query: string; item: EnrichedCommandbarItem }): JSX.Element {
    const icon = item.original.Icon && (
        <item.original.Icon
            sx={{
                color: colorSystem.neutral[6],
                width: 16,
            }}
        />
    );

    if (item.type === 'separator') {
        return <CommandBarItemSeparatorComponent item={item as EnrichedCommandbarItem<'separator'>} />;
    }

    if (item.type === 'category') {
        return (
            <CommandBarItemCategoryComponent
                item={item as EnrichedCommandbarItem<'category'>}
                query={query}
                icon={icon}
            />
        );
    }

    if (item.type === 'link') {
        return <CommandBarItemLinkComponent item={item as EnrichedCommandbarItem<'link'>} query={query} icon={icon} />;
    }

    throw new Error(`Invalid command bar item type: ${item.type}`);
}

const HighlightContainer = (props: any) => {
    return <span {...props} />;
};

const Highlighter = (props: any) => {
    return <span {...props} style={{ color: colorSystem.neutral[9], fontWeight: 500 }} />;
};

function CommandBarContainer(
    props: React.PropsWithChildren<{
        isOpen: boolean;
        onClose: () => void;
    }>,
): JSX.Element {
    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    position: 'fixed',
                    top: '5vh',
                    maxHeight: '600px',
                },
            }}
            maxWidth="sm"
            open={props.isOpen}
            onClose={props.onClose}
        >
            {props.children}
        </Dialog>
    );
}

const EnterIcon = () => {
    return (
        <Box
            className="enter-icon"
            sx={{
                background: colorSystem.primary[1],
                borderRadius: 1,
                border: `1px solid ${colorSystem.primary[6]}`,
                padding: '1px 6px',
                color: colorSystem.primary[6],
                fontSize: '12px',
                fontWeight: 'regular',
            }}
        >
            <Trans>Enter</Trans>
        </Box>
    );
};
