import { t } from '@lingui/macro';
import {
    assertPresent,
    assertUnreachable,
    compareByString,
    groupBy,
    isPresent,
    typeSafeObjectKeys,
} from '@luminovo/commons';
import {
    BomSummaryDTO,
    CostSummaryDTO,
    CustomPartOfferDataDTO,
    CustomPartTypeEnum,
    DerivedScrapQuantityDTO,
    InventoryDataDTO,
    OfferSummaryDTO,
    OffTheShelfOfferDataDTO,
    PartLite,
    PartLiteTypes,
    PartSummaryDTO,
    QuantityUnitDTO,
    SiteDTO,
    SolutionConfigurationCalculationDTO,
    SolutionConfigurationStatusDTO,
    SolutionSelection,
    SolutionStatus,
    SourcingCalculationDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { ScrapQuantityHandlers } from './solutionConfigurationTypes';

type DesignItemData = {
    id: string;
    designator: string | null;
};
export type DesignItemPath = {
    assemblyId: string;
    path: string;
    designators: string[];
    designItemIds: string[];
};
type AssemblyData = {
    id: string;
    designator: string;
};

export type OfferData =
    | {
          type: 'Inventory';
          data: Exclude<InventoryDataDTO, 'inventory_site'> & { inventorySite: SiteDTO };
      }
    | {
          type: 'OffTheShelf';
          data: Exclude<OffTheShelfOfferDataDTO, 'supplier_and_stock_location_id'> & {
              supplier: SupplierAndStockLocationDTO;
          };
      }
    | {
          type: 'CustomPart';
          data: Exclude<CustomPartOfferDataDTO, 'supplier_and_stock_location_id'> & {
              supplier: SupplierAndStockLocationDTO;
          };
      };

export type OfferSummary =
    | { type: 'Consigned' }
    | { type: 'NoOffer' }
    | {
          type: 'Offer';
          data: OfferData;
      };

export type SolutionConfigurationsTable2Context = {
    rfqId: string;
    scrapQuantityHandlers: ScrapQuantityHandlers;
    displaySiteName: boolean;
};

export type SolutionConfigurationTable2Data = {
    id: string;
    solutionConfigurationSummary: {
        selection: SolutionSelection;
        status: SolutionConfigurationStatusDTO;
        manualStatus: SolutionStatus | null;
        designItems: DesignItemData[];
        designItemPaths: DesignItemPath[];
        notes: string | null;
        assemblies: AssemblyData[];
        aggregatedQuantity: QuantityUnitDTO;
        scrapQuantity: DerivedScrapQuantityDTO | null;
        pcbAssemblyName: string | null;
    };
    offerSummary: OfferSummary;
    partSummary: PartSummaryDTO & { cpns: string[] };
    costSummary: CostSummaryDTO;
    bomSummary: BomSummaryDTO;
    token: string | null;
};

export function createSolutionConfigurationTable2Data(
    calculation: SourcingCalculationDTO,
    supplierAndStockLocations: SupplierAndStockLocationDTO[],
    sites: SiteDTO[],
): SolutionConfigurationTable2Data[] {
    const solutionConfigurations = calculation.solution_configurations;

    return Object.values(solutionConfigurations).map((solutionConfiguration) => {
        const configSummary = solutionConfiguration.solution_configuration_summary;
        const { designItems, designItemPaths } = extractDesignItems(configSummary.design_items, calculation);
        const assemblies = extractAssemblies(configSummary.assemblies, calculation);
        const cpns = extractCpns(solutionConfiguration.part_summary.approved_part_options, calculation.ipn_to_cpns);
        const pcbAssemblyName = extractPcbAssemblyName(solutionConfiguration, assemblies);

        return {
            id: solutionConfiguration.id,
            solutionConfigurationSummary: {
                selection: configSummary.selection,
                status: configSummary.status,
                manualStatus: configSummary.manual_status,
                designItems,
                designItemPaths,
                notes: configSummary.notes,
                assemblies,
                aggregatedQuantity: configSummary.aggregated_quantity,
                scrapQuantity: configSummary.scrap_quantity,
                pcbAssemblyName,
            },
            offerSummary: createOfferSummary(solutionConfiguration.offer_summary, supplierAndStockLocations, sites),
            partSummary: {
                ...solutionConfiguration.part_summary,
                cpns,
            },
            costSummary: solutionConfiguration.cost_summary,
            bomSummary: solutionConfiguration.bom_summary,
            token: solutionConfiguration.token,
        };
    });
}

function createOfferSummary(
    offerSummary: OfferSummaryDTO,
    supplierAndStockLocations: SupplierAndStockLocationDTO[],
    sites: SiteDTO[],
): OfferSummary {
    const offerSummaryType = offerSummary.type;
    switch (offerSummaryType) {
        case 'NoOffer':
        case 'Consigned':
            return { type: offerSummaryType };
        case 'Offer':
            const offer = offerSummary.data;
            const offerType = offer.type;
            switch (offerType) {
                case 'OffTheShelf':
                    return {
                        type: offerSummaryType,
                        data: {
                            type: offerType,
                            data: {
                                supplier: assertPresent(
                                    supplierAndStockLocations.find(
                                        (s) => s.id === offer.data.supplier_and_stock_location,
                                    ),
                                ),
                                ...offer.data,
                            },
                        },
                    };
                case 'CustomPart':
                    return {
                        type: offerSummaryType,
                        data: {
                            type: offerType,
                            data: {
                                supplier: assertPresent(
                                    supplierAndStockLocations.find(
                                        (s) => s.id === offer.data.supplier_and_stock_location,
                                    ),
                                ),
                                ...offer.data,
                            },
                        },
                    };
                case 'Inventory':
                    return {
                        type: offerSummaryType,
                        data: {
                            type: offerType,
                            data: {
                                inventorySite: assertPresent(sites.find((s) => s.id === offer.data.inventory_site)),
                                ...offer.data,
                            },
                        },
                    };
                default:
                    assertUnreachable(offerType);
            }
        /* eslint-disable-next-line no-fallthrough */
        default:
            assertUnreachable(offerSummaryType);
    }
}

function extractDesignItems(
    designItemIds: string[],
    sourcingCalculation: SourcingCalculationDTO,
): {
    designItems: DesignItemData[];
    designItemPaths: DesignItemPath[];
} {
    const designItemData = designItemIds.map((designator) => sourcingCalculation.design_items[designator]);
    const assemblyIdToDesignItems = groupBy(designItemData, (designItem) => designItem.assembly);

    const designators = designItemData.map((designItem) => designItem.designator ?? t`Unknown`).sort(compareByString);

    const designItemPaths = typeSafeObjectKeys(assemblyIdToDesignItems)
        .map((assemblyId) => {
            const assembly = sourcingCalculation.assemblies[assemblyId];
            const paths = assembly.paths;
            if (paths.length === 0) {
                return undefined;
            }

            const path = paths[0].join(' > ');
            return { assemblyId: assemblyId.toString(), path, designItemIds, designators };
        })
        .filter(isPresent);

    const designItems = designItemData.map((designItem) => ({ id: designItem.id, designator: designItem.designator }));

    return { designItems, designItemPaths };
}

function extractAssemblies(assemblyIds: string[], sourcingCalculation: SourcingCalculationDTO): AssemblyData[] {
    return assemblyIds.map((assemblyId) => sourcingCalculation.assemblies[assemblyId]).filter(isPresent);
}

function extractIpnId(part: PartLite): string | undefined {
    const kind = part.kind;
    switch (kind) {
        case PartLiteTypes.Custom:
        case PartLiteTypes.OffTheShelf:
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
        case PartLiteTypes.Generic:
            return undefined;
        case PartLiteTypes.Ipn:
        case PartLiteTypes.CustomComponent:
            return part.id;
        default:
            assertUnreachable(kind);
    }
}

function extractCpns(parts: PartLite[], ipnToCpns: Record<string, string[]>): string[] {
    const ipnIds = parts.map(extractIpnId).filter(isPresent);

    return ipnIds.flatMap((ipnId) => ipnToCpns[ipnId]).filter(isPresent);
}

const extractPcbAssemblyName = (
    solutionConfiguration: SolutionConfigurationCalculationDTO,
    assemblies: AssemblyData[],
): string | null => {
    const selectedPart = solutionConfiguration.part_summary.selected_part;
    if (!isPresent(selectedPart)) {
        return null;
    }

    const kind = selectedPart.kind;
    switch (kind) {
        case PartLiteTypes.OffTheShelf:
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Generic:
        case PartLiteTypes.CustomComponent:
        case PartLiteTypes.Ipn:
        case PartLiteTypes.Unknown:
            return null;
        case PartLiteTypes.Custom:
            const isPcb = selectedPart.type.name === CustomPartTypeEnum.PCB;
            if (!isPcb) {
                return null;
            }

            // There can only be a single PCB assembly
            if (assemblies.length !== 1) {
                return null;
            }

            return assemblies[0].designator;
        default:
            assertUnreachable(kind);
    }
};
