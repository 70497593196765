import * as r from 'runtypes';
import {
    ApprovalStatusRuntype,
    CurrencyRuntype,
    MonetaryValueBackendRuntype,
    QuantityUnitDTORuntype,
} from '../backendTypes';
import { BomFileDTORuntype } from '../bomImporter';
import { DemandPostDTORuntype } from '../demand/demandBackendTypes';
import { DesignItemExcelOriginDTORuntype } from '../designItem';
import {
    CustomComponentFullRuntype,
    IpnWithFullPartMatchesRuntype,
    OtsComponentFullRuntype,
} from '../internalPartNumber';
import { DemandDTORuntype } from '../negotiation';
import { PackagingRuntype } from '../offer';
import { AvailabilityRuntype } from '../offer/availablityBackendTypes';
import {
    AllOriginRuntype,
    CustomOptionOfferDTORuntype,
    PriceTypeRuntype,
    StandardPartInventoryOfferDTORuntype,
    StandardPartMarketOfferDTORuntype,
    UnitOfMeasurementDTORuntype,
} from '../offer/offerBackendTypes';
import { PartSpecificationTypes } from '../part';
import {
    CustomFullPartRuntype,
    GenericFullPartRuntype,
    GenericPartWithIdAndFullPackageRuntype,
    OffTheShelfPartWithFullPackageRuntype,
    OtsFullPartRuntype,
    PartLiteRuntype,
    PartOptionOriginRuntype,
    StandardPartTypes,
} from '../part/partBackendTypes';
import { QuoteTrackingLightRuntype } from '../quoteTracking';
import {
    DerivedScrapQuantityDTORuntype,
    SolutionDTORuntype,
    SolutionStatusRuntype,
    TagRuntype,
} from '../solution/solutionBackendTypes';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { SolutionPreferenceDTORuntype } from './solutionConfigurationBackendTypes';

export interface SourcingBreadcrumbItemsDTO extends r.Static<typeof SourcingBreadcrumbItemsDTORuntype> {}
const SourcingBreadcrumbItemsDTORuntype = r.Record({
    design_item_id: r.String,
    designator: r.String,
    parent_assemblies: r.Array(r.Record({ id: r.String, name: r.String })),
});

const AggregatedBreadcrumbsDTORuntype = r.Record({
    breadcrumbs: r.Record({ items: r.Array(SourcingBreadcrumbItemsDTORuntype) }),
});

const StandardFullPartOptionDTORuntype = r.Record({
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
    part: r.Union(
        r.Record({ type: r.Literal(StandardPartTypes.Generic), data: GenericFullPartRuntype }),
        r.Record({ type: r.Literal(StandardPartTypes.OffTheShelf), data: OtsFullPartRuntype }),
        r.Record({ type: r.Literal(StandardPartTypes.Ipn), data: OtsComponentFullRuntype }),
    ),
});

const StandardPartSpecificationRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.OffTheShelf),
    data: r.Record({ is_manufacturer_free: r.Boolean, part_options: r.Array(StandardFullPartOptionDTORuntype) }),
});

const CustomFullPartSpecificationRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.Custom),
    data: r.Array(
        r.Record({
            part: r.Union(
                r.Record({ type: r.Literal('CustomPart'), data: CustomFullPartRuntype }),
                r.Record({ type: r.Literal('CustomComponent'), data: CustomComponentFullRuntype }),
            ),
            approval_status: ApprovalStatusRuntype,
        }),
    ),
});

const PartOptionFullRuntype = r.Record({
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
    part: r.Union(
        r.Record({ type: r.Literal(StandardPartTypes.OffTheShelf), data: OffTheShelfPartWithFullPackageRuntype }),
        r.Record({ type: r.Literal(StandardPartTypes.Generic), data: GenericPartWithIdAndFullPackageRuntype }),
        r.Record({ type: r.Literal(StandardPartTypes.Ipn), data: IpnWithFullPartMatchesRuntype }),
    ),
});

export interface PartSpecificationOffTheShelfFull extends r.Static<typeof PartSpecificationOffTheShelfFullRuntype> {}
export const PartSpecificationOffTheShelfFullRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.OffTheShelf),
    data: r.Record({ is_manufacturer_free: r.Boolean, part_options: r.Array(PartOptionFullRuntype) }),
});

const CustomOptionVariantFullRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.Custom),
    data: r.Array(
        r.Record({
            part: r.Union(
                r.Record({ type: r.Literal('CustomPart'), data: CustomFullPartRuntype }),
                r.Record({ type: r.Literal('CustomComponent'), data: CustomComponentFullRuntype }),
            ),
            approval_status: ApprovalStatusRuntype,
        }),
    ),
});

export const PartSpecificationFullRuntype = r.Union(
    PartSpecificationOffTheShelfFullRuntype,
    CustomOptionVariantFullRuntype,
);

export interface SolutionConfigurationSourcingDTO extends r.Static<typeof SolutionConfigurationSourcingDTORuntype> {}
export const SolutionConfigurationSourcingDTORuntype = r.Record({
    id: r.String,
    effective_solution: SolutionDTORuntype.nullable(),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: r.Null.Or(DerivedScrapQuantityDTORuntype),
    specification: r.Null.Or(r.Union(CustomFullPartSpecificationRuntype, StandardPartSpecificationRuntype)),
    aggregated_breadcrumbs: AggregatedBreadcrumbsDTORuntype,
    is_consigned: r.Boolean,
    notes: r.String.nullable(),
});

export interface SolutionConfigurationFullPartSourcingInformationResponse
    extends r.Static<typeof SolutionConfigurationFullPartSourcingInformationResponseRuntype> {}
export const SolutionConfigurationFullPartSourcingInformationResponseRuntype = r.Record({
    id: r.String,
    effective_solution: SolutionDTORuntype.nullable(),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: r.Null.Or(DerivedScrapQuantityDTORuntype),
    specification: PartSpecificationFullRuntype.nullable(),
    aggregated_breadcrumbs: AggregatedBreadcrumbsDTORuntype,
    is_consigned: r.Boolean,
    notes: r.String.nullable(),
});

const SolutionConfigurationSourcingItemsDTORuntype = r.Record({
    items: r.Array(SolutionConfigurationSourcingDTORuntype),
});

const SolutionStatusCountsDTORuntype = r.Record({
    number_of_error: r.Number,
    number_of_ok: r.Number,
    number_of_warning: r.Number,
});
export type SolutionStatusCountDTO = r.Static<typeof SolutionStatusCountsDTORuntype>;

export interface FullSourcingDTO extends r.Static<typeof FullSourcingDTORuntype> {}

export const CostRuntype = r.Record({ preferred: MonetaryValueBackendRuntype, original: MonetaryValueBackendRuntype });

export type CostDTO = r.Static<typeof CostRuntype>;

const TcoCostSummaryBreakdownRuntype = r.Record({
    packaging_cost: CostRuntype.nullable(),
    discount: CostRuntype.nullable(),
    shipping_cost: CostRuntype.nullable(),
    customs_cost: CostRuntype.nullable(),
    other_cost: CostRuntype.nullable(),
});

export type TcoCostSummaryBreakdownDTO = r.Static<typeof TcoCostSummaryBreakdownRuntype>;

const TcoCostItemRuntype = r.Record({ cost: CostRuntype.nullable(), breakdown: TcoCostSummaryBreakdownRuntype });

export type TcoCostItemDTO = r.Static<typeof TcoCostItemRuntype>;

const TcoCostSummaryRuntype = r.Record({
    unit: TcoCostItemRuntype,
    aggregated: TcoCostItemRuntype,
    scrap: TcoCostItemRuntype,
    required: TcoCostItemRuntype,
    excess: TcoCostItemRuntype,
    total: TcoCostItemRuntype,
});

export type TcoCostSummaryDTO = r.Static<typeof TcoCostSummaryRuntype>;

const AssemblySourcingSummaryRuntype = r.Record({
    unit_price: MonetaryValueBackendRuntype.nullable(),
    total_tco_costs: TcoCostSummaryRuntype,
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    availability: AvailabilityRuntype.nullable(),
    has_missing_solutions: r.Boolean,
});

export type AssemblySourcingSummaryDTO = r.Static<typeof AssemblySourcingSummaryRuntype>;

export const FullSourcingDTORuntype = r.Record({
    sourcing_scenario_id: r.String,
    aggregated_top_level_assembly_information: r.Dictionary(AssemblySourcingSummaryRuntype, r.String),
    solution_configurations_sourcing: SolutionConfigurationSourcingItemsDTORuntype,
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_unit_price: MonetaryValueBackendRuntype.nullable(),
    total_tco_costs: TcoCostSummaryRuntype,
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_availability: AvailabilityRuntype.nullable(),
    status_count: SolutionStatusCountsDTORuntype,
    off_the_shelf_offers: r.Record({ items: r.Array(StandardPartMarketOfferDTORuntype) }),
    inventory_offers: r.Record({ items: r.Array(StandardPartInventoryOfferDTORuntype) }),
    custom_part_offers: r.Record({ items: r.Array(CustomOptionOfferDTORuntype) }),
    quotes_by_solution_config: r.Dictionary(r.Array(QuoteTrackingLightRuntype), r.String),
});

export interface AssemblyQuantities extends r.Static<typeof AssemblyQuantitiesRuntype> {}

export interface ItemObject extends r.Static<typeof ItemObjectRuntype> {}
const ItemObjectRuntype = r.Record({ assembly: r.String, quantity: r.Number });

const AssemblyQuantitiesRuntype = r.Record({ items: r.Array(ItemObjectRuntype) });

export interface SourcingScenarioDTO extends r.Static<typeof SourcingScenarioDTORuntype> {}
export const SourcingScenarioDTORuntype = r.Record({
    id: r.String,
    rfq: r.String,
    name: r.String,
    solution_preference: SolutionPreferenceDTORuntype,
    assembly_quantities: AssemblyQuantitiesRuntype, // @deprecated - use assembly_demands instead
    assembly_demands: r.Array(DemandDTORuntype),
});

export interface SourcingScenarioPostDTO extends r.Static<typeof SourcingScenarioPostDTORuntype> {}
export const SourcingScenarioPostDTORuntype = r.Record({
    rfq: r.String,
    name: r.String,
    solution_preference: SolutionPreferenceDTORuntype,
    assembly_demands: r.Array(
        r.Union(
            r.Record({ kind: r.Literal('existing'), data: r.Record({ id: r.String }) }),
            r.Record({ kind: r.Literal('new'), data: DemandPostDTORuntype }),
        ),
    ),
});

export interface SourcingScenarioPatchDTO extends r.Static<typeof SourcingScenarioPatchDTORuntype> {}
export const SourcingScenarioPatchDTORuntype = SourcingScenarioPostDTORuntype.omit('rfq').asPartial();

export interface SupplierSolutionDTO extends r.Static<typeof SupplierSolutionRuntype> {}
export const SupplierSolutionRuntype = r.Record({
    linked_location: SupplierAndStockLocationDTORuntype,
    sourcing_scenario_solutions: r.Array(
        r.Record({
            solutions: r.Array(SolutionDTORuntype),
            solution_configuration_id: r.String,
            sourcing_scenario: SourcingScenarioDTORuntype,
        }),
    ),
});

export interface SolutionConfigurationExcelOriginInfo
    extends r.Static<typeof SolutionConfigurationExcelOriginInfoRuntype> {}
export const SolutionConfigurationExcelOriginInfoRuntype = r.Record({
    sourcing_scenario_id: r.String,
    solution_configuration_id: r.String,
    bom_files: r.Array(BomFileDTORuntype),
    excel_origins: r.Array(DesignItemExcelOriginDTORuntype),
});

export const SolutionSelectionRuntype = r.Union(r.Literal('Automatic'), r.Literal('Manual'), r.Literal('Unknown'));
export type SolutionSelection = r.Static<typeof SolutionSelectionRuntype>;

const SolutionConfigurationStatusRuntype = r.Union(
    r.Record({ type: r.Literal('NoOffer') }),
    r.Record({ type: r.Literal('NoApprovedParts') }),
    r.Record({
        type: r.Literal('Status'),
        data: r.Record({ status: SolutionStatusRuntype, tags: r.Array(TagRuntype) }),
    }),
);
export type SolutionConfigurationStatusDTO = r.Static<typeof SolutionConfigurationStatusRuntype>;

export const SolutionConfigurationSummaryRuntype = r.Record({
    selection: SolutionSelectionRuntype,
    status: SolutionConfigurationStatusRuntype,
    manual_status: SolutionStatusRuntype.nullable(),
    design_items: r.Array(r.String),
    notes: r.String.nullable(),
    assemblies: r.Array(r.String),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: DerivedScrapQuantityDTORuntype.nullable(),
});

const OffTheShelfOfferDataRuntype = r.Record({
    packaging: PackagingRuntype.nullable(),
    origin: AllOriginRuntype,
    factory_lead_time: r.Number.nullable(),
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_type: PriceTypeRuntype,
    supplier_part_number: r.String.nullable(),
    moq: r.Number.nullable(),
    mpq: r.Number.nullable(),
    lead_time: AvailabilityRuntype.nullable(),
    supplier_and_stock_location: r.String,
    offer_url: r.String.nullable(),
});
export type OffTheShelfOfferDataDTO = r.Static<typeof OffTheShelfOfferDataRuntype>;

const CustomPartOfferDataRuntype = r.Record({
    origin: AllOriginRuntype,
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_type: PriceTypeRuntype.nullable(),
    lead_time: AvailabilityRuntype.nullable(),
    supplier_and_stock_location: r.String,
    offer_url: r.String.nullable(),
});
export type CustomPartOfferDataDTO = r.Static<typeof CustomPartOfferDataRuntype>;

const InventoryOfferDataRuntype = r.Record({
    packaging: PackagingRuntype.nullable(),
    origin: AllOriginRuntype,
    factory_lead_time: r.Number.nullable(),
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_type: PriceTypeRuntype,
    supplier_part_number: r.String.nullable(),
    inventory_site: r.String,
    lead_time: AvailabilityRuntype.nullable(),
});
export type InventoryDataDTO = r.Static<typeof InventoryOfferDataRuntype>;

const OfferDataRuntype = r.Union(
    r.Record({ type: r.Literal('OffTheShelf'), data: OffTheShelfOfferDataRuntype }),
    r.Record({ type: r.Literal('CustomPart'), data: CustomPartOfferDataRuntype }),
    r.Record({ type: r.Literal('Inventory'), data: InventoryOfferDataRuntype }),
);

export type OfferDataDTO = r.Static<typeof OfferDataRuntype>;

export const OfferSummaryRuntype = r.Union(
    r.Record({ type: r.Literal('NoOffer') }),
    r.Record({ type: r.Literal('Consigned') }),
    r.Record({ type: r.Literal('Offer'), data: OfferDataRuntype }),
);
export type OfferSummaryDTO = r.Static<typeof OfferSummaryRuntype>;

export const PartSummaryRuntype = r.Record({
    selected_part: PartLiteRuntype.nullable(),
    selected_part_option: PartLiteRuntype.nullable(),
    approved_part_options: r.Array(PartLiteRuntype),
});
export type PartSummaryDTO = r.Static<typeof PartSummaryRuntype>;

export const CostSummaryRuntype = r.Record({
    unit_price: CostRuntype.nullable(),
    tco_unit_price: CostRuntype.nullable(),
    scrap_cost: CostRuntype.nullable(),
    effective_unit_price: CostRuntype.nullable(),
    line_value: CostRuntype.nullable(),
    tco_costs: TcoCostSummaryRuntype.nullable(),
    one_time_costs: CostRuntype.nullable(),
    excess_price: CostRuntype.nullable(),
    scrap_price: CostRuntype.nullable(),
    base_material_cost: CostRuntype.nullable(),
    total_price: CostRuntype.nullable(),
    currency: CurrencyRuntype,
});
export type CostSummaryDTO = r.Static<typeof CostSummaryRuntype>;

export const BomSummaryRuntype = r.Record({
    bom_ipns: r.Array(r.String),
    bom_cpns: r.Array(r.String),
    bom_notes: r.Array(r.String),
});
export type BomSummaryDTO = r.Static<typeof BomSummaryRuntype>;

export const SolutionConfigurationCalculationRuntype = r.Record({
    id: r.String,
    solution_configuration_summary: SolutionConfigurationSummaryRuntype,
    offer_summary: OfferSummaryRuntype,
    part_summary: PartSummaryRuntype,
    cost_summary: CostSummaryRuntype,
    bom_summary: BomSummaryRuntype,
    token: r.String.nullable(),
});
export type SolutionConfigurationCalculationDTO = r.Static<typeof SolutionConfigurationCalculationRuntype>;

const AssemblySourcingDataRuntype = r.Record({ id: r.String, designator: r.String, paths: r.Array(r.Array(r.String)) });
export type AssemblySourcingDataDTO = r.Static<typeof AssemblySourcingDataRuntype>;

const DesignItemSourcingDataRuntype = r.Record({ id: r.String, assembly: r.String, designator: r.String.nullable() });
export type DesignItemSourcingDataDTO = r.Static<typeof DesignItemSourcingDataRuntype>;

export const SourcingCalculationRuntype = r.Record({
    sourcing_scenario_id: r.String,
    ipn_to_cpns: r.Dictionary(r.Array(r.String), r.String),
    assemblies: r.Dictionary(AssemblySourcingDataRuntype, r.String),
    design_items: r.Dictionary(DesignItemSourcingDataRuntype, r.String),
    solution_configurations: r.Array(SolutionConfigurationCalculationRuntype),
    aggregated_top_level_assembly_information: r.Dictionary(AssemblySourcingSummaryRuntype, r.String),
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_unit_price: MonetaryValueBackendRuntype.nullable(),
    total_tco_costs: TcoCostSummaryRuntype,
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_availability: AvailabilityRuntype.nullable(),
    status_count: SolutionStatusCountsDTORuntype,
});
export type SourcingCalculationDTO = r.Static<typeof SourcingCalculationRuntype>;

export const SourcingCalculationResponseRuntype = r.Record({
    items: r.Array(SourcingCalculationRuntype),
});

export type SourcingCalculationResponseDTO = r.Static<typeof SourcingCalculationResponseRuntype>;
