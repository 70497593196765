import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    SupplierAndStockLocationDTORuntype,
    SupplierAndStockLocationPatchDTORuntype,
    SupplierAndStockLocationPostDTORuntype,
} from './supplierAndStockLocationBackendTypes';

export const supplierAndStockLocationEndpoints = {
    'POST /suppliers-and-stock-locations/bulk': endpoint({
        description: 'Returns a list of supplier-and-stock-locations given their IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String) }),
        responseBody: r.Record({ items: r.Array(SupplierAndStockLocationDTORuntype) }),
    }),

    'POST /suppliers-and-stock-locations': endpoint({
        description: 'Creates a new supplier-and-stock-location',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SupplierAndStockLocationPostDTORuntype,
        responseBody: r.Record({ data: SupplierAndStockLocationDTORuntype }),
        invalidates: [
            'GET /suppliers-and-stock-locations',
            'POST /suppliers-and-stock-locations/bulk',
            'GET /organization-settings/organization-solution-preference',
            'GET /email-template',
        ],
    }),

    'PATCH /suppliers-and-stock-locations/:id': endpoint({
        description: 'Updated a supplier-and-stock-location',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: SupplierAndStockLocationPatchDTORuntype,
        responseBody: r.Record({ data: SupplierAndStockLocationDTORuntype }),
        invalidates: [
            'GET /suppliers-and-stock-locations',
            'POST /suppliers-and-stock-locations/bulk',
            'GET /organization-settings/organization-solution-preference',
            'GET /email-template',
        ],
    }),

    'GET /suppliers-and-stock-locations': endpoint({
        description: 'Returns all supplier-and-stock-locations',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(SupplierAndStockLocationDTORuntype) }),
    }),

    'POST /suppliers-and-stock-locations/bulk-delete': endpoint({
        description: 'Deletes multiple suppliers and stock locations',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.String),
        }),
        responseBody: r.Unknown,
        invalidates: ['GET /suppliers-and-stock-locations'],
    }),
};
