import { Trans, t } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import {
    assertPresent,
    formatDecimal,
    formatLongDateTime,
    formatMonetaryValue,
    formatRelativeTime,
    groupBy,
    transEnum,
} from '@luminovo/commons';
import {
    ApprovedIcon,
    CloseIcon,
    DestructiveSecondaryIconButton,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    SecondaryIconButton,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    OtsComponentFull,
    OtsFullPart,
    Packaging,
    RegionsEnum,
    StandardPartTypes,
    SupplierDTO,
    TotalCostOfOwnershipRuleCategory,
    TotalCostOfOwnershipRuleConditionType,
    TotalCostOfOwnershipRuleDTO,
    TotalCostOfOwnershipScaling,
} from '@luminovo/http-client';
import {
    TotaCostOfOwnershipRuleCategoryTag,
    formatPackaging,
    formatPart,
    totalCostOfOwnershipRuleCategoryTranslations,
    totalCostOfOwnershipRuleConditionTypeTranslations,
    totalCostOfOwnershipScalingTranslations,
} from '@luminovo/sourcing-core';
import { Add, Delete, Edit, WarehouseRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useIpnBulk, useOtsPartBulk } from '../../../../../resources/part/partHandler';
import { useSuppliers } from '../../../../../resources/supplier/supplierHandler';
import { SettingsContainer } from '../../common/SettingsContainer';
import {
    tcoRuleDisabledText,
    useAddTcoRuleDialog,
    useDeleteTcoRuleDialog,
    useEditTcoRuleDialog,
} from './TcoRuleDialog';

const columnHelper = createColumnHelper<TotalCostOfOwnershipRule>();

const columns = [
    columnHelper.text('name', {
        label: () => t`Name`,
        size: 120,
        cell: (item) => item.getValue(),
    }),

    columnHelper.text('description', {
        label: () => t`Description`,
        size: 130,
        cell: (item) => item.getValue(),
    }),

    columnHelper.text((row) => formatConditions(row.conditions), {
        id: 'conditions_text',
        label: () => t`Conditions (expanded)`,
        size: 300,
        initialVisibility: false,
    }),

    columnHelper.array((row) => row.conditions.map((c) => c.condition_type), {
        id: 'conditions',
        label: () => t`Conditions`,
        size: 275,
        options: Object.values(TotalCostOfOwnershipRuleConditionType),
        getOptionLabel: (opt) => transEnum(opt, totalCostOfOwnershipRuleConditionTypeTranslations),
        cell: ({ row }) => formatConditions(row.original.conditions),
    }),

    columnHelper.text(
        (row) => {
            if (row.action.type === 'Absolute') {
                return formatMonetaryValue(row.action.value);
            }
            if (row.action.type === 'Relative') {
                return formatDecimal(Number(row.action.value), { suffix: '%' });
            }
        },

        {
            id: 'action',
            label: () => t`Cost`,
            size: 200,
            align: 'right',
        },
    ),

    columnHelper.enum('category', {
        label: () => t`Category`,
        size: 200,
        options: Object.values(TotalCostOfOwnershipRuleCategory),
        getOptionLabel: (opt) => transEnum(opt, totalCostOfOwnershipRuleCategoryTranslations),
        cell: ({ row }) => <TotaCostOfOwnershipRuleCategoryTag category={row.original.category} />,
    }),

    columnHelper.enum('is_valid', {
        label: () => t`Status`,
        size: 100,
        options: [true, false],
        getOptionLabel: (isValid) => (isValid ? t`Okay` : t`Disabled`),
        cell: (cell) => (
            <Tooltip title={cell.getValue() ? t`Okay` : tcoRuleDisabledText()}>
                <span>{cell.getValue() ? <ApprovedIcon /> : <CloseIcon />}</span>
            </Tooltip>
        ),
    }),

    columnHelper.date('updated_at', {
        label: () => t`Updated`,
        initialVisibility: false,
        size: 80,
        cell: (item) => (
            <Tooltip title={formatLongDateTime(item.getValue())}>
                <Text variant={'inherit'} color={colorSystem.neutral[7]}>
                    {formatRelativeTime(item.getValue())}
                </Text>
            </Tooltip>
        ),
    }),

    columnHelper.action({
        id: 'actions',
        size: 90,
        cell: function Cell({ row }) {
            const { openDialog: openEditDialog } = useEditTcoRuleDialog(row.original.original);
            const { openDialog: openDeleteDialog } = useDeleteTcoRuleDialog(row.original.original);
            return (
                <Flexbox gap={12} justifyContent={'center'}>
                    <Tooltip title={t`Edit rule`}>
                        <SecondaryIconButton onClick={() => openEditDialog()} size="small">
                            <Edit fontSize="inherit" />
                        </SecondaryIconButton>
                    </Tooltip>
                    <Tooltip title={t`Remove rule`}>
                        <DestructiveSecondaryIconButton onClick={() => openDeleteDialog()} size="small">
                            <Delete fontSize="inherit" />
                        </DestructiveSecondaryIconButton>
                    </Tooltip>
                </Flexbox>
            );
        },
    }),

    columnHelper.enum('scaling', {
        label: () => t`Application`,
        size: 120,
        options: Object.values(TotalCostOfOwnershipScaling),
        getOptionLabel: (opt) => transEnum(opt, totalCostOfOwnershipScalingTranslations),
        cell: (cell) => {
            return transEnum(cell.getValue(), totalCostOfOwnershipScalingTranslations);
        },
    }),
];

export function TotalCostOfOwnershipRulesPage() {
    const { data } = useTotalCostOfOwnershipRules();

    const { table } = useTanStackTable({
        data,
        columns,
        enableExcelExport: true,
    });

    return (
        <SettingsContainer title={t`Total cost of ownership rules`} style={{ height: 'calc(100%' }}>
            <Flexbox flexDirection="column" gap={4}>
                <Text>
                    <Trans>
                        The following costs and discounts are automatically added on top of the unit prices if their
                        condition is met.
                    </Trans>
                </Text>

                <Text variant={'body-small'}>
                    <Flexbox gap={4} alignItems={'baseline'}>
                        <Tag attention={'low'} color={'neutral'} label={t`TCO unit price`} />
                        = (
                        <Tag attention={'low'} color={'neutral'} label={t`Unit price`} />
                        +
                        <Tag attention={'low'} color={'green'} label={t`Packaging`} />
                        -
                        <Tag attention={'low'} color={'violet'} label={t`Discount`} />
                        ) +
                        <Tag attention={'low'} color={'teal'} label={t`Customs`} />
                        +
                        <Tag attention={'low'} color={'yellow'} label={t`Shipping`} />
                        +
                        <Tag attention={'low'} color={'blue'} label={t`Other`} />
                    </Flexbox>
                </Text>
            </Flexbox>
            <Box style={{ height: 'calc(100% - 150px)' }}>
                <TanStackTable table={table} ActionButton={ActionButton} EmptyPlaceholder={EmptyPlaceholder} />
            </Box>
        </SettingsContainer>
    );
}

function EmptyPlaceholder(): JSX.Element {
    return (
        <NonIdealState
            Icon={WarehouseRounded}
            title={t`No total cost of ownership rules added yet`}
            description={t`Add your first total cost of ownership rule to get started`}
            overrides={{
                ActionButton,
            }}
        />
    );
}

function ActionButton() {
    const { openDialog } = useAddTcoRuleDialog();
    const isAllowedToEdit = useHasPermission(['edit:organization_settings']);
    return (
        <PrimaryButton disabled={!isAllowedToEdit} size="medium" onClick={openDialog} startIcon={<Add />}>
            <Trans>Add rule</Trans>
        </PrimaryButton>
    );
}

function formatConditions(conditions: InlinedCondition[]): string {
    const groupedConditions = groupBy(conditions, (condition) => condition.condition_type);

    return Object.entries(groupedConditions)
        .map(
            ([type, conditions]) =>
                `${transEnum(
                    type as TotalCostOfOwnershipRuleConditionType,
                    totalCostOfOwnershipRuleConditionTypeTranslations,
                )}: ${conditions.map((c) => formatCondition(c)).join(`, `)}`,
        )
        .join(` AND `);
}

function formatCondition(condition: InlinedCondition): string {
    switch (condition.condition_type) {
        case TotalCostOfOwnershipRuleConditionType.Packaging:
            return formatPackaging(condition.value);

        case TotalCostOfOwnershipRuleConditionType.CountryOfOrigin:
            return condition.value;

        case TotalCostOfOwnershipRuleConditionType.CustomsCode:
            return `${condition.value.code_type.toLocaleUpperCase()} ${condition.value.code}`;

        case TotalCostOfOwnershipRuleConditionType.PartNumber:
            return formatPart(condition.value.data);

        case TotalCostOfOwnershipRuleConditionType.Supplier:
            return condition.value.name;
    }
}

type InlinedCondition =
    | { condition_type: TotalCostOfOwnershipRuleConditionType.Packaging; value: Packaging }
    | { condition_type: TotalCostOfOwnershipRuleConditionType.CountryOfOrigin; value: RegionsEnum }
    | {
          condition_type: TotalCostOfOwnershipRuleConditionType.CustomsCode;
          value: { code_type: 'Hts' | 'Taric'; code: string };
      }
    | {
          condition_type: TotalCostOfOwnershipRuleConditionType.PartNumber;
          value: { type: 'OffTheShelf'; data: OtsFullPart } | { type: 'Ipn'; data: OtsComponentFull };
      }
    | { condition_type: TotalCostOfOwnershipRuleConditionType.Supplier; value: SupplierDTO };

export type TotalCostOfOwnershipRule = Omit<TotalCostOfOwnershipRuleDTO, 'conditions'> & {
    original: TotalCostOfOwnershipRuleDTO;
    conditions: InlinedCondition[];
};

function inlineRule(
    rule: TotalCostOfOwnershipRuleDTO,
    otsParts: OtsFullPart[],
    ipns: OtsComponentFull[],
    suppliers: SupplierDTO[],
): TotalCostOfOwnershipRule {
    return {
        ...rule,
        original: rule,
        conditions: rule.conditions.map((condition): InlinedCondition => {
            switch (condition.condition_type) {
                case TotalCostOfOwnershipRuleConditionType.Packaging:
                case TotalCostOfOwnershipRuleConditionType.CountryOfOrigin:
                case TotalCostOfOwnershipRuleConditionType.CustomsCode:
                    return condition;
                case TotalCostOfOwnershipRuleConditionType.Supplier:
                    return {
                        ...condition,
                        value: assertPresent(suppliers?.find((s) => s.id === condition.value)),
                    };
                case TotalCostOfOwnershipRuleConditionType.PartNumber:
                    if (condition.value.type === StandardPartTypes.OffTheShelf) {
                        return {
                            ...condition,
                            value: {
                                type: 'OffTheShelf',
                                data: assertPresent(otsParts?.find((p) => p.id === condition.value.data)),
                            },
                        };
                    } else if (condition.value.type === StandardPartTypes.Ipn) {
                        return {
                            ...condition,
                            value: {
                                type: 'Ipn',
                                data: assertPresent(ipns?.find((p) => p.id === condition.value.data)),
                            },
                        };
                    }
                    throw new Error('Invalid part type');
                default:
                    return condition;
            }
        }),
    };
}

function extractPartIds(rules: TotalCostOfOwnershipRuleDTO[] | undefined, partType: StandardPartTypes) {
    return rules?.flatMap((rule) =>
        rule.conditions.flatMap((condition) =>
            condition.condition_type === TotalCostOfOwnershipRuleConditionType.PartNumber &&
            condition.value.type === partType
                ? [condition.value.data]
                : [],
        ),
    );
}

/**
 * Fetches the total cost of ownership rules and inlines the conditions values.
 */
export function useTotalCostOfOwnershipRules() {
    const { data: rules } = useHttpQuery('GET /total-cost-of-ownership-rules', {}, {});

    const otsPartIds = React.useMemo(() => extractPartIds(rules, StandardPartTypes.OffTheShelf), [rules]);
    const ipnIds = React.useMemo(() => extractPartIds(rules, StandardPartTypes.Ipn), [rules]);

    const { data: suppliers } = useSuppliers();
    const { data: otsParts } = useOtsPartBulk(otsPartIds, { type: 'OutsideRfQ' });
    const { data: ipns } = useIpnBulk(ipnIds, { type: 'OutsideRfQ' });

    return React.useMemo(() => {
        if (!rules || !suppliers || !otsParts || !ipns) {
            return { data: undefined, isLoading: true };
        }

        const inlinedRules = rules.map((rule) => inlineRule(rule, otsParts, ipns, suppliers));
        return { data: inlinedRules, isLoading: false };
    }, [otsParts, ipns, rules, suppliers]);
}
