import { Trans, t } from '@lingui/macro';
import { formatMonetaryValue, formatToLongDate, isPresent, transEnum, uniq } from '@luminovo/commons';
import {
    Flexbox,
    SecondaryButton,
    TanStackTable,
    Text,
    Tooltip,
    colorSystem,
    createColumnHelper,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { AssemblySourcingSummaryDTO, SolutionStatus, SourcingPreferenceOptions } from '@luminovo/http-client';
import {
    TcoCostSummaryTooltip,
    formatAvailability,
    formatSolutionStatus,
    leadTimeDaysExtractor,
    sourcingPreferenceTranslations,
} from '@luminovo/sourcing-core';
import { Add, InfoRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import * as React from 'react';
import { LabelScenarioOrderSize } from '../../../../../components/LabelScenarioOrderSize';
import { AssemblyLabelWithErrorBoundary } from '../../../../../components/LabelScenarioOrderSize/AssemblyLabelWithErrorBoundary';
import { isTotalCostOfOwnershipEnabled } from '../../../../../featureFlags';
import { useAssemblies } from '../../../../../resources/assembly/assemblyHandler';
import { calculateTotalOrderSize } from '../../../../../resources/sourcingScenario/calculateTotalOrderSize';
import {
    useSourcingCalculationBulk,
    useSourcingScenarios,
} from '../../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { route } from '../../../../../utils/routes';
import { StatusSummaries } from '../../SourcingScenarios/StatusSummaries';
import { AssemblyTitle } from './components/AssemblyTitle';
import { SourcingCentricTableType, SourcingScenarioTableSharedContext } from './utils/types';

const columnHelper = createColumnHelper<SourcingCentricTableType, SourcingScenarioTableSharedContext>();

const columns = [
    columnHelper.text((row) => (row.type === 'groupRow' ? row.scenario.sourcingScenario.name : row.data.designator), {
        id: 'name',
        size: 160,
        label: () => t`Name`,
        cell: (item) => {
            if (item.row.original.type === 'groupRow') {
                return item.row.original.scenario.sourcingScenario.name;
            } else {
                return (
                    <AssemblyTitle
                        assembly={{
                            designator: item.row.original.data.designator,
                            type: item.row.original.data.type,
                        }}
                        isSubRow
                    />
                );
            }
        },
    }),
    columnHelper.number(
        (row) => (row.type === 'groupRow' ? calculateTotalOrderSize(row.scenario.sourcingScenario) : 0),
        {
            id: 'orderSize',
            size: 100,
            label: () => t`Order size`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                return (
                    <LabelScenarioOrderSize
                        sourcingScenario={item.row.original.scenario.sourcingScenario}
                        assemblyId={item.row.original.data?.id}
                    />
                );
            },
        },
    ),
    columnHelper.enum(
        (row) =>
            row.type === 'groupRow'
                ? row.scenario.sourcingScenario.solution_preference.lead_time_preference.type
                : undefined,
        {
            id: 'leadTimePreference',
            size: 140,
            label: () => t`Lead time preference`,
            options: [
                SourcingPreferenceOptions.Fastest,
                SourcingPreferenceOptions.BestPrice,
                SourcingPreferenceOptions.BestPriceBy,
            ],
            getOptionLabel: (opt) => transEnum(opt, sourcingPreferenceTranslations),
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const { type, target } =
                    item.row.original.scenario.sourcingScenario.solution_preference.lead_time_preference;
                const bestDateBy =
                    type === SourcingPreferenceOptions.BestPriceBy && target ? formatToLongDate(target) : '';
                return (
                    <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block' }}>
                        {`${transEnum(type, sourcingPreferenceTranslations)} ${bestDateBy}`.trim()}
                    </Text>
                );
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'groupRow' ? row.scenario.sourcingCalculation?.total_unit_price : row.data?.unit_price),
        {
            id: 'unitPrice',
            size: 100,
            label: () => t`Unit price`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                if (item.row.original.type === 'groupRow' && item.row.subRows.length > 1) {
                    return (
                        <Tooltip
                            variant="white"
                            title={
                                <Flexbox flexDirection={'column'} gap={'4px'}>
                                    {item.row.subRows.map((subRow, index) => (
                                        <Flexbox justifyContent={'space-between'} gap={'24px'} key={index}>
                                            <AssemblyLabelWithErrorBoundary assembly={subRow.original.data?.id} />
                                            <Text>
                                                {formatMonetaryValue(subRow.original.data?.unit_price, 'default', {
                                                    ifAbsent: '-',
                                                })}
                                            </Text>
                                        </Flexbox>
                                    ))}
                                    <Flexbox
                                        justifyContent={'space-between'}
                                        gap={'24px'}
                                        borderTop={`1px solid ${colorSystem.neutral[2]}`}
                                    >
                                        <Text variant="body-semibold">
                                            <Trans>Total unit price</Trans>
                                        </Text>
                                        <Text variant="body-semibold">
                                            {formatMonetaryValue(
                                                item.row.original.scenario.sourcingCalculation?.total_unit_price,
                                            )}
                                        </Text>
                                    </Flexbox>
                                </Flexbox>
                            }
                        >
                            <span style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
                                <Trans>Mixed</Trans>
                                <InfoRounded
                                    style={{
                                        fontSize: '16px',
                                        color: colorSystem.neutral[5],
                                    }}
                                />
                            </span>
                        </Tooltip>
                    );
                }

                return formatMonetaryValue(item.getValue());
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) =>
            row.type === 'groupRow'
                ? row.scenario.sourcingCalculation?.total_one_time_costs
                : row.data.total_one_time_costs,
        {
            id: 'oneTimeCosts',
            size: 100,
            label: () => t`One-time costs`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const itemValue = item.getValue();
                return formatMonetaryValue(itemValue);
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) => (row.type === 'groupRow' ? row.scenario.sourcingCalculation?.total_price : row.data.total_price),
        {
            id: 'totalPrice',
            size: 120,
            label: () => t`Total price`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const itemValue = item.getValue();
                return formatMonetaryValue(itemValue);
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) =>
            row.type === 'groupRow'
                ? row.scenario.sourcingCalculation?.total_excess_material
                : row.data.total_excess_material,
        {
            id: 'totalExcessMaterial',
            size: 100,
            label: () => t`Excess material`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const itemValue = item.getValue();
                return formatMonetaryValue(itemValue);
            },
        },
    ),
    columnHelper.number(
        (row) =>
            row.type === 'groupRow'
                ? leadTimeDaysExtractor(row.scenario.sourcingCalculation?.total_availability ?? null)
                : leadTimeDaysExtractor(row.data.availability),
        {
            id: 'leadTimeDays',
            size: 130,
            label: () => t`Lead time (days)`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const itemValue =
                    item.row.original.type === 'groupRow'
                        ? item.row.original.scenario.sourcingCalculation?.total_availability
                        : item.row.original.data.availability;
                return formatAvailability(itemValue);
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) =>
            row.type === 'groupRow' ? row.scenario.sourcingCalculation?.total_scrap_costs : row.data.total_scrap_costs,
        {
            id: 'totalScrapCosts',
            size: 140,
            initialVisibility: false,
            label: () => t`Scrap costs`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const itemValue = item.getValue();
                return formatMonetaryValue(itemValue);
            },
        },
    ),
    columnHelper.monetaryValue(
        (row) =>
            row.type === 'groupRow'
                ? row.scenario.sourcingCalculation?.total_tco_costs?.total.cost?.preferred
                : row.data.total_tco_costs?.total.cost?.preferred,
        {
            id: 'additionalCosting',
            size: 130,
            initialVisibility: isTotalCostOfOwnershipEnabled(),
            enableHiding: isTotalCostOfOwnershipEnabled(),
            label: () => t`Total TCO cost`,
            cell: (item) => {
                if (item.row.original.type === 'groupRow' && !isPresent(item.row.original.scenario.sourcingCalculation))
                    return <Skeleton />;

                const tcoCostSummary =
                    item.row.original.type === 'groupRow'
                        ? item.row.original.scenario.sourcingCalculation?.total_tco_costs
                        : item.row.original.data.total_tco_costs;

                if (!isPresent(tcoCostSummary)) {
                    return '-';
                }

                return <TcoCostSummaryTooltip summary={tcoCostSummary} show="total" />;
            },
        },
    ),
    columnHelper.array(
        (row) =>
            uniq([
                ...Array(row.scenario.sourcingCalculation?.status_count.number_of_ok).fill(SolutionStatus.Good),
                ...Array(row.scenario.sourcingCalculation?.status_count.number_of_warning).fill(SolutionStatus.Warning),
                ...Array(row.scenario.sourcingCalculation?.status_count.number_of_error).fill(SolutionStatus.Error),
            ]),
        {
            id: 'solutionStatus',
            size: 120,
            align: 'center',
            enableSorting: false,
            options: Object.values(SolutionStatus),
            getOptionLabel: (status) => formatSolutionStatus(status),
            label: () => t`Solution status`,
            cell: ({ row }) =>
                !isPresent(row.original.scenario.sourcingCalculation) ? (
                    <Skeleton />
                ) : (
                    <StatusSummaries sourcingCalculation={row.original.scenario.sourcingCalculation} />
                ),
        },
    ),
];

const useTableData = (sourcingScenarioIds: string[]): { data: SourcingCentricTableType[] | undefined } => {
    const { data: sourcingScenariosDTOs } = useSourcingScenarios(sourcingScenarioIds);
    const { data: sourcingCalculationDTOs } = useSourcingCalculationBulk(sourcingScenarioIds);

    const assemblyIds = React.useMemo(
        () => sourcingScenariosDTOs?.flatMap((s) => uniq(s.assembly_quantities.items.map((i) => i.assembly))),
        [sourcingScenariosDTOs],
    );

    const { data: assemblyDetails } = useAssemblies(assemblyIds);

    return React.useMemo(() => {
        if (!sourcingScenariosDTOs) {
            return { data: undefined };
        }

        const groupedData = sourcingScenariosDTOs.map((s) => ({
            sourcingScenario: s,
            sourcingCalculation: sourcingCalculationDTOs?.find((sc) => sc.sourcing_scenario_id === s.id),
        }));

        const data = groupedData.map((s) => {
            // convert sourcingFull.aggregated_top_level_assembly_information to an array of AssemblyDTO
            const assemblies: SourcingCentricTableType[] = Object.entries(
                s.sourcingCalculation?.aggregated_top_level_assembly_information ?? {},
            ).map(([id, assemblyAggregatedInformation]: [string, AssemblySourcingSummaryDTO]) => {
                const assembly = assemblyDetails?.find((assembly) => assembly.id === id);

                return {
                    type: 'subRow' as const,
                    scenario: s,
                    assemblies: undefined,
                    data: {
                        ...assemblyAggregatedInformation,
                        id,
                        designator: assembly?.designator,
                        type: assembly?.type,
                    },
                };
            });
            return {
                type: 'groupRow' as const,
                scenario: s,
                assemblies,
                data: undefined,
            };
        });

        return { data };
    }, [sourcingScenariosDTOs, sourcingCalculationDTOs, assemblyDetails]);
};

const ActionButton: React.FunctionComponent<{
    sharedContext: SourcingScenarioTableSharedContext;
}> = ({ sharedContext }) => {
    return (
        <SecondaryButton
            size="medium"
            startIcon={<Add />}
            href={route(`/rfqs/:rfqId/sourcing/scenarios/new`, { rfqId: sharedContext.rfqId })}
        >
            <Trans>Add scenario</Trans>
        </SecondaryButton>
    );
};

export const SourcingCentricSourcingScenarioTable: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioIds: string[];
}> = ({ sourcingScenarioIds, rfqId }) => {
    const navigate = useNavigate();
    const { data } = useTableData(sourcingScenarioIds);

    const { table } = useTanStackTable({
        columns,
        data,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        filterFromLeafRows: true,
        sharedContext: {
            rfqId: rfqId,
        },
        getSubRows: (row) => row.assemblies,
        onRowClick: (row) => {
            navigate(
                route(
                    `/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`,
                    {
                        rfqId: row.original.scenario.sourcingScenario.rfq,
                        sourcingScenarioId: row.original.scenario.sourcingScenario.id,
                    },
                    {
                        assemblyId: row.original.type === 'groupRow' ? undefined : row.original.data.id,
                    },
                ),
            );
        },
    });

    return <TanStackTable table={table} size="medium" ActionButton={ActionButton} />;
};
