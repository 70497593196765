import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    CreateOrderInputRuntype,
    InitializePaymentInputRuntype,
    ManyOrderSelectionOptionsRuntype,
    OrderDetailsResponseRuntype,
    OrderOverviewRuntype,
    SalesOrderResponseRuntype,
} from './orderBackendTypes';

export const orderEndpoints = {
    'GET /order-management/order-selection-options': endpoint({
        description: 'Gets all the options for the customer portal that are available to order',
        pathParams: r.Undefined,
        queryParams: r.Record({
            rfq_id: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: ManyOrderSelectionOptionsRuntype,
    }),

    'DELETE /order-management/order-selection-options': endpoint({
        description: 'Deletes the order selection options from the cache to force a recomputation',
        pathParams: r.Undefined,
        queryParams: r.Record({
            rfq_id: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Null,
        // We invalidate and don't remove the GET endpoint because we want to refetch fresh options
        invalidates: ['GET /order-management/order-selection-options'],
    }),

    'POST /order-management/orders/initialize-payment': endpoint({
        description: 'Initializes an order from the customer portal',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: InitializePaymentInputRuntype,
        responseBody: OrderDetailsResponseRuntype,
    }),

    'POST /order-management/orders': endpoint({
        description: 'Places an order from the customer portal',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CreateOrderInputRuntype,
        responseBody: r.Record({ data: SalesOrderResponseRuntype }),
    }),

    'GET /order-management/orders': endpoint({
        description: 'Gets a placed order for an RfQ',
        pathParams: r.Undefined,
        queryParams: r.Record({
            rfq_id: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: OrderOverviewRuntype.nullable(),
        }),
    }),
};
