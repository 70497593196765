import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface QuestComponentsCredentialsDTO extends r.Static<typeof QuestComponentsCredentialsDTORuntype> {}
export const QuestComponentsCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface QuestComponentsCredentialsInputDTO
    extends r.Static<typeof QuestComponentsCredentialsInputDTORuntype> {}
export const QuestComponentsCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface QuestComponentsResponseBodyDTO extends r.Static<typeof QuestComponentsResponseBodyDTORuntype> {}
export const QuestComponentsResponseBodyDTORuntype = r.Record({
    data: QuestComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
