import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    DestructivePrimaryButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SecondaryButton,
    SwitchableChip,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { SolutionConfigurationDTO, SolutionTag } from '@luminovo/http-client';
import {
    hasSolutionConfigurationManualCost,
    hasSolutionTag,
    isConsignedSolution,
    Solution,
} from '@luminovo/sourcing-core';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useMutationUpdateSolutionConfiguration } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';

export function ButtonSolutionSelection({
    solutionConfigurationDTO,
    solution,
}: {
    solutionConfigurationDTO: SolutionConfigurationDTO;
    solution?: Solution;
}): JSX.Element {
    const automaticallySelected =
        isPresent(solution) &&
        hasSolutionTag(solution, SolutionTag.AutoSelected) &&
        !hasSolutionTag(solution, SolutionTag.Selected);

    const { mutateAsync, isPending: patchLoading } = useMutationUpdateSolutionConfiguration(
        solutionConfigurationDTO.id,
    );

    const { openDialog } = useManualCostWarningDialog();

    const updateSolutionToken = React.useCallback(async () => {
        const solutionToken = automaticallySelected ? (solution?.token ?? null) : null;
        await mutateAsync({ solutionToken });
    }, [automaticallySelected, solution, mutateAsync]);

    const isSelectAutomaticallyDisabled = solution === undefined || patchLoading || isConsignedSolution(solution);
    const handleChange = () => {
        if (!isSelectAutomaticallyDisabled) {
            if (hasSolutionConfigurationManualCost(solutionConfigurationDTO)) {
                openDialog(updateSolutionToken);
            } else {
                updateSolutionToken();
            }
        }
    };

    return (
        <Tooltip
            variant="white"
            title={t`When you choose Automatic, we'll pick the best solution for you based on your preferences. But, if the offers change, the best option may also change. If you want to stick with a certain option, switch to Manual.`}
        >
            <span>
                <SwitchableChip
                    label={automaticallySelected ? t`Automatic` : t`Manual selection`}
                    checked={automaticallySelected}
                    isLoading={patchLoading}
                    ContainerProps={{
                        onClick: handleChange,
                    }}
                />
            </span>
        </Tooltip>
    );
}

export function useManualCostWarningDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (updateSolutionToken: () => void) =>
            setDialog(
                <Dialog open={true} fullWidth maxWidth={'sm'} onClose={() => closeDialog()}>
                    <DialogTitle
                        title={t`Changing the selected solution will delete the manually added costs`}
                        handleClose={closeDialog}
                    />
                    <DialogContent>
                        <Text variant="body">
                            <Trans>
                                There are some manually added costs attached to the selected solution. If you select a
                                different solution these will be deleted. Are you sure you want to proceed?
                            </Trans>
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={closeDialog}>
                            <Trans>Keep the solution</Trans>
                        </SecondaryButton>
                        <DestructivePrimaryButton
                            onClick={() => {
                                updateSolutionToken();
                                closeDialog();
                            }}
                        >
                            <Trans>Change and delete costs</Trans>
                        </DestructivePrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}
