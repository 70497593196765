import * as r from 'runtypes';
import {
    DesignItemOriginDTONewRuntype,
    DesignItemOriginDTORuntype,
    ExcelLinesRuntype,
    QuantityUnitDTORuntype,
} from '../backendTypes';
import { PartAlternativeRuntype, PartSpecificationRuntype, PartSuggestion } from '../part';

export const EventSummaryDTORuntype = r.Record({
    category: r.String,
    total: r.Number,
    unresolved: r.Number,
    last_change: r.Null.Or(r.String),
});

export interface DesignItemResponseDTO extends r.Static<typeof DesignItemResponseDTORuntype> {}
export const DesignItemResponseDTORuntype = r.Record({
    id: r.String,
    designator: r.String.nullable(),
    quantity: QuantityUnitDTORuntype.nullable(),
    notes: r.String,
    assembly: r.String,
    do_not_place: r.Boolean,
    part_specification: r.Null.Or(PartSpecificationRuntype),
    origin: DesignItemOriginDTORuntype,
    event_summary: r.Array(EventSummaryDTORuntype),
    is_consigned: r.Boolean,
    aggregation_key: r.String,
    ignore_package_name_mismatch: r.Boolean,
});

export interface DesignItemResponseDTONew extends r.Static<typeof DesignItemResponseDTONewRuntype> {}
export const DesignItemResponseDTONewRuntype = r.Record({
    id: r.String,
    designator: r.String.nullable(),
    quantity: QuantityUnitDTORuntype.nullable(),
    notes: r.String,
    assembly: r.String,
    do_not_place: r.Boolean,
    part_specification: r.Null.Or(PartSpecificationRuntype),
    origin: DesignItemOriginDTONewRuntype,
    event_summary: r.Array(EventSummaryDTORuntype),
    is_consigned: r.Boolean,
    aggregation_key: r.String,
    ignore_package_name_mismatch: r.Boolean,
});

export interface BulkDesignItemDTOAPI extends r.Static<typeof BulkDesignItemDTOAPIRuntype> {}
export const BulkDesignItemDTOAPIRuntype = r.Record({
    items: r.Array(r.String),
});

export function isPartSuggestion(object: PartSuggestion | undefined): object is PartSuggestion {
    return object !== undefined;
}

export interface BulkDesignItemDeleteResponseDTO extends r.Static<typeof BulkDesignItemDeleteResponseDTORuntype> {}
export const BulkDesignItemDeleteResponseDTORuntype = r.Record({
    deleted: r.Number,
});

export interface DesignItemExcelOriginDTO extends r.Static<typeof DesignItemExcelOriginDTORuntype> {}
export const DesignItemExcelOriginDTORuntype = r.Record({
    id: r.String,
    bom_file_id: r.String,
    original_lines: ExcelLinesRuntype,
});

export const DesignItemPartAlternativesRuntype = r.Record({
    id: r.String,
    part_alternatives: r.Array(PartAlternativeRuntype),
});

export interface DesignItemPartAlternativesDTO extends r.Static<typeof DesignItemPartAlternativesRuntype> {}
