import { isPresent } from '@luminovo/commons';
import { OtsFullPart, RfqContext } from '@luminovo/http-client';
import { getOrCreateDataloader } from '../../resources/batching/useBulkQuery';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { assertUnreachable } from '../../utils/typingUtils';

function convertRfqContext(rfqContext: RfqContext) {
    if (rfqContext.type === 'WithinRfQ') {
        return {
            rfq_context: rfqContext.type,
            rfq_id: rfqContext.rfq_id,
        };
    }
    if (rfqContext.type === 'OutsideRfQ') {
        return {
            rfq_context: rfqContext.type,
        };
    }
    assertUnreachable(rfqContext);
}

export function fetchMpnMatches({ genericPartId, rfqContext }: { genericPartId: string; rfqContext: RfqContext }) {
    const dataloader = getOrCreateDataloader('POST /parts/off-the-shelf/search/generic/bulk', {
        idExtractor: (item: { genericPartId: string; otsParts: OtsFullPart[] }) => item.genericPartId,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) =>
            Object.entries(res.data).map(([genericPartId, otsParts]) => {
                return {
                    genericPartId,
                    otsParts,
                };
            }),
    });

    return dataloader.load(genericPartId);
}

export function useMpnMatches(genericPartId: string | undefined, rfqContext: RfqContext) {
    const { isLoading, data } = useHttpQuery(
        'POST /parts/off-the-shelf/search/generic/bulk',
        {
            requestBody: { ids: [genericPartId!], ...convertRfqContext(rfqContext) },
        },
        {
            select: (res) =>
                Object.entries(res.data).map(([genericPartId, otsParts]) => {
                    return {
                        genericPartId,
                        otsParts,
                    };
                })[0],
            enabled: isPresent(genericPartId),
        },
    );

    return {
        isLoading,
        mpnMatches: data?.otsParts ?? [],
    };
}
