import { useCommunicationsDrawer, ViewCommentsButton } from '@/components/CommentsDrawer';
import { t } from '@lingui/macro';
import { PrimaryButton, SecondaryButton, Toolbar } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { PageLayoutCollapsibleSidebar } from '../../../../../components/PageLayoutCollapsibleSidebar';
import { route, UrlParams } from '../../../../../utils/routes';
import { ButtonDeleteQuoteRequest } from '../../../../Negotiations/components/ButtonDeleteQuoteRequest';
import { ButtonDownloadQuoteRequestExcel } from '../../../../Negotiations/components/ButtonDownloadQuoteRequestExcel';
import { useSuspendedQuoteRequest } from '../../../../Negotiations/hooks/negotiationHandlers';
import { NavigationSidebarSourcing } from '../../NavigationSidebarSourcing';
import { QuoteRequestDraftView } from './QuoteRequestDraftView';
import { QuoteRequestSubmittedView } from './QuoteRequestSubmittedView';

function ButtonSendQuoteRequests({
    quoteRequestId,
    negotiationId,
    rfqId,
}: {
    quoteRequestId: string;
    negotiationId: number;
    rfqId: string;
}) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    if (quoteRequest.status !== QuoteRequestStatus.NotSent) {
        return null;
    }

    return (
        <PrimaryButton
            size="medium"
            href={route(
                '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email',
                {
                    rfqId,
                    negotiationId,
                },
                {
                    // TODO: This might break when to many quote requests are selected because we store it in the URL
                    quoteRequestNumbers: quoteRequest.number.toString(),
                },
            )}
        >
            {t`Send request`}
        </PrimaryButton>
    );
}

function ButtonComments({ quoteRequestId }: { quoteRequestId: string }) {
    const { openDrawer } = useCommunicationsDrawer({
        threads: [
            {
                commentType: 'QuoteRequest',
                category: 'Internal',
                typeIds: quoteRequestId,
            },
            /** 
            {
                commentType: 'QuoteRequest',
                category: 'Public',
                typeIds: quoteRequestId,
            },
            */
        ],
    });

    return (
        <ViewCommentsButton
            size={'medium'}
            eventEntity={{ type: 'QuoteRequest', data: quoteRequestId }}
            onClick={() => openDrawer()}
        />
    );
}

function ToolbarInner({
    rfqId,
    negotiationId,
    quoteRequestId,
}: {
    rfqId: string;
    negotiationId: number;
    quoteRequestId: string;
}) {
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Negotiation`,
                    href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests`, {
                        rfqId,
                        negotiationId,
                    }),
                },
                {
                    title: t`Quote request`,
                },
            ]}
        >
            <ButtonComments quoteRequestId={quoteRequestId} />
            <ButtonDeleteQuoteRequest quoteRequestId={quoteRequestId} />
            <ButtonDownloadQuoteRequestExcel quoteRequestId={quoteRequestId} />
            <SecondaryButton
                size="medium"
                href={route(`/rfqs/:rfqId/sourcing/negotiations/quote-requests/importer`, {
                    rfqId,
                })}
            >
                {t`Import quote requests`}
            </SecondaryButton>
            <ButtonSendQuoteRequests rfqId={rfqId} negotiationId={negotiationId} quoteRequestId={quoteRequestId} />
        </Toolbar>
    );
}

function Content({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    switch (quoteRequest.status) {
        case QuoteRequestStatus.NotSent:
            return <QuoteRequestDraftView quoteRequestId={quoteRequestId} />;
        case QuoteRequestStatus.Sent:
        case QuoteRequestStatus.Received:
        case QuoteRequestStatus.Overdue:
        case QuoteRequestStatus.Discarded:
            return <QuoteRequestSubmittedView quoteRequestId={quoteRequestId} />;
    }
}

export function SourcingNegotiationQuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = pathParams.quoteRequestId;
    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
            header={<ToolbarInner rfqId={rfqId} negotiationId={negotiationId} quoteRequestId={quoteRequestId} />}
            layoutVariant="fullWidth"
        >
            <Content quoteRequestId={quoteRequestId} />
        </PageLayoutCollapsibleSidebar>
    );
}
