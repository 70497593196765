import {
    CALCULATION_PERMISSIONS,
    isAssemblyPortalEnabled,
    isCustomerRfQDisabled,
    MANUFACTURING_LITE_PERMISSIONS,
} from '@/featureFlags';
import { route } from '@/utils/routes';
import { t } from '@lingui/macro';
import { isAuthorized, Permission } from '@luminovo/auth';
import { getIntercomArticleURL, isProductionEnvironment, sortBy } from '@luminovo/commons';
import type { CommandBarItem } from '@luminovo/design-system';
import { UserType } from '@luminovo/http-client';
import * as icons from '@mui/icons-material';

const commandBarResources = ({
    defaultOpen,
    permissions,
    userType,
    hasAccessToSourceToContract,
}: {
    defaultOpen: boolean;
    hasAccessToSourceToContract: boolean;
    permissions: Permission[];
    userType: UserType;
}): CommandBarItem[] => [
    {
        type: 'category',
        title: t`Resources`,
        defaultOpen,
        children: sortBy(
            [
                {
                    type: 'link',
                    title: t`RfQs`,
                    href: route('/rfqs'),
                    alwaysHidden:
                        !isAuthorized(permissions, ['view:rfq']) &&
                        (userType === UserType.Customer || isCustomerRfQDisabled()),
                    Icon: icons.RequestPage,
                },
                {
                    type: 'link',
                    title: t`Assemblies`,
                    href: route('/assemblies'),
                    alwaysHidden: userType === UserType.Customer || !isAssemblyPortalEnabled(),
                    Icon: icons.Build,
                },
                {
                    type: 'link',
                    title: t`Customers`,
                    alwaysHidden: !isAuthorized(permissions, ['edit:customer', 'view:customer:all']),
                    href: route('/customers'),
                    Icon: icons.People,
                },
                {
                    type: 'link',
                    title: t`Manufacturers`,
                    href: route('/manufacturer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:supplier']),
                    Icon: icons.Factory,
                },
                {
                    type: 'link',
                    title: t`Part search`,
                    href: route('/parts/off-the-shelf-part-search'),
                    alternatives: [t`MPN search`, t`MPNs`],
                    Icon: icons.ManageSearch,
                },
                {
                    type: 'link',
                    title: t`Part alerts`,
                    alternatives: [],
                    alwaysHidden: !isAuthorized(permissions, ['view:activity']),
                    href: route('/parts/alerts'),
                    Icon: icons.AddAlertRounded,
                },
                {
                    type: 'link',
                    title: t`Components`,
                    alternatives: [t`IPNs`, t`Internal part numbers`],
                    href: route('/parts/components'),
                    Icon: icons.Dvr,
                },
                {
                    type: 'link',
                    href: route('/parts/demands'),
                    alternatives: [],
                    alwaysHidden: userType !== UserType.Internal,
                    Icon: icons.DataUsageRounded,
                    title: t`Demands`,
                },
                {
                    type: 'link',
                    title: t`Purchase orders`,
                    href: route('/purchase-orders'),
                    alwaysHidden: !isAuthorized(permissions, ['view:purchase_order']),
                    Icon: icons.ShoppingCart,
                    alternatives: [t`POs`],
                },
                {
                    type: 'link',
                    title: t`Suppliers`,
                    href: route('/suppliers'),
                    alwaysHidden: !isAuthorized(permissions, ['view:supplier']),
                    Icon: icons.Store,
                },
                {
                    type: 'link',
                    title: t`Supplier portal`,
                    href: route('/supplier-portal'),
                    Icon: icons.Store,
                    alwaysHidden: isProductionEnvironment(),
                },
                {
                    type: 'link',
                    title: t`Users`,
                    href: route('/settings/organization/users'),
                    alwaysHidden: userType !== UserType.Internal,
                    Icon: icons.People,
                },
                {
                    type: 'link',
                    title: t`Sites`,
                    href: route('/settings/organization/sites'),
                    Icon: icons.LocationOn,
                    alwaysHidden: userType !== UserType.Internal,
                    alternatives: [t`Locations`],
                },
                {
                    type: 'link',
                    title: t`Negotiations`,
                    href: route('/negotiations'),
                    alwaysHidden: !hasAccessToSourceToContract || userType !== UserType.Internal,
                    Icon: icons.CurrencyExchange,
                },
                {
                    type: 'link',
                    title: t`Quote requests`,
                    href: route('/parts/quote-requests'),
                    alwaysHidden: !isAuthorized(permissions, ['view:quotation']),
                    Icon: icons.ForwardToInboxRounded,
                },
            ],
            (item) => item.title,
        ),
    },
];

const commandBarSettings = ({ permissions }: { permissions: Permission[] }): CommandBarItem[] => [
    {
        type: 'category',
        title: t`Settings`,
        defaultOpen: false,
        alwaysHidden: !isAuthorized(permissions, ['view:organization_settings']),
        children: sortBy([
            { type: 'link', title: t`Users`, href: route('/settings/organization/users') },
            {
                type: 'link',
                title: t`Currency settings`,
                href: route('/settings/organization/currency'),
                match: 'exact',
                alternatives: [t`Currencies`, t`Exchange rate`, t`Currency preferences`],
            },
            {
                type: 'link',
                title: t`Mock data`,
                alwaysHidden: isProductionEnvironment(),
                href: route('/settings/organization/mock-data'),
            },
            {
                type: 'link',
                title: t`Branding`,
                href: route('/settings/organization/branding'),
                match: 'exact',
                alternatives: [t`Branding`],
            },
            {
                type: 'link',
                title: t`Assembly settings`,
                href: route('/settings/organization/assembly-settings'),
                match: 'exact',
                alternatives: [t`Assembly preferences`],
            },
            {
                type: 'link',
                title: t`Manufacturer settings`,
                href: route('/settings/organization/manufacturer-preferences'),
                match: 'exact',
                alternatives: [t`Manufacturer preferences`],
            },
            {
                type: 'link',
                title: t`PCB panel preferences`,
                href: route('/settings/organization/pcb-panel-preferences'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Carbon footprints`,
                href: route('/settings/organization/carbon-footprints'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Solution preferences`,
                href: route('/settings/organization'),
                alternatives: [
                    t`Sourcing preferences`,
                    t`Configure preferred and approved suppliers`,
                    t`Approved suppliers`,
                    t`Preferred suppliers`,
                ],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Offer age limit`,
                href: route('/settings/organization') + '#offer-age-limit',
                alternatives: [t`Offer age limit`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Inventory preference`,
                href: route('/settings/organization') + '#inventory-preference',
                alternatives: [t`Inventory preferences`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Packaging preferences`,
                href: route('/settings/organization') + '#packaging-preference',
                alternatives: [t`Packaging preferences`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Supplier API integration`,
                href: route('/settings/organization/supplier-api-integrations'),
                alternatives: [t`Supplier API rate limit`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Scrap calculation`,
                href: route('/settings/organization/scrap-calculation'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Total cost of ownership`,
                href: route('/settings/organization/total-cost-of-ownership-rules'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Customer portal`,
                href: route('/settings/organization/customer-portal'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Additional services`,
                href: route('/settings/organization/customer-portal/additional-services'),
                alternatives: [t`Customer portal additional services`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Payment credentials`,
                href: route('/settings/organization/customer-portal/payment-credentials'),
                alternatives: [t`Customer portal payment credentials`, t`Stripe settings`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Supplier portal`,
                href: route('/settings/organization/supplier-portal'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`API and webhooks`,
                href: route('/settings/organization/api-settings'),
                alternatives: [t`API settings`, t`API Docs`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Automatic RfQ number`,
                href: route('/settings/organization/automatic-rfq-number'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Email templates`,
                href: route('/settings/organization/email-templates'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Export templates`,
                href: route('/settings/organization/export-templates'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Source list`,
                href: route('/settings/organization/source-list'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Account security`,
                alternatives: [t`Two-factor authentication`],
                href: route('/settings/user/account-security'),
                alwaysHidden: !isAuthorized(permissions, ['view:organization_settings']),
                match: 'exact',
            },
        ]),
    },
];

const commandBarWorkflows = ({
    defaultOpen,
    hasAccessToSourceToContract,
    userType,
    permissions,
}: {
    defaultOpen: boolean;
    hasAccessToSourceToContract: boolean;
    userType: UserType;
    permissions: Permission[];
}): CommandBarItem[] => [
    {
        type: 'category',
        title: t`Workflows`,
        defaultOpen,
        children: sortBy(
            [
                {
                    type: 'link',
                    title: t`New RfQ`,
                    alwaysHidden:
                        !isAuthorized(permissions, ['create:rfq:customer']) &&
                        !isAuthorized(permissions, ['create:rfq:ems']),
                    href: route('/rfq_creation'),
                    Icon: icons.Add,
                    alternatives: [t`Create RfQ`, t`Start RfQ`, t`Add RfQ`],
                },
                {
                    type: 'link',
                    title: t`New negotiation`,
                    href: route('/negotiations/create'),
                    alwaysHidden: !hasAccessToSourceToContract || userType !== UserType.Internal,
                    Icon: icons.Add,
                    alternatives: [t`Create negotiation`, t`Start negotiation`, t`Add negotiation`],
                },
                {
                    type: 'link',
                    title: t`Import demands`,
                    href: route('/parts/demand/demand-importer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:part_library']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add demands`],
                },
                {
                    type: 'link',
                    title: t`Import components`,
                    href: route('/parts/components/component-importer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:part_library']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Import IPNs`, t`Add components`],
                },
                {
                    type: 'link',
                    title: t`Import suppliers`,
                    href: route('/supplier/importer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:supplier']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add suppliers`],
                },
                {
                    type: 'link',
                    title: t`Import customers`,
                    href: route('/customers/importer'),
                    alwaysHidden: !isAuthorized(permissions, ['edit:customer']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add customers`],
                },
                {
                    type: 'link',
                    title: t`Import supplier contacts`,
                    href: route('/suppliers/supplier-contacts-importer/bulk'),
                    alwaysHidden: !isAuthorized(permissions, ['view:supplier']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add supplier contacts`],
                },
                {
                    type: 'link',
                    title: t`Import offers`,
                    href: route('/offers/mpn-offer-importer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:sourcing']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add offers`],
                },
                {
                    type: 'link',
                    title: t`Import quote requests`,
                    href: route('/parts/quote-requests/importer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:quotation']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add quote requests`],
                },
                {
                    type: 'link',
                    title: t`Import source list`,
                    href: route('/parts/source-list-importer'),
                    alwaysHidden: !isAuthorized(permissions, ['view:sourcing']),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add source list`],
                },
            ],
            (item) => item.title,
        ),
    },
];

const commandBarHelpCenter = (): CommandBarItem[] => {
    return [
        {
            type: 'category',
            title: t`Help center`,
            children: [
                {
                    type: 'link',
                    title: t`How to increase API rate limits`,
                    href: getIntercomArticleURL(171926),
                    Icon: icons.ExitToApp,
                },
                {
                    type: 'link',
                    title: t`Help center home`,
                    href: 'https://help.luminovo.com',
                    Icon: icons.ExitToApp,
                },
            ],
        },
    ];
};

export const defaultCommandBarContents = ({
    hasAccessToSourceToContract,
    userType,
    permissions,
}: {
    hasAccessToSourceToContract: boolean;
    userType: UserType;
    permissions: Permission[];
}): CommandBarItem[] => {
    if (userType !== UserType.Internal) {
        // Until we test further, only show the sidebar for internal users.
        // We don't want to accidentally leak some page to an OEM customer user, or a supplier user.
        return [];
    }
    return [
        {
            type: 'link',
            href: '/rfqs',
            title: t`Home`,
            Icon: icons.Home,
            alwaysHidden: !isAuthorized(permissions, ['view:rfq']),
            match: 'exact',
        },
        {
            type: 'separator',
            title: '',
        },
        ...commandBarResources({ defaultOpen: true, hasAccessToSourceToContract, userType, permissions }),
        {
            type: 'separator',
            title: '',
        },
        ...commandBarWorkflows({ defaultOpen: false, hasAccessToSourceToContract, userType, permissions }),
        {
            type: 'separator',
            title: '',
        },
        ...commandBarSettings({ permissions }),
        ...commandBarHelpCenter(),
    ];
};

export const rfqSidebarContents = ({
    rfqId,
    hasAccessToSourceToContract,
    userType,
    permissions,
}: {
    rfqId: string;
    hasAccessToSourceToContract: boolean;
    userType: UserType;
    permissions: Permission[];
}): CommandBarItem[] => {
    if (userType !== UserType.Internal) {
        // Until we test further, only show the sidebar for internal users.
        // We don't want to accidentally leak some page to an OEM customer user, or a supplier user.
        return [];
    }
    return [
        {
            type: 'link',
            href: '/',
            title: t`Home`,
            Icon: icons.Home,
            match: 'exact',
            alwaysHidden: !isAuthorized(permissions, ['view:rfq']),
        },
        {
            type: 'category',
            title: t`Current RfQ`,
            defaultOpen: true,
            alwaysHidden: !isAuthorized(permissions, ['view:rfq']),
            children: [
                {
                    type: 'link',
                    title: t`Dashboard`,
                    href: route('/rfqs/:rfqId/dashboard', { rfqId }),
                    Icon: icons.Dashboard,
                    alternatives: [t`Overview`],
                },
                {
                    type: 'link',
                    title: t`Design`,
                    alternatives: [t`BOM`],
                    href: route('/rfqs/:rfqId/bom', { rfqId }),
                    Icon: icons.GifBox,
                },
                {
                    type: 'link',
                    title: t`Sourcing`,
                    href: route('/rfqs/:rfqId/sourcing', { rfqId }),
                    alwaysHidden: !isAuthorized(permissions, ['view:sourcing']),
                    Icon: icons.BarChart,
                },
                {
                    type: 'link',
                    title: t`Manufacturing`,
                    href: route('/rfqs/:rfqId/manufacturing', { rfqId }, { forwardIfSingleAssembly: 'true' }),
                    alwaysHidden: !isAuthorized(permissions, MANUFACTURING_LITE_PERMISSIONS),
                    Icon: icons.Build,
                },
                {
                    type: 'link',
                    title: t`Calculation`,
                    alternatives: [t`Costing templates`],
                    href: route('/rfqs/:rfqId/calculation', { rfqId }),
                    alwaysHidden: !isAuthorized(permissions, CALCULATION_PERMISSIONS),
                    Icon: icons.Calculate,
                },
                {
                    type: 'link',
                    title: t`Quotation`,
                    href: route('/rfqs/:rfqId/quotation', { rfqId }),
                    alwaysHidden: !isAuthorized(permissions, ['view:quotation']),
                    Icon: icons.PictureAsPdf,
                },
            ],
        },
        {
            type: 'separator',
            title: '',
        },
        ...commandBarResources({ defaultOpen: false, hasAccessToSourceToContract, userType, permissions }),
        {
            type: 'separator',
            title: '',
        },
        ...commandBarWorkflows({ defaultOpen: false, hasAccessToSourceToContract, userType, permissions }),
        {
            type: 'separator',
            title: '',
        },
        ...commandBarSettings({ permissions }),
        ...commandBarHelpCenter(),
    ];
};
