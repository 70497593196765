import { instantiateParser } from '../parsers/parsers';
import {
    ImportRecord,
    ImportStatus,
    ImporterCell,
    ImporterConfig,
    ImporterRow,
    ImporterTable,
    LinkStatus,
    ParseStatus,
    Position,
    Table,
    TransformRecordActionHandler,
} from '../types';
import { compareParseStatus } from './compareParseStatus';

/**
 * An implementation of the {@link ImporterTable} interface that is immutable.
 */
export class ImmutableImporterTable implements ImporterTable {
    /**
     * Cache for duplicates of unique columns.
     */
    private uniqueValuesIndex: UniqueValuesIndex;

    constructor(
        private rows: ImporterRow[],
        private config: ImporterConfig,
        private transformRecordAction?: TransformRecordActionHandler<ImporterConfig>,
    ) {
        this.uniqueValuesIndex = UniqueValuesIndex.fromRows(this.rows);

        this.rows = this.rows.map((row) => {
            const doesRowHaveDuplicates =
                !Boolean(row.skip) &&
                row.cells.some((cell, columnIndex) => {
                    if (!cell.field.unique) {
                        return false;
                    }
                    const text = cell.text.trim();
                    if (text.length === 0) {
                        return false;
                    }
                    return this.uniqueValuesIndex.hasDuplicates(columnIndex, text);
                });

            if (doesRowHaveDuplicates) {
                return { ...row, status: 'duplicate-error' };
            } else {
                return new ImmutableRow(row.index, row.cells, row.skip, row.import, this.transformRecordAction);
            }
        });
    }

    public static fromTable({
        table,
        config,
        transformRecordAction,
    }: {
        table: Table;
        config: ImporterConfig;
        transformRecordAction?: TransformRecordActionHandler<ImporterConfig>;
    }): ImmutableImporterTable {
        const rows = table.map((row, index): ImporterRow => {
            const cells = config.fields.map((field): ImporterCell => {
                const cells = field.columnIndices.map((colIndex) => row[colIndex]);
                const cell = cells.join('\n');

                return {
                    field,
                    status: { status: 'pending' },
                    text: cell,
                };
            });

            // On an import: skip the row by default on these conditions:
            // - all cells are empty
            // - it's the first row (it's usually the header row)
            const skipRow = cells.every((cell) => cell.text.trim().length === 0) || index === 0;

            return new ImmutableRow(index, cells, skipRow, undefined, transformRecordAction);
        });

        return new ImmutableImporterTable(rows, config, transformRecordAction);
    }

    getRows(): ImporterRow[] {
        return this.rows;
    }

    getRowsWithDuplicates(): ImporterRow[] {
        return this.rows.filter((row) => row.status === 'duplicate-error');
    }

    updateRows(rowsToUpdate: ImporterRow[]): ImporterTable {
        const newRows = this.rows.map((row) => {
            const newRow = rowsToUpdate.find((r) => r.index === row.index);
            if (newRow) {
                return newRow;
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config, this.transformRecordAction);
    }

    async applyParsers(range: {
        row: { from: number; to: number };
        column: { from: number; to: number };
    }): Promise<ImporterTable> {
        const newRows: ImporterRow[] = [];
        for (const row of this.rows) {
            const newCells: ImporterCell[] = [];
            for (const cell of row.cells) {
                // check if the cell is in the range [from,to)
                const isInRowRange = row.index >= range.row.from && row.index < range.row.to;
                const isInColumnRange = true;
                const isInRange = isInRowRange && isInColumnRange;

                if (!isInRange) {
                    newCells.push(cell);
                    continue;
                }

                const parserFn = instantiateParser(cell.field.parser, cell.field);
                try {
                    const parsed = await parserFn([cell.text]);
                    newCells.push({
                        ...cell,
                        status: parsed,
                    });
                } catch (e) {
                    newCells.push({
                        ...cell,
                        status: { status: 'error', message: String(e) },
                    });
                }
            }
            const newRow = new ImmutableRow(row.index, newCells, row.skip, row.import, this.transformRecordAction);
            newRows.push(newRow);
        }
        return new ImmutableImporterTable(newRows, this.config, this.transformRecordAction);
    }

    excludeErrors(): ImporterTable {
        const newRows = this.rows.map((row) => {
            if (row.status === 'error') {
                return new ImmutableRow(row.index, row.cells, true, row.import, this.transformRecordAction);
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config, this.transformRecordAction);
    }

    excludeWarnings(): ImporterTable {
        const newRows = this.rows.map((row) => {
            if (row.status === 'warning') {
                return new ImmutableRow(row.index, row.cells, true, row.import, this.transformRecordAction);
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config, this.transformRecordAction);
    }

    excludeAll(exclude: boolean, indices: number[]): ImporterTable {
        const newRows = this.rows.map((row) => {
            if (indices.includes(row.index)) {
                return new ImmutableRow(row.index, row.cells, exclude, row.import, this.transformRecordAction);
            }
            return row;
        });

        return new ImmutableImporterTable(newRows, this.config, this.transformRecordAction);
    }

    skipRow(index: number): ImporterTable {
        const row = this.getRow(index);
        if (!row) {
            return this;
        }
        const newRow = new ImmutableRow(
            row.index,
            row.cells,
            !Boolean(row.skip),
            row.import,
            this.transformRecordAction,
        );
        return this.updateRows([newRow]);
    }

    countIdenticalMatches(colIndex: number, text: string): number {
        let count = 0;

        for (let i = 0; i < this.rows.length; i++) {
            const cell = this.getCell({ row: i, column: colIndex });
            if (cell?.text === text) {
                count++;
            }
        }
        return count;
    }

    countIncluded(): number {
        return this.rows.filter((row) => row.status !== 'skipped').length;
    }

    filterByParseStatus(status: ParseStatus | null): ImporterRow[] {
        if (status === null) {
            return this.rows;
        }
        return this.rows.filter((row) => row.status === status);
    }

    filterByLinkStatus(status: LinkStatus | null): ImporterRow[] {
        if (status === null) {
            return this.rows;
        }
        return this.rows.filter((row) => (row.record?.action ?? 'skipped') === status);
    }

    getReadyForPreviewPercentage(): number {
        const total = this.rows.length;
        const done = this.rows.filter(
            (row) => row.status === 'done' || row.status === 'skipped' || row.status === 'warning',
        ).length;
        return 100 * (done / total);
    }

    isReadyForPreview(): boolean {
        return this.rows.every((row) => row.status === 'done' || row.status === 'skipped' || row.status === 'warning');
    }

    isReadyForImport(): boolean {
        return this.rows.some((row) => row.record && row.record.action !== 'skipped');
    }

    setCell(position: Position, newCell: ImporterCell): ImporterTable {
        const row = this.getRow(position.row);
        if (!row) {
            // do nothing if the row doesn't exist
            return this;
        }

        const newCells = row.cells.map((cell, index) => {
            if (index === position.column) {
                return newCell;
            }
            return cell;
        });
        const newRow = new ImmutableRow(row.index, newCells, row.skip, row.import, this.transformRecordAction);

        return this.updateRows([newRow]);
    }

    setMatching(position: Pick<Position, 'column'>, text: string, newCell: ImporterCell): ImporterTable {
        let newTable: ImporterTable = this;
        for (let i = 0; i < this.rows.length; i++) {
            if (this.rows[i].skip === true) {
                continue;
            }
            const cell = newTable.getCell({ row: i, column: position.column });
            if (cell?.text === text) {
                newTable = newTable.setCell({ row: i, column: position.column }, { ...newCell });
            }
        }
        return newTable;
    }

    getUniqueColumnsWithDuplicates(): { columnIndex: number; duplicates: number; text: string }[] {
        // calculate duplicates and cache them for performance
        const firstRowCells = this.rows[0]?.cells ?? [];
        return firstRowCells.flatMap((_, columnIndex) => {
            const duplicates = this.uniqueValuesIndex.getDuplicates(columnIndex);
            return duplicates.map((duplicate) => {
                return {
                    columnIndex,
                    duplicates: duplicate.count,
                    text: duplicate.text,
                };
            });
        });
    }

    getCell(position: Position): ImporterCell | undefined {
        const row = this.getRow(position.row);
        if (!row) {
            return undefined;
        }
        return row.cells[position.column];
    }

    getRow(index: number): ImporterRow | undefined {
        return this.rows[index];
    }

    getSize(): number {
        return this.rows.length;
    }

    getParseStatusCount(): Record<ParseStatus, number> {
        const counts: Record<ParseStatus, number> = {
            pending: 0,
            done: 0,
            error: 0,
            skipped: 0,
            warning: 0,
            'duplicate-error': 0,
        };
        for (const row of this.rows) {
            counts[row.status]++;
        }
        return counts;
    }

    getLinkStatusCount(): Record<LinkStatus, number> {
        const counts: Record<LinkStatus, number> = {
            skipped: 0,
            insert: 0,
            update: 0,
        };
        for (const row of this.rows) {
            if (row.status === 'error' || row.status === 'pending') {
                // skip
                continue;
            } else if (row.status === 'skipped' || !row.record) {
                counts['skipped']++;
            } else if (row.record.action === 'insert') {
                counts['insert']++;
            } else if (row.record.action === 'update') {
                counts['update']++;
            }
        }
        return counts;
    }

    getImportStatusCount(): Record<'done' | 'error' | 'skipped', number> {
        const counts: Record<'done' | 'error' | 'skipped', number> = {
            done: 0,
            error: 0,
            skipped: 0,
        };
        for (const row of this.rows) {
            if (!row.import) {
                counts['skipped']++;
            } else if (row.import.success === true) {
                counts['done']++;
            } else if (row.import.success === false) {
                counts['error']++;
            }
        }
        return counts;
    }

    getImportRecords(): ImportRecord<ImporterConfig>[] {
        return this.rows.flatMap((row) => {
            if (row.record && row.record.action !== 'skipped') {
                return [row.record];
            }
            return [];
        });
    }
}

export class ImmutableRow implements ImporterRow {
    record?: ImportRecord<ImporterConfig> | undefined;
    status: ParseStatus;
    import?: ImportStatus | undefined;

    // eslint-disable-next-line max-params
    constructor(
        public index: number,
        public cells: ImporterCell[],
        public skip: boolean = false,
        private importStatus: ImportStatus | undefined,
        private transformRecordAction?: TransformRecordActionHandler<ImporterConfig>,
    ) {
        if (cells.length === 0) {
            throw new Error('Row must have at least one cell');
        }
        this.status = skip
            ? 'skipped'
            : (cells.map((cell) => cell.status.status).sort(compareParseStatus)[0] ?? 'skipped');
        this.import = importStatus;
        this.record =
            this.status === 'done' || this.status === 'warning'
                ? this.transformRecord(transformRecordAction)
                : undefined;
    }

    withDuplicateStatus(): ImporterRow {
        return new ImmutableRow(this.index, this.cells, this.skip, this.importStatus, this.transformRecordAction);
    }

    private transformRecord(
        transformRecordAction?: TransformRecordActionHandler<ImporterConfig>,
    ): ImportRecord<ImporterConfig> {
        const record = this.createRecord();
        return {
            action: transformRecordAction ? transformRecordAction(record) : record.action,
            data: record.data,
        };
    }
    private createRecord(): ImportRecord<ImporterConfig> {
        let action: LinkStatus = 'insert';
        const record: Record<string, unknown> = {};
        for (const cell of this.cells) {
            if ('value' in cell.status) {
                record[cell.field.id] = cell.status.value.id;

                if (cell.status.value.existing === true) {
                    action = 'update';
                }
            } else {
                // if any cell is not done, we can't set the record
                return {
                    action: 'skipped',
                    data: {},
                };
            }
        }
        return {
            action,
            data: record as any,
        };
    }
}

class UniqueValuesIndex {
    private values = new Map<
        string,
        {
            count: number;
            text: string;
            columnIndex: number;
        }
    >();

    static fromRows(rows: ImporterRow[]): UniqueValuesIndex {
        const index = new UniqueValuesIndex();
        for (const row of rows) {
            if (row.skip) {
                continue;
            }
            for (let columnIndex = 0; columnIndex < row.cells.length; columnIndex++) {
                const cell = row.cells[columnIndex];
                if (cell.field.unique) {
                    index.increment(columnIndex, cell.text);
                }
            }
        }
        return index;
    }

    private getKey(columnIndex: number, text: string): string {
        return `${columnIndex}::${text}`;
    }

    private increment(columnIndex: number, text: string) {
        if (text.length === 0) {
            return;
        }
        const key = this.getKey(columnIndex, text);
        const count = this.values.get(key) ?? { count: 0, text, columnIndex };
        this.values.set(key, { ...count, count: count.count + 1 });
    }

    hasDuplicates(columnIndex: number, text: string): boolean {
        const key = this.getKey(columnIndex, text);
        const count = this.values.get(key)?.count ?? 0;
        return count > 1;
    }

    getDuplicates(columnIndex: number): { text: string; count: number }[] {
        const result: { text: string; count: number }[] = [];
        for (const mapEntry of this.values.values()) {
            if (columnIndex === mapEntry.columnIndex && mapEntry.count > 1) {
                result.push({ count: mapEntry.count, text: mapEntry.text });
            }
        }
        return result;
    }
}
