import * as r from 'runtypes';

export type ApiIntegrationStatus = r.Static<typeof ApiIntegrationStatusRuntype>;
export const ApiIntegrationStatusRuntype = r.Union(
    r.Literal('Active'),
    r.Literal('ActiveByDefault'),
    r.Literal('Inactive'),
    r.Literal('MissingApiKey'),
    r.Literal('InvalidCredentials'),
    r.Literal('InvalidApiKey'),
    r.Literal('InvalidSettings'),
    r.Literal('RateLimited'),
    r.Literal('Error'),
);
