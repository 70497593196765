import { isPresent } from '@luminovo/commons';
import { HttpError } from '@luminovo/http-client';
import { GlobalField, ImportBatchHandler, ImportRecord, ImportStatus, ImporterConfig, ImporterTable } from '../types';
import { iterateBatches } from './iterateBatches';

/**
 * Runs the importer on the given table.
 *
 * Splits the table into batches, then calls the onImport function for each batch.
 * Updates the table with the results of the import.
 *
 * The generator yields the table after each batch has been processed.
 *
 * @param table The table that will be imported.
 * @param onImportBatch A function that will be called for each batch of records that are ready for import.
 */
export async function* runImporter<TConfig extends ImporterConfig>({
    table,
    onImportBatch: onImport,
    batchSize,
    globalFields,
}: {
    table: ImporterTable;
    onImportBatch: ImportBatchHandler<TConfig>;
    batchSize: number;
    globalFields?: Array<GlobalField>;
}): AsyncGenerator<ImporterTable> {
    let finalTable = table;
    const records = table
        .filterByLinkStatus(null)
        .filter((r) => r.record !== undefined && r.record.action !== 'skipped');

    const gen = iterateBatches(records, {
        batchSize,
        mapBatch: async (batch) => {
            const records = batch.items.map((r) => r.record).filter(isPresent) as ImportRecord<TConfig>[];
            if (records.length !== batch.items.length) {
                throw new Error(
                    `Illegal state: expected all records to have a record attached. records=${records.length}, items=${batch.items.length}`,
                );
            }
            const importResults = await onImport(records, globalFields)
                // If the import fails, we return an array of import statuses with the error message
                .catch((err): ImportStatus[] => {
                    return records.map(() => ({
                        success: false,
                        message: err instanceof HttpError ? err.code : String(err),
                    }));
                });
            if (importResults.length !== records.length) {
                throw new Error(
                    `Illegal state: expected one result per record. results=${importResults.length}, records=${records.length}`,
                );
            }
            return zip(batch.items, importResults).map(([item, result]) => {
                return {
                    ...item,
                    import: result,
                };
            });
        },
    });

    for await (const rows of gen) {
        finalTable = finalTable.updateRows(rows);
        yield finalTable;
    }
}
function zip<T, U>(a: T[], b: U[]): Array<[T, U]> {
    return a.map((v, i) => [v, b[i]]);
}
