import * as r from 'runtypes';
import { DriverIdRuntype } from '../driver';
import { ResourceDTORuntype } from '../resource/resourceBackendTypes';
import { CategoryRuntype, ManufacturingEntityStatusRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';

const ActivityLevelRuntype = r.Union(r.Literal('Unit'), r.Literal('Batch'), r.Literal('Project'));

export const ACTIVITY_CREATION_ALL_LEVELS = ActivityLevelRuntype.alternatives.map((alternative) => alternative.value);
export type ActivityLevel = r.Static<typeof ActivityLevelRuntype>;

const PanelDefaultSettingsRuntype = r.Union(r.Literal('AsSingleAssembly'), r.Literal('WithinWholePanel'));

const DurationUnitRuntypes = r.Union(r.Literal('Seconds'), r.Literal('Minutes'), r.Literal('Hours'));
export type DurationUnit = r.Static<typeof DurationUnitRuntypes>;

export const DurationUnitOptions: DurationUnit[] = DurationUnitRuntypes.alternatives.map(
    (alternative) => alternative.value,
); // I tried (unsuccessfully) to get this into an extensible function using this https://github.com/pelotom/runtypes/issues/38#issuecomment-809003522

export interface Duration extends r.Static<typeof DurationRuntype> {}
export const DurationRuntype = r.Record({
    amount: r.String, //this is a decimal so should be a string
    unit: DurationUnitRuntypes,
});

export type VariableTime = r.Static<typeof VariableTimeRuntype>;
const VariableTimeRuntype = r.Record({
    variable_unit: DurationRuntype,
    driver: DriverIdRuntype,
});

const TimeCalculationFixedDetails = r.Record({
    type: r.Literal('Fixed'),
    details: r.Record({
        fixed_time: DurationRuntype,
    }),
});

const TimeCalculationLinearDetails = r.Record({
    type: r.Literal('Linear'),
    details: r.Record({
        fixed_time: DurationRuntype.nullable(),
        variable_time: VariableTimeRuntype,
    }),
});

const FormulaDetailsRuntype = r.Record({
    formula: r.String,
    unit: DurationUnitRuntypes,
});

export type FormulaDetails = r.Static<typeof FormulaDetailsRuntype>;

const TimeCalculationFormulaDetails = r.Record({
    type: r.Literal('Formula'),
    details: FormulaDetailsRuntype,
});

export type TimeCalculationFormulaDetailsType = r.Static<typeof TimeCalculationFormulaDetails>;

const TimeCalculationDetails =
    TimeCalculationFixedDetails.Or(TimeCalculationLinearDetails).Or(TimeCalculationFormulaDetails);

export const TimeComponentsRuntype = TimeCalculationDetails;
export type TimeComponents = r.Static<typeof TimeComponentsRuntype>;

const TimeCalculationBatchRuntype = r.Record({
    level: r.Literal('Batch'),
    details: r.Record({
        batch_time_components: TimeComponentsRuntype,
    }),
});
export type TimeCalculationBatch = r.Static<typeof TimeCalculationBatchRuntype>;

const TimeCalculationProjectRuntype = r.Record({
    level: r.Literal('Project'),
    details: r.Record({
        project_time_components: TimeComponentsRuntype,
    }),
});
export type TimeCalculationProject = r.Static<typeof TimeCalculationProjectRuntype>;

export interface TimeCalculationUnit extends r.Static<typeof TimeCalculationUnitRuntype> {}
const TimeCalculationUnitRuntype = r.Record({
    level: r.Literal('Unit'),
    details: r.Record({
        panel_default_settings: PanelDefaultSettingsRuntype,
        unit_time_components: TimeCalculationDetails,
        batch_time_components: TimeCalculationDetails.nullable(),
    }),
});

export const TimeCalculationRuntype =
    TimeCalculationUnitRuntype.Or(TimeCalculationBatchRuntype).Or(TimeCalculationProjectRuntype);
export type TimeCalculation = r.Static<typeof TimeCalculationRuntype>;

export type ResourceDetails = r.Static<typeof ResourceDetailsRuntype>;
const ResourceDetailsRuntype = r.Record({
    resource_id: r.String,
    multiplier: r.String,
});

export type ResourceDetailsDTO = r.Static<typeof ResourceDetailsDTORuntype>;
export const ResourceDetailsDTORuntype = r.Record({
    resource: ResourceDTORuntype,
    multiplier: r.String,
});

export const ActivityDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    internal_number: r.String.nullable(),
    description: r.String.nullable(),
    process: ProcessRuntype,
    category: CategoryRuntype,
    resources_details: r.Array(ResourceDetailsRuntype),
    time_calculation: TimeCalculationRuntype,
    status: ManufacturingEntityStatusRuntype,
    sites: r.Array(r.String),
});

export interface ActivityDTO extends r.Static<typeof ActivityDTORuntype> {}

export const ActivitiesDTORuntype = r.Array(ActivityDTORuntype);

export interface ActivitiesDTO extends r.Static<typeof ActivitiesDTORuntype> {}

export const ActivityPostDTORuntype = ActivityDTORuntype.omit('id', 'status', 'sites');
export interface ActivityPostDTO extends r.Static<typeof ActivityPostDTORuntype> {}

export const ActivityOverwriteDTORuntype = r.Record({
    id: r.String,
    activity: r.String,
    manufacturing_scenario: r.String,
    time_calculation: TimeCalculationRuntype,
});
export const ActivityOverwritePatchDTORuntype = r.Record({
    manufacturing_scenario: r.String,
    time_calculation: TimeCalculationRuntype,
});

export type ActivityOverwritePatchDTO = r.Static<typeof ActivityOverwritePatchDTORuntype>;
export type ActivityOverwriteDTO = r.Static<typeof ActivityOverwriteDTORuntype>;

export const ActivityOverwritePostDTORuntype = ActivityOverwriteDTORuntype.omit('id', 'activity');
export interface ActivityOverwritePostDTO extends r.Static<typeof ActivityOverwritePostDTORuntype> {}
