import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type MyArrowLocaleCurrencyDTO = r.Static<typeof MyArrowLocaleCurrencyDTORuntype>;
export const MyArrowLocaleCurrencyDTORuntype = r.Union(
    r.Literal('USD'),
    r.Literal('EUR'),
    r.Literal('PLN'),
    r.Literal('GBP'),
    r.Literal('CHF'),
    r.Literal('CAD'),
    r.Literal('DKK'),
    r.Literal('SEK'),
    r.Literal('NOK'),
    r.Literal('CZK'),
    r.Literal('ILS'),
    r.Literal('HKD'),
    r.Literal('JPY'),
);

export interface MyArrowCredentialsDTO extends r.Static<typeof MyArrowCredentialsDTORuntype> {}
export const MyArrowCredentialsDTORuntype = r.Union(
    r.Record({
        client_id: r.String,
        client_secret: r.String,
        currency: MyArrowLocaleCurrencyDTORuntype,
    }),
);

export interface MyArrowCredentialsInputDTO extends r.Static<typeof MyArrowCredentialsInputDTORuntype> {}
export const MyArrowCredentialsInputDTORuntype = r.Record({
    client_id: r.String.optional(),
    client_secret: r.String.optional(),
    currency: MyArrowLocaleCurrencyDTORuntype.optional(),
});

export interface MyArrowResponseBodyDTO extends r.Static<typeof MyArrowResponseBodyDTORuntype> {}
export const MyArrowResponseBodyDTORuntype = r.Record({
    data: MyArrowCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
