import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { TechnicalParametersRuntype } from '../part/partBackendTypes';
import { UsedInResponseRuntype } from '../rfq';
import { RfQContextQueryParamsRuntype } from '../rfqContext';
import {
    ComponentSpecificationRuntype,
    ComponentsSearchResponseDTORuntype,
    IpnFormAndFitFormPatchValuesRuntype,
    OtsComponentFullRuntype,
} from './internalPartNumberBackendTypes';

export const internalPartNumberEndpoints = {
    'POST /components/search': endpoint({
        description:
            'Searches IPNs via elasticsearch, expected to be faster than GET /ipns/search, returns an array of OTS and Custom components',
        pathParams: r.Undefined,
        queryParams: r.Undefined,

        requestBody: r
            .Record({
                filters: r.Array(
                    r.Record({
                        field: r.String,
                        operator: r.String,
                        parameter: r.Unknown,
                    }),
                ),
                page_params: r.Number.optional().Or(r.Null),
                global_filter: r.String,
                page_size: r.Number.optional(),
                prioritise_preferred_ipns: r.Boolean.optional(),
            })
            .And(RfQContextQueryParamsRuntype),
        responseBody: ComponentsSearchResponseDTORuntype,
    }),

    'POST /components/import': endpoint({
        description: 'Imports components',
        pathParams: r.Undefined,
        queryParams: r.Record({
            live: r.Boolean.optional(),
        }),
        requestBody: r.Array(
            r.Record({
                component: r.Record({
                    type: r.Literal('OffTheShelf'),
                    data: r.Record({
                        parts: r.Array(
                            r.Record({
                                manufacturer: r.String.optional(),
                                mpn: r.String.optional(),
                            }),
                        ),
                    }),
                }),
                customer_part_numbers: r.Array(r.Unknown),
                description: r.String,
                category: r.String.nullable().optional(),
                internal_part_number: r.Record({
                    revision: r.String.nullable().optional(),
                    value: r.String,
                }),
                is_preferred: r.Boolean,
                is_restricted_to_customers: r.Boolean,
            }),
        ),
        responseBody: r.Unknown,
    }),

    'POST /ipns/search/linked-parts/bulk': endpoint({
        description: 'Returns an array of IpnWithMatchesFullPartRuntype that have the provided parts as linked parts',
        pathParams: r.Undefined,
        queryParams: r.Undefined,

        requestBody: r.Record({
            generic_parts: r.Array(r.String),
            off_the_shelf_parts: r.Array(r.String),
            rfq_context: r.Literal('WithinRfQ'),
            rfq_id: r.String,
        }),
        responseBody: r.Record({
            items: r.Array(OtsComponentFullRuntype),
        }),
    }),
    'POST /ipns/search/suggested-parts/bulk': endpoint({
        description:
            'Returns an array of IpnWithMatchesFullPartRuntype that have the provided parts in their suggestions',
        pathParams: r.Undefined,
        queryParams: r.Undefined,

        requestBody: r.Record({
            generic_parts: r.Array(r.String),
            incomplete_generic_parts: r.Array(TechnicalParametersRuntype),
            rfq_context: r.Literal('WithinRfQ'),
            rfq_id: r.String,
        }),

        responseBody: r.Record({
            items: r.Array(OtsComponentFullRuntype),
        }),
    }),
    'PATCH /ipns/:id/form-and-fit': endpoint({
        description: 'Updates edited form-and-fit for this ipn',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: IpnFormAndFitFormPatchValuesRuntype,
        responseBody: r.Null.optional(),
        invalidates: [
            'POST /ipns/bulk',
            'POST /parts/off-the-shelf/bulk',
            'GET /assemblies/:assemblyId/descendants',
            'POST /parts/availability',
            'POST /components/bulk',
            'POST /components/custom/bulk',
        ],
    }),
    'PATCH /ipns/:id': endpoint({
        description: 'Updates edited component specification for this ipn',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: ComponentSpecificationRuntype,
        responseBody: r.Null.optional(),
        invalidates: [
            'POST /components/search',
            'POST /components/bulk',
            'POST /ipns/search/linked-parts/bulk',
            'POST /ipns/bulk',
        ],
    }),
    'POST /ipns/search/generics/bulk': endpoint({
        description:
            'Returns an array of IpnWithMatchesFullPartRuntype with matching component spec for each generic part id',
        pathParams: r.Undefined,
        queryParams: r.Undefined,

        requestBody: r.Record({
            generic_parts: r.Array(r.String),
            rfq: r.String,
            rfq_context: r.Union(r.Literal('WithinRfQ'), r.Literal('OutsideRfQ')),
            rfq_id: r.String.optional(),
        }),
        responseBody: r.Record({
            items: r.Dictionary(r.Array(OtsComponentFullRuntype), r.String),
        }),
    }),

    'POST /ipns/assemblies/bulk': endpoint({
        description: 'Returns an array of assemblies in which the specified ipn have been used',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String), rfq_context: r.Literal('OutsideRfQ') }),
        responseBody: r.Record({
            data: UsedInResponseRuntype,
        }),
    }),
};
