import * as r from 'runtypes';
import { LanguageEnumRuntype } from '../../utils/i18n';

export interface UserDTO extends r.Static<typeof UserDTORuntype> {}
export const UserDTORuntype = r.Record({
    id: r.String,
    first_name: r.String,
    last_name: r.String,
    email: r.String,
    user_language: LanguageEnumRuntype,
    customer: r
        .Record({
            id: r.String,
        })
        .nullable(),
    position: r.String.nullable(),
    phone_number: r.String.nullable(),
});

export interface UserRolesDTO extends r.Static<typeof UserRolesDTORuntype> {}
export const UserRolesDTORuntype = r.Record({
    user_id: r.String,
    roles: r.Array(r.String).optional().nullable(),
});

export interface UserRolesUpdateDTO extends r.Static<typeof UserRolesUpdateDTORuntype> {}
export const UserRolesUpdateDTORuntype = r.Record({
    admin: r.Boolean,
});

export type UserRole = r.Static<typeof UserRoleRuntype>;
export const UserRoleRuntype = r.Union(r.Literal('Admin'), r.Literal('Member'));

export const allUserRoles = UserRoleRuntype.alternatives.map((alternative) => alternative.value);

export interface UserInviteDTO extends r.Static<typeof UserInviteDTORuntype> {}
export const UserInviteDTORuntype = r.Record({
    id: r.String,
    first_name: r.String,
    last_name: r.String,
    email: r.String,
    user_language: LanguageEnumRuntype,
    user_role: UserRoleRuntype,
    created_at: r.String,
    customer: r
        .Record({
            id: r.String,
        })
        .nullable(),
});

export interface UserInviteInsertDTO extends r.Static<typeof UserInviteInsertDTORuntype> {}
export const UserInviteInsertDTORuntype = r.Record({
    email: r.String,
    first_name: r.String,
    last_name: r.String,
    language: LanguageEnumRuntype.nullable(),
    user_role: UserRoleRuntype,
});

export interface UserUpdateDTO extends r.Static<typeof UserUpdateDTORuntype> {}
export const UserUpdateDTORuntype = r.Record({
    first_name: r.String,
    last_name: r.String,
    phone_number: r.String.nullable(),
    position: r.String.nullable(),
});

export interface CurrentUserUpdateDTO extends r.Static<typeof CurrentUserUpdateDTORuntype> {}
export const CurrentUserUpdateDTORuntype = r.Record({
    user_language: LanguageEnumRuntype,
});
