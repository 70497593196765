function clientConfig() {
    const BACKEND_HOST: string = window.origin;
    const HOST_NAME = window.location.host;
    const BACKEND_BASE = BACKEND_HOST + '/api';
    const WEBSOCKET_BASE = BACKEND_BASE.replace(/^http/, 'ws');

    return {
        BACKEND_HOST,
        HOST_NAME,
        BACKEND_BASE,
        WEBSOCKET_BASE,
    };
}

function devServerConfig() {
    const BACKEND_HOST = process.env.BACKEND_API_HOST ?? 'https://ci-master.dev.luminovo.ai';
    const HOST_NAME = new URL(BACKEND_HOST).host;
    const BACKEND_BASE = BACKEND_HOST + '/api';
    const WEBSOCKET_BASE = BACKEND_BASE.replace(/^http/, 'ws');

    return {
        BACKEND_HOST,
        HOST_NAME,
        BACKEND_BASE,
        WEBSOCKET_BASE,
    };
}

function serverConfig() {
    const BACKEND_HOST = 'http://backend-core:5000/';
    const HOST_NAME = 'localhost:8080';
    const BACKEND_BASE = BACKEND_HOST + '/api';
    const WEBSOCKET_BASE = BACKEND_BASE.replace(/^http/, 'ws');

    return {
        BACKEND_HOST,
        HOST_NAME,
        BACKEND_BASE,
        WEBSOCKET_BASE,
    };
}

export const config = (() => {
    // In Node.js environments, we need to distinguish between gateway and e2e-tests
    if (typeof window === 'undefined') {
        // Gateway uses serverConfig in production, devServerConfig in development
        // E2e-tests always use devServerConfig to connect to the test environment
        const isGateway = process.env.SERVICE_NAME === 'gateway';
        if (isGateway) {
            return process.env.NODE_ENV === 'development' ? devServerConfig() : serverConfig();
        }
        // For e2e-tests and other Node.js environments
        return devServerConfig();
    }
    return clientConfig();
})();
