import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    FullSourcingDTORuntype,
    SolutionConfigurationExcelOriginInfoRuntype,
    SourcingCalculationResponseRuntype,
    SourcingScenarioDTORuntype,
    SourcingScenarioPatchDTORuntype,
    SourcingScenarioPostDTORuntype,
} from './sourcingScenarioBackendTypes';

export const sourcingScenarioEndpoints = {
    'GET /sourcing-scenarios': endpoint({
        description: 'Returns a list of all sourcing scenarios',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(SourcingScenarioDTORuntype) }),
    }),

    'POST /sourcing-scenarios/bulk': endpoint({
        description: 'Returns a list of sourcing scenarios given their ID',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String) }),
        responseBody: r.Record({ items: r.Array(SourcingScenarioDTORuntype) }),
    }),

    'POST /sourcing-scenarios/sourcing-full/bulk': endpoint({
        description: 'Returns a list of sourcing full given their ID',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String) }),
        responseBody: r.Record({ items: r.Array(FullSourcingDTORuntype) }),
    }),

    'POST /sourcing/calculation': endpoint({
        description: 'Returns the sourcing calculation',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                type: r.Literal('Rfq'),
                data: r.String,
            }),
            r.Record({
                type: r.Literal('SourcingScenarios'),
                data: r.Array(r.String),
            }),
        ),
        responseBody: SourcingCalculationResponseRuntype,
    }),

    'POST /sourcing-scenarios': endpoint({
        description: 'Creates a new sourcing scenario',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SourcingScenarioPostDTORuntype,
        responseBody: r.Record({ data: SourcingScenarioDTORuntype }),
        invalidates: [
            'GET /rfqs/:rfqId',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
            'GET /calculations/scenario-combinations',
            'GET /calculations/calculation-assembly-costs',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'GET /panels',
            'POST /sourcing/calculation',
        ],
        removes: ['POST /sourcing-scenarios/bulk'],
    }),

    'PATCH /sourcing-scenarios/:sourcingScenarioId': endpoint({
        description: 'Updates a sourcing scenario',
        pathParams: r.Record({ sourcingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: SourcingScenarioPatchDTORuntype,
        responseBody: r.Record({ data: SourcingScenarioDTORuntype }),
        invalidates: [
            'GET /rfqs/:rfqId',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
            'GET /calculations/scenario-combinations',
            'GET /calculations/calculation-assembly-costs',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'GET /panels',
            'POST /sourcing/calculation',
        ],
    }),

    'DELETE /sourcing-scenarios/:sourcingScenarioId': endpoint({
        description: 'Delete a sourcing scenario',
        pathParams: r.Record({ sourcingScenarioId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.String,
        invalidates: [
            'GET /rfqs/:rfqId',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
            'GET /calculations/calculation-assembly-costs',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'GET /panels',
            'POST /sourcing/calculation',
        ],
        removes: [
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
            'GET /calculations/scenario-combinations',
            'POST /sourcing/calculation',
        ],
    }),

    'POST /sourcing-scenarios/request-information': endpoint({
        description: 'used to request required sourcing information from user',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            sourcing_scenarios: r.Array(r.String),
        }),
        responseBody: r.Unknown,
    }),

    'POST /sourcing-scenarios/excel-origins/bulk': endpoint({
        description: 'Fetches the excel origin for a solution configuration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.String),
        }),
        responseBody: r.Record({
            items: r.Array(SolutionConfigurationExcelOriginInfoRuntype),
        }),
    }),
};
