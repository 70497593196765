import { t, Trans } from '@lingui/macro';
import { Flexbox, PrimaryButton, SecondaryButton, Tooltip } from '@luminovo/design-system';
import * as React from 'react';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { ScrapQuantityHandlers } from './solutionConfigurationTypes';

export function useScrapQuantityHandlers(): ScrapQuantityHandlers {
    const [isEditable, setIsEditable] = React.useState(false);
    const [scrapQuantities, setScrapQuantities] = React.useState<Record<string, number | null>>({});

    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Scrap quantity updated`,
        onSuccess: () => setIsEditable(false),
    });

    const getScrapQuantity = React.useCallback((id: string) => scrapQuantities[id], [scrapQuantities]);

    const setScrapQuantity = React.useCallback((id: string, newQuantity: number | null) => {
        setScrapQuantities((prevQuantities) => ({
            ...prevQuantities,
            [id]: newQuantity,
        }));
    }, []);

    const setAllScrapQuantities = React.useCallback(
        async (ids: string[], newQuantity: number | null) => {
            await mutateAsync({
                requestBody: {
                    items: ids.map((id) => ({ id, update: { manual_scrap_quantity: newQuantity } })),
                },
            });
        },
        [mutateAsync],
    );

    const enableEditing = React.useCallback(() => setIsEditable(true), []);

    const saveChanges = React.useCallback(async () => {
        await mutateAsync({
            requestBody: {
                items: Object.entries(scrapQuantities).map(([id, manual_scrap_quantity]) => ({
                    id,
                    update: { manual_scrap_quantity },
                })),
            },
        });
    }, [mutateAsync, scrapQuantities]);

    const cancelEditing = React.useCallback(() => {
        setIsEditable(false);
        setScrapQuantities({});
    }, []);

    return {
        getScrapQuantity,
        setScrapQuantity,
        setAllScrapQuantities,
        isLoading,
        isEditable,
        enableEditing,
        saveChanges,
        cancelEditing,
    };
}

export const EditScrapQuantityButton: React.FunctionComponent<{
    handlers: ScrapQuantityHandlers;
    solutionConfigurationIds: string[];
}> = ({ handlers, solutionConfigurationIds }) => {
    const { isEditable, isLoading, enableEditing, cancelEditing, saveChanges, setAllScrapQuantities } = handlers;

    const handleSetAllToDefault = () => {
        setAllScrapQuantities(solutionConfigurationIds, null);
    };

    const handleSetAllToZero = () => {
        setAllScrapQuantities(solutionConfigurationIds, 0);
    };

    return (
        <Flexbox gap={8} alignItems={'center'}>
            {isEditable ? (
                <>
                    <Flexbox alignItems={'center'}>
                        <Tooltip title={t`Set all scrap quantities to zero.`}>
                            <SecondaryButton
                                size="medium"
                                onClick={handleSetAllToZero}
                                style={{ marginRight: 8 }}
                                disabled={isLoading}
                            >
                                {t`Set all to zero`}
                            </SecondaryButton>
                        </Tooltip>
                        <Tooltip title={t`Set all scrap quantities to the default value.`}>
                            <SecondaryButton
                                size="medium"
                                onClick={handleSetAllToDefault}
                                style={{ marginRight: 8 }}
                                disabled={isLoading}
                            >
                                {t`Reset to default`}
                            </SecondaryButton>
                        </Tooltip>
                    </Flexbox>
                    <SecondaryButton
                        size="medium"
                        onClick={cancelEditing}
                        style={{ marginRight: 8 }}
                        disabled={isLoading}
                    >
                        {t`Cancel`}
                    </SecondaryButton>
                    <PrimaryButton size="medium" onClick={saveChanges} isLoading={isLoading}>
                        {t`Save`}
                    </PrimaryButton>
                </>
            ) : (
                <Tooltip title={t`Editing the scrap quantity may change the solution.`} arrow>
                    <SecondaryButton size="medium" onClick={enableEditing}>
                        <Trans>Edit scrap qty</Trans>
                    </SecondaryButton>
                </Tooltip>
            )}
        </Flexbox>
    );
};
