import { t, Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { ChipGroup, colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { SourcingScenarioDTO, Tag } from '@luminovo/http-client';
import { isSolutionInNeedOfUpdate, visibleSolutionPreferences } from '@luminovo/sourcing-core';
import { Sync } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { AlertLoadingRfqOffers } from '../../../../components/Alerts';
import { useAssemblies } from '../../../../resources/assembly/assemblyHandler';
import { useForcedOfferUpdate } from '../../../../resources/rfq/rfqHandler';

const NotUpToDateOfferInformation: React.FunctionComponent<{
    rfqId: string;
    solutionConfigurations: { solutionTags: Tag[] | undefined }[];
}> = ({ rfqId, solutionConfigurations }): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useForcedOfferUpdate(rfqId);

    const count = solutionConfigurations.filter((item) => {
        return isSolutionInNeedOfUpdate({ solutionTags: item.solutionTags ?? [] });
    }).length;

    return (
        <Flexbox gap={8} alignItems={'center'} justifyContent={'flex-end'}>
            <Text variant="body" color={colorSystem.neutral[7]}>
                {t`${count} of your selected solutions are not up to date.`}
            </Text>
            <Tooltip title={t`Update API offers`}>
                <SecondaryButton size="small" onClick={() => mutateAsync()} isLoading={isLoading} startIcon={<Sync />}>
                    <Trans>Update offers</Trans>
                </SecondaryButton>
            </Tooltip>
        </Flexbox>
    );
};
export const SourcingConfigurationContentHeader: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    solutionConfigurations: { solutionTags: Tag[] | undefined }[];
}> = ({ rfqId, sourcingScenarioDTO, solutionConfigurations }): JSX.Element => {
    const solutionPreferences = visibleSolutionPreferences(sourcingScenarioDTO.solution_preference);
    const { data: assemblyDetails = [] } = useAssemblies(
        sourcingScenarioDTO.assembly_quantities.items.map((item) => item.assembly),
    );

    return (
        <Flexbox gap={40} justifyContent={'space-between'} alignItems={'center'}>
            <Flexbox flexDirection={'column'} gap={10}>
                <Flexbox gap={12} alignItems={'center'} flexWrap={'wrap'}>
                    <Text variant="h3">{sourcingScenarioDTO.name}</Text>
                    <ChipGroup
                        options={assemblyDetails}
                        getOptionLabel={(option) => {
                            const quantity = sourcingScenarioDTO.assembly_quantities.items?.find(
                                (a) => a.assembly === option.id,
                            )?.quantity;
                            return `${formatDecimal(quantity)}x ${option.designator}`;
                        }}
                    />
                </Flexbox>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {solutionPreferences.join(' • ')}
                </Text>
            </Flexbox>
            <Box width={'50%'}>
                <AlertLoadingRfqOffers
                    rfqId={rfqId}
                    partIds={null}
                    Placeholder={() => (
                        <NotUpToDateOfferInformation rfqId={rfqId} solutionConfigurations={solutionConfigurations} />
                    )}
                />
            </Box>
        </Flexbox>
    );
};
