import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type TiLocaleCurrencyDTO = r.Static<typeof TiLocaleCurrencyDTORuntype>;
export const TiLocaleCurrencyDTORuntype = r.Union(
    r.Literal('USD'),
    r.Literal('EUR'),
    r.Literal('GBP'),
    r.Literal('JPY'),
    r.Literal('AUD'),
    r.Literal('CNY'),
    r.Literal('CHF'),
);

export interface TiCredentialsDTO extends r.Static<typeof TiCredentialsDTORuntype> {}
export const TiCredentialsDTORuntype = r.Union(
    r.Record({
        client_id: r.String,
        client_secret: r.String,
        currency: TiLocaleCurrencyDTORuntype,
    }),
);

export interface TiCredentialsInputDTO extends r.Static<typeof TiCredentialsInputDTORuntype> {}
export const TiCredentialsInputDTORuntype = r.Record({
    client_id: r.String.optional(),
    client_secret: r.String.optional(),
    currency: TiLocaleCurrencyDTORuntype.optional(),
});

export interface TiResponseBodyDTO extends r.Static<typeof TiResponseBodyDTORuntype> {}
export const TiResponseBodyDTORuntype = r.Record({
    data: TiCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
