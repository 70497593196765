import { alElektronikEndpoints } from './alElektronikEndpoints';
import { arrowEndpoints } from './arrowEndpoints';
import { avnetAbacusEndpoints } from './avnetAbacusEndpoints';
import { avnetEbvEndpoints } from './avnetEbvEndpoints';
import { avnetSilicaEndpoints } from './avnetSilicaEndpoints';
import { avnetUsaEndpoints } from './avnetUsaEndpoints';
import { blumeEndpoints } from './blumeEndpoints';
import { buerklinEndpoints } from './buerklinEndpoints';
import { chipCartEndpoints } from './chipCartEndpoints';
import { corestaffEndpoints } from './corestaffEndpoints';
import { digikeyEndpoints } from './digikeyEndpoints';
import { element14Endpoints } from './element14Endpoints';
import { eveEndpoints } from './eveEndpoints';
import { farnellEndpoints } from './farnellEndpoints';
import { futureEndpoints } from './futureEndpoints';
import { gudecoEndpoints } from './gudecoEndpoints';
import { lcscEndpoints } from './lcscEndpoints';
import { masterElectronicsEndpoints } from './masterElectronicsEndpoints';
import { mouserEndpoints } from './mouserEndpoints';
import { myArrowEndpoints } from './myArrowEndpoints';
import { newarkEndpoints } from './newarkEndpoints';
import { onlineComponentsEndpoints } from './onlineComponentsEndpoints';
import { orbweaverEndpoints } from './orbweaverEndpoints';
import { pcbManufacturersEndpoints } from './pcbManufacturersEndpoints';
import { questComponentsEndpoints } from './questComponentsEndpoints';
import { rochesterEndpoints } from './rochesterEndpoints';
import { rsComponentsEndpoints } from './rsComponentsEndpoints';
import { rutronikEndpoints } from './rutronikEndpoints';
import { samtecEndpoints } from './samtecEndpoints';
import { schukatEndpoints } from './schukatEndpoints';
import { sourcengineEndpoints } from './sourcengineEndpoints';
import { tiEndpoints } from './tiEndpoints';
import { tmeEndpoints } from './tmeEndpoints';
import { trustedPartsEndpoints } from './trustedPartsEndpoints';
import { ttiEndpoints } from './ttiEndpoints';
import { venkelEndpoints } from './venkelEndpoints';
import { weltronEndpoints } from './weltronEndpoints';
import { winsourceEndpoints } from './winsourceEndpoints';
import { wuerthEisosEndpoints } from './wuerthEisosEndpoints';

export const thirdPartyEndpoints = {
    ...alElektronikEndpoints,
    ...arrowEndpoints,
    ...avnetAbacusEndpoints,
    ...avnetEbvEndpoints,
    ...avnetSilicaEndpoints,
    ...avnetUsaEndpoints,
    ...blumeEndpoints,
    ...buerklinEndpoints,
    ...chipCartEndpoints,
    ...digikeyEndpoints,
    ...element14Endpoints,
    ...eveEndpoints,
    ...farnellEndpoints,
    ...futureEndpoints,
    ...gudecoEndpoints,
    ...lcscEndpoints,
    ...masterElectronicsEndpoints,
    ...sourcengineEndpoints,
    ...mouserEndpoints,
    ...myArrowEndpoints,
    ...newarkEndpoints,
    ...corestaffEndpoints,
    ...onlineComponentsEndpoints,
    ...orbweaverEndpoints,
    ...pcbManufacturersEndpoints,
    ...questComponentsEndpoints,
    ...rochesterEndpoints,
    ...rsComponentsEndpoints,
    ...rutronikEndpoints,
    ...samtecEndpoints,
    ...schukatEndpoints,
    ...tiEndpoints,
    ...tmeEndpoints,
    ...trustedPartsEndpoints,
    ...ttiEndpoints,
    ...weltronEndpoints,
    ...venkelEndpoints,
    ...wuerthEisosEndpoints,
    ...winsourceEndpoints,
};
