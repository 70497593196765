import { omit } from '@luminovo/commons';
import { ToggleButtonGroupProps, ToggleButtonProps } from '@mui/material';
import * as React from 'react';
import { Text } from '../../Text';
import { ToggleButton, ToggleButtonItem } from '../../ToggleButton';

export type FieldToggleButtonProps<TValue extends ToggleButtonProps['value']> = Omit<
    ToggleButtonGroupProps,
    'onChange'
> & {
    options: TValue[];
    getOptionLabel: (value: TValue) => string;
    onChange: (value: TValue) => void;
    getDisabled?: (value: TValue) => boolean;
};

function FieldToggleButtonInner<TValue extends ToggleButtonProps['value']>(
    { value, options, getOptionLabel, getDisabled, onChange, placeholder, ...rest }: FieldToggleButtonProps<TValue>,
    ref: React.ForwardedRef<unknown>,
): JSX.Element {
    return (
        <ToggleButton ref={ref} {...omit(rest, 'helperText', 'error')}>
            {options.map((option, index) => (
                <ToggleButtonItem
                    key={index}
                    selected={option === value}
                    disabled={getDisabled ? getDisabled(option) : false}
                    value={option}
                    onClick={() => onChange(option)}
                >
                    <Text variant="body" color="inherit">
                        {getOptionLabel(option)}
                    </Text>
                </ToggleButtonItem>
            ))}
        </ToggleButton>
    );
}

export const FieldToggleButton = React.forwardRef(FieldToggleButtonInner) as <
    TValue extends ToggleButtonProps['value'],
>(
    props: FieldToggleButtonProps<TValue> & { ref?: React.ForwardedRef<unknown> },
) => JSX.Element;
